<template>
  <a href="https://recrutement.popvalet.com/">
    <div :class="[`cta_devenir_valet-1`, className || ``]">
      <div class="devenir-valet blogtextbold">DEVENIR VALET</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "CtaDevenirValet2",
  props: ["className"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .cta_devenir_valet-1.cta_devenir_valet-2 {
    width: 80%;
    margin: auto auto 50px auto;
  }
  .cta_devenir_valet-1.cta_devenir_valet-4 {
    left: 50%;
    transform: translate(-50%, 0);
    top: 325px;
  }
  .cta_devenir_valet-1.cta_devenir_valet-3 {
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: 0;
    top: 930px;
    position: absolute;
  }
}

@media screen and (min-width: 992px) {
  .cta_devenir_valet-1.cta_devenir_valet-2 {
    margin-left: 298px;
    width: 205px;
  }
  .cta_devenir_valet-1.cta_devenir_valet-4 {
    left: 427px;
    top: 175px;
  }
  .cta_devenir_valet-1.cta_devenir_valet-3 {
    left: unset;
    margin-left: 47px;
    top: unset;
    position: unset;
  }
}

.cta_devenir_valet-1 {
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  gap: 10px;
  left: 1075px;
  padding: 12px 20px;
  justify-content: center;
  position: absolute;
  top: 20px;
  width: fit-content;
}

.devenir-valet {
  color: var(--bluenavy);
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.cta_devenir_valet-1.cta_devenir_valet-2 {
  height: 44px;
  left: unset;
  position: unset;
  top: unset;
}

.cta_devenir_valet-1.cta_devenir_valet-3 {
  margin-top: 361px;
}
</style>
