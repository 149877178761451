<template>
  <div class="pro-accueil-title-container">
    <div class="pro-accueil-title-background"></div>
    <div>
      <div class="pro-accueil-title-header-container">
        <div class="accroche_home_pro">
          <h1 class="la-solution-logistiq">La solution logistique clé en main pour les professionnels de <span class="underline-yellow">l’automobile</span></h1>
          <p class="transport-en-france">Transport en France et en Europe sous 24h</p>
        </div>
        <div class="key-picture">
          <div class="ellipse-29"></div>
          <img
              class="stock-photo-close-up"
              src="../../img/bfb12382-f27c-4985-b9b4-26c6080bf23d.png"
              alt="stock-photo-close-up-of-the-car-owner-s-hand-holding-the-delivery-key-to-buyers-concept-of-selling-cars-and-2183482591 2"
          />
        </div>
      </div>

        <div class="bloc-5services">
          <div class="services">
            <p class="address isidorasansalt-bold-magic-potion-28px">5 SERVICES POUR VOS BESOINS</p>
            <div class="gpe_services">
              <div class="x5services">
                <div class="group-218">
                  <div class="frame-210">
                    <div class="services_transport_valet">
                      <div class="bulle_valet">
                        <div class="group-169-1"><img class="icon_valet" src="../../img/icon-valet@2x.png" alt="icon_valet" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="trexte_services">
                    <div class="transport-valet">TRANSPORT VALET</div>
                    <div class="livraison-convoyage-jockeyage montserrat-normal-magic-potion-14px">Livraison, convoyage, jockeyage</div>
                  </div>
                </div>
                <div class="group-2">
                  <div class="frame-210">
                    <div class="services_transport_valet">
                      <div class="bulle_valet">
                        <div class="group-169">
                          <div class="icon_valet-1" :style="{ 'background-image': 'url(' + require('../../img/truck@2x.png') + ')' }"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="trexte_services-1">
                    <div class="transport-camion isidorasansalt-bold-magic-potion-15px">TRANSPORT CAMION</div>
                    <p class="dpanneuse-plateau-porte-5-ou-8 montserrat-normal-magic-potion-14px">Dépanneuse, plateau, porte 5 ou 8</p>
                  </div>
                </div>
                <div class="group-2">
                  <div class="frame-210">
                    <div class="services_transport_valet">
                      <div class="bulle_valet">
                        <div class="group-169">
                          <img
                              class="user-interface-repair-tool"
                              src="../../img/user-interface---repair-tool@2x.png"
                              alt="User Interface / Repair Tool"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="trexte_services-1">
                    <div class="transport-camion isidorasansalt-bold-magic-potion-15px">RÉPARATION</div>
                    <p class="dpanneuse-plateau-porte-5-ou-8 montserrat-normal-magic-potion-14px">Mécanique, carosserie, reconditionnement</p>
                  </div>
                </div>
                <div class="group-2">
                  <div class="frame-210">
                    <div class="services_transport_valet">
                      <div class="bulle_valet">
                        <div class="group-169"><img class="icon-search" src="../../img/expertise_voiture.png" alt="icon-search" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="trexte_services-1">
                    <div class="transport-camion isidorasansalt-bold-magic-potion-15px">EXPERTISE</div>
                    <p class="dpanneuse-plateau-porte-5-ou-8 montserrat-normal-magic-potion-14px">Sur site, à distance ou combinée</p>
                  </div>
                </div>
                <div class="group-2">
                  <div class="frame-210">
                    <div class="services_transport_valet">
                      <div class="bulle_valet">
                        <div class="group-169"><img class="icon-refresh" src="../../img/user-interface---reload@2x.png" alt="icon-refresh" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="trexte_services-1">
                    <div class="transport-camion isidorasansalt-bold-magic-potion-15px">RESTITUTION</div>
                    <p class="dpanneuse-plateau-porte-5-ou-8 montserrat-normal-magic-potion-14px">Sur parc ou centre automobile</p>
                  </div>
                </div>
              </div>
              <div class="cta">
                <div class="cta_ouvrir_compte cta_ouvrir_compte-1" @click="scrollToContactForm()">
                  <div class="ouvrir-un-compte blogtextbold">OUVRIR UN COMPTE</div>
                </div>
                <div class="group-71" @click="openChat()">
                  <div class="frame-72">
                    <div class="tre-recont-act blogtextbold">ÊTRE RECONTACTÉ</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "BlocProAccueilTitle",
  methods: {
    scrollToContactForm () {
      document.querySelector('#devenez-client').scrollIntoView({
        behavior: 'smooth'
      });
    },
    openChat() {
      const iframe = document.querySelector('#front-chat-iframe');
      if (iframe) {
        window.FrontChat('show');
      } else {
        console.log('Error, chat is not detected.')
      }
    }
  }
}
</script>

<style scoped>

.pro-accueil-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pro-accueil-title-background {
  background-color: var(--bluemed2);
  width: 100%;
  height: 542px;
  position: absolute;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 10px;
  text-underline-offset: -18px;
  text-decoration-color: var(--mustard);
}

.pro-accueil-title-header-container {
  margin: auto;
  max-width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 440px;
}

.key-picture {
}

.ellipse-29 {
  background-color: var(--mustard);
  position: relative;
  top: 201px;
  left: 26px;
  border-radius: 227.5px;
  height: 455px;
  width: 455px;
}

.stock-photo-close-up {
  position: relative;
  top: -455px;
}

.accroche_home_pro {
  display: flex;
  flex-direction: column;
  gap: 46px;
  width: 560px;
  margin-top: 120px;
  z-index: 0;
}

.la-solution-logistiq {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: 39px;
  font-weight: 600;
  line-height: normal;
  width: 558px;
}

.transport-en-france {
  color: var(--bluenavy);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-weight: 400;
  height: 29px;
  letter-spacing: 0;
  line-height: normal;
  width: 535px;
}

.bloc-5services {
  position: relative;
  background-color: var(--blanc);
  border-radius: 8px;
  box-shadow: 0px 4px 24px #00000014;
  margin: 0 8px 0 8px;
  padding: 60px 0 60px 0;
}

.services {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 54px;
}

.address {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
}

.gpe_services {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 54px;
  width: fit-content;
}

.x5services {
  align-items: flex-start;
  display: flex;
  gap: 22px;
  width: fit-content;
  flex-wrap: wrap;
  justify-content: center;
}

.group-218 {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 162px;
}

.frame-210 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 68px;
  margin-left: 59px;
  width: 68px;
}

.services_transport_valet {
  height: 68px;
  min-width: 68px;
}

.bulle_valet {
  display: flex;
  width: 68px;
}

.group-169-1 {
  align-items: flex-end;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  height: 68px;
  justify-content: flex-end;
  min-width: 68px;
  padding: 11.5px 10.1px;
}

.icon_valet {
  height: 45px;
  width: 46px;
}

.trexte_services {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 66px;
  width: 186px;
}

.transport-valet {
  color: var(--black);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 15px;
  font-weight: 700;
  height: 22px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  text-align: center;
  white-space: nowrap;
  width: 186px;
}

.livraison-convoyage-jockeyage {
  height: 36px;
  letter-spacing: 0;
  line-height: 19.6px;
  text-align: center;
  width: 186px;
}

.group-2 {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 162px;
  position: relative;
}

.group-169 {
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  height: 68px;
  width: 68px;
}

.icon_valet-1 {
  background-size: 100% 100%;
  height: 37px;
  margin-left: 12px;
  margin-top: 15px;
  width: 44px;
}

.user-interface-repair-tool {
  height: 41px;
  margin-left: 13px;
  margin-top: 15px;
  width: 41px;
}

.icon-search {
  height: 32px;
  margin-left: 12px;
  margin-top: 19px;
}

.icon-refresh {
  height: 41px;
  margin-left: 14px;
  margin-top: 13px;
  width: 41px;
}

.cta {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
  justify-content: center;
  position: relative;
  width: fit-content;
}



.trexte_services-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 66px;
  width: 186px;
}

.transport-camion,
.reparation,
.expertise,
.restitution {
  height: 22px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  text-align: center;
  white-space: nowrap;
  width: 186px;
}

.dpanneuse-plateau-porte-5-ou-8,
.mcanique-carosseri,
.sur-site-distance-ou-combine,
.sur-parc-ou-centre-automobile {
  height: 36px;
  letter-spacing: 0;
  line-height: 19.6px;
  text-align: center;
  width: 186px;
}

.cta_ouvrir_compte {
  display: flex;
  height: 44px;
  left: 1073px;
  position: absolute;
  top: 17px;
  width: 247px;
  cursor: pointer;
}

.ouvrir-un-compte {
  background-color: var(--bluenavy);
  border-radius: 34px;
  padding: 12px 40px;
  color: var(--blanc);
  line-height: normal;
  text-align: center;
  width: fit-content;
}

.ouvrir-un-compte:hover {
  background-color: var(--yellow);
  color: var(--bluenavy);
}

.cta_ouvrir_compte.cta_ouvrir_compte-1 {
  left: unset;
  min-width: 247px;
  position: unset;
  top: unset;
  width: unset;
}

.cta_ouvrir_compte.cta_ouvrir_compte-3,
.cta_ouvrir_compte.cta_ouvrir_compte-4 {
  left: 1071px;
}


.group-71 {
  display: flex;
  height: 44px;
  min-width: 244px;
}

.frame-72 {
  align-items: flex-start;
  background-color: var(--blanc);
  color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  padding: 12px 30px;
  width: 244px;
  cursor: pointer;
}

.frame-72:hover {
  background-color: var(--yellow);
}

.tre-recont-act {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

@media screen and (max-width: 1200px) {
  .pro-accueil-title-header-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    height: 680px;
  }
  .accroche_home_pro {
    margin-top: 50px;
  }
  .transport-en-france {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .pro-accueil-title-header-container {
    height: 460px;
  }
  .accroche_home_pro {
    width: 100%;
    max-width: 420px;
  }
  .la-solution-logistiq {
    font-size: 28px;
    width: 100%;
    max-width: 420px;
  }
  .key-picture {
    width: 300px;
    height: 300px;
  }
  .ellipse-29 {
    width: 300px;
    height: 300px;
    top: 107px;
    left: 5px;
  }
  .stock-photo-close-up {
    width: 300px;
    height: 300px;
    top: -310px;
  }
}
</style>