<template>
  <div class="cta_ouvrir_compte">
    <a href="/professionnels#devenez-client" style="text-decoration: none"><div class="ouvrir-un-compte blogtextbold">OUVRIR UN COMPTE</div></a>
  </div>
</template>

<script>
export default {
  name: "CtaOuvrirCompte",
  props: ["className"],
};
</script>

<style>
.cta_ouvrir_compte {
  display: flex;
  height: 44px;
}

.ouvrir-un-compte {
  background-color: var(--bluenavy);
  border-radius: 34px;
  padding: 12px 40px;
  line-height: normal;
  text-align: center;
  width: fit-content;
  color: var(--blanc);
}

.ouvrir-un-compte:hover {
  background-color: var(--yellow);
  color: #002244;
}
</style>
