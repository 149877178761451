<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Restitution sur parc de vos véhicules professionnels" :pro-bulle-yellow="'pro_bulle_yellow_couv'" :pro-title3="'pro_title_3'" :icon-url="require('../../img/Reload.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Vous êtes un <a href="/professionnels" style="color: #002244">professionnel de l’automobile</a> et recherchez une solution de restitution de vos véhicules sur parc ? Fort de ses 20 parcs de stockage répartis sur tout le territoire, Pop Valet facilite votre logistique et vous aide à optimiser à la fois votre transport, vos coûts, ainsi que les interventions nécessaires sur vos véhicules.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Prestations, cas pratiques, services additionnels : présentation.</p>
    </div>
    <div class="pro-services-restitution-gestion-container">
      <h2 class="isidorasansalt-bold-white-28px">La gestion de vos restitutions</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Un confort optimisé et un espace accru : c’est la promesse que vous adresse Pop Valet pour toutes vos restitutions de voitures. Les modalités de gestion assurées par nos équipes incluent :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Le handling d’entrée : chez Pop Valet, nous assurons la manutention de vos voitures directement dans nos parcs de stockage, avec enregistrement des véhicules à la fois dans nos systèmes et dans ceux du parc</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Le stockage de vos véhicules : votre véhicule est stocké dans un parc sécurisé et adapté aux transporteurs jusqu’à 60 jours</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- La gestion administrative : Pop Valet assure la gestion administrative à chaque étape de votre restitution de véhicule (communication avec le loueur, arrêt du contrat de location, transmission du PV de restitution, mise à disposition du véhicule au transporteur…)</p>
    </div>
    <div class="pro-services-restitution-cas-usage-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Quelques cas d’usage de restitution de véhicules</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Concrètement, à quels moments de vos process commerciaux nos solutions de restitution sur parc interviennent-elles ? Pop Valet répond présent pour :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos renouvellements : livraison d’un véhicule et collecte du précédent à restituer</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos fin de location : restitution d’un véhicule sans renouvellement du contrat</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos buy-back : restitution d’un véhicule au constructeur ou au distributeur</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Un cas pratique pour comprendre notre service de restitution de voiture sur parc</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Le concessionnaire automobile Auto Ultra travaille avec plusieurs loueurs de voitures. Il est contacté par le loueur Car Drive. Pour le compte de son client, la société A, Car Drive va passer commande auprès du concessionnaire Auto Ultra pour lui acheter une voiture BMW X1. Car Drive va demander à Auto Ultra de livrer la voiture au client, la société A.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Le loueur Car Drive ne paiera le concessionnaire Auto Ultra que lorsque ce dernier aura effectivement réalisé la livraison et qu’il aura reçu le procès-verbal de livraison. Car Drive va alors devoir restituer à Auto Ultra le véhicule que le concessionnaire lui avait loué au cours des 36 derniers mois, en échange de ce véhicule neuf.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Le concessionnaire Auto Ultra va solliciter Pop Valet pour livrer sa voiture et reprendre l’ancien véhicule. Mais dans ce cas de figure, Auto Ultra exige de Pop Valet non pas qu’il rapatrie la voiture à la concession mais que, à l’inverse, il la conduise dans un parc de stockage. Auto Ultra délègue donc à Pop Valet toute la gestion de la restitution.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Auto Ultra choisit Pop Valet pour que ce dernier, grâce à ses accords conclus avec des parcs de stockage, fasse le lien avec le loueur Car Drive pour informer ce dernier que le véhicule a bien été rendu et, ainsi, mettre fin au précédent contrat de location.</p>
      <h4 class="isidorasansalt-bold-magic-potion-20px">Une gestion de la restitution de A à Z de la part de Pop Valet</h4><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Concrètement, c’est Pop Valet qui va remplir le PV de restitution mais également le transmettre au loueur Car Drive. Pop Valet va indiquer à Car Drive dans lequel de ses parcs vient d’être stocké le véhicule. Ensuite, Pop Valet va se charger des relances auprès de Car Drive pour que ce dernier organise la récupération de son véhicule.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">La voiture restera donc sur le parc de stockage jusqu’à ce que le loueur ait envoyé un camion pour récupérer ladite voiture. Tant que la voiture n’a pas été récupérée et qu’elle demeure sur le parc de stockage, la situation est à la charge de Pop Valet, puisque ce dernier a été choisi pour gérer la restitution de A à Z.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">En conclusion, Pop Valet s’occupe de faire rentrer le véhicule sur parc, de le faire sortir du parc, et de s’assurer que la liaison soit faite entre les différents partis.</p>
    </div>
    <div class="pro-services-restitution-additionnels-container">
      <h2 class="isidorasansalt-bold-white-28px">Au-delà de votre restitution de véhicule : nos services additionnels</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Découvrez quelques-uns des services optionnels pouvant être intégrés aux restitutions assurées par Pop Valet :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Réparation du véhicule : inclut vos réparations mécaniques (amortisseurs, bougies d’allumage, courroies de distribution, freins, plaquettes…) et vos réparations de carrosserie (à la suite d’un accident de la route, d’un accrochage, d’un acte de vandalisme, ou tout simplement des conséquences de l’usure du véhicule…)</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Reconditionnement du véhicule : expertise approfondie de votre véhicule d’occasion par un professionnel qualifié et remise en état dans le but de certifier son bon fonctionnement</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Préparation esthétique avant revente : prestation de car staging avec traitement de la carrosserie (lavage, décontamination mécanique, polissage, lustrage, protection…) et traitement de l’habitacle (désinfection des moquettes, des tissus et du ciel de toit, rénovation de la sellerie de cuir, entretien des plastiques…)</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Expertise à distance : analyse du véhicule sur photographies avec chiffrage des frais de remise en état, que vous soyez loueur ou gestionnaire</p>
    </div>
    <div class="pro-services-restitution-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Découvrez nos autres services logistiques complets pour les professionnels de l’automobile :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a> pour tous vos besoins en livraison, convoyage ou jockeyage</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-voiture-camion" style="color: #002244">transport par camion</a> pour vos déplacements de plusieurs véhicules en simultané</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/reparation" style="color: #002244">révision, réparation et reconditionnement</a> pour tous vos véhicules neufs ou d’occasion</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service <a href="/expertise" style="color: #002244">d’expertise sur site ou à distance</a> pour toute analyse de vos véhicules</p><br>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletProServicesRestitutionInfo",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Restitution sur Parc de vos Véhicules Professionnels | Pop Valet',
    meta: [
      { name: 'description', content: 'Vous êtes un professionnel de l’auto en quête d\'une solution de restitution de vos véhicules sur parc ? Découvrez nos prestations et nos services additionnels.' },
    ]
  }
}
</script>

<style scoped>
.pro-services-restitution-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-restitution-plus-loin-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-restitution-additionnels-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-restitution-additionnels-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-restitution-cas-usage-container h4 {
  margin-top: 25px;
}
.pro-services-restitution-cas-usage-container h3 {
  margin-top: 25px;
}
.pro-services-restitution-cas-usage-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-restitution-cas-usage-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-restitution-gestion-container h3 {
  margin-top: 25px;
}
.pro-services-restitution-gestion-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-restitution-gestion-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-restitution-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-restitution-additionnels-container {
    padding: 100px 50px;
  }
  .pro-services-restitution-cas-usage-container {
    padding: 100px 50px;
  }
  .pro-services-restitution-gestion-container {
    padding: 100px 50px;
  }
}
</style>