<template>
  <div class="container-center-horizontal">
    <div class="accueil-popvalet-pro screen">
      <common-sur-header />
      <common-header
          :nav-items="header.navItems"
          :cta-buttons="header.ctaButtons"
      />
      <bloc-pro-accueil-title/>
      <bloc-nos-temoignages class="pro-home-nostemoignages"></bloc-nos-temoignages>
      <bloc-logos-clients/>
      <bloc-avantages
        :reactivitProps="blocAvantagesProps.reactivitProps"
        :qualitProps="blocAvantagesProps.qualitProps"
      />
      <div class="bloc_technologie">
        <div class="overlap-group7">
          <rserver-un-transport22 :children="rserverUnTransport22Props.children" :link="rserverUnTransport22Props.link" />
          <img class="mockup_popvalet-1" src="../../img/mockup-popvalet-1.png" alt="mockup_popvalet 1" />
          <div class="technologie_pictos_title">
            <p class="b-nficiez-de-la-tec isidorasansalt-bold-magic-potion-28px">
              <span class="isidorasansalt-bold-magic-potion-28px">{{ spanText3 }}</span><br>
              <span class="span-1 blogintro">{{ spanText4 }}</span>
            </p>
            <div class="bloc_technologie_pictos">
              <div class="bloc_technologie_pictos_col">
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/file-and-folder---approve-file@2x.png" alt="file_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Saisie en quelques clics
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/business---calendar@2x.png" alt="calendar_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Disponibilité en temps réel
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/user-interface---approve@2x.png" alt="approve_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Confirmation automatique
                  </div>
                </div>
              </div>
              <div class="bloc_technologie_pictos_col">
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/device---screen@2x.png" alt="device_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    État des lieux digitalisés
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/location-and-map---location-pin@2x.png" alt="location_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Suivi en temps réel
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/user-interface---like-1@2x.png" alt="like_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Recueil des avis
                  </div>
                </div>
              </div>
              <div class="bloc_technologie_pictos_col">
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/business---goal@2x.png" alt="business_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Affectation automatique
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img">
                    <img src="../../img/communication---send-mail@2x.png" alt="mail_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    Envoi automatique des documents
                  </div>
                </div>
                <div class="bloc_technologie_picto">
                  <div class="bloc_technologie_picto_img img_api">
                    <img src="../../img/communication---rss@2x.png" alt="communication_icon">
                  </div>
                  <div class="bloc_technologie_picto_txt">
                    API documentée
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile-notre-technologie">
              <img src="../../img/mockup-popvalet-1.png" alt="mockup-popvalet" class="mockup-mobile-popvalet">
              <a href="/technologie" style="text-decoration: none; color: var(--grey);"><p>Notre technologie ></p></a>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc_reseau">
        <div class="overlap-group6">
          <p class="surname isidorasansalt-bold-magic-potion-28px">
            <span class="isidorasansalt-bold-magic-potion-28px">{{ spanText5 }}</span
            ><br><span class="span-1 blogintro">{{ spanText6 }}</span>
          </p>
          <div class="bloc_reseau_popvalet">
            <div class="x700-experts">
              <img
                  class="stock-photo-man-usin"
                  src="../../img/reseau-1.png"
                  alt="reseau-1"
              /><group124 :spanText1="x2500Valets1Props.group124Props.spanText1" :spanText2="x2500Valets1Props.group124Props.spanText2" />
            </div>
            <div class="x700-experts">
              <img
                class="stock-photo-man-usin"
                src="../../img/reseau-2.png"
                alt="reseau-2"
              /><group124 :spanText1="group124Props.spanText1" :spanText2="group124Props.spanText2" />
            </div>
            <div class="x700-experts">
              <img
                  class="stock-photo-man-usin"
                  src="../../img/reseau-3.png"
                  alt="reseau-3"
              /><group124 :spanText1="x2500Valets2Props.group124Props.spanText1" :spanText2="x2500Valets2Props.group124Props.spanText2" />
            </div>
            <div class="x700-experts">
              <img
                  class="stock-photo-man-usin"
                  src="../../img/reseau-4.png"
                  alt="reseau-4"
              /><group124 :spanText1="x2500Valets3Props.group124Props.spanText1" :spanText2="x2500Valets3Props.group124Props.spanText2" />
            </div>
          </div>
        </div>
      </div>
      <bloc-devenez-client />
      <div class="overlap-group11">
        <div class="bloc_envoi_site_valet">
          <div class="text_acces_valet">
            <p class="vous-souhaitez-conna isidorasansalt-bold-magic-potion-28px">
              <span class="isidorasansalt-bold-magic-potion-28px">{{ spanText7 }}</span
              ><br><span class="span-1 blogintro">{{ spanText8 }}</span>
            </p>
            <cta-devenir-valet :accsValet="ctaDevenirValetProps.accsValet" />
          </div>
          <div class="visuel_acces_valet">
            <div class="grou-container">
              <div class="group-216" :style="{ 'background-image': 'url(' + require('../../img/demi-cercle-yellow@2x.png') + ')' }"></div>
              <img
                class="stock-photo-cheerful"
                src="../../img/photo-cheerful-chauffeur-smiling-at-camera.png"
                alt="stock-photo-cheerful-chauffeur-smiling-at-camera-isolated-on-white-background-1989090218 2"
              />
            </div>
          </div>
        </div>
      </div>
      <bloc-maif :txt-assurance="textAssurance" />
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import TrexteServices from "./TrexteServices";
import Group71 from "./Group71";
import FlechesLogos from "./FlechesLogos";
import LogoTesla from "./LogoTesla";
import BlocLogosClients from "./BlocLogosClients";
import BlocAvantages from "./BlocAvantages";
import RserverUnTransport22 from "./RserverUnTransport22";
import Group111 from "./Group111";
import Group116 from "./Group116";
import X2500Valets from "./X2500Valets";
import Group124 from "./Group124";
import BlocDevenezClient from "./BlocDevenezClient";
import BlocMaif from "./BlocMaif";
import CtaDevenirValet from "./CtaDevenirValet";
import CommonFooter from "@/components/common/CommonFooter";
import BlocProAccueilTitle from "@/components/BlocProAccueilTitle";
import BlocNosTemoignages from "@/components/BlocNosTemoignages";
export default {
  name: "AccueilPopvaletPro",
  components: {
    BlocProAccueilTitle,
    BlocNosTemoignages,
    CommonHeader,
    CommonSurHeader,
    TrexteServices,
    Group71,
    FlechesLogos,
    LogoTesla,
    BlocLogosClients,
    BlocAvantages,
    RserverUnTransport22,
    Group111,
    Group116,
    X2500Valets,
    Group124,
    BlocDevenezClient,
    BlocMaif,
    CtaDevenirValet,
    CommonFooter,
  },
  props: [
    'header',
    "spanText1",
    "spanText2",
    "text2",
    "uneSuperQualitDe",
    "spanText3",
    "spanText4",
    "saisieEnQuelquesClics",
    "tatDesLieuxDigitaliss",
    "disponibilitEnTempsRel",
    "suiviEnTempsRel",
    "recueilDesAvis",
    "envoiAutomatiqueDesDocuments",
    "apiDocumente",
    "spanText5",
    "spanText6",
    "overlapGroup2",
    "enTantQueProfessi",
    "spanText7",
    "spanText8",
    "trexteServices1Props",
    "trexteServices2Props",
    "trexteServices3Props",
    "trexteServices4Props",
    "ctaOuvrirCompteProps",
    "group71Props",
    "blocAvantagesProps",
    "rserverUnTransport22Props",
    "group1112Props",
    "group1161Props",
    "group1113Props",
    "group1162Props",
    "group1114Props",
    "x2500Valets1Props",
    "group124Props",
    "x2500Valets2Props",
    "x2500Valets3Props",
    "ctaDevenirValetProps",
    "textAssurance"
  ],
};
</script>

<style>
.bloc_technologie_pictos_col {
  display: flex;
  margin-bottom: 21px;
}
.bloc_technologie_picto {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
}
.bloc_technologie_picto_img img {
  height: 32px;
  margin-left: 13px;
  margin-top: 12px;
  width: 31px;
}
.bloc_technologie_picto_img.img_api img {
  margin-left: 16px;
  margin-top: 8px;
}
.bloc_technologie_picto_img {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  width: 56px;
  margin-bottom: 10px;
}
.bloc_technologie_picto_txt {
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  color: var(--bluenavy);
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 154px;
}

@media screen and (max-width: 767px) {
  .bloc_technologie_picto {
    margin: 14px 4px;
  }
  .bloc_technologie_pictos_col {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .bloc_technologie_pictos_col {
    justify-content: center;
  }
  .accueil-popvalet-pro {
    width: 1440px;
  }
  .bloc_tmoignages {
    min-width: 1446px;
  }
  .overlap-group8 {
    width: 1440px;
  }
  .overlap-group2 {
    align-self: flex-start;
  }
  .bloc_technologie {
    min-width: 100%;
    height: unset;
    padding: unset;
  }
  .overlap-group11 {
    width: 100%;
  }
  .bloc_envoi_site_valet {
    width: 100%;
    flex-direction: column;
    height: unset;
  }
  .text_acces_valet {
    margin-left: unset;
    margin-top: 50px;
    width: 100%;
    gap: 100px;
    align-items: center;
  }
  .visuel_acces_valet {
    justify-content: center;
  }
  .vous-souhaitez-conna {
    width: 100%;
    padding: 15px;
  }
  .overlap-group7 {
    width: 100%;
    height: unset;
  }
  .b-nficiez-de-la-tec {
    margin-left: unset;
    width: 100%;
    height: unset;
    text-align: center;
    padding: 30px;
    margin-top: 40px;
  }
  .technologie_pictos_title {
    left: unset;
    position: unset;
    top: unset;
    width: 100%;
    height: unset;
    min-height: 490px;
    gap: 50px;
  }
  .flex-row {
    gap: 0;
    min-width: 100%;
    justify-content: center;
  }
  .mockup_popvalet-1 {
    display: none;
  }
  .mockup-mobile-popvalet {
    height: auto;
    width: 100%;
  }
  .mobile-notre-technologie {
    display: flex;
  }
  .pictos_technologie {
    width: 100%;
    justify-content: center;
  }
  .group-container-2 {
    min-width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .bloc_reseau {
    height: unset;
    min-width: 100%;
  }
  .overlap-group6 {
    width: 100%;
  }
  .surname {
    margin-right: unset;
    width: 100%;
    text-align: center;
  }
  .bloc_reseau_popvalet {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .bloc_reseau {
    height: 664px;
    min-width: 1442px;
  }
  .overlap-group6 {
    width: 1440px;
  }
  .surname {
    margin-right: 289px;
    width: 747px;
  }
  .bloc_reseau_popvalet {
    width: 1200px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .accueil-popvalet-pro {
    width: 1440px;
  }
  .bloc_tmoignages {
    min-width: 1446px;
  }
  .overlap-group8 {
    width: 1440px;
  }
  .overlap-group2 {
    align-self: flex-start;
  }
  .bloc_technologie {
    min-width: 1440px;
    height: 664px;
    padding: 72px 169px;
  }
  .overlap-group11 {
    width: 1442px;
  }
  .bloc_envoi_site_valet {
    width: 1440px;
    height: 407px;
  }
  .text_acces_valet {
    margin-left: 137px;
    margin-top: 109px;
    width: 627px;
    gap: 48px;
  }
  .vous-souhaitez-conna {
    width: 625px;
  }
  .overlap-group7 {
    width: 1041px;
    height: 505px;
  }
  .b-nficiez-de-la-tec {
    margin-left: 33px;
    width: 747px;
    height: 66px;
  }
  .technologie_pictos_title {
    left: 0;
    position: absolute;
    top: 0;
    width: 782px;
    height: 490px;
    gap: 37px;
  }
  .flex-row {
    gap: 0;
    min-width: 432px;
  }
  .mockup-mobile-popvalet {
    display: none;
  }
  .mobile-notre-technologie {
    display: none;
  }
  .pictos_technologie {
    width: 430px;
  }
  .group-container-2 {
    min-width: 423px;
  }
}

@media screen and (min-width: 1441px) {
  .accueil-popvalet-pro {
    width: 100%;
  }
  .bloc_tmoignages {
    min-width: 100%;
  }
  .overlap-group8 {
    width: 100%;
  }
  .overlap-group2 {
    align-self: center;
  }
  .bloc_technologie {
    min-width: 100%;
    justify-content: center;
    height: 664px;
    padding: 72px 169px;
  }
  .overlap-group11 {
    width: 100%;
  }
  .bloc_envoi_site_valet {
    width: 100%;
    justify-content: center;
    height: 407px;
  }
  .text_acces_valet {
    margin-left: 0;
    margin-top: 109px;
    width: 627px;
    gap: 48px;
  }
  .bloc_reseau {
    height: 664px;
    min-width: 1442px;
  }
  .overlap-group6 {
    width: 1440px;
  }
  .surname {
    margin-right: 289px;
    width: 747px;
  }
  .bloc_reseau_popvalet {
    width: 1200px;
  }
  .vous-souhaitez-conna {
    width: 625px;
  }
  .overlap-group7 {
    width: 1041px;
    height: 505px;
  }
  .b-nficiez-de-la-tec {
    margin-left: 33px;
    width: 747px;
    height: 66px;
  }
  .technologie_pictos_title {
    left: 0;
    position: absolute;
    top: 0;
    width: 782px;
    height: 490px;
    gap: 37px;
  }
  .flex-row {
    gap: 0;
    min-width: 432px;
  }
  .mockup-mobile-popvalet {
    display: none;
  }
  .mobile-notre-technologie {
    display: none;
  }
  .pictos_technologie {
    width: 430px;
  }
  .group-container-2 {
    min-width: 423px;
  }
}

.mobile-notre-technologie {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.mobile-notre-technologie p {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
}

.accueil-popvalet-pro {
  align-items: center;
  background-color: var(--blanc);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.bloc_tmoignages {
  align-items: flex-start;
  display: flex;
  height: 664px;
  margin-left: 6px;
  margin-top: 112px;
}

.overlap-group8 {
  align-items: center;
  background-color: var(--grisclair);
  display: flex;
  flex-direction: column;
  min-height: 664px;
  padding: 89px 115px;
}

.nos-tmoignages-ce-q {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 1px;
  min-height: 66px;
  text-align: center;
  width: 577px;
}

.span-1 {
  color: var(--bluenavy);
  font-weight: 400;
  line-height: 28px;
}

.text-2 {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: 94px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  margin-top: 14px;
  min-height: 49px;
  text-align: center;
  width: 62px;
}

.overlap-group2 {
  height: 90px;
  margin-top: 38px;
  position: relative;
  width: 1208px;
}

.une-super-qualit-de {
  left: 190px;
  letter-spacing: 0;
  line-height: 39.2px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 830px;
}

.noms {
  display: flex;
  height: 45px;
  margin-left: 3px;
  margin-top: 51px;
  width: 231px;
}

.bernard-thomas-directeur-tesla-france {
  height: 34.87px;
  margin-left: 18.9px;
  margin-top: 4.2px;
  width: 192.39px;
}

.overlap-group3 {
  height: 91px;
  margin-right: 23px;
  margin-top: 31px;
  position: relative;
  width: 351px;
}

.personnages {
  align-items: flex-start;
  display: flex;
  gap: 22px;
  height: 91px;
  left: 27px;
  min-width: 206px;
  position: absolute;
  top: 0;
}

.group-56 {
  border-radius: 46px/45.59px;
  height: 91px;
  width: 92px;
}

.group-72 {
  border: 4px solid;
  border-color: var(--bluenavy);
  border-radius: 46px/45.59px;
  height: 91px;
  width: 92px;
}

.logo_maif {
  align-items: flex-start;
  display: flex;
  gap: 213px;
  height: 68px;
  left: 0;
  min-width: 351px;
  position: absolute;
  top: 11px;
}

.overlap-group {
  align-items: flex-start;
  background-color: var(--blanc);
  border-radius: 34.5px/34.19px;
  display: flex;
  height: 68px;
  min-width: 69px;
  padding: 14px 8px;
}

.logo-maif {
  height: 40px;
  object-fit: cover;
  width: 53px;
}

.bloc_technologie {
  align-items: flex-end;
  background-color: var(--grisclair);
  display: flex;
}

.overlap-group7 {
  position: relative;
}

.mockup_popvalet-1 {
  height: 343px;
  left: 526px;
  position: absolute;
  top: 103px;
  width: 515px;
}

.technologie_pictos_title {
  display: flex;
  flex-direction: column;
}

.b-nficiez-de-la-tec {
  letter-spacing: 0;
  line-height: 28px;
}

.pictos_technologie {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 21px;
  min-height: 387px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  height: 251px;
}

.group-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 21px;
  min-height: 251px;
  width: 142px;
}

.group-11 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  width: 142px;
}

.group {
  display: flex;
  height: 56px;
  margin-left: 42px;
  position: relative;
  width: 56px;
}

.saisie-en-quelques-clics,
.tat-des-lieux-digitaliss,
.disponibilit-en-temps-rel,
.suivi-en-temps-rel {
  color: var(--bluenavy);
  font-weight: 400;
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 140px;
}

.overlap-group-1 {
  height: 115px;
  position: relative;
  width: 142px;
}

.group-11-1 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  left: 0;
  position: absolute;
  top: 0;
  width: 142px;
}

.group-1 {
  background-color: var(--yellow);
  border-radius: 28px;
  height: 56px;
  margin-left: 42px;
  width: 56px;
}

.icon-desktop,
.location-and-map-location-pin {
  height: 31px;
  left: 55px;
  position: absolute;
  top: 13px;
  width: 31px;
}

.group-container-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 21px;
  min-height: 251px;
  position: relative;
  width: 142px;
}

.group-119 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  margin-left: 2px;
  position: relative;
  width: 110px;
}

.recueil-des-avis {
  color: var(--bluenavy);
  font-weight: 400;
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 108px;
}

.group-container-2 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

.group-container-3 {
  height: 115px;
  position: relative;
  width: 293px;
}

.group-121 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  left: 140px;
  position: absolute;
  top: 0;
  width: 153px;
}

.envoi-automatique-des-documents {
  color: var(--bluenavy);
  font-weight: 400;
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 154px;
}

.group-122 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  width: 122px;
}

.group-115 {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-left: 31px;
  width: 56px;
}

.icon-signal {
  height: 32px;
  margin-left: 19px;
  margin-top: 9px;
  width: 31px;
}

.api-documente {
  color: var(--bluenavy);
  font-weight: 400;
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 120px;
}

.bloc_reseau {
  align-items: flex-start;
  display: flex;
  margin-left: 2px;
}

.overlap-group6 {
  align-items: center;
  background-color: var(--blanc);
  display: flex;
  flex-direction: column;
  gap: 53px;
  min-height: 664px;
  padding: 81px 0;
}

.surname {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 6px;
  min-height: 66px;
}

.bloc_reseau_popvalet {
  display: flex;
  gap: 20px;
  position: relative;
}

.x700-experts {
  display: flex;
  flex-direction: column;
  height: 377px;
  position: relative;
  width: 285px;
}

.stock-photo-man-usin {
  height: 191px;
  width: 285px;
}

.overlap-group11 {
  margin-left: 2px;
  margin-top: 127px;
  position: relative;
}

.bloc_envoi_site_valet {
  background-color: #ffe88c75;
  display: flex;
  gap: 88px;
}

.text_acces_valet {
  display: flex;
  flex-direction: column;
  height: 198px;
  position: relative;
}

.vous-souhaitez-conna {
  height: 106px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}

.visuel_acces_valet {
  align-items: flex-start;
  display: flex;
  margin-top: 44px;
  min-width: 366.79px;
  padding: 0 0px;
}

.grou-container {
  height: 363px;
  position: relative;
  width: 367px;
}

.group-216 {
  background-size: 100% 100%;
  height: 219px;
  left: 19px;
  position: absolute;
  top: 143px;
  width: 348px;
}

.stock-photo-cheerful {
  height: 363px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 300px;
}
</style>
