<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Politique de confidentialité" :icon-url="require('../../../img/valet.png')"/>
    <bloc-privacy-policy-maif :assurance-url="assuranceUrl"></bloc-privacy-policy-maif>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonSurHeader from "@/components/common/CommonSurHeader.vue";
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import BlocPrivacyPolicyMaif from "@/components/maif/BlocPrivacyPolicyMaif.vue";
import GeneralHeader from "@/components/GeneralHeader.vue";

export default {
  name: 'PrivacyPolicyMaif',
  components: {
    GeneralHeader,
    BlocPrivacyPolicyMaif,
    CommonFooter,
    CommonHeader,
    CommonSurHeader
  },
  props: [
    'header',
    'assuranceUrl',
    'isMaif'
  ],
}
</script>

<style>

</style>