<template>
  <div class="organisation-container">
    <div class="overlap-group5-2">
      <div class="notre-organisation-title isidorasansalt-bold-white-28px">Fonctionnement de la solution</div>
      <div class="organisation-flow">
        <div class="organisation-bloc">
          <div class="organisation-bloc-rectangle organisation-bloc-1-rectangle"></div>
          <img class="schema_1" src="../../../img/schema-1_2x.webp" alt="schema_1" />
          <div class="sun1"></div>
          <div class="chaque-jour-des-vhic montserrat-normal-magic-potion-16px">
            Après avoir vérifié que le paiement auprès du garage ait été réalisé, informez celui-ci qu’un chauffeur viendra récupérer le véhicule.
          </div>
          <div class="rserver-un-transport-3">
            <div class="rserver-un-transport-4 isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Pré</span>alable</div>
          </div>
        </div>
        <div class="organisation-bloc">
          <div class="organisation-bloc-rectangle organisation-bloc-2-rectangle"></div>
          <div class="schema2">
            <img class="group-161" src="../../../img/group-161@2x.png" alt="Group 161" />
            <div class="group-160">
              <div class="group-156">
                <div class="overlap-group2-4">
                  <div class="group-153" :style="{ 'background-image': 'url(' + require('../../../img/ecommerce-web-page-rafiki@2x.png') + ')' }">
                    <div class="overlap-group-48">
                      <img class="device" src="../../../img/device-2@2x.png" alt="Device" />
                      <div class="rectangle-1"></div>
                      <img class="group-159" src="../../../img/group-159@2x.png" alt="Group 159" />
                      <img
                          class="pop_logotype_rvb-13"
                          src="../../../img/pop-logotype-rvb-1@2x.png"
                          alt="pop_logotype_rvb"
                      />
                      <img class="device-1" src="../../../img/device-3@2x.png" alt="Device" />
                      <img class="device-2" src="../../../img/device-4@2x.png" alt="Device" />
                    </div>
                  </div>
                  <img class="line-1-1" src="../../../img/line-1@2x.png" alt="Line 1" />
                  <img class="line-2" src="../../../img/line-2@2x.png" alt="Line 2" />
                  <img class="line-4-3" src="../../../img/line-4@2x.png" alt="Line 4" />
                  <img class="line-5" src="../../../img/line-5@2x.png" alt="Line 5" />
                  <img class="line-6-1" src="../../../img/line-6-1@2x.png" alt="Line 6" />
                  <img class="line-7-2" src="../../../img/line-7@2x.png" alt="Line 7" />
                  <img class="vector-28" src="../../../img/vector@2x.png" alt="Vector" />
                  <img class="vector-29" src="../../../img/vector-1@2x.png" alt="Vector" />
                  <div class="overlap-group1-16">
                    <img class="group-154" src="../../../img/group-154_2x.webp" alt="Group 154" />
                    <img class="device-3" src="../../../img/device-1@2x.png" alt="Device"/>
                  </div>
                  <img class="no-connectionpana" src="../../../img/no-connection-pana@2x.png" alt="no-connection/pana" />
                </div>
              </div>
            </div>
            <img class="device-4" src="../../../img/device@2x.png" alt="Device" />
          </div>
          <div class="organisation-bloc-2-text">
            <div class="pop-valet-recueille montserrat-normal-magic-potion-16px">
              Vous réservez un chauffeur professionnel sur notre site et réglez le coût de la prestation correspondante.
            </div>
          </div>
          <div class="notre-tecnologie">
            <div class="notre-technologie isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Réser</span>vation</div>
          </div>
        </div>
        <div class="organisation-bloc">
          <div class="organisation-bloc-rectangle organisation-bloc-3-rectangle"></div>
          <img class="schema3" src="../../../img/schema3_2x.webp" alt="schema3" />
          <div class="sun3"></div>
          <div class="organisation-bloc-3-text">
            <div class="notre-meilleur-valet montserrat-normal-magic-potion-16px">
              Un chauffeur professionnel se charge de récupérer votre véhicule et de le livrer à l’adresse de votre choix.
            </div>
          </div>
          <div class="savoirplus-6 voir-tous-les-uses-cases-6">
            <div class="en-savoir-plus isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Récup</span>ération</div>
          </div>
        </div>
      </div>
      <img class="fleche1" src="../../../img/fleche1@2x.png" alt="fleche1" />
      <img class="fleche2" src="../../../img/fleche2@2x.png" alt="fleche2" />
      <img class="fleche1-responsive" src="../../../img/fleche1-responsive.svg" alt="fleche1 responsive" />
      <img class="fleche2-responsive" src="../../../img/fleche2-responsive.svg" alt="fleche2 responsive" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocFonctionnementSolution"
}
</script>

<style scoped>
.organisation-container {
  background-color: var(--bluenavy);
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.overlap-group5-2 {
  max-width: 1440px;
}

.organisation-flow {
  margin-top: 150px;
  margin-bottom: 100px;
  display: flex;
}

.organisation-bloc {
  height: 340px;
  width: 420px;
}

.organisation-bloc-rectangle {
  background-color: var(--blanc);
  height: 340px;
  width: 420px;
}

.organisation-bloc-3-rectangle {
  border-radius: 0 8px 8px 0;
}

.organisation-bloc-3-text {
  display: flex;
  height: 97px;
  position: relative;
  left: 89px;
  top: -495px;
  width: 318px;
}

.notre-meilleur-valet {
  flex: 1;
  height: 74.98px;
  margin-left: 2.42%;
  margin-right: 2.63%;
  margin-top: 25.1px;
  width: 301.96px;
  text-align: center;
  line-height: 19.5px;
}

.schema3 {
  height: 289px;
  width: 296px;
  position: relative;
  top: -465px;
  left: 100px;
}

.sun3 {
  background-color: var(--yellow);
  border-radius: 10px;
  height: 20px;
  left: 338px;
  position: relative;
  top: -680px;
  width: 20px;
}

.organisation-bloc-1-rectangle {
  border-radius: 8px 0 0 8px;
}

.chaque-jour-des-vhic {
  height: 75px;
  left: 51px;
  position: relative;
  top: -360px;
  width: 313px;
  text-align: center;
  line-height: 19.5px;
}

.schema_1 {
  height: 177px;
  left: 14px;
  position: relative;
  top: -385px;
  width: 386px;
}

.sun1 {
  background-color: var(--yellow);
  border-radius: 10px;
  height: 20px;
  left: 134px;
  position: relative;
  top: -550px;
  width: 20px;
}

.organisation-bloc-2-text {
  display: flex;
  height: 97px;
  left: 75px;
  position: relative;
  top: -460px;
  width: 310px;
}

.pop-valet-recueille {
  width: 310px;
  text-align: center;
  line-height: 19.5px;
}

.schema2 {
  height: 301px;
  left: 0;
  position: relative;
  top: -480px;
  width: 438px;
}

.group-161 {
  height: 224px;
  left: 0;
  position: absolute;
  top: 77px;
  width: 438px;
}

.group-160 {
  display: flex;
  height: 290px;
  left: 69px;
  position: absolute;
  top: 0;
  width: 304px;
}

.group-156 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  height: 290.42px;
  justify-content: center;
  min-width: 100%;
}

.overlap-group2-4 {
  height: 290px;
  position: relative;
  width: 304px;
}

.group-153 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 254px;
  justify-content: flex-end;
  left: 0;
  min-width: 304px;
  padding: 9.2px 20.4px;
  position: absolute;
  top: 37px;
}

.overlap-group-48 {
  height: 163px;
  position: relative;
  width: 260px;
}

.device {
  height: 160px;
  left: 0;
  position: absolute;
  top: 0;
  width: 258px;
}

.rectangle-1 {
  background-color: var(--blanc);
  height: 22px;
  left: 0;
  position: absolute;
  top: 139px;
  width: 260px;
}

.group-159 {
  height: 52px;
  left: 35px;
  position: absolute;
  top: 108px;
  width: 61px;
}

.pop_logotype_rvb-13 {
  height: 21px;
  left: 113px;
  position: absolute;
  top: 142px;
  width: 110px;
}

.device-1 {
  height: 31px;
  left: 158px;
  position: absolute;
  top: 86px;
  width: 31px;
}

.device-2 {
  height: 31px;
  left: 210px;
  position: absolute;
  top: 35px;
  width: 31px;
}

.line-1-1 {
  height: 18px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 106px;
  width: 20px;
}

.line-2 {
  height: 8px;
  left: 84px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 19px;
}

.line-4-3 {
  height: 28px;
  left: 206px;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 33px;
}

.line-5 {
  height: 9px;
  left: 55px;
  object-fit: cover;
  position: absolute;
  top: 123px;
  width: 54px;
}

.line-6-1 {
  height: 22px;
  left: 129px;
  object-fit: cover;
  position: absolute;
  top: 117px;
  width: 55px;
}

.line-7-2 {
  height: 11px;
  left: 129px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 102px;
}

.vector-28 {
  height: 115px;
  left: 150px;
  position: absolute;
  top: 64px;
  width: 132px;
}

.vector-29 {
  height: 17px;
  left: 141px;
  position: absolute;
  top: 168px;
  width: 23px;
}

.overlap-group1-16 {
  height: 128px;
  left: 97px;
  position: absolute;
  top: 0;
  width: 153px;
}

.group-154 {
  height: 98px;
  left: 0;
  position: absolute;
  top: 0;
  width: 153px;
}

.device-3 {
  height: 31px;
  left: 3px;
  position: absolute;
  top: 97px;
  width: 31px;
}

.no-connectionpana {
  height: 31px;
  left: 24px;
  position: absolute;
  top: 118px;
  width: 31px;
}

.device-4 {
  height: 31px;
  left: 124px;
  position: absolute;
  top: 76px;
  width: 31px;
}

.fleche1 {
  height: 74px;
  left: 290px;
  position: relative;
  top: -467px;
  width: 180px;
}

.fleche2 {
  height: 45px;
  left: 643px;
  position: relative;
  top: -459px;
  width: 126px;
}

.fleche1-responsive {
  display: none;
}
.fleche2-responsive {
  display: none;
}

.notre-organisation-title {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 80px;
}

.savoirplus-6 {
  display: flex;
  height: 23px;
  margin-bottom: -10.5px;
  min-width: 158px;
}

.en-savoir-plus {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 158px;
}

.savoirplus-6.voir-tous-les-uses-cases-6 {
  display: flex;
  height: 23px;
  left: 168px;
  position: relative;
  top: -470px;
  width: 158px;
}

.rserver-un-transport-3 {
  display: flex;
  height: 22px;
  left: 105px;
  position: relative;
  top: -338px;
  width: 208px;
}

.rserver-un-transport-4 {
  text-align: center;
  width: 215px;
}

.rserver-un-transport-3.rserver-un-transport-5 .rserver-un-transport-4 {
  margin-left: unset;
  margin-right: unset;
  width: 208px;
}

.notre-tecnologie {
  display: flex;
  height: 22px;
  left: 95px;
  position: relative;
  top: -460px;
  width: 250px;
  justify-content: center;
}

.notre-technologie {
  text-align: center;
  width: 190px;
}

.notre-tecnologie.rserver-un-transport .notre-technologie {
  margin-left: 6.25%;
  margin-right: 6.25%;
  width: 182px;
}

@media screen and (max-width: 1260px) {
  .organisation-flow {
    flex-direction: column;
    gap: 170px;
    margin-bottom: 0;
  }
  .organisation-bloc-rectangle {
    border-radius: 8px;
  }

  .schema3 {
    left: 50px;
  }
  .sun3 {
    left: 280px;
  }
  .organisation-bloc-3-text {
    left: 50px;
  }
  .savoirplus-6.voir-tous-les-uses-cases-6 {
    left: 127px;
  }

  .organisation-bloc-2-text {
    left: 60px;
  }

  .fleche1, .fleche2 {
    display: none;
  }

  .fleche1-responsive, .fleche2-responsive {
    display: unset;
    position: relative;
  }
  .fleche1-responsive {
    top: -1060px;
    left: 20px;
  }
  .fleche2-responsive {
    top: -550px;
    left: 320px;
  }
}

@media screen and (max-width: 576px) {
  .notre-organisation-title {
    width: 270px;
  }
}

@media screen and (max-width: 576px) {
  .organisation-bloc {
    width: 320px;
  }
  .organisation-flow {
    transform: scale(0.8);
    margin-top: 0;
    margin-left: -66px;
  }
  .fleche1-responsive {
    top: -1010px;
    left: -20px;
  }
  .fleche2-responsive {
    top: -600px;
    left: 215px;
  }
}
</style>