<template>
  <div :class="[`notre-tecnologie`, className || ``]">
    <a :href="link" style="text-decoration: none"><div class="notre-technologie montserrat-medium-gunsmoke-18px">{{ children }}</div></a>
  </div>
</template>

<script>
export default {
  name: "RserverUnTransport22",
  props: ["children", "className", "link"],
};
</script>

<style>
.notre-tecnologie {
  display: flex;
  height: 22px;
  left: 674px;
  position: absolute;
  top: 483px;
  width: 250px;
}

.notre-technologie {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 7.69%;
  margin-right: 7.21%;
  text-align: center;
  width: 190px;
}

.notre-tecnologie.rserver-un-transport,
.notre-tecnologie.rserver-un-transport-1 {
  left: 125px;
  top: 410px;
}

.notre-tecnologie.rserver-un-transport .notre-technologie {
  margin-left: 6.25%;
  margin-right: 6.25%;
  width: 182px;
}

@media screen and (max-width: 991px) {
  .notre-tecnologie {
    display: none
  }
}
</style>
