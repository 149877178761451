<template>
  <div class="popvalet-general-qualite screen">
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Qualité de service" :icon-url="require('../../img/user-interface---like-6@2x.png')"/>
    <div class="popvalet-general-qualite-item">
      <div class="overlap-group10-4">
        <p class="lorem-ipsum-dolor-si-7 isidorasansalt-semi-bold-magic-potion-16px">Si autant de professionnels et de particuliers font confiance à la solution de transport automobile Pop Valet, c’est aussi et surtout pour la qualité des prestations que nous fournissons. Livraison, convoyage, jockeyage, transport par camion, réparation ou encore reconditionnement : toutes nos missions s’appuient sur un suivi méthodique et minutieux de leur déroulé.
          <br><br>
            De la sélection de nos chauffeurs jusqu’à notre pôle qualité dédié, découvrez les moyens que nous nous donnons pour atteindre nos exigences.
          <br><br>
          <a href="/qualite-transports" style="color: #002244">En savoir plus</a>
        </p>
      </div>
    </div>
    <div class="bloc_operateur_log">
      <div class="overlap-group9-3">
        <div class="overlap-group1-20">
          <img class="operateur-1" src="../../img/operateur-qualite.png" alt="operateur 1" />
          <div class="overlap-group-64">
            <div class="rectangle-298"></div>
            <div class="x92-de-scoring-moyen montserrat-semi-bold-magic-potion-22px">
              <span class="span-55 montserrat-semi-bold-magic-potion-22px">{{ spanText1 }}</span
              ><br><span class="span-56 montserrat-medium-magic-potion-14px">{{ spanText2 }}</span>
            </div>
            <div class="group-170"><img class="icon-thumbs_up-2" src="../../img/user-interface---like-4@2x.png" alt="icon-thumbs_up" /></div>
          </div>
        </div>
        <div class="flex-col-25">
          <div class="flex-col-26">
            <img class="icon-volume" src="../../img/user-interface---volume-up@2x.png" alt="icon-volume" />
            <div class="oprateur-logistique">{{ oprateurLogistique }}</div>
          </div>
          <div class="bloc_logistique_txt">
            <div class="bloc_logistique_txt_container" style="display: flex">
              <img src="../../img/user-interface---approve-tick--3@2x.png" alt="">
              <p>Collecte des disponibilités des valets en amont</p>
            </div>
            <div class="bloc_logistique_txt_container" style="display: flex">
              <img src="../../img/user-interface---approve-tick--3@2x.png" alt="">
              <p>Réception des missions confiées par des clients</p>
            </div>
            <div class="bloc_logistique_txt_container" style="display: flex">
              <img src="../../img/user-interface---approve-tick--3@2x.png" alt="">
              <p>Optimisation grâce à notre algorithme</p>
            </div>
            <div class="bloc_logistique_txt_container" style="display: flex">
              <img src="../../img/user-interface---approve-tick--3@2x.png" alt="">
              <p>Dispatch aux valets les plus perfomants (scoring)</p>
            </div>
            <div class="bloc_logistique_txt_container" style="display: flex">
              <img src="../../img/user-interface---approve-tick--3@2x.png" alt="">
              <p>Réalisation de la mission sous 24h</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bloc_selection">
      <div class="flex-row-20">
        <img class="vector-1-2" src="../../img/vector-1-1.png" alt="Vector 1" />
        <div class="overlap-group1-21">
          <img class="line-6-4" src="../../img/line-6@2x.png" alt="Line 6" />
          <div class="slection-formation isidorasansalt-bold-magic-potion-28px">{{ slectionFormation }}</div>
        </div>
      </div>
      <div class="overlap-group8-4">
        <div class="overlap-group-65">
          <div class="rectangle-298"></div>
          <div class="x4-de-candidats-retennus montserrat-semi-bold-magic-potion-22px">
            <span class="span-55 montserrat-semi-bold-magic-potion-22px">{{ spanText3 }}</span
            ><br><span class="span-56 montserrat-medium-magic-potion-14px">{{ spanText4 }}</span>
          </div>
          <div class="group-170"><img class="icon-thumbs_up-2" src="../../img/user-interface---like-4@2x.png" alt="icon-thumbs_up" /></div>
        </div>
        <img class="vector-2-1" src="../../img/vector-2-1.png" alt="Vector 2" />
        <div class="group-211">
          <div class="overlap-group-container-3">
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="candidatures-offres montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText5 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText6 }}</span>
              </p>
              <div class="overlap-group-66">
                <div class="number-6 montserrat-semi-bold-white-16px">{{ number1 }}</div>
              </div>
            </div>
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="formation-thorique montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText7 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText8 }}</span>
              </p>
              <div class="overlap-group-62">
                <div class="number-5 montserrat-semi-bold-white-16px">{{ number2 }}</div>
              </div>
            </div>
          </div>
          <div class="overlap-group-container-3">
            <div class="overlap-group3-7">
              <div class="rectangle-298-2"></div>
              <p class="tri-et-slection-app montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText9 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText10 }}</span>
              </p>
              <div class="overlap-group-62">
                <div class="number-5 montserrat-semi-bold-white-16px">{{ number3 }}</div>
              </div>
            </div>
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="formation-pratique-v montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText11 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText12 }}</span>
              </p>
              <div class="overlap-group-67">
                <div class="number-7 montserrat-semi-bold-white-16px">{{ number4 }}</div>
              </div>
            </div>
          </div>
          <div class="overlap-group-container-3">
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="entretien-vido-vali montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText13 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText14 }}</span>
              </p>
              <div class="overlap-group-62">
                <div class="number-5 montserrat-semi-bold-white-16px">{{ number5 }}</div>
              </div>
            </div>
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="bilan-et-dcision-va montserrat-semi-bold-magic-potion-16px">
                <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText15 }}</span
                ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText16 }}</span>
              </p>
              <div class="overlap-group-62">
                <div class="number-5 montserrat-semi-bold-white-16px">{{ number6 }}</div>
              </div>
            </div>
          </div>
          <div class="overlap-group-container-3">
            <div class="overlap-group-61">
              <div class="rectangle-298-1"></div>
              <p class="signature-du-contrat montserrat-semi-bold-magic-potion-16px">
              <span class="span-55 montserrat-semi-bold-magic-potion-16px">{{ spanText17 }}</span
              ><br><span class="span-56 montserrat-normal-magic-potion-12px">{{ spanText18 }}</span>
              </p>
              <div class="overlap-group-62">
                <div class="number-5 montserrat-semi-bold-white-16px">{{ number7 }}</div>
              </div>
            </div>
            <div class="overlap-group-65-mobile">
              <div class="rectangle-298"></div>
              <div class="x4-de-candidats-retennus montserrat-semi-bold-magic-potion-22px">
            <span class="span-55 montserrat-semi-bold-magic-potion-22px">{{ spanText3 }}</span
            ><br><span class="span-56 montserrat-medium-magic-potion-14px">{{ spanText4 }}</span>
              </div>
              <div class="group-170"><img class="icon-thumbs_up-2" src="../../img/user-interface---like-4@2x.png" alt="icon-thumbs_up" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bloc-notre-organisation />
    <div class="overlap-group13">
      <div class="overlap-group2-12">
        <div class="bloc_quali">
          <div class="group-212">
            <div class="contrle-qualit isidorasansalt-bold-magic-potion-30px">{{ contrleQualit }}</div>
            <div class="flex-row-21-mobile">
              <div style="display: flex">
                <img class="icon-check_mark-3" src="../../img/user-interface---approve-tick-@2x.png" alt="icon-check_mark" />
                <p class="convaincus-que-la-qu-mobile">Convaincus que la Qualité de service fera la différence à long terme sur notre métier, nous y avons dédié un pôle à part entière.</p>
              </div>
              <br>
              <div style="display: flex">
                <img class="icon-check_mark-3" src="../../img/user-interface---approve-tick-@2x.png" alt="icon-check_mark" />
                <p class="convaincus-que-la-qu-mobile">Chaque mission réalisée est contrôlée au niveau de sa conformité: respect des process (et donc des créneaux), des documents et des états des lieux.</p>
              </div>
              <br>
              <div style="display: flex">
                <img class="icon-check_mark-3" src="../../img/user-interface---approve-tick-@2x.png" alt="icon-check_mark" />
                <p class="convaincus-que-la-qu-mobile">Le taux de conformité est l'une des bases du calcul du scoring de chacun de nos Valets</p>
              </div>
            </div>
            <div class="flex-row-21">
              <div class="icon-check_mark-container-1">
                <img class="icon-check_mark-3" src="../../img/user-interface---approve-tick-@2x.png" alt="icon-check_mark" /><img
                  class="icon-check_mark-4"
                  src="../../img/user-interface---approve-tick-@2x.png"
                  alt="icon-check_mark"
                /><img class="icon-check_mark-5" src="../../img/user-interface---approve-tick-@2x.png" alt="icon-check_mark" />
              </div>
              <p class="convaincus-que-la-qu" v-html="convaincusQueLaQu"></p>
            </div>
          </div>
        </div>
        <div class="overlap-group-70">
          <div class="rectangle-298-3"></div>
          <p class="x97-de-nos-missions-sont-conformes montserrat-semi-bold-magic-potion-22px">
            <span class="span-55 montserrat-semi-bold-magic-potion-22px">{{ spanText19 }}</span
            ><br><span class="span-56 montserrat-medium-magic-potion-14px">{{ spanText20 }}</span>
          </p>
          <div class="group-170"><img class="icon-thumbs_up-2" src="../../img/education---badge-10@2x.png" alt="icon-thumbs_up" /></div>
        </div>
      </div>
      <div class="missions_pole_quali">
        <div class="group-238" :style="{ 'background-image': 'url(' + require('../../img/rectangle-299.png') + ')' }">
          <div class="missions_pole_quali_mobile">
            <div class="missions_pole_quali_mobile_title">
              <img style="height: 58px" src="../../img/user-interface---like-7@2x.png" alt="icon-thumbs_up" />
              <div style="margin-left: 15px" class="missions-du-ple-qualit">{{ missionsDuPleQualit }}</div>
            </div>
            <div>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">Organiser et contrôler les formations pratiques et théoriques de nos Valets</p>
              </div>
              <br>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">Evaluer régulièrement nos Valets sur le terrain</p>
              </div>
              <br>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">S'assurer que les procédures demandées par les clients soient respectées</p>
              </div>
              <br>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">Rappeler à l'ordre nos Valets lorsque nécéssaire et prendre des actions</p>
              </div>
              <br>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">S'assurer du respect des documents (lisibilité) et des états des lieux</p>
              </div>
              <br>
              <div style="display: flex; align-items: center">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" />
                <p class="organiser-et-contrl-mobile">Travailler à l'obtention des normes, dont ISO 9001</p>
              </div>
            </div>
          </div>
          <img class="icon-thumbs_up-1 missions_pole_quali_desktop" src="../../img/user-interface---like-7@2x.png" alt="icon-thumbs_up" />
          <div class="group-213 missions_pole_quali_desktop">
            <div class="missions-du-ple-qualit">{{ missionsDuPleQualit }}</div>
            <div class="flex-row-22">
              <div class="user-interface-approve-container">
                <img class="user-interface-approve-2" src="../../img/Approve.png" alt="User Interface / Approve" /><img
                  class="user-interface-approve"
                  src="../../img/Approve.png"
                  alt="User Interface / Approve"
              /><img
                  class="user-interface-approve-1"
                  src="../../img/Approve.png"
                  alt="User Interface / Approve"
              /><img class="user-interface-approve" src="../../img/Approve.png" alt="User Interface / Approve" /><img
                  class="user-interface-approve"
                  src="../../img/Approve.png"
                  alt="User Interface / Approve"
              /><img class="user-interface-approve-1" src="../../img/Approve.png" alt="User Interface / Approve" />
              </div>
              <p class="organiser-et-contrl" v-html="organiserEtContrl"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonHeader from "@/components/common/CommonHeader";
import Group170 from "./Group170";
import VoirTousLesUsesCases2 from "./VoirTousLesUsesCases2";
import RserverUnTransport from "./RserverUnTransport";
import RserverUnTransport22 from "./RserverUnTransport22";
import CommonFooter from "@/components/common/CommonFooter";
import blocNotreOrganisation from "@/components/BlocNotreOrganisation";
import GeneralHeader from "@/components/GeneralHeader";
export default {
  name: "PopvaletGeneralQualite",
  components: {
    GeneralHeader,
    CommonSurHeader,
    CommonHeader,
    blocNotreOrganisation,
    Group170,
    VoirTousLesUsesCases2,
    RserverUnTransport,
    RserverUnTransport22,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif",
    "qualitDeService",
    "spanText1",
    "spanText2",
    "oprateurLogistique",
    "collecteDesDisponi",
    "slectionFormation",
    "spanText3",
    "spanText4",
    "spanText5",
    "spanText6",
    "number1",
    "spanText7",
    "spanText8",
    "number2",
    "spanText9",
    "spanText10",
    "number3",
    "spanText11",
    "spanText12",
    "number4",
    "spanText13",
    "spanText14",
    "number5",
    "spanText15",
    "spanText16",
    "number6",
    "spanText17",
    "spanText18",
    "number7",
    "notreOrganisation",
    "contrleQualit",
    "convaincusQueLaQu",
    "spanText19",
    "spanText20",
    "missionsDuPleQualit",
    "organiserEtContrl",
    "voirTousLesUsesCases2Props",
    "rserverUnTransportProps",
    "rserverUnTransport22Props",
  ],
};
</script>

<style>
@media screen and (max-width: 767px) {
  .missions-du-ple-qualit {
    font-size: 20px;
  }
  .overlap-group-70 {
    top: 558px;
    left: 50%;
    transform: translate(-50%, 10px);
  }
  .missions_pole_quali {
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 50px;
    width: 100%;
    top: 750px;
  }
  .bloc_quali {
    width: 100%;
    height: 800px;
  }
  .overlap-group13 {
    height: 1815px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .missions-du-ple-qualit {
    font-size: 30px;
  }
  .overlap-group-70 {
    top: 370px;
    left: 50%;
    transform: translate(-50%, 10px);
  }
  .missions_pole_quali {
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 50px;
    width: 100%;
    top: 553px;
  }
  .bloc_quali {
    width: 100%;
    height: 664px;
  }
  .overlap-group13 {
    height: 1100px;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .missions_pole_quali_mobile_title {
    display: flex;
    margin: 25px 0;
    align-items: center;
    flex-direction: column;
  }
  .missions_pole_quali_mobile {
    padding: 25px;
    width: 100%;
  }
  .missions_pole_quali_desktop {
    display: none;
  }
  .group-238 {
    width: 100%;
  }
  .overlap-group5-6 {
    width: 1440px;
  }
  .overlap-group6-6 {
    left: 130px;
    top: 74px;
  }
  .overlap-group2-12 {
    width: 100%;
  }
  .couv_vision-1 {
    align-items: center;
    justify-content: center;
    min-width: 100%;
    padding: 0;
  }
  .popvalet-general-qualite-item {
    align-items: center;
    min-width: 100%;
  }
  .overlap-group10-4 {
    align-items: center;
    justify-content: center;
    min-width: 100%;
    padding: 25px;
  }
}

@media screen and (max-width: 1099px) {
  .flex-row-21 {
    display: none;
  }
  .group-212 {
    width: fit-content;
    margin: 50px 25px;
  }
  .overlap-group-64 {
    left: 50%;
    transform: translate(-77%, 77%);
  }
  .overlap-group9-3 {
    padding: 60px 10px;
  }
  .flex-col-26 {
    align-items: center;
  }
  .flex-col-25 {
    width: 100%;
    align-items: center;
  }
  .operateur-1 {
    height: 250px;
  }
  .bloc_operateur_log {
    position: relative;
  }
  .overlap-group1-20 {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .flex-row-21-mobile {
    display: none;
  }
  .flex-row-21 {
    display: flex;
  }
  .group-212 {
    width: 637px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  .overlap-group-64 {
    left: 0;
  }
  .flex-col-26 {
    align-items: flex-start;
  }
  .flex-col-25 {
    width: 473px;
    align-items: flex-start;
  }
  .operateur-1 {
    height: 429px;
    left: 68px;
    position: absolute;
    top: 0;
    width: 570px;
  }
  .overlap-group1-20 {
    height: 429px;
    position: relative;
    width: 638px;
  }
  .overlap-group9-3 {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    gap: 53px;
    height: 664px;
    width: 100%;
    padding: 117px 10px;
  }
  .bloc_operateur_log {
    align-items: flex-start;
    display: flex;
    width: 100%;
    position: relative;
  }
}

@media screen and (min-width: 992px) and (max-width: 1399px) {
  .missions-du-ple-qualit {
    font-size: 30px;
  }
  .overlap-group-70 {
    top: 370px;
    left: 50%;
    transform: translate(-50%, 10px);
  }
  .missions_pole_quali_mobile_title {
    display: flex;
    margin: 25px 0;
    align-items: center;
  }
  .missions_pole_quali_mobile {
    padding: 25px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
  }
  .missions_pole_quali_desktop {
    display: none;
  }
  .group-238 {
    width: 100%;
  }
  .overlap-group5-6 {
    width: 1440px;
  }
  .overlap-group6-6 {
    left: 130px;
    top: 74px;
  }
  .overlap-group13 {
    height: 936px;
    width: 100%;
  }
  .overlap-group2-12 {
    width: 100%;
  }
  .bloc_quali {
    width: 100%;
    height: 664px;
  }
  .missions_pole_quali {
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 50px;
    width: 100%;
    top: 553px;
  }
  .couv_vision-1 {
    align-items: center;
    justify-content: center;
  }
  .popvalet-general-qualite-item {
    align-items: center;
    min-width: 100%;
  }
  .overlap-group10-4 {
    padding: 90px 223px;
  }
}

@media screen and (max-width: 1224px) {
  .overlap-group8-4 {
    position: relative;
  }
  .overlap-group-65-mobile {
    position: absolute;
    bottom: 0;
  }
  .overlap-group-65 {
    display: none;
  }
  .group-211 {
    flex-direction: column;
  }
  .bloc_selection {
    align-items: center;
    padding: 88px 0;
  }
}

@media screen and (min-width: 1225px) and (max-width: 1399px) {
  .overlap-group-65-mobile {
    display: none;
  }
  .group-211 {
    align-items: flex-start;
    height: 367px;
    left: 0;
    min-width: 1197px;
    position: absolute;
    top: 0;
  }
  .overlap-group8-4 {
    height: 377px;
    position: relative;
    width: 1197px;
  }
  .bloc_selection {
    min-height: 664px;
    padding: 88px 120px;
  }
  .flex-row-20 {
    position: relative;
    right: 350px;
  }
  .bloc_selection {
    width: 100%;
    align-items: center;
  }
}

@media screen and (min-width: 1400px) {
  .missions-du-ple-qualit {
    font-size: 30px;
  }
  .flex-row-21-mobile {
    display: none;
  }
  .flex-row-21 {
    display: flex;
  }
  .group-213 {
    display: flex;
  }
  .missions_pole_quali_mobile {
    display: none;
  }
  .group-238 {
    width: 990px;
    height: 383px;
  }
  .couv_vision-1 {
    justify-content: center;
    align-items: flex-end;
    min-width: 1440px;
    padding: 110px 528px;
  }
  .popvalet-general-qualite-item {
    align-items: flex-start;
    min-width: 1442px;
    justify-content: center;
  }
  .overlap-group9-3 {
    width: 100%;
    justify-content: center;
  }
  .bloc_selection {
    width: 100%;
    align-items: center;
  }
  .flex-row-20 {
    position: relative;
    right: 350px;
  }
  .overlap-group5-6 {
    width: 100%;
  }
  .overlap-group6-6 {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .overlap-group13 {
    width: 100%;
    height: 936px;
  }
  .overlap-group2-12 {
    width: 100%;
  }
  .bloc_quali {
    width: 100%;
    height: 664px;
  }
  .missions_pole_quali {
    left: 50%;
    transform: translate(-50%, 0);
    width: 990px;
    height: 383px;
    top: 553px;
  }
  .overlap-group10-4 {
    align-items: flex-start;
    min-width: 1440px;
    padding: 90px 223px;
  }

  .overlap-group-65-mobile {
    display: none;
  }
  .group-211 {
    align-items: flex-start;
    height: 367px;
    left: 0;
    min-width: 1197px;
    position: absolute;
    top: 0;
  }
  .overlap-group8-4 {
    height: 377px;
    position: relative;
    width: 1197px;
  }
  .bloc_selection {
    min-height: 664px;
    padding: 88px 120px;
  }
  .overlap-group-64 {
    left: 0;
  }
  .flex-col-26 {
    align-items: flex-start;
  }
  .flex-col-25 {
    width: 473px;
    align-items: flex-start;
  }
  .operateur-1 {
    height: 429px;
    left: 68px;
    position: absolute;
    top: 0;
    width: 570px;
  }
  .overlap-group1-20 {
    height: 429px;
    position: relative;
    width: 638px;
  }
  .overlap-group9-3 {
    align-items: flex-start;
    display: flex;
    gap: 53px;
    height: 664px;
    min-width: 1440px;
    padding: 117px 72px;
  }
  .bloc_operateur_log {
    align-items: flex-start;
    display: flex;
    min-width: 1444px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .overlap-group-70 {
    right: 80px;
    top: 323px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1900px) {
  .overlap-group-70 {
    right: 190px;
    top: 323px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .group-212 {
    width: 637px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 118px;
  }
}

@media screen and (min-width: 1901px) {
  .group-212 {
    width: 637px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 118px;
  }
  .overlap-group-70 {
    right: 280px;
    top: 323px;
  }
}

.bloc_logistique_txt_container {
  margin-top: 18px;
}

.bloc_logistique_txt_container.mobile {
  display: flex;
}

.bloc_logistique_txt_container.mobile p {
  margin-left: 5px;
}

.bloc_logistique_txt_container p {
  color: white;
  align-items: center;
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: var(--font-size-s);
}

.bloc_logistique_txt_container img {
  height: 25px;
}

.popvalet-general-qualite {
  background-color: var(--blanc);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.couv_vision-1 {
  background-color: var(--zumthor);
  display: flex;
  height: 355px;
}

.overlap-group12-1 {
  height: 134px;
  position: relative;
  width: 384px;
}

.titre_couv_vision-1 {
  display: flex;
  height: 134px;
  left: 125px;
  position: absolute;
  top: 0;
  width: 134px;
}

.bulle_titre_vision-1 {
  background-color: var(--yellow);
  border-radius: 67px;
  display: flex;
  height: 134px;
  width: 134px;
}

.icon-thumbs_up {
  height: 56px;
  margin-left: 39px;
  margin-top: 18px;
  width: 56px;
}

.overlap-group-63 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 74px;
  width: 384px;
}

.qualit-de-service-2 {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 384px;
}

.popvalet-general-qualite-item {
  display: flex;
}

.overlap-group10-4 {
  background-color: var(--blanc);
  display: flex;
}

.lorem-ipsum-dolor-si-7 {
  letter-spacing: 0;
  line-height: 28.8px;
  min-height: 83px;
  text-align: center;
}

.overlap-group9-3 {
  background-color: var(--bluenavy);
}

.overlap-group-64 {
  height: 111px;
  position: absolute;
  top: 113px;
  width: 250px;
}

.rectangle-298 {
  background-color: var(--blanc);
  border-radius: 4px;
  box-shadow: 0px 4px 12px #0000001f;
  height: 92px;
  left: 25px;
  position: absolute;
  top: 19px;
  width: 225px;
}

.x92-de-scoring-moyen {
  left: 68px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 44px;
  width: 140px;
}

.span-55 {
  line-height: 24px;
}

.span-56 {
  line-height: 21px;
}

.flex-col-25 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 11px;
  min-height: 372px;
}

.flex-col-26 {
  display: flex;
  flex-direction: column;
  min-height: 117px;
  width: 282px;
}

.icon-volume {
  height: 71px;
  width: 71px;
}

.oprateur-logistique {
  color: var(--blanc);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: var(--font-size-xl);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45.9px;
  min-height: 46px;
  min-width: 282px;
  text-align: center;
  white-space: nowrap;
}

.flex-row-19 {
  align-items: center;
  display: flex;
  gap: 3px;
  height: 240px;
  min-width: 473px;
}

.icon-check_mark-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  min-height: 210px;
  width: 24px;
}

.icon-check_mark-1 {
  height: 24px;
  width: 24px;
}

.icon-check_mark-2 {
  height: 24px;
  margin-top: 18px;
  width: 24px;
}

.icon-check_mark {
  height: 24px;
  margin-top: 24px;
  width: 24px;
}

.collecte-des-disponi {
  color: var(--blanc);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 48px;
  min-height: 240px;
  min-width: 446px;
}

.bloc_selection {
  background-color: var(--blanc);
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.flex-row-20 {
  align-items: flex-start;
  display: flex;
  gap: 11px;
  height: 69px;
  margin-left: 76px;
  min-width: 351px;
}

.vector-1-2 {
  align-self: flex-end;
  height: 66px;
  width: 15px;
}

.overlap-group1-21 {
  height: 36px;
  position: relative;
  width: 325px;
}

.line-6-4 {
  height: 6px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 131px;
}

.slection-formation {
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 324px;
}

.overlap-group-65 {
  height: 111px;
  left: 937px;
  position: absolute;
  top: 200px;
  width: 250px;
}

.overlap-group-65-mobile {
  height: 111px;
  width: 250px;
}

.x4-de-candidats-retennus {
  left: 68px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 44px;
  width: 163px;
}

.vector-2-1 {
  height: 22px;
  left: 929px;
  position: absolute;
  top: 356px;
  width: 64px;
}

.group-211 {
  display: flex;
  gap: 35px;
}

.overlap-group-container-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 33px;
  min-height: 367px;
  width: 273px;
}

.overlap-group-61 {
  height: 167px;
  position: relative;
  width: 273px;
}

.rectangle-298-1 {
  background-color: var(--blanc);
  border-radius: 4px;
  box-shadow: 0px 4px 12px #0000001f;
  height: 150px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 256px;
}

.candidatures-offres,
.entretien-vido-vali,
.signature-du-contrat {
  left: 44px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 47px;
  width: 198px;
}

.overlap-group-66 {
  align-items: flex-end;
  background-color: var(--bluenavy);
  border-radius: 16.5px;
  display: flex;
  height: 33px;
  left: 0;
  min-width: 33px;
  padding: 4px 13px;
  position: absolute;
  top: 0;
}

.number-6 {
  letter-spacing: 0;
  line-height: 24px;
  min-width: 7px;
  white-space: nowrap;
}

.formation-thorique {
  left: 40px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 38px;
  width: 211px;
}

.overlap-group-62 {
  align-items: flex-end;
  background-color: var(--bluenavy);
  border-radius: 16.5px;
  display: flex;
  height: 33px;
  justify-content: flex-end;
  left: 0;
  min-width: 33px;
  padding: 4px 10px;
  position: absolute;
  top: 0;
}

.number-5 {
  letter-spacing: 0;
  line-height: 24px;
  min-width: 10px;
  white-space: nowrap;
}

.overlap-group3-7 {
  height: 167px;
  position: relative;
  width: 265px;
}

.rectangle-298-2 {
  background-color: var(--blanc);
  border-radius: 4px;
  box-shadow: 0px 4px 12px #0000001f;
  height: 150px;
  left: 9px;
  position: absolute;
  top: 17px;
  width: 256px;
}

.tri-et-slection-app {
  left: 47px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 47px;
  width: 196px;
}

.formation-pratique-v {
  left: 44px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 34px;
  width: 211px;
}

.overlap-group-67 {
  align-items: flex-end;
  background-color: var(--bluenavy);
  border-radius: 16.5px;
  display: flex;
  height: 33px;
  justify-content: flex-end;
  left: 0;
  min-width: 33px;
  padding: 4px 9px;
  position: absolute;
  top: 0;
}

.number-7 {
  letter-spacing: 0;
  line-height: 24px;
  min-width: 11px;
  white-space: nowrap;
}

.bilan-et-dcision-va {
  left: 44px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 34px;
  width: 198px;
}

.overlap-group5-6 {
  background-color: var(--bluenavy);
  height: 664px;
  position: relative;
}

.overlap-group6-6 {
  height: 480px;
  position: absolute;
  width: 1185px;
}

.overlap-group-68 {
  height: 464px;
  left: 749px;
  position: absolute;
  top: 16px;
  width: 436px;
}

.rectangle-24-3 {
  background-color: var(--blanc);
  border-radius: 8px;
  height: 338px;
  left: 0;
  position: absolute;
  top: 126px;
  width: 436px;
}

.bloc_schema_pro-2 {
  display: flex;
  height: 97px;
  left: 89px;
  position: absolute;
  top: 272px;
  width: 318px;
}

.notre-meilleur-valet-1 {
  height: 74.98px;
  margin-left: 9.1px;
  margin-top: 25.1px;
  width: 298.79px;
}

.sun3-1 {
  background-color: var(--yellow);
  border-radius: 10px;
  height: 20px;
  left: 338px;
  position: absolute;
  top: 77px;
  width: 20px;
}

.overlap-group1-22 {
  height: 384px;
  left: 0;
  position: absolute;
  top: 96px;
  width: 419px;
}

.rectangle-24-4 {
  background-color: var(--blanc);
  border-radius: 8px;
  height: 338px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 419px;
}

.chaque-jour-des-vhic-1 {
  height: 75px;
  left: 40px;
  position: absolute;
  top: 217px;
  width: 307px;
}

.sun1-1 {
  background-color: var(--yellow);
  border-radius: 10px;
  height: 20px;
  left: 134px;
  position: absolute;
  top: 15px;
  width: 20px;
}

.overlap-group4-7 {
  height: 480px;
  left: 364px;
  position: absolute;
  top: 0;
  width: 438px;
}

.rectangle-24-5 {
  background-color: var(--blanc);
  border-radius: 8px;
  height: 338px;
  left: 40px;
  position: absolute;
  top: 142px;
  width: 378px;
}

.bloc_schema_pro-3 {
  display: flex;
  height: 97px;
  left: 75px;
  position: absolute;
  top: 288px;
  width: 310px;
}

.pop-valet-recueille-1 {
  height: 74.98px;
  margin-left: 5.8px;
  margin-top: 25.1px;
  width: 296.54px;
}

.overlap-group3-8 {
  height: 301px;
  left: 0;
  position: absolute;
  top: 0;
  width: 438px;
}

.group-161-1 {
  height: 224px;
  left: 0;
  position: absolute;
  top: 77px;
  width: 438px;
}

.group-160-1 {
  display: flex;
  height: 290px;
  left: 69px;
  position: absolute;
  top: 0;
  width: 304px;
}

.group-156-1 {
  align-items: flex-start;
  display: flex;
  height: 290.42px;
  justify-content: center;
  min-width: 304.41px;
}

.overlap-group2-11 {
  height: 290px;
  position: relative;
  width: 304px;
}

.group-153-1 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 254px;
  justify-content: flex-end;
  left: 0;
  min-width: 304px;
  padding: 9.2px 20.4px;
  position: absolute;
  top: 37px;
}

.overlap-group-69 {
  height: 163px;
  position: relative;
  width: 260px;
}

.device-5 {
  height: 160px;
  left: 0;
  position: absolute;
  top: 0;
  width: 258px;
}

.rectangle-1-1 {
  background-color: var(--blanc);
  height: 22px;
  left: 0;
  position: absolute;
  top: 139px;
  width: 260px;
}

.group-159-1 {
  height: 52px;
  left: 35px;
  position: absolute;
  top: 108px;
  width: 61px;
}

.device-6 {
  height: 31px;
  left: 158px;
  position: absolute;
  top: 86px;
  width: 31px;
}

.device-7 {
  height: 31px;
  left: 210px;
  position: absolute;
  top: 35px;
  width: 31px;
}

.line-1-3 {
  height: 18px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 106px;
  width: 20px;
}

.line-2-1 {
  height: 8px;
  left: 84px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 19px;
}

.line-4-4 {
  height: 28px;
  left: 206px;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 33px;
}

.line-5-1 {
  height: 9px;
  left: 55px;
  object-fit: cover;
  position: absolute;
  top: 123px;
  width: 54px;
}

.line-6-5 {
  height: 22px;
  left: 129px;
  object-fit: cover;
  position: absolute;
  top: 117px;
  width: 55px;
}

.line-7-4 {
  height: 11px;
  left: 129px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 102px;
}

.vector-48 {
  height: 115px;
  left: 150px;
  position: absolute;
  top: 64px;
  width: 132px;
}

.vector-49 {
  height: 17px;
  left: 141px;
  position: absolute;
  top: 168px;
  width: 23px;
}

.overlap-group1-23 {
  height: 128px;
  left: 97px;
  position: absolute;
  top: 0;
  width: 153px;
}

.group-154-1 {
  height: 98px;
  left: 0;
  position: absolute;
  top: 0;
  width: 153px;
}

.device-8 {
  height: 31px;
  left: 3px;
  position: absolute;
  top: 97px;
  width: 31px;
}

.no-connectionpana-1 {
  height: 31px;
  left: 24px;
  position: absolute;
  top: 118px;
  width: 31px;
}

.device-9 {
  height: 31px;
  left: 124px;
  position: absolute;
  top: 76px;
  width: 31px;
}

.fleche1-1 {
  height: 74px;
  left: 260px;
  position: absolute;
  top: 111px;
  width: 180px;
}

.notre-organisation-1 {
  left: 222px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 74px;
  width: 267px;
}

.overlap-group13 {
  position: relative;
  margin-bottom: 150px;
}

.overlap-group2-12 {
  height: 664px;
  left: 0;
  position: absolute;
  top: 0;
}

.bloc_quali {
  background-color: var(--grisclair);
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
}

.group-212 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
  min-height: 362px;
  padding: 0;
}

.contrle-qualit {
  letter-spacing: 0;
  line-height: 49.2px;
  min-height: 50px;
  white-space: nowrap;
}

.flex-row-21 {
  align-items: flex-start;
  gap: 2px;
  height: 295px;
  min-width: 633px;
}

.icon-check_mark-container-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 7.68px;
  min-height: 216px;
  width: 26px;
}

.icon-check_mark-3 {
  height: 26px;
  width: 26px;
}

.icon-check_mark-4 {
  height: 26px;
  margin-top: 50px;
  width: 26px;
}

.icon-check_mark-5 {
  height: 26px;
  margin-top: 87px;
  width: 26px;
}

.convaincus-que-la-qu {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 34px;
  min-height: 295px;
  width: 604px;
}

.convaincus-que-la-qu-mobile {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: var(--font-size-xs);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 34px;
}

.overlap-group-70 {
  height: 111px;
  position: absolute;
  width: 327px;
}

.rectangle-298-3 {
  background-color: var(--blanc);
  border-radius: 4px;
  box-shadow: 0px 4px 12px #0000001f;
  height: 92px;
  left: 25px;
  position: absolute;
  top: 19px;
  width: 302px;
}

.x97-de-nos-missions-sont-conformes {
  left: 68px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 44px;
  width: 234px;
}

.missions_pole_quali {
  display: flex;
  position: absolute;
}

.group-238 {
  background-size: 100% 100%;
  display: flex;
  gap: 35.1px;
}

.icon-thumbs_up-1 {
  height: 58px;
  margin-left: 112.3px;
  margin-top: 35px;
  width: 58.18px;
}

.group-213 {
  align-items: flex-start;
  flex-direction: column;
  gap: 29px;
  margin-top: 35px;
  min-height: 314px;
  width: 755.28px;
}

.missions-du-ple-qualit {
  color: var(--blanc);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 49.2px;
  min-height: 39px;
  white-space: nowrap;
}

.flex-row-22 {
  align-items: center;
  display: flex;
  gap: 3px;
  height: 246px;
  margin-left: 1.1px;
  min-width: 750px;
}

.user-interface-approve-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  min-height: 230px;
  width: 26px;
}

.user-interface-approve-2 {
  height: 24px;
  width: 26px;
}

.user-interface-approve {
  height: 24px;
  margin-top: 16px;
  width: 26px;
}

.user-interface-approve-1 {
  height: 24px;
  margin-top: 19px;
  width: 26px;
}

.organiser-et-contrl {
  color: var(--blanc);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 41px;
  width: 720px;
}

.organiser-et-contrl-mobile {
  color: var(--blanc);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 41px;
  margin-left: 5px;
}
</style>
