<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="La qualité pour tous vos transports de véhicules sur route" :icon-url="require('../../img/user-interface---like-6@2x.png')"/>
    <div class="general-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Si autant de professionnels et de particuliers font confiance à la <a href="/" style="color: #002244">solution de transport automobile Pop Valet</a>, c’est aussi et surtout pour la qualité des prestations que nous fournissons. Livraison, convoyage, jockeyage, transport par camion, réparation ou encore reconditionnement : toutes nos missions s’appuient sur un suivi méthodique et minutieux de leur déroulé.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">De la sélection de nos chauffeurs jusqu’à notre pôle qualité dédié, découvrez les moyens que nous nous donnons pour atteindre nos exigences.</p>
    </div>
    <div class="general-qualite-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Les trois garants de la qualité Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’excellence des services de transports de voitures Pop Valet repose sur trois piliers : découvrez-les.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un opérateur logistique intégré</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Contrairement à ses confrères, Pop Valet ne sollicite ni plateformes externes, ni partenaires intermédiaires : nous réalisons l’ensemble de nos missions en tant qu’opérateur logistique intégré, ce qui nous permet de garder le contrôle sur l’ensemble de nos prestations de A à Z. Concrètement, nous maîtrisons les disponibilités de nos Valets et leur assignons les missions selon leurs performances.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des Valets scrupuleusement sélectionnés puis formés</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Seuls 4 % des personnes candidatant à un poste de chauffeur auprès de Pop Valet valident le processus dans son intégralité. Outre des prérequis liés au nombre de points sur le permis de conduire et aux années d’expérience derrière le volant, nous soumettons nos potentiels collaborateurs à un entretien vidéo ainsi qu’à une double formation, théorique puis pratique.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un contrôle qualité à chaque étape de vos missions</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Respect des délais, livraison des documents, signature des états des lieux : nous nous assurons que chaque partie de nos process soient dûment effectuées. 97 % de nos missions sont jugées parfaitement conformes par nos clients.</p>
    </div>
    <div class="general-qualite-missions-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Les missions du pôle qualité de Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Chez Pop Valet, nous avons acquis l’intime conviction que dans un secteur aussi concurrentiel que celui du transport de véhicules sur route, une qualité de service irréprochable constituait un critère de sélection au moins aussi important qu’une compétitivité tarifaire. C’est forts de cette certitude que nous avons tenu à dédier un pôle entièrement consacré à la qualité de nos prestations.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Les six leviers d’action de notre pôle qualité</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">La mission de notre service qualité s’articule en six grands pans :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- S’assurer que les procédures demandées par les clients soient bien respectées</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Garantir le respect des documents (y compris leur lisibilité) et des états des lieux</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Organiser et contrôler les formations pratique et théorique de nos Valets</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Évaluer régulièrement nos Valets sur le terrain</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rappeler les process à nos Valets lorsque nécessaire et prendre des actions correctives si besoin</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Travailler à l’obtention de normes, dont ISO 9001 (définit les critères applicables à un système de management de la qualité)</p>
    </div>
    <div class="general-qualite-savoir-plus-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">En savoir plus sur Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour mieux nous connaître et vous familiariser avec nos différentes solutions, découvrez :</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Qui nous sommes : comment Pop Valet est né et qui sont ses fondateurs</p><br>-->
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/reactivite" style="color: #002244">Notre réactivité</a> : comment nos outils technologiques nous distinguent de nos confrères en matière d’optimisation des délais</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/popvalet-general-assurance" style="color: #002244">Nos assurances</a> : gros plan sur nos assurances partenaires, les dommages couverts, et les protections assurées à la fois pour nos clients et pour nos Valets</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Notre blog : l’actualité de Pop Valet et du transport automobile</p>-->
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import GeneralHeader from "@/components/GeneralHeader";
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletGeneralQualiteInfo",
  components: {
    GeneralHeader,
    CommonHeader,
    CommonSurHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif",
  ],
  metaInfo: {
    title: 'Contrôle Qualité pour vos Transports de Véhicules | Pop Valet',
    meta: [
      { name: 'description', content: 'De la sélection de nos chauffeurs jusqu’à notre pôle dédié, découvrez les moyens que nous nous donnons pour garantir la qualité de toutes nos missions.' },
    ]
  }
}
</script>

<style scoped>
.general-qualite-savoir-plus-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-qualite-savoir-plus-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.general-qualite-missions-container h3 {
  margin-top: 25px;
}
.general-qualite-missions-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-qualite-missions-container {
  padding: 100px 150px;
  line-height: 29px;
}
.general-qualite-definition-container h3 {
  margin-top: 25px;
}
.general-qualite-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-qualite-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.general-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .general-qualite-savoir-plus-container {
    padding: 100px 50px;
  }
  .general-qualite-missions-container {
    padding: 100px 50px;
  }
  .general-qualite-definition-container {
    padding: 100px 50px;
  }
}
</style>