<template>
  <div class="voir-tous-les-uses-cases">
    <p class="voir-tous-les-uses-cases-1 montserrat-medium-gunsmoke-18px">
      <a href="/nos-use-cases" style="text-decoration: none"><span class="span-2 montserrat-medium-gunsmoke-18px">Voir tous les use cases ></span></a>
    </p>
  </div>
</template>

<script>
export default {
  name: "VoirTousLesUsesCases",
  props: ["spanText1", "spanText2", "spanText3", "className"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .voir-tous-les-uses-cases {
    margin-top: 50px;
  }
}

.voir-tous-les-uses-cases {
  display: flex;
  height: 23px;
  width: 238px;
}

.voir-tous-les-uses-cases-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 238px;
}

.voir-tous-les-uses-cases.voir-tous-les-uses-cases-2 {
  flex: 1;
  height: unset;
  margin-left: 41.67%;
  margin-right: 41.81%;
  max-height: 23px;
  width: unset;
}
</style>
