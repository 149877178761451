<template>
  <div class="bloc_chiffres_cls-2">
    <div class="flex-row-17 isidorasansalt-bold-magic-potion-30px">
      <div class="flex-row-18">
        <div class="flex-col-21">
          <div class="overlap-group1-18">
            <div class="pop-valet-cest-1 isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Pop Valet</span>, c’est...</div>
          </div>
          <div class="pop-valet-is-icon first-icons">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/transport---car-3@2x.png" alt="icon-transport" />
            </div>
          </div>
          <div class="flex-col-item-1">
                <span class="isidorasansalt-bold-magic-potion-30px">+ 250 000</span
                ><br><span class="span-48 montserrat-normal-magic-potion-14px">VÉHICULES TRANSPORTÉS</span>
          </div>
          <div class="pop-valet-is-icon first-icons">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/Like.png" alt="icon-like" />
            </div>
          </div>
          <div class="flex-col-item-1">
                <span class="isidorasansalt-bold-magic-potion-30px">4,7/5</span
                ><br><span class="span-48 montserrat-normal-magic-potion-14px">MOYENNE DES AVIS CLIENTS</span>
          </div>
        </div>
        <div class="flex-col-22">
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/users.png" alt="icon-users" />
            </div>
          </div>
          <div class="address-7">
                <span class="isidorasansalt-bold-magic-potion-30px">3 500</span
                ><br><span class="span-48 montserrat-normal-magic-potion-14px">VALETS EN FRANCE</span>
          </div>
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/people.png" alt="icon-people" />
            </div>
          </div>
          <div class="x32-salaris-ddis-1">
                <span class="isidorasansalt-bold-magic-potion-30px">+ 30</span
                ><br><span class="span-48 montserrat-normal-magic-potion-14px">COLLABORATEURS DÉDIÉS</span>
          </div>
        </div>
      </div>
      <div class="flex-row-18">
        <div class="flex-col-23">
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/Thunder.png" alt="icon-thunder" />
            </div>
          </div>
          <p class="x97-taux-de-conformit-1">
              <span class="isidorasansalt-bold-magic-potion-30px">99 %</span
              ><br><span class="span-48 montserrat-normal-magic-potion-14px">TAUX DE CONFORMITÉ</span>
          </p>
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/Key.png" alt="icon-key" />
            </div>
          </div>
          <div class="x550-clients-automobiles-1">
              <span class="isidorasansalt-bold-magic-potion-30px">+ 600</span
              ><br><span class="span-48 montserrat-normal-magic-potion-14px">CLIENTS AUTOMOBILES</span>
          </div>
        </div>
        <div class="flex-col-24 isidorasansalt-bold-magic-potion-30px">
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/Backup.png" alt="icon-backup" />
            </div>
          </div>
          <div class="address-8">
              <span class="isidorasansalt-bold-magic-potion-30px">30 MIN</span
              ><br><span class="span-48 montserrat-normal-magic-potion-14px">DÉLAI DE RÉPONSE</span>
          </div>
          <div class="pop-valet-is-icon">
            <div class="overlap-group-56">
              <img class="icons-popvaletis" src="../../img/medal.png" alt="icon-medal" />
            </div>
          </div>
          <div class="x10-annes-dexistence-1">
              <span class="isidorasansalt-bold-magic-potion-30px">+ 10</span
              ><br><span id="reserver-transport" class="span-48 montserrat-normal-magic-potion-14px">ANNÉES D'EXISTENCE</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transport from "@/components/Transport";
import delai from "@/components/Delai";
export default {
  name: "PopValetIs",
  components: {
    transport,
    delai
  }
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .bloc_chiffres_cls-2 {
    min-width: 100%;
  }
  .flex-row-17 {
    height: unset;
    min-height: 664px;
    flex-direction: column;
    width: 100%;
    padding: 50px 0;
  }
  .flex-row-18 {
    align-self: unset;
    align-items: center;
    height: unset;
    min-height: 426px;
    flex-direction: column;
    min-width: 100%;
    gap: 60px;
  }
  .flex-col-23 {
    margin-left: 0;
  }
  .flex-col-21 {
    width: 100%;
  }
  .flex-col-item-1 {
    width: 100%;
  }
  .flex-col-24 {
    margin-left: 0;
    margin-top: 0;
  }
  .pop-valet-is-icon {
    margin-left: 0;
  }
}

@media screen and (min-width: 992px) {
  .flex-row-18 {
    align-self: flex-start;
    align-items: flex-end;
    height: 426px;
    min-width: 487px;
    gap: 23px;
  }
  .flex-col-23 {
    margin-left: 26px;
  }
  .flex-col-21 {
    width: 241px;
  }
  .flex-col-item-1 {
    width: 223px;
  }
  .flex-col-24 {
    margin-left: 17px;
    margin-top: 48px;
  }
  .pop-valet-is-icon {
    margin-left: 0;
  }
  .pop-valet-is-icon.first-icons {
    margin-left: 19px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .bloc_chiffres_cls-2 {
    min-width: 1456px;
  }
  .flex-row-17 {
    min-width: 1440px;
    height: 664px;
    padding: 88px 222px;
  }
}

@media screen and (min-width: 1441px) {
  .bloc_chiffres_cls-2 {
    min-width: 100%;
  }
  .flex-row-17 {
    min-width: 100%;
    justify-content: center;
    height: 664px;
    padding: 88px 222px;
  }
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -13px;
  text-decoration-color: var(--mustard);
}

.bloc_chiffres_cls-2 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
}

.flex-row-17 {
  align-items: center;
  background-color: var(--grisclair);
  display: flex;
}

.general-homepage .flex-row-17 {
  background-color: white;
}

.flex-row-18 {
  display: flex;
}

.flex-col-21 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 426px;
  position: relative;
}

.overlap-group1-18 {
  height: 36px;
  margin-right: 6px;
  position: relative;
  width: 235px;
}

.pop-valet-cest-1 {
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 234px;
}

.pop-valet-is-icon {
  align-items: center;
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  margin-top: 74px;
  min-width: 56px;
  padding: 9px;
}

.overlap-group-56 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  min-width: 37px;
}

.icons-popvaletis {
  height: auto;
  width: 37px;
}

.flex-col-item-1 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 18px;
  min-height: 48px;
  text-align: center;
}

.span-48 {
  line-height: 19.6px;
}

.flex-col-22 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 316px;
  position: relative;
  width: 223px;
}

.address-7,
.x32-salaris-ddis-1,
.x97-taux-de-conformit-1,
.x550-clients-automobiles-1,
.address-8,
.x10-annes-dexistence-1 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 18px;
  min-height: 48px;
  text-align: center;
  width: 223px;
}

.flex-col-23 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  min-height: 316px;
  position: relative;
  width: 223px;
}

.flex-col-24 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 316px;
  position: relative;
  width: 223px;
}
</style>