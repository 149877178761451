<template>
  <div class="pro-services-header-container">
    <div :class="[`bulle_yellow_couv`, proBulleYellow || ``]">
      <div class="bulle_valet-1">
        <img class="icon_valet-2" :src="iconUrl" alt="icon_valet" />
      </div>
    </div>
    <p :class="[`title-3 isidorasansalt-semi-bold-magic-potion-38px underline-white`, proTitle3 || ``]">{{text}}</p>
  </div>
</template>

<script>
export default {
  name: "ProServicesHeaderP",
  props: [
    'iconUrl',
    'text',
    'proBulleYellow',
    'proTitle3'
  ]
}
</script>

<style scoped>
.pro-services-header-container {
  background-color: var(--bluemed2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 355px;
  width: 100%;
}

.bulle_yellow_couv {
  height: 134px;
  width: 134px;
}

.title-3 {
  text-align: center;
  position: relative;
  top: -57px;
  height: 0;
}

.bulle_valet-1 {
  background-color: var(--yellow);
  border-radius: 67px;
  display: flex;
  height: 134px;
  justify-content: flex-end;
  min-width: 134px;
  padding: 20px 37.3px;
  position: relative;
}

.icon_valet-2 {
  height: 57px;
}

.underline-white {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 10px;
  text-underline-offset: -18px;
  text-decoration-color: white;
}

.pro-services-header-container p {
  padding: 0 250px;
}

@media screen and (max-width: 991px) {
  .pro-services-header-container p {
    padding: 0;
  }
  .pro_bulle_yellow_couv {
    position: relative;
    bottom: 50px;
  }
  .pro_title_3 {
    top: -110px;
  }
}
</style>