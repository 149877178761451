<template>
  <div :class="[`fonctionnement3`, className || ``]">
    <img
      class="location-and-map-location-pin-3"
      src="../../img/location-and-map---location-pin-1@2x.png"
      alt="Location and Map / location pin"
    />
    <div class="line-9">
      <div class="overlap-group-79">
        <img
          class="line_fonctionnement-3"
          src="../../img/line-fonctionnement-2@2x.png"
          alt="Line_fonctionnement"
        />
        <div class="number-10 montserrat-bold-white-16px">{{ number }}</div>
      </div>
    </div>
    <camions3
      :spanText1="camions3Props.spanText1"
      :spanText2="camions3Props.spanText2"
      :className="camions3Props.className"
    />
  </div>
</template>

<script>
import Camions3 from "./Camions3";
export default {
  name: "Fonctionnement3",
  components: {
    Camions3,
  },
  props: ["number", "className", "camions3Props"],
};
</script>

<style>
.fonctionnement3 {
  display: flex;
  flex-direction: column;
  height: 230px;
  left: 462px;
  position: absolute;
  top: 0;
  width: 433px;
}

.location-and-map-location-pin-3 {
  height: 90px;
  margin-left: 246px;
  width: 90px;
}

.line-9 {
  align-items: flex-start;
  display: flex;
  margin-top: 14px;
  min-width: 307px;
}

.overlap-group-79 {
  height: 26px;
  margin-top: -1px;
  position: relative;
  width: 304px;
}

.line_fonctionnement-3 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 304px;
}

.number-10 {
  left: 281px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
  width: 23px;
}

.fonctionnement3.fonctionnement4 {
  left: 767px;
}
</style>
