<template>
  <div class="trexte_services-1">
    <div class="transport-camion isidorasansalt-bold-magic-potion-15px">{{ transportCamion }}</div>
    <p class="dpanneuse-plateau-porte-5-ou-8 montserrat-normal-magic-potion-14px">{{ dpanneusePlateauPorte5Ou8 }}</p>
  </div>
</template>

<script>
export default {
  name: "TrexteServices",
  props: ["transportCamion", "dpanneusePlateauPorte5Ou8"],
};
</script>

<style>
.trexte_services-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 66px;
  width: 186px;
}

.transport-camion,
.reparation,
.expertise,
.restitution {
  height: 22px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  text-align: center;
  white-space: nowrap;
  width: 186px;
}

.dpanneuse-plateau-porte-5-ou-8,
.mcanique-carosseri,
.sur-site-distance-ou-combine,
.sur-parc-ou-centre-automobile {
  height: 36px;
  letter-spacing: 0;
  line-height: 19.6px;
  text-align: center;
  width: 186px;
}
</style>
