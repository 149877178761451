<template>
  <div class="container-center-horizontal">
    <div class="popvalet-pro-services-expertise screen">
      <common-sur-header/>
      <common-header
          :nav-items="header.navItems"
          :cta-buttons="header.ctaButtons"
      />
      <pro-services-header text="Expertise" :icon-url="require('../../img/Research.png')"/>
      <pro-services-intro :intro-text="introText" :services="services"/>
      <pro-services-use-cases :use-cases="useCases"/>
      <pro-services-avantages :avantages="avantages"/>
      <div class="bloc_fonctionnement_mobile">
        <div class="bloc_fonctionnement_container">
          <div class="bloc_fonctionnement_expertise_title isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">FONCTIONNEMENT</span> DE L'EXPERTISE</div>
          <div>

            <div>
              <img
                  class="location-and-map-location-pin-1-mobile"
                  src="../../img/location-and-map---location-pin-1@2x.png"
                  alt="Location and Map / location pin"
              />
              <div class="bloc_fonctionnement_step">
                <div class="bloc_fonctionnement_step_number"><p>1</p></div>
              </div>
              <div class="bloc_fonctionnement_txt">
                <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-24px">COMMANDE</span>
                <span class="span-70 montserrat-normal-magic-potion-14px">Saisie de la demande dans notre espace professionnelle</span>
              </div>
            </div>

            <div class="bloc_fonctionnement_step_arrow">
              <img src="../../img/line-8@2x.png" alt="">
            </div>

            <div>
              <img
                  class="location-and-map-location-pin-1-mobile"
                  src="../../img/location-and-map---location-pin-1@2x.png"
                  alt="Location and Map / location pin"
              />
              <div class="bloc_fonctionnement_step">
                <div class="bloc_fonctionnement_step_number"><p>2</p></div>
              </div>
              <div class="bloc_fonctionnement_txt">
                <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">Revelé de dommages</span>
                <span class="span-70 montserrat-normal-magic-potion-14px">Relevé de dommages à travers 40 photos de l'intérieur et de l'extérieur</span>
              </div>
            </div>

            <div class="bloc_fonctionnement_step_arrow">
              <img src="../../img/line-8@2x.png" alt="">
            </div>

            <div>
              <img
                  class="location-and-map-location-pin-1-mobile"
                  src="../../img/location-and-map---location-pin-1@2x.png"
                  alt="Location and Map / location pin"
              />
              <div class="bloc_fonctionnement_step">
                <div class="bloc_fonctionnement_step_number"><p>3</p></div>
              </div>
              <div class="bloc_fonctionnement_txt">
                <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">Trajet</span>
                <span class="span-70 montserrat-normal-magic-potion-14px">Suivi des étapes en temps réel et notifications intermédiaires</span>
              </div>
            </div>

            <div class="bloc_fonctionnement_step_arrow">
              <img src="../../img/line-8@2x.png" alt="">
            </div>

            <div>
              <img
                  class="location-and-map-location-pin-1-mobile"
                  src="../../img/location-and-map---location-pin-1@2x.png"
                  alt="Location and Map / location pin"
              />
              <div class="bloc_fonctionnement_step">
                <div class="bloc_fonctionnement_step_number"><p>4</p></div>
              </div>
              <div class="bloc_fonctionnement_txt">
                <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">Rapport</span>
                <span class="span-70 montserrat-normal-magic-potion-14px">Chiffrage précis par un expert certifié sous 24h</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc_fonctionnement">
        <div class="fonctionnement-container">
          <img class="line_fonctionnement" src="../../img/line-fonctionnement-4@2x.png" alt="Line_fonctionnement" />
          <div class="fonctionnement-de-lexpertise isidorasansalt-bold-magic-potion-28px">
            {{ fonctionnementDeLExpertise }}
          </div>
        </div>
        <div class="fonctionnement-container-1">
          <div class="fonctionnement1-5">
            <img
              class="location-and-map-location-pin-1"
              src="../../img/location-and-map---location-pin-1@2x.png"
              alt="Location and Map / location pin"
            />
            <div class="line">
              <div class="overlap-group-77">
                <img class="line_fonctionnement-1" src="../../img/line-fonctionnement@2x.png" alt="Line_fonctionnement" />
                <div class="number-8 montserrat-bold-white-16px">{{ number1 }}</div>
              </div>
            </div>
            <div class="camions-3">
              <p class="commande-saisie-de-l isidorasansalt-bold-magic-potion-20px">
                <span class="isidorasansalt-bold-magic-potion-20px">{{ spanText11 }}</span
                ><br><span class="span-70 montserrat-normal-magic-potion-14px">{{ spanText12 }}</span>
              </p>
            </div>
          </div>
          <div class="fonctionnement2">
            <img
              class="location-and-map-location-pin-2"
              src="../../img/location-and-map---location-pin-1@2x.png"
              alt="Location and Map / location pin"
            />
            <div class="line-3">
              <div class="overlap-group-78">
                <img class="line_fonctionnement-2" src="../../img/line-fonctionnement-1@2x.png" alt="Line_fonctionnement" />
                <div class="number-9 montserrat-bold-white-16px">{{ number2 }}</div>
              </div>
            </div>
            <camions3 :spanText1="camions3Props.spanText1" :spanText2="camions3Props.spanText2" />
          </div>
          <fonctionnement3
            :number="fonctionnement31Props.number"
            :camions3Props="fonctionnement31Props.camions3Props"
          />
          <fonctionnement3
            :number="fonctionnement32Props.number"
            :className="fonctionnement32Props.className"
            :camions3Props="fonctionnement32Props.camions3Props"
          />
        </div>
      </div>
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import PictoReactivite from "./PictoReactivite";
import VoirTousLesUsesCases2 from "./VoirTousLesUsesCases2";
import Camions3 from "./Camions3";
import Fonctionnement3 from "./Fonctionnement3";
import CommonFooter from "@/components/common/CommonFooter";
import ProServicesHeader from "@/components/ProServicesHeader";
import ProServicesIntro from "@/components/ProServicesIntro";
import ProServicesAvantages from "@/components/ProServicesAvantages";
import ProServicesUseCases from "@/components/ProServicesUseCases";
export default {
  name: "PopvaletProServicesExpertise",
  components: {
    ProServicesUseCases,
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    ProServicesIntro,
    ProServicesAvantages,
    PictoReactivite,
    VoirTousLesUsesCases2,
    Camions3,
    Fonctionnement3,
    CommonFooter,
  },
  props: [
    'header',
    'introText',
    'services',
    'avantages',
    'useCases',
    "fonctionnementDeLExpertise",
    "number1",
    "spanText11",
    "spanText12",
    "number2",
    "ctaOuvrirCompteProps",
    "voirTousLesUsesCases2Props",
    "camions3Props",
    "fonctionnement31Props",
    "fonctionnement32Props",
  ]
};
</script>

<style>

@media screen and (max-width: 1439px) {
  .bloc_fonctionnement {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .bloc_fonctionnement {
    display: flex;
  }
  .bloc_fonctionnement_mobile {
    display: none;
  }
}

.popvalet-pro-services-expertise {
  width: 100%;
}

.bloc_fonctionnement {
  align-items: flex-start;
  background-color: var(--blanc);
  flex-direction: column;
  gap: 84px;
  min-height: 555px;
  padding: 88px 0;
  width: 1440px;
  margin: auto;
}

.bloc_fonctionnement_mobile {
  padding: 30px;
  margin: 25px 0;
}

.bloc_fonctionnement_txt {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.bloc_fonctionnement_txt_title {
  margin-bottom: 15px;
  text-transform: uppercase;
}

.bloc_fonctionnement_txt span {
  text-align: center;
}

.bloc_fonctionnement_step {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.bloc_fonctionnement_step div {
  padding: 10px 13px;
  background-color: #002244;
  color: white;
  border-radius: 30px;
  font-family: var(--font-family-isidora_sans_alt-bold);
}

.bloc_fonctionnement_step_number p {
  position: relative;
  bottom: 1px;
}

.bloc_fonctionnement_expertise_title {
  text-align: center;
  margin-bottom: 60px;
}

.bloc_fonctionnement_step_arrow {
  text-align: center;
  margin-bottom: 40px;
}

.bloc_fonctionnement_step_arrow img {
  height: 150px;
}

.fonctionnement-container {
  height: 36px;
  margin-left: 222px;
  position: relative;
  width: 494px;
}

.line_fonctionnement {
  height: 6px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 255px;
}

.fonctionnement-de-lexpertise {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 494px;
}

.fonctionnement-container-1 {
  align-self: center;
  height: 230px;
  position: relative;
  width: 1200px;
}

.fonctionnement1-5 {
  display: flex;
  flex-direction: column;
  height: 230px;
  left: 0;
  position: absolute;
  top: 0;
  width: 285px;
}

.location-and-map-location-pin-1-mobile {
  height: 90px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.location-and-map-location-pin-1 {
  height: 90px;
  margin-left: 98px;
  width: 90px;
}

.line {
  align-items: flex-start;
  display: flex;
  margin-left: 28px;
  margin-top: 14px;
  min-width: 131px;
}

.overlap-group-77 {
  height: 26px;
  margin-top: -1px;
  position: relative;
  width: 128px;
}

.line_fonctionnement-1 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 128px;
}

.number-8 {
  left: 105px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
  width: 23px;
}

.camions-3 {
  display: flex;
  margin-top: 24px;
  width: 287px;
}

.commande-saisie-de-l {
  height: 76px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  width: 285px;
}

.span-70 {
  line-height: 19.6px;
}

.fonctionnement2 {
  display: flex;
  flex-direction: column;
  height: 230px;
  left: 157px;
  position: absolute;
  top: 0;
  width: 433px;
}

.location-and-map-location-pin-2 {
  height: 90px;
  margin-left: 246px;
  width: 90px;
}

.line-3 {
  align-items: flex-start;
  display: flex;
  height: 25.58px;
  margin-top: 14.2px;
  min-width: 307px;
}

.overlap-group-78 {
  height: 26px;
  margin-top: -1px;
  position: relative;
  width: 304px;
}

.line_fonctionnement-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 304px;
}

.number-9 {
  left: 282px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 23px;
}
</style>
