<template>
  <div class="camions-1">
    <div class="x-lorem-ipsum-lorem-ipsum isidorasansalt-bold-gunsmoke-20px">
      <span class="isidorasansalt-bold-white-20px">{{ spanText1 }}</span
      ><br><span class="span-52 montserrat-normal-white-14px">{{ spanText2 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Camions",
  props: ["spanText1", "spanText2"],
};
</script>

<style>
@media screen and (max-width: 1440px) {
  .x-lorem-ipsum-lorem-ipsum {
    margin-top: 40px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
  .x-lorem-ipsum-lorem-ipsum {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1441px) {
  .x-lorem-ipsum-lorem-ipsum {
    width: 240px;
  }
}
.camions-1 {
  display: flex;
  width: 242px;
}

.x-lorem-ipsum-lorem-ipsum {
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.span-52 {
  line-height: 19.6px;
}
</style>
