<template>
  <div class="sur-header-container">
    <div class="sur-header">
      <div class="rating-group">
        <div class="rating-sub-group">
          <div class="rating-text isidorasansalt-semi-bold-white-14px">
            4,7 / 5
          </div>
          <div>
            <img class="star" src="../../../img/solid---favorite-1@2x.png" alt="9,7 / 10" />
            <img class="star" src="../../../img/solid---favorite-1@2x.png" alt="9,7 / 10" />
            <img class="star" src="../../../img/solid---favorite-1@2x.png" alt="9,7 / 10" />
            <img class="star" src="../../../img/solid---favorite-1@2x.png" alt="9,7 / 10" />
            <img class="star" src="../../../img/solid---favorite-1@2x.png" alt="9,7 / 10" />
          </div>
        </div>
        <div class="rating-count-text montserrat-normal-white-10px">
          <a href="https://www.avis-verifies.com/avis-clients/popvalet.com" target="_blank" style="color: white; text-decoration: none">+ 4 000 AVIS CLIENTS</a>
        </div>
      </div>
      <div class="quality-group">
        <img class="quality-icon" src="../../../img/user-interface---like-7@2x.png" alt="Qualité de service" />
        <div class="quality-text isidorasansalt-semi-bold-white-14px">
          <a href="/qualite" style="color: white; text-decoration: none">QUALITÉ DE SERVICE</a>
        </div>
      </div>
      <div class="reactivity-group">
        <img class="reactivity-icon" src="../../../img/time---watch-1@2x.png" alt="Réactivité" />
        <div class="reactivity-text isidorasansalt-semi-bold-white-14px">
          <a href="/reactivite" style="color: white; text-decoration: none">RÉACTIVITÉ</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonSurHeader"
}
</script>

<style scoped>
.sur-header-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: var(--bluenavy);
  height: 48px;
  width: 100%;
}

.sur-header {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 576px) {
  .sur-header-container {
    display: none;
  }
}

.rating-group {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.rating-sub-group {
  display: flex;
  gap: 5px;
  margin-left: 8px;
  width: 101.01px;
}

.rating-text {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.star {
  height: 7px;
  margin-left: 3px;
  width: 7px;
}

.rating-count-text {
  margin-top: 2px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.quality-group, .reactivity-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.quality-icon, .reactivity-icon {
  height: 20px;
  width: 20px;
}

.quality-text, .reactivity-text {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin-bottom: 5px;
}
</style>