<template>
  <div class="container-center-horizontal">
    <div class="popvalet-particulier-accueil screen">
      <common-sur-header />
      <common-header
          :nav-items="header.navItems"
          :cta-buttons="header.ctaButtons"
      />
      <bloc-particulier-accueil-title></bloc-particulier-accueil-title>
      <bloc-etapes-reservation-transport></bloc-etapes-reservation-transport>
      <bloc-avantages
        :className="blocAvantagesProps.className"
        :reactivitProps="blocAvantagesProps.reactivitProps"
        :qualitProps="blocAvantagesProps.qualitProps"
      />
      <bloc-logos-clients/>
      <pop-valet-is></pop-valet-is>
      <div class="bloc-iframe-reservation">
        <div class="bloc-iframe_container">
          <img class="bloc-iframe-transport-icon" src="../../img/transport---car-2@2x.png" alt="transport_car" />
          <p class="bloc-iframe-reservation-txt isidorasansalt-bold-white-28px">
            <span class="isidorasansalt-bold-white-28px">RESERVER VOTRE TRANSPORT</span>
            <br>
            <span class="span-12 blogintro">Rapidement avec Pop Valet et gagnez du temps</span>
            <a class="bloc-iframe-cta-button blogtextbold yellow" href="/reserver-votre-transport">Réserver</a>
          </p>
        </div>
      </div>
      <bloc-maif :txt-assurance="textAssurance" />
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonHeader from "@/components/common/CommonHeader";
import CommonFooter from "@/components/common/CommonFooter";
import BlocAvantages from "./BlocAvantages";
import BlocLogosClients from "./BlocLogosClients";
import BlocMaif from "./BlocMaif";
import PopValetIs from "@/components/PopValetIs";
import BlocParticulierAccueilTitle from "@/components/BlocParticulierAccueilTitle";
import BlocEtapesReservationTransport from "@/components/BlocEtapesReservationTransport";
export default {
  name: "PopvaletParticulierAccueil",
  components: {
    BlocEtapesReservationTransport,
    BlocParticulierAccueilTitle,
    PopValetIs,
    CommonSurHeader,
    CommonHeader,
    CommonFooter,
    BlocAvantages,
    BlocLogosClients,
    BlocMaif,
  },
  props: [
    'header',
    "blocAvantagesProps",
      "textAssurance"
  ],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .group-174 .un-statut-dauto-entrepreneur-actif {
    margin-left: 10px;
  }
  .group-174 {
    margin-left: 0;
  }
  .bloc-iframe-reservation {
    width: 100%;
    padding: 80px 25px;
    background-color: #002244;
  }
  .popvalet-particulier-accueil {
    width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .group-174 .un-statut-dauto-entrepreneur-actif {
    margin-left: 20px;
  }
  .group-174 {
    margin-left: 20px;
  }
  .bloc-iframe-reservation {
    width: 100%;
    padding: 80px 200px;
    background-color: #002244;
  }
  .popvalet-particulier-accueil {
    width: 1440px;
  }
}

@media screen and (min-width: 1441px) {
  .group-174 .un-statut-dauto-entrepreneur-actif {
    margin-left: 20px;
  }
  .group-174 {
    margin-left: 20px;
  }
  .bloc-iframe-reservation {
    width: 100%;
    padding: 80px 200px;
    background-color: #002244;
  }
  .popvalet-particulier-accueil {
    width: 100%;
  }
}

.bloc-iframe-cta-button {
  color: var(--bluenavy);
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  margin: 15px auto;
  padding: 15px 38px;
  cursor: pointer;
  width: fit-content;
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  position: relative;
}

.bloc-iframe-cta-button:hover {
  background-color: #F4C300;
}

.bloc-iframe-transport-icon {
  height: 67px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 21px;
  width: 67px;
  margin-top: 35px;
}

.bloc_iframe_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ellipse_iframe {
  background-color: var(--white);
  border-radius: 117px;
  height: 234px;
  width: 234px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.group-174 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bloc-iframe-reservation-txt {
  text-align: center;
  margin-top: 35px;
}

.bloc-iframe-reservation {
  width: 100%;
  background-color: #002244;
  position: relative;
}

.popvalet-particulier-accueil {
  align-items: center;
  background-color: var(--blanc);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

</style>
