<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <div class="landing-main-section">
      <iframe class="iframe-reservation" :src="'https://admin.popvalet.com/reservation/' + idReservation"></iframe>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletReserverTransport",
  components: {
    CommonHeader,
    CommonSurHeader,
    CommonFooter
  },
  props: [
    'header',
    'idReservation'
  ],
}
</script>

<style scoped>
.iframe-reservation {
  width: 100%;
  height: 600px;
}
</style>