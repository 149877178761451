<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Convoyage de véhicules par la route en France et en Europe" :icon-url="require('../../img/valet.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">La livraison de véhicules neufs, d’occasion ou de location en France et en Europe constitue l’un des piliers du service de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a> orchestré par Pop Valet. En 24 heures seulement, nos quelque 2 500 chauffeurs assurent la livraison de tous vos véhicules à vos clients, les professionnels comme les particuliers, à l’adresse indiquée grâce à notre solution numérique.
        Le convoyage de véhicules routiers en France et en Europe est l’un des fondements de Pop Valet et de son service de transport par Valet. Toute l’année, nos 2 500 chauffeurs se mobilisent pour transporter vos voitures d’un point A à un point B. Nos atouts : optimisation du trajet, réduction des coûts, et réalisation de toute intervention nécessaire sur votre véhicule ou votre flotte.
        À qui s’adresse notre service de convoyage ? Qui sont les Valets assurant la liaison entre l’adresse de départ et celle de destination ?
        Nos équipes vous éclairent.
      </p>
    </div>
    <div class="pro-services-convoyage-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Définition du convoyage de voiture</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le convoyage, plus rarement appelé "convoiement", est l’action de convoyer un véhicule (une voiture, un utilitaire, un van…), autrement dit de le transporter par la route d’une adresse à une autre, sans transport d’individus ou de matériel dans ledit véhicule.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le mot "convoyage" est issu de deux termes latins :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Cum, qui veut dire "avec"</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Viare, qui signifie "faire route"</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Et c’est exactement ce que propose Pop Valet : faire route avec vous.</p><br>
    </div>
    <div class="pro-services-convoyage-solution-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Le convoyage de véhicules : une solution pour tous les professionnels automobiles</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Quelle que soit la nature de votre activité automobile, notre offre de convoyage s’efforce de répondre à vos besoins. Exemples :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vous êtes gestionnaire de parc : vous êtes chargé de la supervision et de l’entretien de l’ensemble de la flotte que compte votre parc</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vous êtes loueur automobile : vous mettez à disposition de vos clients particuliers ou professionnels des véhicules en location à courte durée, à longue durée (LLD) ou avec option d’achat (LOA)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vous êtes distributeur automobile : vous exercez dans une concession automobile et distribuez des voitures ou d’autres véhicules routiers</p>
    </div>
    <div class="pro-services-convoyage-transport-container">
      <h2 class="isidorasansalt-bold-white-28px">Vos transports externes ou internes à votre entreprise</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Pop Valet déploie son service de convoyage autant pour vos clients que pour vos collaborateurs.</p><br>
      <h3 class="isidorasansalt-bold-white-24px">Le convoyage de vos véhicules entre votre société et un acteur externe</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous souhaitez faire transporter un ou plusieurs véhicule(s) vers un client ou un prestataire ? Nos Valets interviennent et garantissent une réception en bonne et due forme.</p><br>
      <h3 class="isidorasansalt-bold-white-24px">Le convoyage de vos véhicules entre deux adresses de votre société</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous souhaitez déplacer un véhicule entre deux concessions de votre réseau, deux agences de location sous la même franchise ou encore deux ateliers automobiles dont vous avez la charge ? N’imposez plus cette tâche chronophage à vos collaborateurs et confiez-la aux Valets professionnels de Pop Valet. </p>
    </div>
    <div class="pro-services-convoyage-solutions-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Des solutions multimodales de transfert adaptées à vos besoins</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Avez-vous besoin d’un Valet pour transporter une voiture unique ou, à l’inverse, d’un camion pour faire transiter plusieurs véhicules d’un point A à un point B ? Le service de convoyage Pop Valet répond présent dans les deux cas.</p><br>
      <h3 class="isidorasansalt-bold-magic-potion-24px">Votre transfert par convoyeur simple</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Un chauffeur Pop Valet se rend à l’adresse de votre choix, prend le volant du véhicule à convoyer, et le livre directement à l’adresse de destination.</p><br>
      <h3 class="isidorasansalt-bold-magic-potion-24px">Votre transfert par convoyeur et camion</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Un trajet grand axe ? Nos services incluent la possibilité d’un cumul avec un transport de vos voitures en camion. La livraison finale demeure assurée par nos Valets.</p>
    </div>
    <div class="pro-services-convoyage-avantages-container">
      <h2 class="isidorasansalt-bold-white-28px">Les avantages du service de convoyage de véhicules Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’excellence et l’exigence Pop Valet reposent sur une série de forces. Large couverture territoriale, assurance de vos véhicules, outils technologiques performants ou encore partenaires d’expérience : découvrez-les.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Vos transports de véhicules dans toute la France et en Europe</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Déployés dans toute la France, Corse incluse (une majoration s’applique pour l’Île de Beauté), et agissant au départ et à destination de toute l’Europe, nous sommes l’unique acteur du marché à fonctionner en tant qu’opérateur logistique avec une gestion directe des missions confiées, sans intermédiation.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les distances sur lesquelles nous opérons sont sans limites : nos valets répondent présents pour les trajets courts comme pour ceux de plusieurs milliers de kilomètres.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Vos véhicules bien assurés</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Voiture d’occasion comme voiture de luxe, vos véhicules bénéficient de garanties pendant tout le transport, de l’adresse d’enlèvement à celle de livraison (assistance sans franchise kilométrique, RC automobile, RC professionnelle…).</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Nos outils numériques au service de vos acheminements</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Sur notre interface dédiée, vous prenez quelques minutes pour nous indiquer le lieu de départ et d’arrivée de votre véhicule, les dates souhaitées, et le ou les véhicule(s) concerné(s). Nos Valets s’occupent du reste et assurent également le bon déroulement de l’état des lieux digitalisé.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Nos chauffeurs sélectionnés et évalués</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Seuls 4 % des candidats à un poste de Valet chez Pop Valet valident le processus dans son intégralité : c’est dire le niveau d’exigence que nous nous sommes fixé. À réception, le destinataire du véhicule évalue la prestation du conducteur.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Nos partenaires de confiance</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">De la MAIF au groupe CAT, ils nous accompagnent au quotidien dans nos prestations de livraisons par la route.</p><br>

      <h4 class="isidorasansalt-bold-white-28px">MAIF</h4><br>
      <p class="isidorasansalt-semi-bold-white-18px">La société d’assurance mutuelle fait confiance à Pop Valet pour le rapatriement des véhicules post-sinistre de ses assurés. Après réparation, nos chauffeurs récupèrent ainsi le véhicule auprès du garage et le conduisent depuis le lieu du sinistre jusqu’au domicile des assurés.</p><br>

      <h4 class="isidorasansalt-bold-white-28px">Reezocar</h4><br>
      <p class="isidorasansalt-semi-bold-white-18px">Cette filiale de la Société Générale réalise ses approvisionnements de véhicules à l’étranger (Allemagne, Belgique, Pays-Bas, Luxembourg, Italie, Espagne et Royaume-Uni) puis confie à Pop Valet le soin de les livrer après leur reconditionnement.</p><br>

      <h4 class="isidorasansalt-bold-white-28px">Groupe CAT</h4><br>
      <p class="isidorasansalt-semi-bold-white-18px">Comptant parmi les leaders de la logistique véhicules en Europe, le groupe s’est entouré des services de Pop Valet pour participer à l’expédition de ses quelque 10 millions de véhicules par an.</p>
    </div>
    <div class="pro-services-convoyage-services-additionnels-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Au-delà de votre convoyage de véhicule : nos services additionnels</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Découvrez quelques-uns des services optionnels pouvant être intégrés aux convoyages assurés par Pop Valet :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Lavage intérieur et extérieur du véhicule : notre solution garantit le rendu d’un véhicule propre (pare-brise, carrosserie, roues, habitacle…)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Remise à niveau de carburant et recharge électrique : votre véhicule est rendu prêt à rouler (essence pour votre voiture thermique et batterie pour votre voiture électrique)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Réparation : votre voiture est livrée réparée en cas de panne, grâce aux partenaires de réparation de Pop Valet</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Reconditionnement : votre voiture est livrée reconditionnée s’il s’agit d’un véhicule de seconde main devant être remis en conformité</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Préparation esthétique avant revente : du traitement de la carrosserie à celui de l’habitacle, votre voiture bénéficie d’un entretien cosmétique en cas de revente</p>
    </div>
    <div class="pro-services-convoyage-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">De la livraison au jockeyage, découvrez les autres solutions de mobilité proposées par Pop Valet :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/convoyage-voiture" style="color: #002244">livraison de véhicules</a> si vous avez besoin de livrer une voiture, un utilitaire ou autre à un client professionnel ou particulier</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/jockeyage-voiture" style="color: #002244">jockeyage de véhicules</a> si vous êtes en quête d’un service de conciergerie automobile pour emmener une voiture vers tout acte d’entretien ou de réparation</p><br>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopValetProServicesValetConvoyage",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Convoyage de Véhicules par Route (France/Europe) | Pop Valet',
    meta: [
      { name: 'description', content: 'Profitez d’un réseau de plus de 2 500 chauffeurs mobilisés pour transporter vos voitures d’un point A à un point B en France et dans toute l’Europe.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.popvalet.com/convoyage-voiture' }
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://www.popvalet.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Transport avec chauffeur",
              "item": "https://www.popvalet.com/transport-avec-chauffeur"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Convoyage de véhicules",
              "item": "https://www.popvalet.com/convoyage-voiture"
            }
          ]
        }
      }
    ]
  }
}
</script>

<style scoped>
.pro-services-convoyage-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-plus-loin-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-services-additionnels-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-services-additionnels-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-avantages-container h4 {
  margin-top: 15px;
}
.pro-services-convoyage-avantages-container h3 {
  margin-top: 25px;
}
.pro-services-convoyage-avantages-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-avantages-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-solutions-container h3 {
  margin-top: 25px;
}
.pro-services-convoyage-solutions-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-solutions-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-transport-container h3 {
  margin-top: 25px;
}
.pro-services-convoyage-transport-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-transport-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-solution-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-solution-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-convoyage-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-convoyage-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-convoyage-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-services-additionnels-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-avantages-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-solutions-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-transport-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-solution-container {
    padding: 100px 50px;
  }
  .pro-services-convoyage-definition-container {
    padding: 100px 50px;
  }
}
</style>