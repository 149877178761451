<template>
  <div class="pop-valet-our-reviews-container">
    <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow-popvalet-is">Découvrez ce que nos clients</span> pensent de notre service</h2>

    <div class="pop-valet-reviews-row">

      <div class="pop-valet-review-bloc">
        <p class="isidorasansalt-bold-magic-potion-20px">Carole B.</p>
        <div class="reviews-stars">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
        </div>
        <p class="montserrat-normal-magic-potion-14px" style="text-align: center">Voiturier très aimable et compétent. Merci !</p>
      </div>

      <div class="pop-valet-review-bloc">
        <p class="isidorasansalt-bold-magic-potion-20px">Catherine G.</p>
        <div class="reviews-stars">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
        </div>
        <p class="montserrat-normal-magic-potion-14px" style="text-align: center">Prestation très professionnelle. Informations précises sur le déroulement et respect de ces dernières. Je recommanderais vraiment ce service.</p>
      </div>

      <div class="pop-valet-review-bloc">
        <p class="isidorasansalt-bold-magic-potion-20px">Solène I.</p>
        <div class="reviews-stars">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
          <img src="../../../img/white-star.png" alt="white-star">
        </div>
        <p class="montserrat-normal-magic-potion-14px" style="text-align: center">Voiturier ponctuel, professionnel, et sympathique. Ce service est très pratique.</p>
      </div>

    </div>
    <div style="margin-top: 80px">
      <a href="https://www.avis-verifies.com/avis-clients/popvalet.com" target="_blank" class="all-reviews-cta-button yellow blogtextbold">Voir tous les avis</a>
    </div>
  </div>
</template>

<script>
import transport from "@/components/Transport";
import delai from "@/components/Delai";
export default {
  name: "BlocDecouvrezLesAvis",
  components: {
    transport,
    delai
  }
}
</script>

<style>
@media screen and (min-width: 1600px) {
  .pop-valet-our-reviews-container {
    padding: 80px 400px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1599px) {
  .pop-valet-our-reviews-container {
    padding: 80px 300px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .pop-valet-our-reviews-container {
    padding: 80px 200px;
  }
}
@media screen and (max-width: 991px) {
  .pop-valet-our-reviews-container {
    width: 100%;
    padding: 50px 30px;
  }
  .pop-valet-reviews-row {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .pop-valet-reviews-row {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .pop-valet-review-bloc {
    margin: 30px 0;
  }
}

.all-reviews-cta-button {
  color: var(--bluenavy);
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  justify-content: center;
  padding: 15px 38px;
  cursor: pointer;
  width: fit-content;
  letter-spacing: 0;
  margin: 0 auto;
}

.all-reviews-cta-button:hover {
  background-color: #F4C300;
  color: var(--bluenavy);
}

.pop-valet-our-reviews-container {
  background-color: #fffbfb;
}
.pop-valet-reviews-row {
  display: flex;
  justify-content: space-between;
  margin-top: 75px;
}
.pop-valet-review-bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: fit-content;
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.pop-valet-review-bloc p {
  margin: 10px 0;
  line-height: 19px;
}
.reviews-stars {
  display: flex;
  background-color: #FADC41;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;
}
.reviews-stars img {
  height: 15px;
  margin: 0 1px;
}
</style>