<template>
  <div class="general-accueil-title-container">
    <!--    <div class="general-accueil-carousel-arrows">-->
    <!--      <img class="general-accueil-carousel-arrow-left" @click="goPrev" src="../../img/arrow-left.png" alt="">-->
    <!--      <img class="general-accueil-carousel-arrow-right" @click="goNext" src="../../img/arrow-right.png" alt="">-->
    <!--    </div>-->
    <div class="general-accueil-title-background">
      <div class="flicking-viewport">
        <Flicking ref="flicking" :plugins="plugins" :options="{circular: true}">
          <div class="landing-page-panel">
            <div class="laughing-container">
              <div class="general-accueil-title-text-container">
                <div v-if="isMatmut" class="title-logo-matmut">
                  <img src="../../../img/logo_matmut.png" alt="logo_matmut">
                </div>
                <h1 class="general-accueil-title-text">
                  Rapatriement de véhicules <span class="underline-yellow">suite à assistance</span>
                </h1>
                <div class="maif-home-subtitle montserrat-medium-magic-potion-20px">Faites livrer votre véhicule à votre adresse par un chauffeur</div>
                <a class="reserver-cta-button yellow" href="/reserver-votre-transport">Réserver</a>
              </div>
              <div class="laughing">
                <div class="ellipse-yellow"></div>
                <img class="stock-photo-laughing"
                     src="../../../img/stock-photo-laughing-cheerful-funny-young-bearded-business-man-s_2x.webp"
                     alt="stock-photo-laughing-cheerful-funny-young-bearded-business-man-s-wearing-classic-white-shirt-stand-pointing-2208392061_2 2" />
                <div class="opacity-block" style="position: absolute; bottom: 0; right: 0; width: 100%; height: 70%; background: linear-gradient(to top, rgba(238,244,252,1), rgba(238,244,252,0));"></div>
              </div>
            </div>
          </div>
          <!--          <div class="landing-page-panel-2">-->
          <!--            <div class="laughing-container">-->
          <!--              <div class="general-accueil-title-text-container-2">-->
          <!--                <h2 class="general-accueil-title-text">-->
          <!--                  Gagnez le convoyage aller/retour de votre voiture vers votre <span class="underline-yellow">résidence secondaire</span> cet été-->
          <!--                </h2>-->
          <!--                <a class="cta-button-landing blogtextbold" href="/convoyage-residence-secondaire">J'en profite</a>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </Flicking>
      </div>
    </div>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";

const plugins = [new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: false })];

export default {
  name: "BlocMaifTitle",
  components: {
    Flicking: Flicking
  },
  props: [
    'header',
    'isMatmut',
  ],
  methods: {
    goNext() {
      this.$refs.flicking.next();
    },
    goPrev() {
      this.$refs.flicking.prev();
    }
  },
  data() {
    return {
      plugins,
      autoplay: true,
    };
  },
}
</script>

<style scoped>
@import url("../../../node_modules/@egjs/vue-flicking/dist/flicking.css");

@media screen and (min-width: 1360px) {
  .landing-page-panel-2 {
    background-position-y: -264px;
  }
}
@media screen and (max-width: 1359px) {
  .landing-page-panel-2 {
    background-position-y: unset;
  }
}
.reserver-cta-button {
  color: var(--bluenavy);
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  justify-content: center;
  padding: 15px 38px;
  cursor: pointer;
  width: fit-content;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 auto;
}
.reserver-cta-button:hover {
  background-color: #F4C300;
  color: var(--bluenavy);
}
.maif-home-subtitle {
  margin-top: 15px;
  margin-bottom: 50px;
  line-height: 28px;
}
.general-accueil-carousel-arrow-left {
  z-index: 2;
  position: absolute;
  left: 0;
  margin-left: 25px;
  top: 45%;
}
.general-accueil-carousel-arrow-right {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-right: 25px;
  top: 45%;
}
.general-accueil-carousel-arrows img:hover {
  cursor: pointer;
}
.cta-button-landing {
  color: var(--bluenavy);
  text-transform: uppercase;
}
.cta-button-landing {
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 12px 38px;
  margin-top: 40px;
  margin-left: 100px;
  width: fit-content;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}
.landing-page-panel-2 {
  background-image: url(../../../img/landing-page-photo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  height: 648px;
  width: 100%;
}
.landing-page-panel {
  background-color: #eef4fb;
  display: block;
  height: 587px;
  width: 100%;
}
.flicking-viewport {
  margin-left: auto;
  margin-right: auto;
  transition: height .5s;
  width: 100%;
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none;
}

.general-accueil-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 648px;
  position: relative;
}

.general-accueil-title-background {
  width: 100%;
  height: 648px;
  position: absolute;
  background-color: #eef4fb;
}

.general-accueil-title-background2 {
  width: 100%;
  height: 648px;
  position: absolute;
  background-color: purple;
}

.general-accueil-title {
  max-width: 1440px;
  width: 100%;
}

.laughing-container {
  margin: auto;
  max-width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.general-accueil-title-text-container {
  margin-left: 50px;
  margin-top: 100px;
  padding: 20px;
  width: 560px;
  z-index: 1;
}

.general-accueil-title-text-container-2 {
  margin-top: 120px;
  padding: 20px;
  width: 680px;
  z-index: 1;
}

.general-accueil-title-text {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 10px;
  text-underline-offset: -18px;
  text-decoration-color: var(--mustard);
}

.laughing {
  width: 455px;
  height: 455px;
}
.ellipse-yellow {
  position: relative;
  background-color: var(--mustard);
  border-radius: 227.5px;
  height: 455px;
  width: 455px;
  top: 183px;
}
.stock-photo-laughing {
  position: relative;
  height: 532px;
  object-fit: cover;
  width: 373px;
  top: -400px;
  left: 70px;
}

.title-logo-matmut {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.title-logo-matmut img {
  width: 50%;
}


.background-bluenavy {
  background-image: url(../../../img/ellipse-18-2.png);
}
.background-yellow {
  background-image: url(../../../img/ellipse-18.png);
}
.background-violet {
  background-image: url(../../../img/ellipse-18-1.png);
}

@media screen and (max-width: 576px) {
  .general-accueil-title-text-container {
    width: unset;
    margin-top: 50px;
    margin-left: 0;
  }
  .general-accueil-title-text {
    color: var(--bluenavy);
    font-family: var(--font-family-isidora_sans_alt-semibold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
  }

  .laughing {
    width: 300px;
    height: 300px;
  }
  .ellipse-yellow {
    position: relative;
    background-color: var(--mustard);
    border-radius: 150px;
    height: 300px;
    width: 300px;
    top: 137px;
  }
  .stock-photo-laughing {
    position: relative;
    height: 344px;
    top: -280px;
    left: 50px;
    width: auto;
  }
}

@media screen and (max-width: 992px) {
  .cta-button-landing {
    margin-left: auto;
    margin-right: auto;
  }
  .general-accueil-title-text-container-2 {
    width: unset;
  }
  .laughing-container {
    flex-direction: column;
    align-items: center;
  }
  .general-accueil-title-text-container {
    margin-top: 50px;
    margin-left: 0;
  }
  .opacity-block {
    display: none;
  }
  .landing-page-panel {
    height: 648px;
  }
}
</style>