<template>
  <div class="reactivit">
    <div class="bloc_reactivit">
      <div class="contenu_recativite">
        <picto-reactivite :className="pictoReactiviteProps.className" />
        <p class="ractivit-lorem-ips montserrat-medium-magic-potion-20px">
          <span class="montserrat-medium-magic-potion-20px">RÉACTIVITÉ <br /></span
          ><span class="span-6 textcourantsmall"><br />Pour vos transports automobiles, notre réactivité est en rupture totale avec les délais traditionnels, et un délai d’exécution inférieur à 48 heures.</span>
        </p>
        <voir-tous-les-uses-cases2 :children="voirTousLesUsesCases2Props.children" :link="voirTousLesUsesCases2Props.link" />
      </div>
    </div>
  </div>
</template>

<script>
import PictoReactivite from "./PictoReactivite";
import VoirTousLesUsesCases2 from "./VoirTousLesUsesCases2";
export default {
  name: "Reactivit",
  components: {
    PictoReactivite,
    VoirTousLesUsesCases2,
  },
  props: ["pictoReactiviteProps", "voirTousLesUsesCases2Props"],
};
</script>

<style>

@media screen and (max-width: 991px) {
  .bloc_reactivit,
  .bloc_reactivit-1,
  .bloc_reactivit-2 {
    margin-top: 25px;
  }
  .reactivit,
  .reactivit-1,
  .reactivit-2 {
    margin-left: unset;
  }
}

@media screen and (min-width: 992px) {
  .reactivit,
  .reactivit-1,
  .reactivit-2 {
    margin-left: 66px;
  }
}

.reactivit,
.reactivit-1,
.reactivit-2 {
  display: flex;
  height: 425px;
  width: 325px;
}

.bloc_reactivit,
.bloc_reactivit-1,
.bloc_reactivit-2 {
  align-items: center;
  background-color: var(--blanc);
  border-radius: 8px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  padding: 40px 54px;
  width: 283px;
}

.contenu_recativite,
.contenu_recativite-1,
.contenu_recativite-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 100%;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.ractivit-lorem-ips,
.ractivit-lorem-ips-1,
.ractivit-lorem-ips-2 {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 250px;
}

.span-6,
.span-v7e7ju,
.span-0Ro4wu {
  color: var(--bluenavy);
  font-weight: 400;
}
</style>
