<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <bloc-maif-title :is-matmut="isMatmut"></bloc-maif-title>
    <bloc-decouvrir-pop-valet v-if="assuranceName === 'MAIF'"></bloc-decouvrir-pop-valet>
    <bloc-fonctionnement-solution></bloc-fonctionnement-solution>
    <bloc-nos-methodes></bloc-nos-methodes>
    <bloc-decouvrez-les-avis v-if="isMatmut"></bloc-decouvrez-les-avis>
    <common-footer :is-maif="true"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonSurHeader from "@/components/common/CommonSurHeader.vue";
import BlocMaifTitle from "@/components/maif/BlocMaifTitle.vue";
import BlocDecouvrirPopValet from "@/components/maif/BlocDecouvrirPopValet.vue";
import BlocFonctionnementSolution from "@/components/maif/BlocFonctionnementSolution.vue";
import BlocNosMethodes from "@/components/maif/BlocNosMethodes.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import BlocDecouvrezLesAvis from "@/components/matmut/BlocDevouvrezLesAvis.vue";

export default {
  name: "AccueilMaifPopvalet",
  components: {
    BlocDecouvrezLesAvis,
    CommonFooter,
    BlocNosMethodes,
    BlocFonctionnementSolution,
    BlocDecouvrirPopValet,
    BlocMaifTitle,
    CommonSurHeader,
    CommonHeader,
  },
  props: [
    'header',
    'assuranceName',
    'isMatmut'
  ],
}
</script>

<style>

</style>