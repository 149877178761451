<template>
  <div :class="[`camions-5`, className || ``]">
    <p class="relev-de-dommages-r isidorasansalt-bold-magic-potion-24px">
      <span class="span-71 isidorasansalt-bold-magic-potion-24px">{{ spanText1 }}</span
      ><br><span class="span-72 montserrat-normal-magic-potion-14px">{{ spanText2 }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Camions3",
  props: ["spanText1", "spanText2", "className"],
};
</script>

<style>
.camions-5 {
  display: flex;
  margin-left: 148px;
  margin-top: 24.2px;
  width: 287px;
}

.relev-de-dommages-r {
  height: 76px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  width: 285px;
}

.span-72 {
  line-height: 19.6px;
}

.camions-5.camions-4 {
  margin-top: 24px;
}
</style>
