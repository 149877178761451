<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Révision, réparation et reconditionnement de vos véhicules professionnels" :pro-bulle-yellow="'pro_bulle_yellow_couv'" :pro-title3="'pro_title_3'" :icon-url="require('../../img/Repair Tool.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Vous êtes <a href="/professionnels" style="color: #002244">un professionnel de l’automobile</a> et recherchez une solution de transport incluant la révision, la réparation ou le reconditionnement d’un ou plusieurs de vos véhicules ? Pop Valet répond présent : avant la livraison d’une voiture ou simplement dans le cadre de la maintenance de votre parc auto.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Prestations couvertes, avantages : nos équipes vous présentent leur méthode.</p>
    </div>
    <div class="pro-services-reparation-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Révision, réparation, reconditionnement : de quoi parle-t-on ?</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’entretien, la réparation et le reconditionnement d’une voiture désignent trois mesures sensiblement différentes. Rappel signé Pop Valet.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Révision : un entretien régulier pour assurer sécurité et bon fonctionnement</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">La révision est une opération périodique. Elle consiste à examiner, à intervalles réguliers, les différents composants de votre véhicule. L’enjeu de ces visites ? Participer tout à la fois à la fiabilité, à la performance et à la longévité de la voiture.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">La révision d’une voiture s’articule autour de quatre grands axes :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les contrôles visuels : état des pneus, de la roue de secours, des éléments de direction et de suspension, des plaquettes et disques de frein, des amortisseurs, de la transmission, des roulements…</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les contrôles à capot levé : liquide de frein, niveau d’huile, température, lave-glace…</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Le tour du véhicule : éclairage, immatriculation, balais d’essuie-glace…</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les contrôles sous le véhicule : échappement, câbles de frein à main, potentielles fuites du moteur…</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Selon les véhicules de votre flotte, la révision est à réaliser tous les 15 000 kilomètres à 30 000 kilomètres.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Réparation : une remise en état de votre véhicule</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">La réparation est une opération qui intervient lorsqu’un véhicule est accidenté. Sont généralement distinguées :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les réparations mécaniques</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les réparations de carrosserie</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les réparations mécaniques concernent le fonctionnement du moteur et de toutes les autres parties mécaniques du véhicule. Le garagiste ou mécanicien chargé de votre véhicule peut ainsi changer les éléments suivants :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Amortisseurs</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Bougies d’allumage</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Courroies de distribution</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Durites</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Freins</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Plaquettes</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Etc.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les réparations de carrosserie, elles, concernent l’enveloppe extérieure de la voiture. L’apparence de celle-ci peut avoir été endommagée à la suite :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’un accrochage</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’une erreur d’inattention sur un parking</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’un accident de la route</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’un acte de vandalisme</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Etc.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Dans des cas semblables, votre voiture est confiée à un carrossier, le professionnel spécialisé dans la tôlerie automobile. Il assure à la fois l’esthétique de votre voiture, mais aussi la sécurité des passagers une fois sa mission remplie.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Reconditionnement : une garantie pour un véhicule d’occasion</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Un véhicule reconditionné est un véhicule d’occasion soumis à une expertise approfondie par un professionnel qualifié. Le but : garantir son bon état de fonctionnement et, conséquemment, rassurer les automobilistes sur l’historique du véhicule et son état réel.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le reconditionnement est applicable uniquement aux modèles d’occasion ; généralement, il s’agit de voitures à faible kilométrage. Plusieurs étapes émaillent cette opération, les principales étant :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’analyse complète du véhicule</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">La révision et l’entretien de la voiture</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">les potentielles réparations de la carrosserie et l’application de peinture</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">le nettoyage extérieur et intérieur</p>
    </div>
    <div class="pro-services-reparation-popvalet-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Ce que peut faire Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous êtes gestionnaire de flotte, vous exercez dans une concession automobile, une agence de location ou tout autre lieu dédié à l’automobile ? La solution Pop Valet s’adresse à vous.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Révision, réparation ou reconditionnement hors transport</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Un de vos véhicules nécessite un soin urgent ? Depuis notre site internet ou notre interface, vous renseignez l’adresse de collecte. Selon l’état de votre véhicule (roulant ou non roulant) et vos besoins spécifiques, nous dépêchons un Valet, un camion porte-véhicule ou une dépanneuse pour transporter votre véhicule vers l’un de nos garages ou centres d’entretien partenaires.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Une fois l’entretien, la réparation ou le reconditionnement réalisés, Pop Valet vous restitue le véhicule à l’adresse préalablement indiquée.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Révision, réparation ou reconditionnement dans le cadre d’un transport</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous avez besoin de livrer l’un de vos véhicules à un client particulier ou professionnel ou de le convoyer d’un point A à un point B tout en vous assurant que le véhicule livré est parfaitement fonctionnel ? Là aussi, il vous suffit d’indiquer l’adresse de collecte depuis notre site internet ou notre interface et, selon l’état du véhicule, Pop Valet sollicite l’un de ses Valets, camions ou dépanneuses.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Le véhicule ainsi récupéré est conduit en centre d’entretien, réparé, puis directement livré à votre client pour un gain de temps maximal. Ce fonctionnement est particulièrement approprié, à titre d’illustration, pour les marchands de véhicules d’occasion.</p>
    </div>
    <div class="pro-services-reparation-avantages-container">
      <h2 class="isidorasansalt-bold-white-28px">Les avantages Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Couverture territoriale, optimisation de votre cycle de vente, compétitivité tarifaire : découvrez trois bonnes raisons de nous choisir.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un réseau national</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les solutions mises en place par Pop Valet couvre l’ensemble du territoire métropolitain avec :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- 15 garages automobiles habilités pour effectuer tous vos travaux de réparation (mécanique et carrosserie) avec préparation esthétique</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Près de 2 500 Valets professionnels formés pour récupérer vos véhicules</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Plus de 150 véhicules adaptés à vos transports : camions plateau, camions porte 8, dépanneuses</p><br>

      <h3 class="isidorasansalt-bold-white-24px">La réduction du cycle de vente</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">La méthode appliquée par Pop Valet nous permet d’aider les professionnels de l’automobile à décomplexifier leur cycle de vente, autrement dit de gagner du temps dans les étapes qui vous séparent de la remise du produit final à votre client. Plus besoin de solliciter vos collaborateurs pour remettre votre véhicule en état puis le livrer : notre logistique nous permet d’effectuer tout ceci en votre nom.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des prix compétitifs</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le réseau de partenaires que nous avons tissé et les outils technologiques que nous avons développés nous permettent de vous proposer des tarifs comptant parmi les plus attractifs du marché. Les devis que nous dressons dès votre passage de commande incluent la totalité de notre prestation : pas de frais cachés à la livraison.</p>
    </div>
    <div class="pro-services-reparation-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Découvrez les autres solutions de mobilité proposées par Pop Valet :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a> pour tous vos besoins en livraison, convoyage ou jockeyage</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-voiture-camion" style="color: #002244">transport par camion</a> pour vos déplacements de plusieurs véhicules en simultané</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service <a href="/expertise" style="color: #002244">d’expertise sur site ou à distance</a> pour toute analyse de vos véhicules</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/restitution" style="color: #002244">restitution sur parc</a> pour déléguer l’intégralité de votre gestion</p>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletProServicesReparationInfo",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header'
  ],
  metaInfo: {
    title: 'Véhicules : Révision, Réparation, Reconditionnement | Pop Valet',
    meta: [
      { name: 'description', content: 'Professionnel automobile ? Découvrez nos solutions de transport incluant la révision, la réparation ou le reconditionnement d’un ou plusieurs de vos véhicules.' },
    ]
  }
}
</script>

<style scoped>
.pro-services-reparation-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-reparation-plus-loin-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-reparation-avantages-container h3 {
  margin-top: 25px;
}
.pro-services-reparation-avantages-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-reparation-avantages-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-reparation-popvalet-container h3 {
  margin-top: 25px;
}
.pro-services-reparation-popvalet-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-reparation-popvalet-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-reparation-definition-container h3 {
  margin-top: 25px;
}
.pro-services-reparation-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-reparation-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-reparation-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-reparation-avantages-container {
    padding: 100px 50px;
  }
  .pro-services-reparation-popvalet-container {
    padding: 100px 50px;
  }
  .pro-services-reparation-definition-container {
    padding: 100px 50px;
  }
}
</style>