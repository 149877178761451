<template>
  <div class="general-accueil-title-container">
<!--    <div class="general-accueil-carousel-arrows">-->
<!--      <img class="general-accueil-carousel-arrow-left" @click="goPrev" src="../../img/arrow-left.png" alt="">-->
<!--      <img class="general-accueil-carousel-arrow-right" @click="goNext" src="../../img/arrow-right.png" alt="">-->
<!--    </div>-->
    <div class="general-accueil-title-background">
      <div class="flicking-viewport">
        <Flicking ref="flicking" :plugins="plugins" :options="{circular: true}">
          <div class="landing-page-panel">
            <div class="laughing-container">
              <div class="general-accueil-title-text-container">
                <p class="general-accueil-title-text">
                  Nous transportons vos véhicules avec le sourire <span class="underline-yellow">depuis 2012</span>
                </p>
              </div>
              <div class="laughing">
                <div class="ellipse-yellow"></div>
                <img class="stock-photo-laughing"
                     src="../../img/stock-photo-laughing-cheerful-funny-young-bearded-business-man-s_2x.webp"
                     alt="stock-photo-laughing-cheerful-funny-young-bearded-business-man-s-wearing-classic-white-shirt-stand-pointing-2208392061_2 2" />
              </div>
            </div>
          </div>
<!--          <div class="landing-page-panel-2">-->
<!--            <div class="laughing-container">-->
<!--              <div class="general-accueil-title-text-container-2">-->
<!--                <h2 class="general-accueil-title-text">-->
<!--                  Gagnez le convoyage aller/retour de votre voiture vers votre <span class="underline-yellow">résidence secondaire</span> cet été-->
<!--                </h2>-->
<!--                <a class="cta-button-landing blogtextbold" href="/convoyage-residence-secondaire">J'en profite</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </Flicking>
      </div>
    </div>
    <div class="general-accueil-title">
      <div class="persona_container">
        <div class="personna_1">
          <div class="icone_valet-1-1 icone_valet-1">
            <div class="group-66-1">
              <div class="overlap-group-50 background-bluenavy">
                <img class="group-5-1" src="../../img/transport---car-1@2x.png" alt="Group 5" />
              </div>
            </div>
          </div>
          <p class="je-suis-un-professio isidorasansalt-semi-bold-magic-potion-19px">
            Je suis un professionnel de l’automobile et j’ai besoin de transports réguliers et de services logistiques
          </p>
          <router-link to="/professionnels">
            <div class="cta_devenir_client">
              <div class="devenir-client blogtextbold">DEVENIR CLIENT</div>
            </div>
          </router-link>
        </div>
        <div class="personna_2">
          <div class="icone_valet-1-1 icone_valet-1">
            <div class="group-66-1">
              <div class="overlap-group-50 background-yellow">
                <img class="group-5-1" src="../../img/group-5@2x.png" alt="Group 5" />
              </div>
            </div>
          </div>
          <p class="je-suis-convoyeur-po isidorasansalt-semi-bold-magic-potion-19px">
            Je suis convoyeur Pop Valet ou souhaite le devenir
          </p>
          <a href="https://recrutement.popvalet.com/">
            <div class="cta_devenir_valet-5">
              <div class="frame-1-1">
                <div class="devenir-valet-3 blogtextbold">DEVENIR VALET</div>
              </div>
            </div>
          </a>
        </div>
        <div class="personna_3">
          <div class="icone_valet">
            <div class="group-66">
              <div class="overlap-group-46 background-violet">
                <img class="icon-location_pin" src="../../img/vector-2@2x.png" alt="icon-location_pin" />
              </div>
            </div>
          </div>
          <p class="jai-un-besoin-uniqu isidorasansalt-semi-bold-magic-potion-19px">
            J’ai un besoin unique ou ponctuel de transport de véhicule
          </p>
          <router-link to="/particuliers">
            <div class="cta_reserver">
              <div class="frame-2">
                <div class="rserver blogtextbold">RÉSERVER</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";

const plugins = [new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: false })];

export default {
  name: "BlocGeneralAccueilTitle",
  components: {
    Flicking: Flicking
  },
  methods: {
    goNext() {
      this.$refs.flicking.next();
    },
    goPrev() {
      this.$refs.flicking.prev();
    }
  },
  data() {
    return {
      plugins,
      autoplay: true,
    };
  },
}
</script>

<style scoped>
@import url("../../node_modules/@egjs/vue-flicking/dist/flicking.css");

@media screen and (min-width: 1360px) {
  .landing-page-panel-2 {
    background-position-y: -264px;
  }
}
@media screen and (max-width: 1359px) {
  .landing-page-panel-2 {
    background-position-y: unset;
  }
}
.general-accueil-carousel-arrow-left {
  z-index: 2;
  position: absolute;
  left: 0;
  margin-left: 25px;
  top: 45%;
}
.general-accueil-carousel-arrow-right {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-right: 25px;
  top: 45%;
}
.general-accueil-carousel-arrows img:hover {
  cursor: pointer;
}
.cta-button-landing {
  color: var(--bluenavy);
  text-transform: uppercase;
}
.cta-button-landing {
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 12px 38px;
  margin-top: 40px;
  margin-left: 100px;
  width: fit-content;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}
.landing-page-panel-2 {
  background-image: url(../../img/landing-page-photo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  height: 648px;
  width: 100%;
}
.landing-page-panel {
  background-color: #eef4fb;
  display: block;
  height: 648px;
  width: 100%;
}
.flicking-viewport {
  margin-left: auto;
  margin-right: auto;
  transition: height .5s;
  width: 100%;
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none;
}

.general-accueil-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 648px;
  position: relative;
}

.general-accueil-title-background {
  width: 100%;
  height: 648px;
  position: absolute;
  background-color: #eef4fb;
}

.general-accueil-title-background2 {
  width: 100%;
  height: 648px;
  position: absolute;
  background-color: purple;
}

.general-accueil-title {
  max-width: 1440px;
  width: 100%;
}

.laughing-container {
  margin: auto;
  max-width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.general-accueil-title-text-container {
  margin-left: 50px;
  margin-top: 120px;
  padding: 20px;
  width: 530px;
  z-index: 1;
}

.general-accueil-title-text-container-2 {
  margin-top: 120px;
  padding: 20px;
  width: 680px;
  z-index: 1;
}

.general-accueil-title-text {
  color: var(--bluenavy);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 10px;
  text-underline-offset: -18px;
  text-decoration-color: var(--mustard);
}

.laughing {
  width: 455px;
  height: 455px;
}
.ellipse-yellow {
  position: relative;
  background-color: var(--mustard);
  border-radius: 227.5px;
  height: 455px;
  width: 455px;
  top: 150px;
}
.stock-photo-laughing {
  position: relative;
  height: 532px;
  object-fit: cover;
  width: 373px;
  top: -450px;
  left: 85px;
}


.persona_container {
  width: 100%;
  position: relative;
  top: 380px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
}


.background-bluenavy {
  background-image: url(../../img/ellipse-18-2.png);
}
.background-yellow {
  background-image: url(../../img/ellipse-18.png);
}
.background-violet {
  background-image: url(../../img/ellipse-18-1.png);
}

.personna_2 {
  order: 1;
  margin-top: 62px;
  align-items: center;
  background-color: var(--blanc);
  border-radius: 8px;
  box-shadow: 0px 4px 24px #00000014;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 44px 43px;
}

.je-suis-convoyeur-po,
.jai-un-besoin-uniqu {
  height: 87px;
  letter-spacing: 0;
  line-height: 26.6px;
  text-align: center;
  width: 231px;
}

.personna_3 {
  order: 3;
  margin-top: 62px;
  align-items: center;
  background-color: var(--blanc);
  border-radius: 8px;
  box-shadow: 0 4px 24px #00000014;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 44px 43px;
}

.icone_valet {
  display: flex;
  height: 76px;
  min-width: 76px;
}

.group-66 {
  align-items: flex-start;
  display: flex;
  height: 76px;
  justify-content: center;
  min-width: 76px;
}

.overlap-group-46 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 88px;
  margin-top: -6px;
  min-width: 88px;
  padding: 21.5px 23.7px;
}

.icon-location_pin {
  height: 44px;
  width: 40px;
}

.personna_1 {
  order: 2;
  z-index: 1;
  align-items: center;
  background-color: var(--blanc);
  border-radius: 8px;
  box-shadow: 0 4px 24px #00000014;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 44px;
}

.je-suis-un-professio {
  height: 114px;
  letter-spacing: 0;
  line-height: 26.6px;
  text-align: center;
  width: 275px;
}


.icone_valet-1-1 {
  display: flex;
  height: 76px;
  min-width: 76px;
}
.group-66-1 {
  align-items: flex-start;
  display: flex;
  height: 76px;
  justify-content: center;
  min-width: 76px;
}
.overlap-group-50 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 88px;
  margin-top: -6px;
  min-width: 88px;
  padding: 20.7px 19.8px;
}
.group-5-1 {
  height: 46px;
  width: 48px;
}
.icone_valet-1-1.icone_valet-1 .overlap-group-50 {
  padding: 20.7px 20.2px;
}


.cta_devenir_valet-5 {
  display: flex;
  height: 44px;
  min-width: 217px;
}
.frame-1-1 {
  align-items: flex-start;
  background-color: var(--blanc);
  color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  flex: 1;
  gap: 10px;
  padding: 12px 40px;
  width: 217px;
}
.frame-1-1:hover {
  background-color: var(--yellow);
}
.devenir-valet-3 {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}


.cta_reserver {
  display: flex;
  height: 44px;
  min-width: 176px;
}
.frame-2 {
  align-items: flex-start;
  background-color: var(--blanc);
  color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--violet);
  border-radius: 34px;
  display: flex;
  flex: 1;
  gap: 10px;
  padding: 12px 44px;
  width: 176px;
}
.frame-2:hover {
  background-color: var(--violet);
  color: white;
}
.rserver {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.cta_devenir_client {
  align-items: flex-start;
  background-color: var(--blanc);
  color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--bluenavy);
  border-radius: 34px;
  display: flex;
  gap: 10px;
  padding: 12px 44px;
  width: fit-content;
}

.cta_devenir_client:hover {
  color: white;
  background-color: var(--bluenavy);
  border-color: var(--bluenavy);
}

.devenir-client {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

@media screen and (max-width: 576px) {
  .general-accueil-title-text-container {
    width: unset;
    margin-top: 50px;
    margin-left: 0;
  }
  .general-accueil-title-text {
    color: var(--bluenavy);
    font-family: var(--font-family-isidora_sans_alt-semibold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
  }

  .laughing {
    width: 300px;
    height: 300px;
  }
  .ellipse-yellow {
    position: relative;
    background-color: var(--mustard);
    border-radius: 150px;
    height: 300px;
    width: 300px;
    top: 137px;
  }
  .stock-photo-laughing {
    position: relative;
    height: 344px;
    top: -280px;
    left: 50px;
    width: auto;
  }
}

@media screen and (max-width: 992px) {
  .cta-button-landing {
    margin-left: auto;
    margin-right: auto;
  }
  .general-accueil-title-text-container-2 {
    width: unset;
  }
  .laughing-container {
    flex-direction: column;
    align-items: center;
  }
  .general-accueil-title-text-container {
    margin-top: 50px;
    margin-left: 0;
  }

  .persona_container {
    top: 380px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    z-index: 1;
  }
  .personna_1, .personna_2, .personna_3 {
    max-width: 350px;
    width: 100%;
  }
  .personna_1 {
    z-index: unset;
    order: 1;
  }
  .personna_2 {
    order: 2;
    margin-top: 0;
  }
  .personna_3 {
    order: 3;
    margin-top: 0;
  }

}
</style>