<template>
  <div id="devenez-client" class="bloc_devenez_client">
    <div class="bloc_ouvrir_compte">
      <div class="overlap-group-7">
        <div class="ellipse_ouvrez_compte"></div>
        <img class="user-interface-login" src="../../img/user-interface---login@2x.png" alt="User Interface / Login" /><br><br>
        <p class="ouvrez-un-compte-pro isidorasansalt-bold-white-28px">
          <span class="isidorasansalt-bold-white-28px">OUVREZ UN COMPTE</span><br>
          <span class="span-12 blogintro">Profitez de la solution logistique la plus complète du marché</span>
        </p>
      </div>
    </div>
    <div class="inscription_pro">
      <div class="devenez-client isidorasansalt-bold-white-28px">DEVENEZ CLIENT</div>
      <div id="typeform" class="devenez-client-typeform" data-tf-widget="kdjGLT53" data-tf-opacity="100" data-tf-iframe-props="title=Création Compte Client" data-tf-transitive-search-params data-tf-medium="snippet"></div>
    </div>
    <div class="devenez-client-message">
      <p class="isidorasansalt-semi-bold-white-18px">Ce formulaire s'adresse à nos clients existants ou potentiels. Si vous êtes chauffeur et souhaitez devenir Valet, merci de cliquer sur le lien "page d'accueil Valets" et procéder à votre inscription.</p>
    </div>
  </div>
</template>

<script>
import {Simplert} from "vue2-simplert-plugin";
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import CtaDevenirValet2 from "@/components/CtaDevenirValet2";

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

export default {
  name: "BlocDevenezClient",
  components: {
    Simplert,
    ValidationProvider,
    ValidationObserver,
    CtaDevenirValet2
  },
  data: function() {
    return {
      companyName: '',
      contactName: '',
      email: '',
      phone: '',
      transports: ''
    };
  },
  mounted () {
    this.$nextTick(() => {
      var ts = document.createElement('script');
      ts.setAttribute('src', '//embed.typeform.com/next/embed.js');
      var tfDiv = document.getElementById('typeform');
      if (tfDiv) {
        tfDiv.insertAdjacentElement('afterend', ts);
      }
    });
  },
}
</script>

<style scoped>
.devenez-client-typeform {
  width: 550px;
  height: 550px;
}

.devenez-client-message {
  padding: 20px 50px 0 50px;
  line-height: 29px;
}

.pro_cta_devenir_valet {
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  padding: 12px 20px;
  width: fit-content;
}

.pro_cta_devenir_valet_button {
  display: flex;
  width: fit-content;
  margin: auto;
  text-decoration: none;
}

.devenir-valet {
  color: var(--bluenavy);
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.text-field {
  width: 100%;
  background-color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--blanc);
  border-radius: 30px;
  height: 44px;
  padding: 10px;
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.text-field::placeholder {
  opacity: 0.7;
  color: white;
}

.error {
  margin-top: 5px;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
  color: red;
}

.text-field-error {
  border-color: red;
}


.bloc_devenez_client {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--bluenavy);
  border-radius: 8px;
  margin-top: 44px;
  max-width: 1200px;
  width: 100%;
  padding: 50px 0;
  gap: 30px;
}

.bloc_ouvrir_compte {
  align-self: center;
}

.overlap-group-7 {
  min-height: 234px;
  position: relative;
  width: 332px;
}

.ellipse_ouvrez_compte {
  background-color: var(--white);
  border-radius: 117px;
  height: 234px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 234px;
}

.user-interface-login {
  height: 67px;
  left: 132px;
  position: relative;
  margin-top: 16px;
}

.ouvrez-un-compte-pro {
  line-height: 28px;
  text-align: center;
}

.span-12 {
  color: var(--blanc);
  font-weight: 400;
  line-height: 28px;
}

.inscription_pro {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  max-width: 394px;
}
.inscription_pro > span {
  width: 100%
}

.devenez-client {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.champs_inscription_pro {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.champs_inscription_pro > * {
  width: 100%;
}

.submit-button {
  width: 100%;
  background-color: var(--yellow);
  border: none;
  border-radius: 34px;
  padding: 12px 0;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  color: var(--bluenavy);
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .devenez-client-typeform {
    width: 100%;
    height: 400px;
  }

  .bloc_devenez_client {
    flex-direction: column;
    align-items: center;
  }
}
</style>
