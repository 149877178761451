<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Comment ça marche ?" :icon-url="require('../../../img/pen.png')"/>
    <bloc-rapatrier-votre-vehicule :assurance-name="assuranceName"></bloc-rapatrier-votre-vehicule>
    <bloc-fonctionnement-solution></bloc-fonctionnement-solution>
    <div class="comment-ca-marche-list-section">
      <div class="comment-ca-marche-first-card-container">
        <bloc-card-maif :title-card="titleCard1" :text-card="textCard1" :icon-url="iconUrl1"></bloc-card-maif>
      </div>
      <div class="comment-ca-marche-card-container">
        <bloc-card-maif :title-card="titleCard2" :text-card="textCard2" :icon-url="iconUrl2"></bloc-card-maif>
      </div>
      <div class="comment-ca-marche-last-card-container">
        <bloc-card-maif :title-card="titleCard3" :text-card="textCard3" :icon-url="iconUrl3"></bloc-card-maif>
      </div>
    </div>
    <common-footer :is-maif="true"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonSurHeader from "@/components/common/CommonSurHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import GeneralHeader from "@/components/GeneralHeader.vue";
import BlocFonctionnementSolution from "@/components/maif/BlocFonctionnementSolution.vue";
import RapatrierVotreVehicule from "@/components/maif/BlocRapatrierVotreVehicule.vue";
import BlocRapatrierVotreVehicule from "@/components/maif/BlocRapatrierVotreVehicule.vue";
import BlocCardMaif from "@/components/maif/BlocCardMaif.vue";

export default {
  name: "CommentCaMarcheMaifPopValet",
  components: {
    BlocCardMaif,
    BlocRapatrierVotreVehicule,
    RapatrierVotreVehicule,
    BlocFonctionnementSolution,
    GeneralHeader,
    CommonFooter,
    CommonSurHeader,
    CommonHeader,
  },
  props: [
    'header',
    'titleCard1',
    'textCard1',
    'iconUrl1',
    'titleCard2',
    'textCard2',
    'iconUrl2',
    'titleCard3',
    'textCard3',
    'iconUrl3',
    'assuranceName'
  ],
}
</script>

<style>
.comment-ca-marche-last-card-container {
  padding: 0 100px 100px 100px;
}
.comment-ca-marche-first-card-container {
  padding: 100px 100px 0 100px;
}
.comment-ca-marche-card-container {
  padding: 100px;
}
@media screen and (max-width: 991px) {
  .comment-ca-marche-last-card-container {
    padding: 0 30px 100px 30px;
  }
  .comment-ca-marche-first-card-container {
    padding: 100px 30px 0 30px;
  }
  .comment-ca-marche-card-container {
    padding: 30px;
  }
}
</style>