<template>
  <div class="popvalet-general-vision screen">
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Notre vision" :icon-url="require('../../img/location-and-map---direction-tool@2x.png')"/>
    <div class="bloc_intro">
      <div class="overlap-group10-3">
        <p class="nous-sommes-convainc isidorasansalt-semi-bold-magic-potion-16px">{{ nousSommesConvainc }}
        <br><br>
          Grands champs de prestations, expériences digitales, atouts de notre solution : notre carte d’identité.
        <br><br>
          <a href="/vision-logistique-automobile" style="color: #002244">En savoir plus</a>
        </p>
      </div>
    </div>
    <div class="bloc_chronologie_mobile">
      <h2 class="isidorasansalt-bold-white-28px"><span class="underline-yellow">Pop Valet</span> en quelques dates</h2>
      <div class="bloc_chronologie_mobile_container">
        <div class="bloc_chronologie_mobile_row">
          <div class="x-lorem-ipsum-lorem-ipsum">
            <p class="isidorasansalt-bold-white-20px" style="text-transform: uppercase">Création de Parkadom</p>
            <p class="montserrat-normal-white-14px" style="margin-top: 10px">la première plateforme de partage de parkings</p>
            <p class="number">2012</p>
          </div>
          <div class="x-lorem-ipsum-lorem-ipsum">
            <p class="isidorasansalt-bold-white-20px" style="text-transform: uppercase">Lancement de Pop Valet</p>
            <p class="montserrat-normal-white-14px" style="margin-top: 10px">service de voiturier à la demande</p>
            <p class="number-1">2015</p>
          </div>
        </div>
        <div class="bloc_chronologie_mobile_row">
          <div class="x-lorem-ipsum-lorem-ipsum">
            <p class="isidorasansalt-bold-white-20px" style="text-transform: uppercase">Pivot de Pop Valet</p>
            <p class="montserrat-normal-white-14px" style="margin-top: 10px">vers le transport automobile à la demande</p>
            <p class="number-2">2016</p>
          </div>
          <div class="x-lorem-ipsum-lorem-ipsum">
            <p class="isidorasansalt-bold-white-20px" style="text-transform: uppercase">Levée de fonds série A</p>
            <p class="montserrat-normal-white-14px" style="margin-top: 10px">avec la MAIF et Inter-Mutuelles Assistance</p>
            <p class="number-3">2019</p>
          </div>
        </div>
        <div class="bloc_chronologie_mobile_row">
          <div class="x-lorem-ipsum-lorem-ipsum">
            <p class="isidorasansalt-bold-white-20px" style="text-transform: uppercase">Lancement de nouveaux services</p>
            <p class="montserrat-normal-white-14px" style="margin-top: 10px">et refonte de notre site internet </p>
            <p class="number-4">2023</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bloc_chronologie">
      <div class="overlap-group-container-1">
        <div class="overlap-group1-19">
          <img class="line-7-3" src="../../img/line-7-3@2x.png" alt="Line 7" />
          <div class="popvalet-en-quelques-dates isidorasansalt-bold-white-28px">{{ popvaletEnQuelquesDates }}</div>
        </div>
        <div class="overlap-group6-4">
          <div class="fonctionnement1">
            <camions :spanText1="camions1Props.spanText1" :spanText2="camions1Props.spanText2" />
            <div class="camions">
              <div class="number">{{ number1 }}</div>
            </div>
          </div>
          <div class="overlap-group-container-2">
            <div class="overlap-group8-3">
              <div class="fonctionnement1-1">
                <camions :spanText1="camions2Props.spanText1" :spanText2="camions2Props.spanText2" />
                <div class="camions">
                  <div class="number-1">{{ number2 }}</div>
                </div>
              </div>
              <div class="overlap-group4-6">
                <div class="rectangle-310"></div>
                <div class="line_timeline1">
                  <div class="group-236-1">
                    <div class="overlap-group-59">
                      <img class="line-18-1" src="../../img/line-18@2x.png" alt="Line 18" />
                      <div class="ellipse-40-1"></div>
                    </div>
                  </div>
                  <div class="picto_gerez_planning group-237"><img class="icon" src="../../img/location-and-map---direction-board@2x.png" alt="icon-calendar" /></div>
                </div>
              </div>
            </div>
            <div class="overlap-group3-5">
              <div class="fonctionnement1-2">
                <camions2 :spanText1="camions21Props.spanText1" :spanText2="camions21Props.spanText2" />
                <div class="camions">
                  <div class="number-2">{{ number3 }}</div>
                </div>
              </div>
              <div class="overlap-group5-5">
                <div class="rectangle-312"></div>
                <div class="line_timeline2">
                  <div class="picto_gerez_planning group-237-1"><img class="icon" src="../../img/location-and-map---direction-board@2x.png" alt="icon-calendar" /></div>
                  <div class="group-236-2">
                    <div class="overlap-group-60">
                      <img class="line-18-2" src="../../img/line-18-1@2x.png" alt="Line 18" />
                      <div class="ellipse-40-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overlap-group2-10">
              <div class="rectangle-313"></div>
              <div class="line_timeline3">
                <div class="group-236">
                  <div class="overlap-group-58">
                    <img class="line-18" src="../../img/line-18-3@2x.png" alt="Line 18" />
                    <div class="ellipse-40"></div>
                  </div>
                </div>
                <div class="picto_gerez_planning group-237-2"><img class="icon" src="../../img/location-and-map---direction-board-3@2x.png" alt="icon-calendar" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overlap-group7-4">
        <div class="fonctionnement1-3">
          <camions :spanText1="camions3Props.spanText1" :spanText2="camions3Props.spanText2" />
          <div class="camions">
            <div class="number-3">{{ number4 }}</div>
          </div>
        </div>
        <div class="fonctionnement1-4">
          <camions2 :spanText1="camions22Props.spanText1" :spanText2="camions22Props.spanText2" />
          <div class="camions">
            <div class="number-4">{{ number5 }}</div>
          </div>
        </div>
        <div class="overlap-group3-6">
          <div class="rectangle-314"></div>
          <div class="rectangle-315"></div>
          <div class="line_timeline2 line_timeline4">
            <div class="picto_gerez_planning group-237-3"><img class="icon" src="../../img/location-and-map---direction-board@2x.png" alt="icon-calendar" /></div>
            <div class="group-236-2">
              <div class="overlap-group-60">
                <img class="line-18-2" src="../../img/line-18-2@2x.png" alt="Line 18" />
                <div class="ellipse-40-2"></div>
              </div>
            </div>
          </div>
          <div class="line_timeline1 line_timeline5">
            <div class="group-236-1">
              <div class="overlap-group-59">
                <img class="line-18-1" src="../../img/line-18-4@2x.png" alt="Line 18" />
                <div class="ellipse-40-1"></div>
              </div>
            </div>
            <div class="picto_gerez_planning group-237-4"><img class="icon" src="../../img/location-and-map---direction-board@2x.png" alt="icon-calendar" /></div>
          </div>
        </div>
      </div>
    </div>
    <bloc-avantages2
      :lesAvantagesDeLaSolutionPopValet="blocAvantages2Props.lesAvantagesDeLaSolutionPopValet"
      :reactivitProps="blocAvantages2Props.reactivitProps"
      :qualitProps="blocAvantages2Props.qualitProps"
    />
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonHeader from "@/components/common/CommonHeader";
import Camions from "./Camions";
import LineTimeline1 from "./LineTimeline1";
import Camions2 from "./Camions2";
import LineTimeline2 from "./LineTimeline2";
import PictoGerezPlanning from "./PictoGerezPlanning";
import BlocAvantages2 from "./BlocAvantages2";
import CommonFooter from "@/components/common/CommonFooter";
import ProServicesHeader from "@/components/ProServicesHeader";
import GeneralHeader from "@/components/GeneralHeader";
export default {
  name: "PopvaletGeneralVision",
  components: {
    GeneralHeader,
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    Camions,
    LineTimeline1,
    Camions2,
    LineTimeline2,
    PictoGerezPlanning,
    BlocAvantages2,
    CommonFooter
  },
  props: [
    "header",
    "isMaif",
    "title",
    "nousSommesConvainc",
    "popvaletEnQuelquesDates",
    "number1",
    "number2",
    "number3",
    "number4",
    "number5",
    "camions1Props",
    "camions2Props",
    "lineTimeline11Props",
    "camions21Props",
    "lineTimeline21Props",
    "pictoGerezPlanningProps",
    "camions3Props",
    "camions22Props",
    "lineTimeline22Props",
    "lineTimeline12Props",
    "blocAvantages2Props",
  ],
};
</script>

<style>
.bloc_chronologie_mobile_container {
  margin-top: 50px;
}

.popvalet-general-vision {
  background-color: var(--blanc);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.bloc_intro {
  align-items: flex-start;
  display: flex;
}

.overlap-group10-3 {
  align-items: center;
  background-color: var(--blanc);
  display: flex;
}

.nous-sommes-convainc {
  letter-spacing: 0;
  line-height: 28.8px;
  min-height: 132px;
  text-align: center;
}

.bloc_chronologie_mobile {
  background-color: var(--bluenavy);
  padding: 88px 50px;
}

.bloc_chronologie_mobile h2 {
  text-align: center;
}

.bloc_chronologie {
  align-items: flex-start;
  background-color: var(--bluenavy);
  height: 555px;
  margin-top: 0.2px;
  min-width: 1440px;
  padding: 88px 120px;
}

.overlap-group-container-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 42px;
  min-height: 360px;
  width: 721px;
}

.overlap-group1-19 {
  height: 36px;
  margin-right: 23px;
  position: relative;
  width: 494px;
}

.line-7-3 {
  height: 6px;
  left: 2px;
  position: absolute;
  top: 23px;
  width: 120px;
}

.popvalet-en-quelques-dates {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 494px;
}

.overlap-group6-4 {
  height: 282px;
  position: relative;
  width: 721px;
}

.fonctionnement1 {
  display: flex;
  flex-direction: column;
  height: 94px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 240px;
}

.camions {
  display: flex;
  margin-left: 70px;
  width: 105px;
}

.number {
  color: var(--bluemed);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 36px;
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.overlap-group-container-2 {
  height: 282px;
  left: 0;
  position: absolute;
  top: 0;
  width: 721px;
}

.overlap-group8-3 {
  height: 152px;
  left: 0;
  position: absolute;
  top: 130px;
  width: 478px;
}

.fonctionnement1-1 {
  display: flex;
  flex-direction: column;
  height: 94px;
  left: 238px;
  position: absolute;
  top: 58px;
  width: 240px;
}

.number-1 {
  color: var(--grey);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 36px;
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.overlap-group4-6 {
  height: 152px;
  left: 0;
  position: absolute;
  top: 0;
  width: 240px;
}

.rectangle-310 {
  background-color: var(--bluemed);
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 240px;
}

.overlap-group3-5 {
  height: 154px;
  left: 240px;
  position: absolute;
  top: 0;
  width: 476px;
}

.fonctionnement1-2 {
  display: flex;
  flex-direction: column;
  height: 94px;
  left: 236px;
  position: absolute;
  top: 10px;
  width: 240px;
}

.number-2 {
  color: var(--blanc);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 36px;
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.overlap-group5-5 {
  height: 154px;
  left: 0;
  position: absolute;
  top: 0;
  width: 241px;
}

.rectangle-312 {
  background-color: var(--grey);
  height: 24px;
  left: 0;
  position: absolute;
  top: 130px;
  width: 241px;
}

.overlap-group2-10 {
  height: 152px;
  left: 481px;
  position: absolute;
  top: 130px;
  width: 240px;
}

.rectangle-313 {
  background-color: var(--blanc);
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 240px;
}

.line_timeline3 {
  display: flex;
  flex-direction: column;
  height: 147px;
  left: 81px;
  position: absolute;
  top: 5px;
  width: 68px;
}

.group-236 {
  align-items: flex-end;
  display: flex;
  height: 79px;
  margin-left: 28px;
  min-width: 13px;
}

.overlap-group-58 {
  height: 80px;
  margin-bottom: -1.5px;
  position: relative;
  width: 13px;
}

.line-18 {
  height: 78px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 3px;
}

.ellipse-40 {
  background-color: var(--blanc);
  border: 1px solid;
  border-radius: 6.5px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.overlap-group7-4 {
  align-self: flex-end;
  height: 282px;
  margin-bottom: 19px;
  position: relative;
  width: 479px;
}

.fonctionnement1-3 {
  display: flex;
  flex-direction: column;
  height: 94px;
  left: 0;
  position: absolute;
  top: 188px;
  width: 240px;
}

.number-3 {
  color: var(--yellow);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 36px;
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.fonctionnement1-4 {
  display: flex;
  flex-direction: column;
  height: 94px;
  left: 231px;
  position: absolute;
  top: 10px;
  width: 240px;
}

.number-4 {
  color: var(--violet);
  font-family: var(--font-family-isidora_sans_alt-bold);
  font-size: 36px;
  font-weight: 700;
  height: 46px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.overlap-group3-6 {
  height: 282px;
  left: 0;
  position: absolute;
  top: 0;
  width: 479px;
}

.rectangle-314 {
  background-color: var(--yellow);
  height: 24px;
  left: 0;
  position: absolute;
  top: 130px;
  width: 240px;
}

.rectangle-315 {
  background-color: var(--violet);
  height: 24px;
  left: 239px;
  position: absolute;
  top: 130px;
  width: 240px;
}

@media screen and (max-width: 991px) {
  .nous-sommes-convainc {
    width: 100%;
  }
  .overlap-group10-3 {
    min-width: 100%;
    padding: 50px;
  }
  .bloc_intro {
    min-width: 100%;
  }
  .bloc_chronologie {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .overlap-group10-3 {
    margin: auto;
    padding: 90px 223px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .bloc_chronologie_mobile_row {
    display: flex;
    justify-content: space-around;
  }
  .overlap-group10-3 {
    margin: auto;
    padding: 90px 223px;
  }
  .bloc_chronologie {
    display: none;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .bloc_chronologie_mobile {
    display: none
  }
  .bloc_chronologie {
    display: flex;
    justify-content: center;
  }
  .rectangle-310 {
    width: 180px;
    left: -90px;
  }
  .rectangle-312 {
    width: 180px;
    left: -70px;
  }
  .rectangle-313 {
    width: 180px;
    left: -50px;
  }
  .rectangle-314 {
    width: 180px;
    left: -31px;
  }
  .rectangle-315 {
    width: 180px;
    left: 148px;
  }
  .line_timeline1 {
    left: -28px;
  }
  .line_timeline2 {
    left: -10px;
  }
  .line_timeline3 {
    left: 8px;
  }
  .line_timeline2.line_timeline4 {
    left: 25px;
  }
  .line_timeline1.line_timeline5 {
    left: 210px;
  }
  .overlap-group5-5 {
    left: -80px;
  }
  .overlap-group2-10 {
    left: 320px;
  }
  .overlap-group3-6 {
    left: -241px;
  }
  .fonctionnement1 {
    left: -105px;
    top: -35px;
  }
  .fonctionnement1-1 {
    left: 70px;
    top: 18px;
  }
  .fonctionnement1-2 {
    left: 8px;
    top: 5px;
  }
  .fonctionnement1-3 {
    left: -295px;
    top: 145px;
  }
  .fonctionnement1-4 {
    left: -110px;
    top: 10px;
  }
  .bloc_chronologie {
    min-width: unset;
  }
  .overlap-group7-4 {
    width: unset;
  }
  .overlap-group-container-1 {
    align-items: unset;
  }
}

@media screen and (min-width: 1441px) {
  .number {
    width: 103px;
  }
  .number-1 {
    width: 103px;
  }
  .number-2 {
    width: 103px;
  }
  .number-3 {
    width: 103px;
  }
  .number-4 {
    width: 103px;
  }
  .overlap-group10-3 {
    min-width: 1440px;
    padding: 90px 223px;
  }
  .bloc_intro {
    min-width: 1440px;
  }
  .bloc_intro {
    justify-content: center;
  }
  .bloc_chronologie {
    display: flex;
    justify-content: center;
  }
  .bloc_chronologie_mobile {
    display: none;
  }
}
</style>
