<template>
  <div class="group-71">
    <div class="frame-72">
      <div class="tre-recont-act blogtextbold">{{ treRecontact }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group71",
  props: ["treRecontact"],
};
</script>

<style>
.group-71 {
  display: flex;
  height: 44px;
  min-width: 244px;
}

.frame-72 {
  align-items: flex-start;
  background-color: var(--blanc);
  border: 1px solid;
  border-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  padding: 12px 30px;
  width: 244px;
}

.tre-recont-act {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}
</style>
