<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Notre technologie : un algorithme et une interface au service de vos transports de véhicules" :icon-url="require('../../img/Internet.png')"/>
    <div class="general-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Faire appel à l’équipe Pop Valet, c’est aussi faire le choix du digital au service de vos solutions de transport automobile. Forts de notre fonctionnement d’opérateur logistique intégré, nous collectons les disponibilités de nos Valets en amont de la réception des missions que nous confient nos clients.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Notre algorithme nous permet non seulement d’optimiser nos prestations, mais aussi de les dispatcher à nos Valets les plus performants grâce à un système de scoring. Le résultat ? Des délais de réalisation de missions raccourcis pour une haute qualité de réalisation. Détails.</p><br>
    </div>
    <div class="general-technologie-atouts-container">
      <h2 class="isidorasansalt-bold-white-28px">Les atouts-clés de notre technologie</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Réunies, les caractéristiques de la technologie propulsée par Pop Valet nous permettent de vous faire gagner en productivité pour l’ensemble de vos missions de convoyage, de livraison, de réparation ou autres. Inventaire.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Une saisie en quelques clics</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Quelle que soit la nature de votre besoin, vous complétez votre demande en moins d’une minute.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Nos disponibilités en temps réel</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Lorsque vous renseignez vos disponibilités, vous ne perdez pas de temps à attendre qu’un membre de notre équipe vous réponde ; vous y accédez en temps réel vous-même et choisissez conséquemment le créneau de votre convenance.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Une confirmation automatique</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Pressé(e) ? Une fois votre commande passée et validée, vous obtenez une confirmation immédiate. Votre mission peut bientôt commencer.</p>

      <h3 class="isidorasansalt-bold-white-24px">Un suivi en temps réel</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Lorsque l’un de nos Valets conduit une voiture que vous lui avez confiée, vous pouvez visualiser en direct sa position sur une carte.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un recueil des avis</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous obtenez un feedback de votre client quant à la qualité de sa livraison et donnez vous-même votre avis sur la prestation de votre Valet.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Une affectation automatique</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous tirez parti d’un dispatch basé sur la qualité ; sont priorisés nos Valets les mieux notés.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un envoi automatique des documents</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Ni paperasse à stocker, ni besoin de nous relancer ; avant, pendant et après votre mission, vous recevez vos documents numérisés en haute qualité.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Une API documentée</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">API ou iFrame : vous profitez d’une interface intuitive et professionnelle sur les supports de votre choix pouvant s’intégrer à vos processus, grâce à notre équipe technique en interne.</p>
    </div>
    <div class="general-technologie-demat-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Des états des lieux dématérialisés et géolocalisés</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Élément quintessentiel de toute prestation de transport de véhicule, l’état des lieux est un document écrit constatant l’état dans lequel se trouve une voiture à la collecte puis à la livraison. Il est signé par chacun des partis concernés par le transfert pour limiter les responsabilités.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Chez Pop Valet, il est systématique et complet – et pas simplement contradictoire – quelle que soit la nature de la mission qui nous est confiée. Dématérialisé et géolocalisé, il est modulable en fonction de vos besoins et peut, par exemple, intégrer la liste des effets inclus dans l’habitacle.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Enfin, il génère instantanément un rapport de mission au format PDF, fichier qui est stocké et consultable à l’envi dans votre interface et qui reprend l’ensemble des éléments traités dans le cadre de la mission :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Check-lists</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Documents</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Photographies</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Etc.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Qu’incluent les états des lieux Pop Valet ?</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour l’ensemble de nos missions, nos documents incorporent :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Les photographies horodatées</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- L’annotation des potentiels dommages</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Le relevé du niveau d’essence et du kilométrage</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- La signature de chacune des parties</p><br>
    </div>
    <div class="general-technologie-savoir-plus-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">En savoir plus sur Pop Valet</h2>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour mieux nous connaître et vous familiariser avec nos différentes solutions, découvrez également <a href="/vision" style="color: #002244">notre vision</a> : comment s’articulent nos différentes prestations pour vous permettre d’optimiser votre logistique automobile.</p>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "TechnologieInfo",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif"
  ],
  metaInfo: {
    title: 'Notre Technologie : Algorithme/Interface (Transports) | Pop Valet',
    meta: [
      { name: 'description', content: 'Faire appel à Pop Valet, c’est aussi faire le choix du digital pour vos solutions de transport automobile. Découvrez notre algorithme et notre interface.' },
    ]
  }
}
</script>

<style scoped>
.general-technologie-savoir-plus-container h3 {
  margin-top: 25px;
}
.general-technologie-savoir-plus-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-technologie-savoir-plus-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.general-technologie-demat-container h3 {
  margin-top: 25px;
}
.general-technologie-demat-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-technologie-demat-container {
  padding: 100px 150px;
  line-height: 29px;
}
.general-technologie-atouts-container h3 {
  margin-top: 25px;
}
.general-technologie-atouts-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-technologie-atouts-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.general-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .general-technologie-savoir-plus-container {
    padding: 100px 50px;
  }
  .general-technologie-demat-container {
    padding: 100px 50px;
  }
  .general-technologie-atouts-container {
    padding: 100px 50px;
  }
}
</style>