<template>
  <div :class="[`bloc_avantages`, className || ``]">
    <p class="les-avantages-de-la-solution-pop-valet isidorasansalt-bold-white-28px">
      LES AVANTAGES DE LA SOLUTION POP VALET
    </p>
    <reactivit
      :pictoReactiviteProps="reactivitProps.pictoReactiviteProps"
      :voirTousLesUsesCases2Props="reactivitProps.voirTousLesUsesCases2Props"
    />
    <qualit
      :voirTousLesUsesCases2Props="qualitProps.voirTousLesUsesCases2Props"
    />
  </div>
</template>

<script>
import Reactivit from "./Reactivit";
import Qualit from "./Qualit";
export default {
  name: "BlocAvantages",
  components: {
    Reactivit,
    Qualit,
  },
  props: ["className", "reactivitProps", "qualitProps"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .bloc_avantages {
    min-width: 100%;
    align-items: center;
    height: unset;
    flex-direction: column;
    gap: 35px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .bloc_avantages {
    min-width: 1440px;
    align-items: flex-end;
    height: unset;
  }
}

@media screen and (min-width: 1441px) {
  .bloc_avantages {
    min-width: 100%;
    justify-content: center;
    align-items: flex-end;
    height: unset;
  }
}

.bloc_avantages {
  background-color: var(--bluenavy);
  display: flex;
  margin-top: 2px;
  padding: 103px 222px;
  position: relative;
}

.les-avantages-de-la-solution-pop-valet {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 64px;
  width: 339px;
}

.bloc_avantages.bloc_avantages-1 {
  margin-top: 106px;
}
</style>
