<template>
  <div class="services-2500-valets-container">
    <div class="services-2500-valets">
      <p class="une-quipe-de-plus-d isidorasansalt-bold-magic-potion-28px">
        Une équipe de plus de <span class="underline-yellow"><span class="no-wrap">2 500</span> valets dans toute la France</span>
      </p>
      <div class="valets-cards-container">
        <div class="valet-card" v-for="valet in valets">
          <img class="valet-picture" :src="valet.picture" alt="8058af_ce698fcea60d4bfa9069a6f825cc9a6b~mv2"/>
          <div :class="valet.class" style="width: 284px; line-height: 21px">
            <p class="isidorasansalt-semi-bold-magic-potion-16px">{{ valet.description }}</p>
          </div>
          <p class="xavier-convoyeur-pop-valet-depuis-7-ans montserrat-medium-white-20px">
            <span class="montserrat-medium-magic-potion-20px">{{ valet.title }}</span><br>
            <span class="span-63 textcourantsmall">{{ valet.subTitle }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProServices2500Valets",
  data: function() {
    return {
      valets: [{
        picture: require('../../img/valet-julien.jpeg'),
        title: 'Julien',
        subTitle: 'Convoyeur Pop Valet depuis 7 ans',
        description: 'L\'avantage avec Pop Valet, c\'est que je suis libre d\'organiser mon agenda et d\'indiquer mes disponibilités.',
        class: "valet-description-1"
      }, {
        picture: require('../../img/valet-julie.jpeg'),
        title: 'Julie',
        subTitle: 'Convoyeuse Pop Valet depuis 3 ans',
        description: 'J\'ai beaucoup d\'autonomie dans la réalisation de mes missions mais je peux aussi compter sur des interlocuteurs au sein de Pop Valet.',
        class: "valet-description-2"
      }, {
        picture: require('../../img/valet-paul.jpeg'),
        title: 'Paul',
        subTitle: 'Convoyeur Pop Valet depuis 2 ans',
        description: 'J\'ai choisi Pop Valet car les délais de paiement sont les plus courts du secteur et les rémunérations sont attractives.',
        class: "valet-description-1"
      }, {
        picture: require('../../img/valet-xavier.jpeg'),
        title: 'Xavier',
        subTitle: 'Convoyeur Pop Valet depuis 7 ans',
        description: 'Le convoyage est synonyme de liberté, et c\'est exactement ce que je recherchais en complément de mon activité salariée.',
        class: "valet-description-2"
      }]
    };
  }
}
</script>

<style scoped>
.valet-picture:hover {
  border-radius: 12px 12px 0 0;
}

.valet-picture:hover ~ .xavier-convoyeur-pop-valet-depuis-7-ans {
  opacity: 0;
}

.valet-picture:hover ~ .valet-description-2 {
  bottom: -80px;
}

.valet-picture:hover ~ .valet-description-1 {
  bottom: -60px;
}

.valet-description-2 {
  border-radius: 0 0 10px 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: absolute;
  bottom: 225px;
  transition: bottom 0.3s ease;
}

.valet-description-1 {
  border-radius: 0 0 10px 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: absolute;
  bottom: 225px;
  transition: bottom 0.3s ease;
}

.services-2500-valets-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.services-2500-valets {
  max-width: 1440px;
  margin-top: 115px;
  margin-bottom: 125px;
}

.une-quipe-de-plus-d {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 70px;
  padding: 0 10px;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -13px;
  text-decoration-color: var(--mustard);
}

.no-wrap {
  white-space: nowrap;
}

.valets-cards-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.valet-card {
  position: relative;
}

.valet-picture {
  height: 354px;
  width: 284px;
  border-radius: 12px;
  cursor: pointer;
  z-index: 2;
  position: relative;
  transition: border-radius 0.3s ease, box-shadow 0.3s ease;
}

.xavier-convoyeur-pop-valet-depuis-7-ans {
  margin-top: 10px;
  letter-spacing: 0;
  line-height: normal;
  width: 285px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.span-63 {
  color: var(--grey);
  font-weight: 400;
}
</style>