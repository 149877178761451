<template>
  <div class="maif-container">
    <div class="maif-component">
      <p class="maif-text isidorasansalt-bold-magic-potion-28px">
        {{txtAssurance}}
      </p>
      <div class="maif-logo">
        <p class="maif-logo-text montserrat-medium-magic-potion-20px">
          Pop Valet est assuré par la
        </p>
        <a href="/popvalet-general-assurance"><img class="maif-logo-img" src="../../img/logo-maif.png" alt="Logo MAIF"/></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocMaif",
  props: ["txtAssurance"]
}
</script>

<style scoped>
.maif-logo-img:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.2)
}
.maif-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: var(--white);
  width: 100%;
  padding: 60px 20px;
}
.maif-component {
  display: flex;
  flex-direction: column;
}
.maif-text {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  max-width: 850px;
}
.maif-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.maif-logo-text {
  margin-top: 15px;
}
.maif-logo-img {
  width: 120px;
}

@media screen and (max-width: 576px) {
  .maif-component {
    gap: 30px;
  }
  .maif-logo {
    flex-direction: column;
  }
}

</style>