<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Réparation" :icon-url="require('../../img/Repair Tool.png')"/>
    <div class="pro-reparation-description-section">
      <div class="pro-reparation-description-text-container">
        <p class="isidorasansalt-semi-bold-magic-potion-16px">Vous êtes un professionnel de l’automobile et recherchez une solution de transport incluant la révision, la réparation ou le reconditionnement d’un ou plusieurs de vos véhicules ? Pop Valet répond présent : avant la livraison d’une voiture ou simplement dans le cadre de la maintenance de votre parc auto.</p><br>
        <p class="isidorasansalt-semi-bold-magic-potion-16px">Prestations couvertes, avantages : nos équipes vous présentent leur méthode.</p><br>
      </div>
      <div class="pro-reparation-description-icon-container">
        <div class="pro-reparation-description-icon">
          <img src="../../img/garage.png" alt="">
        </div>
      </div>
      <div class="pro-reparation-description-icon-text-container">
        <p class="montserrat-semi-bold-magic-potion-20px">GARAGES INDÉPENDANTS PARTENAIRES</p>
        <p class="montserrat-semi-bold-magic-potion-20px">Pour des réparations rapides, efficaces et compétitives</p>
      </div>
      <div class="pro-reparation-description-read-more-container">
        <div class="en-savoir-plus montserrat-medium-gunsmoke-18px" style="margin: 10px auto"><a href="/reparation-vehicules-professionnels" style="text-decoration: none">En savoir plus ></a></div>
      </div>
    </div>
    <div class="pro-reparation-cas-usage-section">
      <div class="pro-reparation-cas-usage-image-container">
        <div class="pro-reparation-cas-usage-image"></div>
      </div>
      <div class="pro-reparation-cas-usage-text-container">
        <img class="pro-reparation-cas-usage-text-container-img" src="../../img/goal.png" alt="">
        <h2 class="isidorasansalt-semi-bold-magic-potion-28px">Cas d'usage</h2>
        <div class="pro-reparation-cas-usage-text-container-list pro-reparation-cas-usage-text-container-list-margin">
          <img src="../../img/Approve.png" alt="">
          <p class="isidorasansalt-semi-bold-magic-potion-19px"><span class="isidorasansalt-bold-magic-potion-19px">Restitution :</span> Réparation en amont d'une restitution pour éviter une surfacturation par le loueur</p>
        </div>
        <div class="pro-reparation-cas-usage-text-container-list pro-reparation-cas-usage-text-container-list-margin">
          <img src="../../img/Approve.png" alt="">
          <p class="isidorasansalt-semi-bold-magic-potion-19px"><span class="isidorasansalt-bold-magic-potion-19px">Location Courte Durée :</span> Remise en état suite à des dommages survenus lors du contrat de location</p>
        </div>
        <div class="pro-reparation-cas-usage-text-container-list pro-reparation-cas-usage-text-container-list-margin">
          <img src="../../img/Approve.png" alt="">
          <p class="isidorasansalt-semi-bold-magic-potion-19px"><span class="isidorasansalt-bold-magic-potion-19px">Reconditionnement :</span> Réparations optimisées en vue de la revente du véhicule</p>
        </div>
        <div class="pro-reparation-cas-usage-text-container-list">
          <img src="../../img/Approve.png" alt="">
          <p class="isidorasansalt-semi-bold-magic-potion-19px"><span class="isidorasansalt-bold-magic-potion-19px">Accident/ Sinistre :</span> Interventions ciblées pour réparer des dommages spécifiques</p>
        </div>
      </div>
    </div>
    <pro-services-avantages :avantages="avantages"/>
    <div class="pro-reparation-fonctionnement-section">
      <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">FONCTIONNEMENT</span> DE LA RÉPARATION</h2>
      <div class="pro-reparation-fonctionnement-timeline-container">
        <div class="pro-reparation-fonctionnement-timeline-container-tl"></div>
        <div class="pro-reparation-fonctionnement-timeline-container-tl-elements pro-reparation-fonctionnement-timeline-container-tl-elements-1">
          <img src="../../img/pin.png" alt="">
          <div class="pro-reparation-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">1</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">DEVIS</h3>
          <p class="montserrat-normal-magic-potion-14px">Chiffrage des travaux sous 24h</p>
        </div>
        <div class="pro-reparation-fonctionnement-timeline-container-tl-elements pro-reparation-fonctionnement-timeline-container-tl-elements-2">
          <img src="../../img/pin.png" alt="">
          <div class="pro-reparation-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">2</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">TRANSPORT</h3>
          <p class="montserrat-normal-magic-potion-14px">Prise en charge du véhicule en 24h à l'adresse de votre choix</p>
        </div>
        <div class="pro-reparation-fonctionnement-timeline-container-tl-elements pro-reparation-fonctionnement-timeline-container-tl-elements-3">
          <img src="../../img/pin.png" alt="">
          <div class="pro-reparation-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">3</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">RÉPARATION</h3>
          <p class="montserrat-normal-magic-potion-14px">Réparation dans un garage sélectionné sous 5 jours</p>
        </div>
        <div class="pro-reparation-fonctionnement-timeline-container-tl-elements pro-reparation-fonctionnement-timeline-container-tl-elements-4">
          <img src="../../img/pin.png" alt="">
          <div class="pro-reparation-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">4</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">LIVRAISON</h3>
          <p class="montserrat-normal-magic-potion-14px">Réaffectation ou livraison sous 1 jour à l'adresse de votre choix</p>
        </div>
      </div>
    </div>
    <div class="bloc_fonctionnement_mobile">
      <div class="bloc_fonctionnement_container">
        <div class="bloc_fonctionnement_reparation_title isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">FONCTIONNEMENT</span> DE LA RÉPARATION</div>
        <div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>1</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">DEVIS</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Chiffrage des travaux sous 24h</span>
            </div>
          </div>
          <div class="bloc_fonctionnement_step_arrow">
            <img src="../../img/line-8@2x.png" alt="">
          </div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>2</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">TRANSPORT</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Prise en charge du véhicule en 24h à l'adresse de votre choix</span>
            </div>
          </div>
          <div class="bloc_fonctionnement_step_arrow">
            <img src="../../img/line-8@2x.png" alt="">
          </div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>3</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">RÉPARATION</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Réparation dans un garage sélectionné sous 5 jours</span>
            </div>
          </div>
          <div class="bloc_fonctionnement_step_arrow">
            <img src="../../img/line-8@2x.png" alt="">
          </div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>4</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">LIVRAISON</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Réaffectation ou livraison sous 1 jour à l'adresse de votre choix</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";
import ProServicesAvantages from "@/components/ProServicesAvantages";

export default {
  name: "PopvaletProServicesReparation",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
    ProServicesAvantages
  },
  props: [
    'header',
    'avantages'
  ]
}
</script>

<style scoped>
.en-savoir-plus.montserrat-medium-gunsmoke-18px a {
  color: var(--grey)
}
.en-savoir-plus.montserrat-medium-gunsmoke-18px a:hover {
  color: var(--bluenavy)
}
.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -12px;
  -webkit-text-decoration-color: var(--mustard);
  text-decoration-color: var(--mustard);
}
.pro-reparation-fonctionnement-timeline-container-tl-elements-4 {
  left: 82%;
  bottom: -97px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements-3 {
  left: 51%;
  bottom: -97px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements-2 {
  left: 22%;
  bottom: -97px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements-1 {
  left: -5%;
  bottom: -77px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements-group-step {
  color: white;
  background-color: #002244;
  padding: 4px 0;
  width: 24px;
  border-radius: 15px;
  text-align: center;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements p {
  width: 285px;
  text-align: center;
  line-height: 20px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements img {
  margin-bottom: 15px;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pro-reparation-fonctionnement-timeline-container-tl-elements {
  display: flex;
  position: absolute;
}
.pro-reparation-fonctionnement-timeline-container-tl {
  height: 6px;
  background-color: #002244;
  width: 100%;
}
.pro-reparation-fonctionnement-timeline-container {
  margin-top: 200px;
  position: relative;
}
.pro-reparation-fonctionnement-section {
  padding: 90px 250px 90px 250px;
  min-height: 550px;
}
.pro-reparation-avantages-container-first-row-container-left,
.pro-reparation-avantages-container-second-row-container-left {
  margin-right: 225px;
}
.pro-reparation-avantages-container-first-row-container p,
.pro-reparation-avantages-container-second-row-container p {
  color: white;
  margin-left: 25px;
  display: flex;
  align-items: center;
  width: 330px;
  line-height: 22px;
}
.pro-reparation-avantages-container-first-row-container img,
.pro-reparation-avantages-container-second-row-container img {
  height: 47px;
}
.pro-reparation-avantages-container-first-row-container,
.pro-reparation-avantages-container-second-row-container {
  display: flex;
}
.pro-reparation-avantages-container-first-row,
.pro-reparation-avantages-container-second-row {
  margin-top: 80px;
  display: flex;
}
.pro-reparation-avantages-section h2 {
  color: white;
}
.pro-reparation-avantages-section {
  padding: 90px 250px 90px 250px;
  background-color: #002244;
}
.pro-reparation-cas-usage-text-container-list img {
  height: 22px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}
.pro-reparation-cas-usage-text-container-list {
  display: flex;
  line-height: 29px;
}
.pro-reparation-cas-usage-text-container-list-margin {
  margin-bottom: 15px;
}
.pro-reparation-cas-usage-text-container h2 {
  margin-bottom: 20px;
}
.pro-reparation-cas-usage-text-container-img {
  margin-bottom: 10px;
}
.pro-reparation-cas-usage-text-container {
  padding: 0 40px;
}
.pro-reparation-cas-usage-section {
  padding: 90px 250px 90px 250px;
  display: flex;
  background-color: #F7F6F4;
}
.pro-reparation-cas-usage-image {
  height: 430px;
  width: 580px;
  background-image: url(../../img/Garage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -50px;
  border-radius: 8px;
}
.pro-reparation-description-icon-container {
  margin-bottom: 25px;
}
.pro-reparation-description-text-container {
  margin-bottom: 50px;
}
.pro-reparation-description-icon-container {
  display: flex;
  justify-content: center;
}
.pro-reparation-description-icon img {
  height: 37px;
}
.pro-reparation-description-icon {
  background-color: var(--yellow);
  padding: 15px 12px;
  border-radius: 50px;
}
.pro-reparation-description-section p {
  text-align: center;
  line-height: 29px;
}

.pro-reparation-description-section {
  padding: 90px 250px 90px 250px;
}
.bloc_fonctionnement_mobile {
  display: none;
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .pro-reparation-fonctionnement-section {
    padding: 90px 150px 90px 100px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .pro-reparation-fonctionnement-section {
    padding: 90px 150px 90px 100px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .pro-reparation-fonctionnement-timeline-container-tl-elements p {
    width: 215px;
  }
  .pro-reparation-cas-usage-section {
    padding: 90px 100px 90px 100px;
  }
  .pro-reparation-avantages-container-first-row-container-left,
  .pro-reparation-avantages-container-second-row-container-left {
    margin-right: 50px;
  }
  .pro-reparation-avantages-section {
    padding: 90px 100px 90px 100px;
  }
}

@media screen and (max-width: 991px) {
  .pro-reparation-fonctionnement-section {
    display: none;
  }
  .bloc_fonctionnement_reparation_title {
    text-align: center;
    margin-bottom: 60px;
  }
  .bloc_fonctionnement_mobile {
    padding: 30px;
    margin: 25px 0;
    display: block;
  }
  .pro-reparation-avantages-container-first-row,
  .pro-reparation-avantages-container-second-row {
    margin-top: 40px;
  }
  .pro-reparation-avantages-container-second-row-container {
    margin-top: 25px;
  }
  .pro-reparation-avantages-container-first-row-container {
    margin-top: 25px;
  }
  .pro-reparation-avantages-container-first-row-container-left,
  .pro-reparation-avantages-container-second-row-container-left {
    margin-right: 0;
  }
  .pro-reparation-avantages-container-second-row {
    flex-direction: column;
  }
  .pro-reparation-avantages-container-first-row {
    flex-direction: column;
  }
  .pro-reparation-avantages-section {
    padding: 90px 30px 90px 30px;
  }
  .pro-reparation-cas-usage-text-container {
    margin-top: 50px;
    padding: 0;
  }
  .pro-reparation-cas-usage-image {
    height: 250px;
    width: 100%;
  }
  .pro-reparation-cas-usage-section {
    padding: 90px 30px 90px 30px;
    flex-direction: column;
  }
  .pro-reparation-description-section {
    padding: 90px 30px 90px 30px;
  }
}
</style>