<template>
  <div :class="[`delai`, className || ``]">
    <img
      class="user-interface-backup"
      src="../../img/user-interface---backup@2x.png"
      alt="User Interface / Backup"
    />
  </div>
</template>

<script>
export default {
  name: "Delai",
  props: ["className"],
};
</script>

<style>
.delai {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-left: 1px;
  width: 56px;
}

.user-interface-backup {
  flex: 1;
  height: 36px;
  margin-left: 16.07%;
  margin-right: 17.86%;
  margin-top: 10px;
  width: 37px;
}

.delai.annee_existence,
.delai.annee_existence-1 {
  margin-left: 5px;
  margin-top: 72px;
}

.delai.annee_existence .user-interface-backup {
  height: 37px;
}

.delai.delai-1 .user-interface-backup {
  flex: unset;
  margin-left: 9px;
  margin-right: unset;
}

.delai.annee_existence-1 .user-interface-backup {
  flex: unset;
  height: 37px;
  margin-left: 9px;
  margin-right: unset;
}
</style>
