<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Restitution" :icon-url="require('../../img/Reload.png')"/>
    <div class="pro-restitution-description-section">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Vous êtes un professionnel de l’automobile et recherchez une solution de restitution de vos véhicules sur parc ? Fort de ses 20 parcs de stockage répartis sur tout le territoire, Pop Valet facilite votre logistique et vous aide à optimiser à la fois votre transport, vos coûts, ainsi que les interventions nécessaires sur vos véhicules.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Prestations, cas pratiques, services additionnels : présentation.</p><br>
      <a href="/restitution-vehicules-professionnels" style="color: #002244"><p class="isidorasansalt-semi-bold-magic-potion-16px">En savoir plus</p></a>
    </div>
    <div class="pro-restitution-toggle">
      <div class="restitution-toggle">
        <input type="checkbox" id="restitution-toggle-btn" v-on:click="goNext">
        <label for="restitution-toggle-btn">
          <img class="restitution-toggle-btn-pop-1" src="../../img/mini-pop-1.png" alt="">
          <img class="restitution-toggle-btn-pop-2" src="../../img/mini-pop-2.png" alt="">
        </label>
      </div>
    </div>
    <div class="pro-restitution-parcours-section-container">
      <div class="flicking-viewport">
        <Flicking ref="flicking" :plugins="plugins" :options="{circular: true}">
          <div class="pro-restitution-parcours-section">
            <div class="pro-restitution-parcours-section-1">
              <div class="pro-restitution-parcours-title">
                <div class="pro-restitution-parcours-title-container">
                  <h2 class="isidorasansalt-bold-magic-potion-28px">UN PARCOURS DE RESTITUTION DIRECTE AVEC POP VALET !</h2>
                  <p class="isidorasansalt-semi-bold-magic-potion-24px pro-restitution-parcours-title-text"><span class="underline-yellow">Un procédé accéléré</span> pour le confort des concessions</p>
                </div>
              </div>
              <div class="pro-restitution-parcours-container">
                <div class="pro-restitution-parcours-container">
                  <div class="pro-restitution-parcours-container-row">
                    <div class="pro-restitution-parcours-container-row-step-card">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">1</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img" src="../../img/restitution-approvisionnement.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Approvisionnement</h3>
                        <p class="montserrat-normal-magic-potion-12px">Commandes de véhicules</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">2</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-2" src="../../img/restitution-livraison-vn.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Livraison du VN</h3>
                        <p class="montserrat-normal-magic-potion-12px">Auprès des clients</p>
                      </div>
                    </div>
                    <img class="pro-restitution-parcours-container-smiley" src="../../img/restitution-smiley.png" alt="">
                    <img class="pro-restitution-parcours-container-arrow-down" src="../../img/restitution-arrow-down.png" alt="">
                  </div>
                  <div class="pro-restitution-parcours-container-row">
                    <div class="pro-restitution-parcours-container-row-step-card">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">4</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-4" src="../../img/restitution-revente.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Revente</h3>
                        <p class="montserrat-normal-magic-potion-12px">Par le loueur</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">3</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-3" src="../../img/restitution-restitution.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Restitution</h3>
                        <p class="montserrat-normal-magic-potion-12px">Directement sur le parc du loueur</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pro-restitution-parcours-section">
            <div class="pro-restitution-parcours-section-2">
              <div class="pro-restitution-parcours-title">
                <div class="pro-restitution-parcours-title-container">
                  <h2 class="isidorasansalt-bold-magic-potion-28px">UN PARCOURS CLASSIQUE DE RESTITUTION DE VÉHICULES !</h2>
                  <p class="isidorasansalt-semi-bold-magic-potion-24px pro-restitution-parcours-title-text"><span class="underline-yellow">Un procédé long</span> coûteux et fastidieux pour les équipes</p>
                </div>
              </div>
              <div class="pro-restitution-parcours-container">
                <div class="pro-restitution-parcours-container">
                  <div class="pro-restitution-parcours-container-row">
                    <div class="pro-restitution-parcours-container-row-step-card">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">1</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img" src="../../img/restitution-approvisionnement.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Approvisionnement</h3>
                        <p class="montserrat-normal-magic-potion-12px">Commandes de véhicules</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">2</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-2" src="../../img/restitution-livraison-vn.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Livraison du VN</h3>
                        <p class="montserrat-normal-magic-potion-12px">Auprès des clients</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">3</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-5" src="../../img/restitution-convoyage.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Convoyage</h3>
                        <p class="montserrat-normal-magic-potion-12px">Retour du VO en concession</p>
                      </div>
                    </div>
                    <img class="pro-restitution-parcours-container-arrow-right" src="../../img/restitution-arrow-down.png" alt="">
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin pro-restitution-parcours-container-row-step-card-position">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">4</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-5" src="../../img/restitution-stockage.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Stockage</h3>
                        <p class="montserrat-normal-magic-potion-12px">Du VO en concession</p>
                      </div>
                    </div>
                    <img class="pro-restitution-parcours-container-arrow-left" src="../../img/restitution-arrow-down.png" alt="">
                  </div>
                  <div class="pro-restitution-parcours-container-row">
                    <div class="pro-restitution-parcours-container-row-step-card">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">7</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-4" src="../../img/restitution-revente.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Revente</h3>
                        <p class="montserrat-normal-magic-potion-12px">Par le loueur</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">6</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-3" src="../../img/restitution-restitution.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Restitution</h3>
                        <p class="montserrat-normal-magic-potion-12px">Directement sur le parc du loueur</p>
                      </div>
                    </div>
                    <div class="pro-restitution-parcours-container-row-step-card pro-restitution-parcours-container-row-step-card-margin">
                      <p class="pro-restitution-parcours-container-row-step-card-text-numero montserrat-bold-magic-potion-16px">5</p>
                      <img class="pro-restitution-parcours-container-row-step-card-img-6" src="../../img/restitution-administratif.png" alt="">
                      <div class="pro-restitution-parcours-container-row-step-card-text">
                        <h3 class="montserrat-bold-magic-potion-16px">Administratif</h3>
                        <p class="montserrat-normal-magic-potion-12px">Clôture du contrat</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Flicking>
      </div>
    </div>
    <div class="pro-restitution-gestion-section">
      <div class="pro-restitution-gestion-section-title">
        <h2 class="isidorasansalt-bold-magic-potion-28px">GESTION DE VOS RESTITUTIONS</h2>
        <p class="isidorasansalt-bold-magic-potion-28px">Pour plus de confort et d'espace</p>
      </div>
      <div class="pro-restitution-gestion-section-title-text">
        <div class="pro-restitution-gestion-section-title-text-row">
          <div class="pro-restitution-gestion-section-title-text-row-image-1">
            <img src="../../img/handling.png" alt="">
          </div>
          <p class="isidorasansalt-semi-bold-magic-potion-18px">HANDLING D'ENTRÉE dans nos parcs de stockage, enregistrement du véhicule dans nos systèmes et ceux du parc</p>
        </div>
        <div class="pro-restitution-gestion-section-title-text-row pro-restitution-gestion-section-title-text-row-margin">
          <div class="pro-restitution-gestion-section-title-text-row-image-2">
            <img src="../../img/stockage.png" alt="">
          </div>
          <p class="isidorasansalt-semi-bold-magic-potion-18px">STOCKAGE du véhicule dans un parc sécurisé et adapté aux transporteurs à travers un forfait intégrant 30 jours</p>
        </div>
        <div class="pro-restitution-gestion-section-title-text-row pro-restitution-gestion-section-title-text-row-margin">
          <div class="pro-restitution-gestion-section-title-text-row-image-3">
            <img src="../../img/document.png" alt="">
          </div>
          <p class="isidorasansalt-semi-bold-magic-potion-18px">GESTION ADMINISTRATIVE auprès du loueur, arrêt du contrat de location, transmission du PV de restitution et mise à disposition du véhicule au transporteur</p>
        </div>
      </div>
    </div>
    <div class="pro-restitution-avantages-section">
      <h2 class="isidorasansalt-bold-white-28px">LES AVANTAGES DE CETTE SOLUTION</h2>
      <div class="pro-restitution-avantages-container-first-row">
        <div class="pro-restitution-avantages-container-first-row-container pro-restitution-avantages-container-first-row-container-left">
          <img src="../../img/coche_yellow.png" alt="">
          <p class="isidorasansalt-semi-bold-white-18px">Economie sur les frais de transport et de stockage</p>
        </div>
        <div class="pro-restitution-avantages-container-first-row-container">
          <img src="../../img/coche_yellow.png" alt="">
          <p class="isidorasansalt-semi-bold-white-18px">Réduction des risques d'avaries</p>
        </div>
      </div>
      <div class="pro-restitution-avantages-container-second-row">
        <div class="pro-restitution-avantages-container-second-row-container pro-restitution-avantages-container-second-row-container-left">
          <img src="../../img/coche_yellow.png" alt="">
          <p class="isidorasansalt-semi-bold-white-18px">Optimisation de votre temps et de vos équipes</p>
        </div>
        <div class="pro-restitution-avantages-container-second-row-container">
          <img src="../../img/coche_yellow.png" alt="">
          <p class="isidorasansalt-semi-bold-white-18px">Gain d'espace</p>
        </div>
      </div>
    </div>
    <div class="pro-restitution-fonctionnement-section">
      <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">CAS D'USAGE DE RESTITUTION</span> DE VÉHICULE</h2>
      <div class="pro-restitution-fonctionnement-timeline-container">
        <div class="pro-restitution-fonctionnement-timeline-container-tl"></div>
        <div class="pro-restitution-fonctionnement-timeline-container-tl-elements pro-restitution-fonctionnement-timeline-container-tl-elements-1">
          <img src="../../img/pin.png" alt="">
          <div class="pro-restitution-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">1</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">BUY-BACK</h3>
          <p class="montserrat-normal-magic-potion-14px">Restitution d'un véhicule au constructeur ou au distributeur</p>
        </div>
        <div class="pro-restitution-fonctionnement-timeline-container-tl-elements pro-restitution-fonctionnement-timeline-container-tl-elements-2">
          <img src="../../img/pin.png" alt="">
          <div class="pro-restitution-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">2</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">RENOUVELLEMENT</h3>
          <p class="montserrat-normal-magic-potion-14px">Livraison d'un véhicule et collecte du précédent à restituer</p>
        </div>
        <div class="pro-restitution-fonctionnement-timeline-container-tl-elements pro-restitution-fonctionnement-timeline-container-tl-elements-3">
          <img src="../../img/pin.png" alt="">
          <div class="pro-restitution-fonctionnement-timeline-container-tl-elements-group-step montserrat-bold-magic-potion-16px">3</div>
          <h3 class="isidorasansalt-bold-magic-potion-24px">FIN DE LOCATION</h3>
          <p class="montserrat-normal-magic-potion-14px">Restitution d'un véhicule sans renouvellement du contrat</p>
        </div>
      </div>
    </div>
    <div class="bloc_fonctionnement_mobile">
      <div class="bloc_fonctionnement_container">
        <div class="bloc_fonctionnement_restitution_title isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">CAS D'USAGE DE RESTITUTION</span> DE VÉHICULE</div>
        <div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>1</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">BUY-BACK</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Restitution d'un véhicule au constructeur ou au distributeur</span>
            </div>
          </div>
          <div class="bloc_fonctionnement_step_arrow">
            <img src="../../img/line-8@2x.png" alt="">
          </div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>2</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">RENOUVELLEMENT</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Livraison d'un véhicule et collecte du précédent à restituer</span>
            </div>
          </div>
          <div class="bloc_fonctionnement_step_arrow">
            <img src="../../img/line-8@2x.png" alt="">
          </div>
          <div>
            <div class="bloc_fonctionnement_step">
              <div class="bloc_fonctionnement_step_number"><p>3</p></div>
            </div>
            <div class="bloc_fonctionnement_txt">
              <span class="bloc_fonctionnement_txt_title isidorasansalt-bold-magic-potion-20px">FIN DE LOCATION</span>
              <span class="span-70 montserrat-normal-magic-potion-14px">Restitution d'un véhicule sans renouvellement du contrat</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";
import {Flicking} from "@egjs/vue-flicking";

export default {
  name: "PopvaletProServicesRestitution",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
    Flicking: Flicking
  },
  props: [
    'header'
  ],
  methods: {
    goNext() {
      this.$refs.flicking.next();
    },
    goPrev() {
      this.$refs.flicking.prev();
    }
  },
  data() {
    return {
      autoplay: true,
    };
  },
}
</script>

<style scoped>
.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -12px;
  -webkit-text-decoration-color: var(--mustard);
  text-decoration-color: var(--mustard);
}
.pro-restitution-fonctionnement-timeline-container-tl-elements-3 {
  right: -5%;
  bottom: -97px;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements-2 {
  left: 50%;
  bottom: -97px;
  transform: translate(-50%, 0);
}
.pro-restitution-fonctionnement-timeline-container-tl-elements-1 {
  left: -5%;
  bottom: -97px;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements-group-step {
  color: white;
  background-color: #002244;
  padding: 4px 0;
  width: 24px;
  border-radius: 15px;
  text-align: center;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements p {
  width: 285px;
  text-align: center;
  line-height: 20px;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements img {
  margin-bottom: 15px;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pro-restitution-fonctionnement-timeline-container-tl-elements {
  display: flex;
  position: absolute;
}
.pro-restitution-fonctionnement-timeline-container-tl {
  height: 6px;
  background-color: #002244;
  width: 100%;
}
.pro-restitution-fonctionnement-timeline-container {
  margin-top: 200px;
  position: relative;
}
.pro-restitution-fonctionnement-section {
  padding: 90px 250px 90px 250px;
  min-height: 550px;
  background-color: #F7F6F4;
}
.pro-restitution-avantages-container-first-row-container-left,
.pro-restitution-avantages-container-second-row-container-left {
  margin-right: 225px;
}
.pro-restitution-avantages-container-first-row-container p,
.pro-restitution-avantages-container-second-row-container p {
  color: white;
  margin-left: 25px;
  display: flex;
  align-items: center;
  width: 330px;
  line-height: 22px;
}
.pro-restitution-avantages-container-first-row-container img,
.pro-restitution-avantages-container-second-row-container img {
  height: 47px;
}
.pro-restitution-avantages-container-first-row-container,
.pro-restitution-avantages-container-second-row-container {
  display: flex;
}
.pro-restitution-avantages-container-first-row,
.pro-restitution-avantages-container-second-row {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.pro-restitution-avantages-section h2 {
  color: white;
}
.pro-restitution-avantages-section {
  padding: 90px 250px 90px 250px;
  background-color: #002244;
}
.pro-restitution-gestion-section-title-text-row-margin p {
  line-height: 22px;
}
.pro-restitution-gestion-section-title-text-row-margin {
  margin-top: 30px;
}
.pro-restitution-gestion-section-title-text-row {
  display: flex;
  align-items: center;
}
.pro-restitution-gestion-section-title-text-row-image-3 img {
  height: 32px;
}
.pro-restitution-gestion-section-title-text-row-image-3 {
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 69px;
  padding: 18px 22px;
  border-radius: 35px;
  margin-right: 25px;
}
.pro-restitution-gestion-section-title-text-row-image-2 img {
  height: 44px;
}
.pro-restitution-gestion-section-title-text-row-image-2 {
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 69px;
  padding: 13px 15px;
  border-radius: 35px;
  margin-right: 25px;
}
.pro-restitution-gestion-section-title-text-row-image-1 img {
  height: 31px;
}
.pro-restitution-gestion-section-title-text-row-image-1 {
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 69px;
  padding: 18px 11px;
  border-radius: 35px;
  margin-right: 25px;
}
.pro-restitution-gestion-section-title-text {
  background-color: white;
  padding: 50px 40px;
  width: 634px;
}
.pro-restitution-gestion-section-title {
  padding: 50px 0;
}
.pro-restitution-gestion-section {
  padding: 90px 250px 90px 250px;
  display: flex;
  justify-content: space-between;
  background-color: #F7F6F4;
  align-items: center;
}
.pro-restitution-parcours-title-container h2 {
  margin-top: 8px;
}
.pro-restitution-toggle {
  padding: 90px 250px 0;
}
.restitution-toggle {
  width: 80px;
  height: 40px;
  position: relative;
  border-radius: 25px;
  border: solid 3px #002244;
}
.restitution-toggle-btn-pop-2 {
  position: relative;
  left: 1px;
}
.restitution-toggle-btn-pop-1 {
  position: relative;
  left: 0.8px;
}
.restitution-toggle label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: calc(100% - 34px);
  background-color: var(--yellow);
  cursor: pointer;
  transition: all 0.3s ease;
}
#restitution-toggle-btn {
  display: none;
}
#restitution-toggle-btn:checked + label {
  left: 2px;
  background-color: #002244;
}
.pro-restitution-parcours-section-container {
  position: relative;
}
.pro-restitution-parcours-container-row-step-card.pro-restitution-parcours-container-row-step-card-position {
  position: absolute;
  right: -310px;
  top: 105px;
}
.pro-restitution-parcours-container-row-step-card-margin {
  margin-left: 50px;
}
.pro-restitution-parcours-container-row-step-card-text-numero {
  color: white;
  background-color: #002244;
  padding: 4px 0;
  width: 24px;
  border-radius: 15px;
  position: absolute;
  left: -10px;
  top: -22px;
}
.pro-restitution-parcours-container-row-step-card-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 20px;
  width: 100%;
}
.pro-restitution-parcours-container-row-step-card-img,
.pro-restitution-parcours-container-row-step-card-img-2 {
  position: absolute;
  top: -50px;
  right: 50%;
  transform: translate(50%, 0);
}
.pro-restitution-parcours-container-row-step-card-img-3 {
  position: absolute;
  top: -20px;
  right: 50%;
  transform: translate(50%, 0);
}
.pro-restitution-parcours-container-row-step-card-img-4 {
  position: absolute;
  top: -35px;
  right: 50%;
  transform: translate(50%, 0);
}
.pro-restitution-parcours-container-row-step-card-img-5 {
  position: absolute;
  top: -75px;
  right: 50%;
  transform: translate(50%, 0);
}
.pro-restitution-parcours-container-row-step-card-img-6 {
  position: absolute;
  top: -35px;
  right: 50%;
  transform: translate(50%, 0);
}
.pro-restitution-parcours-container-row-step-card p {
  margin-top: 10px;
}
.pro-restitution-parcours-container-row-step-card {
  width: 256px;
  min-height: 150px;
  box-shadow: 0 4px 12px 0 #0000001F;
  text-align: center;
  border-radius: 4px;
  position: relative;
}
.pro-restitution-parcours-container-arrow-left {
  position: absolute;
  right: -60px;
  bottom: -190px;
  transform: rotate(50deg);
}
.pro-restitution-parcours-container-arrow-right {
  position: absolute;
  right: -60px;
  top: 0;
  transform: rotate(-50deg);
}
.pro-restitution-parcours-container-arrow-down {
  position: absolute;
  right: -40px;
  bottom: -55px;
}
.pro-restitution-parcours-container-smiley {
  position: absolute;
  right: -90px;
  bottom: 10px;
}
.pro-restitution-parcours-container-row {
  display: flex;
  margin-top: 50px;
  position: relative;
  width: fit-content;
}
.pro-restitution-parcours-container {
  margin-top: 100px;
}
.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -12px;
  -webkit-text-decoration-color: var(--mustard);
  text-decoration-color: var(--mustard);
}
.pro-restitution-parcours-title-text {
  margin-top: 8px;
}
.pro-restitution-parcours-section {
  padding: 0 250px 90px 250px;
  width: 100%;
}
.pro-restitution-description-section p {
  text-align: center;
  line-height: 29px;
}
.pro-restitution-description-section {
  padding: 90px 250px 90px 250px;
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .pro-restitution-fonctionnement-section {
    padding: 90px 150px 90px 100px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .pro-restitution-fonctionnement-section {
    padding: 90px 150px 90px 100px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .pro-restitution-fonctionnement-timeline-container-tl-elements p {
    width: 230px;
  }
  .pro-restitution-avantages-container-first-row-container-left,
  .pro-restitution-avantages-container-second-row-container-left {
    margin-right: 50px;
  }
  .pro-restitution-avantages-section {
    padding: 90px 100px 90px 100px;
  }
  .pro-restitution-gestion-section {
    padding: 90px 125px 90px 125px;
  }
}

@media screen and (max-width: 991px) {
  .pro-restitution-fonctionnement-section {
    display: none;
  }
  .bloc_fonctionnement_restitution_title {
    text-align: center;
    margin-bottom: 60px;
  }
  .bloc_fonctionnement_mobile {
    padding: 30px;
    margin: 25px 0;
    display: block;
  }
  .pro-restitution-avantages-container-first-row,
  .pro-restitution-avantages-container-second-row {
    margin-top: 40px;
  }
  .pro-restitution-avantages-container-second-row-container {
    margin-top: 25px;
  }
  .pro-restitution-avantages-container-first-row-container {
    margin-top: 25px;
  }
  .pro-restitution-avantages-container-first-row-container-left,
  .pro-restitution-avantages-container-second-row-container-left {
    margin-right: 0;
  }
  .pro-restitution-avantages-container-second-row {
    flex-direction: column;
  }
  .pro-restitution-avantages-container-first-row {
    flex-direction: column;
  }
  .pro-restitution-avantages-section {
    padding: 90px 30px 90px 30px;
  }
  .pro-restitution-gestion-section-title-text {
    width: 100%;
  }
  .pro-restitution-gestion-section {
    padding: 40px 30px 90px 30px;
    flex-direction: column;
  }
  .pro-restitution-parcours-container-arrow-left {
    display: none;
  }
  .pro-restitution-parcours-container-arrow-right {
    display: none;
  }
  .pro-restitution-parcours-container-arrow-down {
    display: none;
  }
  .pro-restitution-parcours-container-smiley {
    display: none;
  }
  .pro-restitution-parcours-container-row-step-card.pro-restitution-parcours-container-row-step-card-position {
    position: relative;
    right: unset;
    top: unset;
  }
  .pro-restitution-parcours-container-row-step-card-margin {
    margin-top: 81px;
  }
  .pro-restitution-parcours-container-row-step-card-margin {
    margin-left: 0;
  }
  .pro-restitution-parcours-container-row {
    flex-direction: column;
    width: unset;
    align-items: center;
    margin-top: 81px;
  }
  .pro-restitution-parcours-section {
    padding: 0 30px 90px 30px;
  }
  .pro-restitution-toggle {
    padding: 35px 30px 0;
  }
  .pro-restitution-description-section {
    padding: 90px 30px 90px 30px;
  }
}
</style>