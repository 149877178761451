<template>
  <div class="camions-2">
    <p class="x-lorem-ipsum-lorem-ipsum-1 isidorasansalt-bold-white-20px">
      <span class="isidorasansalt-bold-white-20px">{{ spanText1 }}</span
      ><br><span class="span-54 montserrat-normal-white-14px">{{ spanText2 }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Camions2",
  props: ["spanText1", "spanText2"],
};
</script>

<style>
.camions-2 {
  display: flex;
  width: 242px;
}

.x-lorem-ipsum-lorem-ipsum-1 {
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  width: 240px;
}

.span-54 {
  line-height: 19.6px;
}
</style>
