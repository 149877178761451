<template>
  <div>
    <div class="cta_devenir_valet">
      <a href="https://recrutement.popvalet.com/" class="frame-1" style="text-decoration: none">
        <div class="accs-valet blogtextbold">{{ accsValet }}</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaDevenirValet",
  props: ["accsValet"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .cta_devenir_valet {
    margin-left: unset;
  }
}

@media screen and (min-width: 992px) {
  .cta_devenir_valet {
    margin-left: 204px;
  }
}

.cta_devenir_valet {
  display: flex;
  height: 44px;
  width: 217px;
}

.frame-1 {
  align-items: flex-start;
  justify-content: center;
  background-color: var(--blanc);
  color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  flex: 1;
  gap: 10px;
  margin-left: 4.38%;
  margin-right: 4.38%;
  padding: 12px 30px;
  width: 198px;
}

.frame-1:hover {
  background-color: var(--yellow);
}

.accs-valet {
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}
</style>
