<template>
  <div class="comment-ca-marche-card">
    <div class="comment-ca-marche-card-title">
      <img :src="iconUrl" alt="">
      <p class="isidorasansalt-bold-magic-potion-24px underline-yellow-card">{{titleCard}}</p>
    </div>
    <div class="comment-ca-marche-card-text">
      <p class="isidorasansalt-semi-bold-magic-potion-19px">{{textCard}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlocCardMaif',
  props: [
    'titleCard',
    'textCard',
    'iconUrl'
  ],
}
</script>

<style>
.underline-yellow-card {
  position: relative;
}

.underline-yellow-card::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  border-bottom: 6px solid var(--mustard);
  z-index: -1;
}
.comment-ca-marche-card-text p {
  line-height: 35px;
}
.comment-ca-marche-card-text {
  padding: 100px 40px 100px 0;
  width: 65%;
}
.comment-ca-marche-card-title p {
  margin-top: 15px;
}
.comment-ca-marche-card-title img {
  height: 60px;
}
.comment-ca-marche-card-title {
  padding: 100px 40px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.comment-ca-marche-card {
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}
@media screen and (max-width: 991px) {
  .comment-ca-marche-card-text {
    padding: 25px 25px 100px 25px;
    width: 100%;
  }
  .comment-ca-marche-card-title {
    padding: 70px 25px;
    width: 100%;
  }
  .comment-ca-marche-card {
    flex-direction: column;
  }
}
</style>