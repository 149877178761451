<template>
  <div class="pop-valet-is-container">
    <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow-popvalet-is">Nos</span> Méthodes</h2>
    <div class="pop-valet-is-row">
      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-1">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="organisation" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">ADN B2C</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">La culture de la relation client. Depuis plus de 5 ans.</p>
      </div>

      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-3">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="feedback" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">SÉLECTION</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">Un personnel trié sur le volet. Des standards à respecter.</p>
      </div>

      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-5">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="euro-coin" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">FORMATION</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">Formation pratique et théorique. Satisfaire aux exigences de nos clients.</p>
      </div>
    </div>

    <div class="pop-valet-is-row">
      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-2">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="calendar" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">QUALITÉ</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">La recherche de l’excellence. Une évolution continuelle de nos process.</p>
      </div>

      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-4">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="credit-card" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">RÉACTIVITÉ</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">Une réponse dans l’heure à vos demandes. Rapidité d’exécution</p>
      </div>

      <div class="pop-valet-is-row-part">
        <div class="pop-valet-is-icon-6">
          <img class="pop-valet-is-icon-img" src="../../../img/users---approve-user-1@2x.png" alt="icon-key" />
        </div>
        <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">TECHNOLOGIE</p>
        <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">La culture de la relation client. Depuis plus de 5 ans.</p>
      </div>
    </div>
  </div>
</template>

<script>
import transport from "@/components/Transport";
import delai from "@/components/Delai";
export default {
  name: "PopValetIs",
  components: {
    transport,
    delai
  }
}
</script>

<style>
.pop-valet-is-row-part-description {
  margin-top: 8px;
  line-height: 22px;
}
.pop-valet-is-row-part-title {
  margin-top: 17px;
  margin-bottom: 0;
}
.pop-valet-is-icon-img {
  height: 32px;
}
.pop-valet-is-icon-6 {
  padding: 12px 12px;
}
.pop-valet-is-icon-5 {
  padding: 12px 12px;
}
.pop-valet-is-icon-4 {
  padding: 12px 12px;
}
.pop-valet-is-icon-3 {
  padding: 12px 12px;
}
.pop-valet-is-icon-2 {
  padding: 12px 12px;
}
.pop-valet-is-icon-1 {
  padding: 12px 12px;
}
.pop-valet-is-icon-1,
.pop-valet-is-icon-2,
.pop-valet-is-icon-3,
.pop-valet-is-icon-4,
.pop-valet-is-icon-5,
.pop-valet-is-icon-6 {
  background-color: #FADC41;
  border-radius: 28px;
  width: fit-content;
  margin: auto;
  display: flex;
}
.underline-yellow-popvalet-is {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -13px;
  text-decoration-color: #FADC41;
}
.isidorasansalt-bold-magic-potion-20px {
  color: #002244;
  font-family: "Isidora Sans Alt-Bold", Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-normal-magic-potion-14px {
  color: #002244;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .pop-valet-is-row-part {
    width: 245px;
    text-align: center;
    margin-top: 50px;
  }
  .pop-valet-is-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }
  .pop-valet-is-container {
    width: 100%;
    padding: 50px 30px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .pop-valet-is-container {
    padding: 80px 200px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1599px) {
  .pop-valet-is-container {
    padding: 80px 300px;
  }
}
@media screen and (min-width: 1600px) {
  .pop-valet-is-container {
    padding: 80px 400px;
  }
}
@media screen and (min-width: 992px) {
  .pop-valet-is-row-part {
    width: 245px;
    text-align: center;
  }
  .pop-valet-is-row {
    display: flex;
    justify-content: space-between;
    margin-top: 75px;
  }
  .pop-valet-is-container {
    width: 100%;
  }
}
</style>