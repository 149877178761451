<template>
  <div>
    <div class="accordion-header" @click="toggle">
      <span style="font-size: 18px">{{ isOpen ? '-' : '+' }}</span>
      <slot name="header"></slot>
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-show="isOpen" class="accordion-content" ref="content">
        <br>
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter(el) {
      el.style.maxHeight = '0';
    },
    enter(el) {
      el.style.maxHeight = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.maxHeight = '0';
    },
  },
};
</script>

<style>
.accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
}

.accordion-header span {
  margin-right: 10px;
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.2s ease;
}
</style>