<template>
  <div class="bloc_avantages-2">
    <p class="les-avantages-de-la-solution-pop-valet-2 isidorasansalt-bold-magic-potion-28px">
      {{ lesAvantagesDeLaSolutionPopValet }}
    </p>
    <reactivit
      :pictoReactiviteProps="reactivitProps.pictoReactiviteProps"
      :voirTousLesUsesCases2Props="reactivitProps.voirTousLesUsesCases2Props"
    />
    <qualit
      :voirTousLesUsesCases2Props="qualitProps.voirTousLesUsesCases2Props"
    />
  </div>
</template>

<script>
import Reactivit from "./Reactivit";
import Qualit from "./Qualit";
export default {
  name: "BlocAvantages2",
  components: {
    Reactivit,
    Qualit,
  },
  props: ["lesAvantagesDeLaSolutionPopValet", "reactivitProps", "qualitProps"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .les-avantages-de-la-solution-pop-valet-2 {
    text-align: center;
  }
  .bloc_avantages-2 {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .bloc_avantages-2 {
    justify-content: center;
    align-items: flex-end;
    height: 525px;
    position: relative;
    padding: 50px 222px;
  }
}

@media screen and (min-width: 1441px) {
  .bloc_avantages-2 {
    justify-content: center;
    align-items: flex-end;
    height: 525px;
    position: relative;
    padding: 50px 222px;
  }
}

.bloc_avantages-2 {
  background-color: var(--grisclair);
  display: flex;
}

.les-avantages-de-la-solution-pop-valet-2 {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 64px;
  width: 339px;
}
</style>
