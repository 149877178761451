<template>
  <div class="container-center-horizontal">
    <div class="popvalet-general-accueil screen">
      <common-sur-header />
      <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
      />
      <bloc-general-accueil-title />
      <bloc-logos-clients class="logos_clients_home"/>
      <bloc-notre-organisation />
      <pop-valet-is class="general-homepage"></pop-valet-is>
      <bloc-nos-temoignages></bloc-nos-temoignages>
      <bloc-maif :txt-assurance="textAssurance" />
      <div class="pro-home-solution-container">
        <h1 class="isidorasansalt-bold-magic-potion-28px center-title">Convoyage voiture : La solution de mobilité innovante pour les professionnels</h1>
        <br>
        <p class="isidorasansalt-semi-bold-magic-potion-18px">
          Pop Valet, leader dans le secteur de la mobilité automobile, propose des services de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage de voiture</strong> partout en France sous 24h. Que ce soit pour la livraison à domicile, le jockeyage après-vente ou les transferts inter-sites, Pop Valet est la réponse idéale aux besoins de mobilité des professionnels.
        </p>
        <br>
        <Accordion>
          <template v-slot:header>
            <h2 class="isidorasansalt-bold-magic-potion-24px">Convoyage de voiture : Une solution pratique et rapide</h2>
          </template>
          <template v-slot:content>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Le <strong class="isidorasansalt-bold-magic-potion-18px">convoyage de voiture</strong> est une solution pratique pour les entreprises qui nécessitent le transport rapide et sécurisé de véhicules. Que ce soit pour une livraison directe au client ou pour déplacer une flotte d’un site à un autre, Pop Valet garantit un service efficace et professionnel.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Avantages du convoyage voiture avec Pop Valet
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Disponibilité</strong> : Pop Valet assure des services de convoyage dans toute la France en moins de 24 heures. Nous comprenons l'importance de la rapidité et de la réactivité pour les entreprises, c'est pourquoi notre service est conçu pour être disponible à tout moment.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Professionnalisme</strong> : Nos conducteurs expérimentés veillent à la sécurité et à la ponctualité de chaque livraison. Tous nos Valets sont formés pour garantir un convoyage sans encombre, respectant les normes de sécurité les plus strictes.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Flexibilité</strong> : Adaptation aux besoins spécifiques de chaque entreprise, qu'il s'agisse de livraisons uniques ou de mouvements de flotte réguliers. Nous offrons des solutions sur mesure pour répondre aux exigences particulières de chaque client.
            </p>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              En choisissant Pop Valet pour vos besoins de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage auto</strong>, vous bénéficiez d'un service fiable et personnalisé. Nos experts en logistique se chargent de tout, de la planification à l'exécution, pour que vous puissiez vous concentrer sur votre cœur de métier. De plus, notre service client est toujours disponible pour répondre à vos questions et vous assister tout au long du processus.
            </p>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pour en savoir plus sur nos services de <a href="/transport-avec-chauffeur" style="color: #002244">transport avec chauffeur</a>, explorez nos solutions dédiées aux professionnels.
            </p>
          </template>
        </Accordion><br>
        <Accordion>
          <template v-slot:header>
            <h2 class="isidorasansalt-bold-magic-potion-24px">Comment fonctionne le convoyage de véhicule avec Pop Valet</h2>
          </template>
          <template v-slot:content>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Le processus de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage de véhicule</strong> avec Pop Valet est conçu pour être simple et efficace. Nous prenons en charge toutes les étapes, de la prise en charge du véhicule à sa livraison, en passant par la planification logistique.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Étapes du processus de convoyage
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">1. Demande de convoyage</strong> : Soumettez votre demande via notre plateforme en ligne. Notre système intuitif vous permet de spécifier tous les détails nécessaires pour un convoyage optimal.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">2. Planification</strong> : Notre équipe planifie l’itinéraire le plus efficient pour le transport de votre véhicule. En tenant compte des contraintes de temps et des conditions de circulation, nous optimisons chaque trajet.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">3. Prise en charge</strong> : Un chauffeur professionnel récupère le véhicule à l'adresse indiquée. Nos chauffeurs sont ponctuels et veillent à ce que le véhicule soit pris en charge dans les meilleures conditions.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">4. Transport</strong> : Le véhicule est acheminé en toute sécurité jusqu’à sa destination. Nous utilisons des technologies de suivi avancées pour assurer une surveillance constante du véhicule en transit.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">5. Livraison</strong> : Le véhicule est livré dans les délais impartis. À la réception, une inspection finale est effectuée pour garantir que le véhicule est en parfait état.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pour toute question sur nos tarifs de convoyage, consultez notre page sur le <a href="" style="color: #002244">transport de voiture</a>. Nos tarifs sont transparents et compétitifs, adaptés aux besoins des professionnels.
            </p>
          </template>
        </Accordion><br>
        <Accordion>
          <template v-slot:header>
            <h2 class="isidorasansalt-bold-magic-potion-24px">L’interface professionnelle de convoyage de véhicule de Pop Valet : Une technologie avancée pour un service optimisé</h2>
          </template>
          <template v-slot:content>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pop Valet utilise une <strong class="isidorasansalt-bold-magic-potion-18px">interface de convoyage de véhicule</strong> avancée pour offrir un service de haute qualité à ses clients professionnels. Cette technologie permet une gestion optimisée des demandes et un suivi en temps réel des véhicules.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Caractéristiques de notre plateforme de convoyage
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Interface intuitive</strong> : Facilité d'utilisation pour la soumission et le suivi des demandes de convoyage. Notre interface utilisateur est conçue pour être accessible et simple d'utilisation, même pour les non-initiés.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Suivi en temps réel</strong> : Les clients peuvent suivre la progression de leur véhicule en temps réel. Grâce à des notifications régulières, vous êtes toujours informé de l'état de votre convoyage.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Gestion centralisée</strong> : Toutes les informations sur les convoyages sont centralisées pour une gestion efficace. Cela permet une coordination optimale entre les différents acteurs impliqués dans le processus de convoyage.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              En choisissant Pop Valet, vous bénéficiez non seulement d’un service de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage automobile</strong> de qualité, mais aussi d’un outil technologique qui simplifie vos opérations de transport. Notre plateforme est régulièrement mise à jour pour intégrer les dernières innovations et offrir une expérience utilisateur toujours plus fluide.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pour découvrir toutes les fonctionnalités de notre plateforme et comment elle peut bénéficier à votre entreprise, visitez notre page dédiée au <a href="/transport-voiture-camion" style="color: #002244">transport de voiture par camion</a>.
            </p>
          </template>
        </Accordion><br>
        <Accordion>
          <template v-slot:header>
            <h2 class="isidorasansalt-bold-magic-potion-24px">Les avantages du convoyage automobile pour les professionnels</h2>
          </template>
          <template v-slot:content>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Le <strong class="isidorasansalt-bold-magic-potion-18px">convoyage automobile</strong> présente de nombreux avantages pour les entreprises. Il permet non seulement de gagner du temps, mais aussi d'optimiser les coûts et de garantir une satisfaction client accrue.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Avantages du convoyage automobile
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Gain de temps</strong> : Les entreprises n’ont plus besoin de mobiliser leurs propres ressources pour le transport de véhicules. Le temps économisé peut être réinvesti dans d'autres activités stratégiques.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Réduction des coûts</strong> : Optimisation des itinéraires et réduction des dépenses liées au personnel et à la logistique. En externalisant le convoyage, les entreprises peuvent réduire leurs coûts fixes et profiter d'une tarification plus flexible.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              <strong class="isidorasansalt-bold-magic-potion-18px">- Satisfaction client</strong> : Livraison rapide et sécurisée des véhicules, améliorant ainsi l'expérience client. Un service de convoyage professionnel renforce la réputation de l'entreprise auprès de ses clients et partenaires.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pop Valet s'engage à fournir un service de convoyage de qualité, répondant aux besoins spécifiques des professionnels. En collaborant avec nous, vous choisissez un partenaire fiable et expérimenté pour toutes vos opérations de mobilité.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Pour plus d'informations sur nos services, visitez notre page de <a href="/livraison-voiture" style="color: #002244">livraison de voiture</a>.
            </p>
          </template>
        </Accordion><br>
        <Accordion>
          <template v-slot:header>
            <h2 class="isidorasansalt-bold-magic-potion-24px">Pourquoi choisir Pop Valet pour vos besoins de convoyage</h2>
          </template>
          <template v-slot:content>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Choisir Pop Valet pour vos besoins de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage de voiture</strong>, c'est opter pour un partenaire de confiance qui comprend les exigences des professionnels. Nos solutions sont conçues pour offrir une valeur ajoutée à votre entreprise en termes de service, de fiabilité et de technologie.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Engagement envers la qualité
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Nous nous engageons à offrir un service de haute qualité à chaque étape du convoyage. De la prise en charge à la livraison, chaque détail est soigné pour garantir la satisfaction totale de nos clients. Nos chauffeurs sont formés pour respecter les normes les plus élevées en matière de sécurité et de service client.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Innovation technologique
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Notre plateforme de <strong class="isidorasansalt-bold-magic-potion-18px">convoyage de véhicule</strong> utilise les dernières technologies pour offrir une expérience utilisateur optimisée. Le suivi en temps réel, la gestion centralisée des données et une interface intuitive sont autant d'éléments qui rendent notre service unique.
            </p><br>
            <p class="isidorasansalt-bold-magic-potion-18px">
              Expertise et expérience
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              Avec des années d'expérience dans le secteur du convoyage, Pop Valet dispose de l'expertise nécessaire pour gérer toutes sortes de demandes de convoyage. Que vous ayez besoin de transporter un seul véhicule ou une flotte entière, nous avons les solutions adaptées à vos besoins.
            </p><br>
            <p class="isidorasansalt-semi-bold-magic-potion-18px">
              En conclusion, le <strong class="isidorasansalt-bold-magic-potion-18px">convoyage voiture</strong> avec Pop Valet est une solution complète et fiable pour les professionnels. Nos services flexibles, notre engagement envers la qualité et notre utilisation de technologies avancées nous permettent de répondre aux attentes les plus exigeantes. Faites confiance à Pop Valet pour tous vos besoins de mobilité automobile et découvrez la différence que peut faire un service de convoyage professionnel.
            </p><br>
          </template>
        </Accordion>
      </div>
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonHeader from "@/components/common/CommonHeader";
import BlocGeneralAccueilTitle from "@/components/BlocGeneralAccueilTitle";
import BlocLogosClients from "./BlocLogosClients";
import BlocNotreOrganisation from "@/components/BlocNotreOrganisation";
import PopValetIs from "@/components/PopValetIs";
import BlocNosTemoignages from "@/components/BlocNosTemoignages";
import BlocMaif from "./BlocMaif";
import CommonFooter from "@/components/common/CommonFooter";
import Accordion from "@/components/Accordion.vue";
import CtaOuvrirCompte from "@/components/CtaOuvrirCompte.vue";

export default {
  name: "PopvaletGeneralAccueil",
  components: {
    CtaOuvrirCompte,
    Accordion,
    CommonHeader,
    CommonSurHeader,
    BlocGeneralAccueilTitle,
    BlocLogosClients,
    BlocNotreOrganisation,
    PopValetIs,
    BlocNosTemoignages,
    BlocMaif,
    CommonFooter
  },
  props: [
    'header',
    "textAssurance"
  ],
  metaInfo: {
    title: 'Convoyage voiture : La solution de mobilité innovante pour les professionnels',
    meta: [
      { name: 'description', content: 'Pop Valet offre des services de convoyage voiture pour les professionnels, garantissant un transport rapide et sécurisé partout en France sous 24h. Découvrez nos solutions personnalisées et fiables.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.popvalet.com/' }
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://www.popvalet.com/"
            }
          ]
        }
      }
    ]
  }
};
</script>

<style>
.pro-home-cta-section {
  background-color: var(--bluemed2);
  display: flex;
  justify-content: center;
  padding: 25px;
}
.pro-home-solution-container .center-title {
  text-align: center;
}
.pro-home-solution-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}

@media screen and (max-width: 991px) {
  .popvalet-general-accueil {
    width: 1440px;
  }
  .pro-home-solution-container {
    padding: 60px 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .popvalet-general-accueil {
    width: 1440px;
  }
}

@media screen and (min-width: 1441px) {
  .popvalet-general-accueil {
    width: 100%;
    align-items: unset;
  }
}

.popvalet-general-accueil {
  background-color: var(--blanc);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
</style>
