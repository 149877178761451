<template>
  <div class="qualit">
    <div class="bloc_qualite">
      <div class="contenu_qualite">
        <div class="picto_qualit"><img class="education-badge-2" src="../../img/education---badge-5@2x.png" alt="Education / badge" /></div>
        <p class="qualit-lorem-ipsum montserrat-medium-magic-potion-20px">
          <span class="montserrat-medium-magic-potion-20px">QUALITÉ <br /></span
          ><span class="span-8 textcourantsmall"><br />De la sélection de nos chauffeurs jusqu’à notre pôle dédié, Pop Valet se donne les moyens pour vous garantir la qualité de toutes nos missions.</span>
        </p>
        <voir-tous-les-uses-cases2 :children="voirTousLesUsesCases2Props.children" :link="voirTousLesUsesCases2Props.link"/>
      </div>
    </div>
  </div>
</template>

<script>
import VoirTousLesUsesCases2 from "./VoirTousLesUsesCases2";
export default {
  name: "Qualit",
  components: {
    VoirTousLesUsesCases2,
  },
  props: ["voirTousLesUsesCases2Props"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .bloc_qualite,
  .bloc_qualite-1,
  .bloc_qualite-2 {
    margin-top: 25px;
  }
  .qualit,
  .qualit-1,
  .qualit-2 {
    margin-left: unset;
  }
}

@media screen and (min-width: 992px) {
  .qualit,
  .qualit-1,
  .qualit-2 {
    margin-left: 17px;
  }
}

.qualit,
.qualit-1,
.qualit-2 {
  display: flex;
  height: 425px;
  width: 325px;
}

.bloc_qualite,
.bloc_qualite-1,
.bloc_qualite-2 {
  align-items: center;
  background-color: var(--blanc);
  border-radius: 8px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  padding: 40px 54px;
  width: 283px;
}

.contenu_qualite,
.contenu_qualite-1,
.contenu_qualite-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 100%;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.picto_qualit,
.picto_qualit-1,
.picto_qualit-2 {
  background-color: var(--yellow);
  border-radius: 34.5px;
  display: flex;
  height: 69px;
  margin-top: -10.5px;
  width: 69px;
}

.education-badge-2,
.education-badge-13,
.education-badge-14 {
  height: 44px;
  margin: auto;
  width: 44px;
}

.qualit-lorem-ipsum,
.qualit-lorem-ipsum-1,
.qualit-lorem-ipsum-2 {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 230px;
}

.span-8,
.span-fxeKxY,
.span-cWYzlF {
  color: var(--bluenavy);
  font-weight: 400;
}
</style>
