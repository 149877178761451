<template>
  <div :class="[`icon_remuneration`, className || ``]">
    <img
      class="business-credit-card"
      src="../../img/business---credit-card@2x.png"
      alt="Business / credit card"
    />
  </div>
</template>

<script>
export default {
  name: "Transport",
  props: ["className"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .icon_remuneration.moyenne,
  .icon_remuneration.moyenne-1 {
    margin-left: 0;
  }
  .icon_remuneration.clients_auto,
  .icon_remuneration.clients_auto-1 {
    margin-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .icon_remuneration.moyenne,
  .icon_remuneration.moyenne-1 {
    margin-left: 19px;
  }
  .icon_remuneration.clients_auto,
  .icon_remuneration.clients_auto-1 {
    margin-right: 0;
  }
}

.icon_remuneration {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-right: 1px;
  margin-top: 33px;
  width: 56px;
}

.business-credit-card {
  height: 37px;
  margin-left: 10px;
  margin-top: 10px;
  width: 37px;
}

.icon_remuneration.transport {
  margin-left: 19px;
  margin-right: unset;
  margin-top: 74px;
}

.icon_remuneration.transport .business-credit-card,
.icon_remuneration.moyenne .business-credit-card,
.icon_remuneration.clients_auto .business-credit-card {
  flex: 1;
  margin-left: 17.86%;
  margin-right: 16.07%;
}

.icon_remuneration.moyenne,
.icon_remuneration.moyenne-1 {
  margin-right: unset;
  margin-top: 72px;
}

.icon_remuneration.valets,
.icon_remuneration.taux_conformite,
.icon_remuneration.valets-1,
.icon_remuneration.tuax_conformite {
  margin-left: 1px;
  margin-right: unset;
  margin-top: unset;
}

.icon_remuneration.valets .business-credit-card,
.icon_remuneration.taux_conformite .business-credit-card {
  flex: 1;
  height: 36px;
  margin-left: 17.86%;
  margin-right: 16.07%;
}

.icon_remuneration.salaries_dedies,
.icon_remuneration.salaries_dedies-1 {
  margin-top: 72px;
}

.icon_remuneration.salaries_dedies .business-credit-card {
  flex: 1;
  margin-left: 17.86%;
  margin-right: 17.86%;
  width: 36px;
}

.icon_remuneration.clients_auto,
.icon_remuneration.clients_auto-1 {
  margin-top: 72px;
}

.icon_remuneration.valets-1 .business-credit-card,
.icon_remuneration.tuax_conformite .business-credit-card {
  height: 36px;
}

.icon_remuneration.salaries_dedies-1 .business-credit-card {
  width: 36px;
}
</style>
