<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Notre réactivité pour toutes vos missions de transport de véhicules sur route" :icon-url="require('../../img/stopwatch.png')"/>
    <div class="general-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">La <a href="/" style="color: #002244">solution de transport automobile Pop Valet</a> s’accompagne d’une réactivité en rupture totale avec les délais traditionnels constatés chez nos confrères. Nous prenons en charge votre véhicule et le livrons dans des délais inférieurs à 48 heures pour des missions sur le territoire français.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Nos atouts ? Une équipe d’une trentaine de salariés investis et réactifs qui cultivent la relation client, mais aussi et surtout une technologie au service de ce dynamisme. Explications.</p><br>
    </div>
    <div class="general-reactivite-sans-pop-container">
      <h2 class="isidorasansalt-bold-white-28px">Votre transport automobile sans Pop Valet…</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">En tant que professionnel ou particulier, peut-être en avez-vous déjà fait l’expérience : les autres acteurs du transport automobile à la demande sollicitent des plateformes tiers pour organiser leur logistique. Concrètement, cette multiplication d’intermédiaires se matérialise des façons suivantes :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Pas de collecte de disponibilités des chauffeurs</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Choix des trajets par les chauffeurs et non par le transporteur</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Sélection de la plateforme la moins onéreuse via un appel d’offres</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les conséquences ? Une qualité de service très aléatoire, et une réactivité limitée : les délais de réalisation des missions dépassent fréquemment les 72 heures.</p>
    </div>
    <div class="general-reactivite-avec-pop-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">… et votre transport automobile avec Pop Valet…</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">À l’inverse, les équipes Pop Valet visent l’excellence opérationnelle. Notre atout-clé dans la maîtrise de notre chaîne de valeur ? Zéro intermédiaire mais un opérateur logistique intégré. Dans la pratique, notre solution implique :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Une collecte des disponibilités des Valets en amont : lorsque vous passez commande, nous savons immédiatement qui parmi nos chauffeurs est disponible pour réaliser au plus vite votre mission – et pas l’inverse</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Une optimisation de la logistique grâce à notre algorithme : après acceptation des missions, notre logiciel nous permet de les organiser par lots, de façon automatisée</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Un dispatch aux Valets les plus performants : notre stratégie de scoring, qui permet d’affecter un score à chacun de nos chauffeurs, encourage nos conducteurs les plus professionnels</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Un suivi scrupuleux de chaque étape des missions confiées : de la collecte de votre véhicule jusqu’à sa livraison en passant par de potentiels besoins d’entretien ou de réparation, nous limitons les risques de retard en traçant votre voiture</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Résultat ? Une réalisation de la prestation entre 24 heures et 48 heures pour toute mission de livraison, convoyage ou encore jockeyage confiée sur la France métropolitaine.</p>
    </div>
    <div class="general-reactivite-savoir-plus-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">En savoir plus sur Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour mieux nous connaître et vous familiariser avec nos différentes solutions, découvrez :</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Qui nous sommes : comment Pop Valet est né et qui sont ses fondateurs</p><br>-->
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/qualite" style="color: #002244">Nos engagements qualité</a> : les moyens que nous nous donnons pour atteindre nos exigences, de la sélection minutieuse de nos chauffeurs jusqu’à notre pôle qualité dédié</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/popvalet-general-assurance" style="color: #002244">Nos assurances</a> : gros plan sur nos assurances partenaires, les dommages couverts, et les protections assurées à la fois pour nos clients et pour nos Valets</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Notre blog : l’actualité de Pop Valet et du transport automobile</p>-->
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "ReactiviteForteInfo",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif"
  ],
  metaInfo: {
    title: 'Transport de Véhicules sur Route : notre Réactivité | Pop Valet',
    meta: [
      { name: 'description', content: 'Pour vos transports automobiles, notre réactivité est en rupture totale avec les délais traditionnels : nous exécutons vos missions en moins de 48 heures.' },
    ]
  }
}
</script>

<style scoped>
.general-reactivite-savoir-plus-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-reactivite-savoir-plus-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.general-reactivite-avec-pop-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-reactivite-avec-pop-container {
  padding: 100px 150px;
  line-height: 29px;
}
.general-reactivite-sans-pop-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-reactivite-sans-pop-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.general-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .general-reactivite-savoir-plus-container {
    padding: 100px 50px;
  }
  .general-reactivite-avec-pop-container {
    padding: 100px 50px;
  }
  .general-reactivite-sans-pop-container {
    padding: 100px 50px;
  }
}
</style>