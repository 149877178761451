<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Notre vision : faciliter la logistique automobile pour tous" :icon-url="require('../../img/location-and-map---direction-tool@2x.png')"/>
    <div class="general-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Faire appel à l’équipe Pop Valet, c’est vous faire accompagner par des spécialistes pour toutes vos thématiques liées à la logistique automobile. Notre vision ? Vous permettre, grâce à nos outils digitaux, de combiner une série de services pour créer votre propre flux logistique sur-mesure et, ainsi, développer vos nouvelles mobilités et vous accompagner dans tous vos besoins.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Grands champs de prestations, expériences digitales, atouts de notre solution : notre carte d’identité.</p>
    </div>
    <div class="general-vision-solution-container">
      <h2 class="isidorasansalt-bold-white-28px">Les six colonnes de la solution Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">La vision de Pop Valet repose sur six piliers, tous inhérents à la mobilité de vos véhicules. Découvrez-les.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Le transport</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Nous déployons des solutions multimodales pour tous vos convoyages : nous transportons vos véhicules par convoyeur simple ou via une combinaison convoyeur et camion. Nos transports sont réalisés soit entre votre société et un acteur externe, soit entre deux adresses appartenant à votre société.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">La livraison</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Nos Valets assurent la livraison de toutes vos voitures, vos petits ou gros utilitaires (jusqu’à 20m3), vans, camping-cars, voitures sans permis... Vous pouvez faire livrer un véhicule neuf (VN), un véhicule d’occasion (VO), ou un véhicule de location (VLOC).</p><br>

      <h3 class="isidorasansalt-bold-white-24px">La réparation</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Lorsque votre véhicule a été accidenté, nous sollicitons notre réseau pour assurer en votre nom toutes les réparations mécaniques ou de carrosserie. En répondant également présent pour vos révisions, Pop Valet participe aussi à la fiabilité, à la performance et à la longévité des voitures de votre parc.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">La restitution</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Pop Valet assure la gestion administrative à chaque étape de votre restitution de véhicule : communication avec le loueur, arrêt du contrat de location, transmission du PV de restitution, mise à disposition du véhicule au transporteur…</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Le stockage</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Fort de ses 20 parcs de stockage répartis sur tout le territoire, Pop Valet accueille toute l’année vos voitures et utilitaires sur ses milliers d’emplacements.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">L’expertise</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Sur tout le territoire métropolitain, Pop Valet met à votre service son réseau d’experts agréés dans le cadre d’un sinistre, d’une reprise ou encore d’une réaffectation.</p>
    </div>
    <div class="general-vision-digital-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Les expériences digitales</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Notre vision, c’est aussi le numérique mis au service de vos besoins. Les outils développés et utilisés par Pop Valet nous permettent de répondre à vos attentes avec une réactivité et une qualité de service toujours accrues. Nos expériences digitales incluent notamment :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos rachats cash</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos test drives</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos approvisionnement à l’étranger</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos tiers de confiance auprès d’un particulier</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos paiements à distance</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Vos rapatriements post-sinistre</p>
    </div>
    <div class="general-vision-popvalet-container">
      <h2 class="isidorasansalt-bold-white-28px">Ce que Pop Valet met en place pour ses clients</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Cinq bonnes raisons de choisir Pop Valet ? Et accordant votre confiance à nos services :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous gagnez en productivité grâce à nos solutions logistiques complètes</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous servez vos clients et vos utilisateurs sous 24 heures partout en France tout en réduisant les délais</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous placez la qualité avant tout et restez serein(e) dans l’exécution opérationnelle</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous accélérez vos activités avec notre technologie</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous simplifiez le quotidien de vos équipes avec notre interface intuitive et ergonomique</p>
    </div>
    <div class="general-vision-savoir-plus-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">En savoir plus sur Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour mieux nous connaître et vous familiariser avec nos différentes solutions, découvrez également <a href="/technologie" style="color: #002244">notre technologie</a> : comment nos outils digitaux tels que notre algorithme et notre interface vous garantissent des délais raccourcis.</p>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonFooter from "@/components/common/CommonFooter";
import GeneralHeader from "@/components/GeneralHeader";

export default {
  name: "PopvaletGeneralVisionInfo",
  components: {
    GeneralHeader,
    CommonHeader,
    CommonSurHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif"
  ],
  metaInfo: {
    title: 'Notre Vision : la Logistique Automobile pour Tous | Pop Valet',
    meta: [
      { name: 'description', content: 'Pop Valet vous permet de combiner une série de services pour créer votre propre flux logistique sur-mesure et, ainsi, développer vos nouvelles mobilités.' },
    ]
  }
}
</script>

<style scoped>
.general-vision-savoir-plus-container  h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-vision-savoir-plus-container {
  padding: 100px 150px;
  line-height: 29px;
}
.general-vision-popvalet-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-vision-popvalet-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.general-vision-digital-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-vision-digital-container {
  padding: 100px 150px;
  line-height: 29px;
}
.general-vision-solution-container h3 {
  margin-top: 25px;
}
.general-vision-solution-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-vision-solution-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.general-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .general-vision-savoir-plus-container {
    padding: 100px 50px;
  }
  .general-vision-popvalet-container {
    padding: 100px 50px;
  }
  .general-vision-digital-container {
    padding: 100px 50px;
  }
  .general-vision-solution-container {
    padding: 100px 50px;
  }
}
</style>