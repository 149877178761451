<template>
  <div :class="[`rserver-un-transport-3`, className || ``]">
    <div class="rserver-un-transport-4 montserrat-medium-gunsmoke-18px">Réserver un transport &gt;</div>
  </div>
</template>

<script>
export default {
  name: "RserverUnTransport",
  props: ["className"],
};
</script>

<style>
.rserver-un-transport-3 {
  display: flex;
  height: 22px;
  left: 90px;
  position: absolute;
  top: 314px;
  width: 208px;
}

.rserver-un-transport-4 {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1.44%;
  margin-right: -1.92%;
  text-align: center;
  width: 215px;
}

.rserver-un-transport-3.rserver-un-transport-5 .rserver-un-transport-4 {
  margin-left: unset;
  margin-right: unset;
  width: 208px;
}
</style>
