<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Nos Uses Cases" :icon-url="require('../../img/mortarboard.png')"/>
    <div class="general-uses-cases-container">
      <div class="general-uses-cases-section">
        <div class="general-use-case use-case-logo-to-right">
          <p class="montserrat-medium-magic-potion-20px"><span style="font-weight: bold">La MAIF</span> fait confiance à Pop Valet pour le rapatriement des véhicules post-sinistre de ses assurés, via un iFrame conçu et développé sur mesure (<a href="https://maif.popvalet.com">https://maif.popvalet.com</a>). Pop Valet récupère ainsi le véhicule sur le lieu du sinistre après réparation. Ces activités nécessitent une réactivité à toute épreuve que Pop Valet met en place avec grande satisfaction (4,7/5).</p>
          <div class="general-use-case-logo-right">
            <img src="../../img/logo-maif-use-case.png" alt="logo_use_case">
          </div>
        </div>
      </div>
      <div class="general-uses-cases-section">
        <div class="general-use-case use-case-logo-to-left">
          <div class="general-use-case-logo-left">
            <img src="../../img/logo-reezocar-use-case.png" alt="logo_use_case">
          </div>
          <p class="montserrat-medium-magic-potion-20px"><span style="font-weight: bold">Reezocar</span>, filiale de la Société Générale, fait appel à Pop Valet pour la livraison à domicile des VO achetés sur sa plateforme, mais aussi pour ses approvisionnements de véhicules à l'étranger (Allemagne, BeNeLux, Italie, Espagne, UK). Ils sont ensuite apportés en reconditionnement puis livrés au client final. La réactivité et la qualité de service de Pop Valet ont su séduire Reezocar et Vivacar.</p>
        </div>
      </div>
      <div class="general-uses-cases-section">
        <div class="general-use-case use-case-logo-to-right">
          <p class="montserrat-medium-magic-potion-20px"><span style="font-weight: bold">Le Groupe suisse Emil Frey</span>, premier distributeur automobile européen, fait confiance à Pop Valet pour ses livraisons de VN, VO et véhicules de loueurs, mais aussi pour prendre en charge les véhicules vers ses ateliers, partout en France. La collaboration initiée en 2017, s'étend au fil du temps à de plus en plus de sites du réseau Emil Frey, gage de satisfaction et de qualité de service.</p>
          <div class="general-use-case-logo-right">
            <img src="../../img/logo-emil-frey-use-case.png" alt="logo_use_case">
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "NosUsesCases",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
    "header"
  ]
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .general-uses-cases-section {
    padding: 50px 53px 50px 53px;
  }
  .use-case-logo-to-left {
    flex-direction: column;
  }
  .use-case-logo-to-right {
    flex-direction: column-reverse;
  }
  .general-use-case-logo-left {
    margin-bottom: 25px;
  }
  .general-use-case-logo-right {
    margin-bottom: 25px;
  }
  .general-use-case {
    padding: 50px 50px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
  .general-uses-cases-section {
    padding: 50px 123px 50px 123px;
  }
  .general-use-case-logo-left {
    margin-right: 55px;
  }
  .general-use-case-logo-right {
    margin-left: 55px;
  }
  .general-use-case {
    padding: 100px 100px;
  }
}
@media screen and (min-width: 1441px) {
  .general-uses-cases-section {
    padding: 50px 223px 50px 223px;
  }
  .general-use-case-logo-left {
    margin-right: 55px;
  }
  .general-use-case-logo-right {
    margin-left: 55px;
  }
  .general-use-case {
    padding: 100px 100px;
  }
}
.general-uses-cases-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
.general-use-case {
  display: flex;
  min-height: 300px;
  background-color: #F7F6F4;
  border-radius: 10px;
  align-items: center;
}
.general-use-case p {
  line-height: 36px;
}
</style>