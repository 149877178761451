import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Simplert } from 'vue2-simplert-plugin'
import "../styleguide.css"
import "../globals.css"
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css'
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.use(Simplert);
Vue.use(VueMeta);

new Vue({
  render: h => h(App),
  router
  }).$mount("#app");

router.afterEach((to, from) => {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'page_view', {page_path: to.path});
  }

  if (window.location.hostname.startsWith('www.popvalet.com')) {
    if (window.location.pathname === '/professionnels') {
      window.FrontChat('init', {
        chatId: '93e609571280d416e363939ad947f9dd',
        useDefaultLauncher: true
      });
    } else {
      window.FrontChat('init', {
        chatId: '93e609571280d416e363939ad947f9dd',
        useDefaultLauncher: false
      });
    }
  }
});