<template>
  <div class="bloc_logos_clients bloc_logos_clients-2">
    <div class="logos_clients">
      <div>
        <div class="intro_clients">
          <div>
            <p class="isidorasansalt-bold-magic-potion-28px">
              <span class="underline-yellow">Ils nous font confiance</span>
            </p>
          </div>
        </div>
      </div>
      <fleches-logos />
      <div class="logos_clients-1">
        <img
          class="logo_emil_frey"
          src="../../img/logo-emil-frey-use-case.png"
          alt="logo_emil_frey"
        />
        <img
          class="reezocar-logo-1"
          src="../../img/reezocar-logo-1@2x.png"
          alt="reezocar-logo 1"
        />
        <div class="group-32" :style="{ 'background-image': 'url(' + require('../../img/logo-maif-2_2x.webp') + ')' }"></div>
        <img
            class="logo_arval"
            src="../../img/logo-arval.png"
            alt="logo_arval"
        />
        <img
            class="logo_inter_mutuelle"
            src="../../img/logo-inter-mutuelle-assistance.png"
            alt="logo_inter_mutuelle_assistance"
        />
        <img
            class="logo_athlon"
            src="../../img/logo_athlon.webp"
            alt="logo_athlon"
        />
      </div>
    </div>
    <voir-tous-les-uses-cases />
  </div>
</template>

<script>
import FlechesLogos from "./FlechesLogos";
import VoirTousLesUsesCases from "./VoirTousLesUsesCases";
export default {
  name: "BlocLogosClients",
  components: {
    FlechesLogos,
    VoirTousLesUsesCases,
  },
  props: ["group32"],
};
</script>

<style>
@media screen and (max-width: 991px) {
  .bloc_logos_clients {
    width: 100%;
    height: unset;
    min-height: 664px;
    margin-bottom: 130px;
  }
  .logos_clients {
    width: 100%;
    height: 100%;
  }
  .intro_clients {
    min-width: 100%;
  }
  .text_logos {
    margin-left: unset;
    min-height: 260px;
  }
  .intro_clients {
    min-width: 100%;
  }
  .overlap-group-3 {
    width: 100%;
  }
  .de-500-clients-pro {
    padding: 25px;
    width: 100%;
  }
  .logos_clients-1 {
    justify-content: center;
    width: 100%;
    margin-left: unset;
    flex-direction: column;
    align-items: center;
    height: unset;
  }
  .bloc_logos_clients.bloc_logos_clients-2 .logos_clients-1 {
    width: 100%;
    gap: 50px;
  }
  .group-32 {
    width: 180.3px;
    margin-left: 0;
    margin-top: 0;
  }
  .group-3 {
    margin-left: 25px;
  }
  .reezocar-logo-1 {
    margin-left: 25px;
    margin-top: 0;
  }
  .bloc_logos_clients.bloc_logos_clients-2.logos_clients_home {
    height: unset;
    margin-top: 900px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .bloc_logos_clients {
    width: 100%;
    height: 664px;
  }
  .logos_clients {
    margin-left: auto;
    margin-right: auto;
    width: 1208px;
    height: 338.79px;
  }
  .text_logos {
    margin-left: 107px;
    width: 107px;
  }
  .intro_clients {
    min-width: 568px;
  }
  .overlap-group-3 {
    width: 566px;
  }
  .logos_clients-1 {
    width: 1008.65px;
    height: 160.79px;
  }
  .bloc_logos_clients.bloc_logos_clients-2 .logos_clients-1 {
    width: 100%;
    justify-content: center;
  }
  .group-32 {
    width: 180.3px;
    margin-top: 24.1px;
  }
  .group-3 {
    margin-left: 174px;
  }
  .reezocar-logo-1 {
    margin-left: 40px;
    margin-top: 60px;
  }
  .logo_inter_mutuelle {
    margin-left: 40px;
  }
  .logo_athlon {
    margin-left: 20px;
  }
  .bloc_logos_clients.bloc_logos_clients-2.logos_clients_home {
    margin-top: 250px;
  }
}

@media screen and (min-width: 1441px) {
  .bloc_logos_clients {
    width: 100%;
    height: 664px;
  }
  .logos_clients {
    margin-left: auto;
    margin-right: auto;
    width: 1208px;
    height: 338.79px;
  }
  .text_logos {
    margin-left: 107px;
    width: 107px;
  }
  .intro_clients {
    min-width: 568px;
  }
  .overlap-group-3 {
    width: 566px;
  }
  .logos_clients-1 {
    width: 1008.65px;
    height: 160.79px;
  }
  .bloc_logos_clients.bloc_logos_clients-2 .logos_clients-1 {
    width: 100%;
    justify-content: center;
  }
  .group-32 {
    width: 180.3px;
    margin-top: 24.1px;
  }
  .group-3 {
    margin-left: 174px;
  }
  .reezocar-logo-1 {
    margin-left: 40px;
    margin-top: 60px;
  }
  .logo_inter_mutuelle {
    margin-left: 40px;
  }
  .logo_athlon {
    margin-left: 20px;
  }
  .bloc_logos_clients.bloc_logos_clients-2.logos_clients_home {
    margin-top: 250px;
  }
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -13px;
  text-decoration-color: var(--mustard);
}

.logos_clients_home .logos_clients {
  margin-top: 50px;
}

.bloc_logos_clients {
background-color: var(--blanc);
display: flex;
flex-direction: column;
gap: 41.2px;
margin-top: 1px;
position: relative;
align-items: center;
}

.logos_clients {
display: flex;
flex-direction: column;
gap: 26px;
margin-top: 130px;
position: relative;
}

.intro_clients {
display: flex;
justify-content: center;
}

.logos_clients-1 {
display: flex;
}

.logo_emil_frey {
  height: 76px;
  margin-top: 35.1px;
}

.logo_arval {
  height: 59px;
  margin-top: 42px;
}

.logo_inter_mutuelle {
  height: 59px;
  margin-top: 40px;
}

.logo_athlon {
  height: 76px;
  margin-top: 36px;
}

.logo_tesla-1-1 {
height: 76px;
margin-left: 0;
margin-top: 26.1px;
object-fit: cover;
width: 66px;
}

.group-3 {
height: 160.79px;
margin-top: 0;
width: 145px;
}

.reezocar-logo-1 {
height: 28px;
object-fit: cover;
width: 163px;
}

.group-32 {
background-position: 50% 50%;
background-size: cover;
height: 100px;
}

.bloc_logos_clients.bloc_logos_clients-2 {
margin-top: unset;
}
</style>
