<template>
  <div class="pro-services-avantages-container">
    <div class="pro-services-avantages">
      <p class="pro-services-avantages-title isidorasansalt-bold-white-28px">
        LES AVANTAGES DE CETTE SOLUTION
      </p>
      <div class="pro-services-avantages-items">
        <div class="avantage-item" v-for="avantage in avantages">
          <img class="coche_yellow" src="../../img/coche_yellow.png" alt="coche_yellow" />
          <p class="livraison-du-client isidorasansalt-semi-bold-white-18px">{{avantage}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProServicesAvantages",
  props: [
      'avantages'
  ]
}
</script>

<style scoped>
.pro-services-avantages-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--bluenavy);
  padding: 100px;
}

.pro-services-avantages {
  width: 100%;
  max-width: 1200px;
}

.pro-services-avantages-title {
  text-align: center;
  margin-bottom: 50px;
}

.pro-services-avantages-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 45px;
}

.avantage-item {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 500px;
}

.coche_yellow {
  width: 45px;
  height: 40px;
}

@media screen and (max-width: 576px) {
  .pro-services-avantages-container {
    padding: 100px 10px;
  }
}
</style>