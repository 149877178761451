<template>
  <div class="group-124">
    <div class="overlap-group-6">
      <p class="address-1 blogtextbold">
        <span class="span-9 blogtextbold">{{ spanText1 }}</span
        ><br><br><span class="textcourantsmall">{{ spanText2 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group124",
  props: ["spanText1", "spanText2"],
};
</script>

<style>
.group-124 {
  align-items: flex-start;
  display: flex;
  min-width: 287px;
}

.overlap-group-6 {
  align-items: flex-end;
  background-color: var(--blanc);
  box-shadow: 0px 4px 24px #00000014;
  display: flex;
  height: 186px;
  min-width: 285px;
  padding: 30px 32px;
}

.address-1,
.plus-de-700-experts,
.address-2,
.address-3 {
  color: var(--bluenavy);
  font-weight: 400;
  line-height: 19.2px;
  min-height: 125px;
  width: 221px;
}

.span-9 {
  font-weight: 500;
}
</style>
