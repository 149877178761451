<template>
  <div class="pro-services-intro-container">
    <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">{{introText}}</p>
    <div class="sub-services-container">
      <div class="sub-service-bloc" v-for="(service, i) in services">
        <div class="service-yellow-picto">
          <img class="service-icon" :src="service.iconUrl" alt="icon-key" />
        </div>
        <div class="service-name montserrat-medium-magic-potion-20px">{{service.name}}</div>
        <div class="en-savoir-plus montserrat-medium-gunsmoke-18px" @click="openModal(i)">En savoir plus ></div>
        <template v-if="service.popup">
          <vue-final-modal
              v-model="showModal[i]"
              @click-outside="showModal[i] = false"
              classes="modal-container"
              content-class="modal-content">
            <div class="service-modal-container">
              <div class="service-yellow-picto">
                <img class="service-icon" :src="service.iconUrl" alt="icon-key" />
              </div>
              <div>
                <p class="modal__title">{{service.popup.title}}</p>
                <p>{{service.popup.content}}</p><br>
                <a v-if="service.popup.link" :href="service.popup.link" style="color: #002244"><p>En savoir plus</p></a>
              </div>
            </div>
          </vue-final-modal>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {VueFinalModal, ModalsContainer} from 'vue-final-modal';
export default {
  name: "ProServicesIntro",
  props: [
      'introText',
      'services'
  ],
  components: {
    VueFinalModal,
    ModalsContainer
  },
  data: function() {
    return {
      showModal: Array(this.services.length).fill(false)
    };
  },
  methods: {
    openModal: function(index) {
      Vue.set(this.showModal, index, true);
    }
  }
}
</script>

<style scoped>

.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
}

.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}

.sub-services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  max-width: 1000px;
  margin: auto;
  margin-top: 120px;
  justify-content: space-around;
}

.sub-service-bloc {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: center;
  position: relative;
}

.service-yellow-picto {
  background-color: var(--yellow);
  border-radius: 34.5px;
  height: 69px;
  min-width: 69px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-icon {
  max-height: 46px;
  max-width: 46px;
}

.service-name {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

.en-savoir-plus {
  cursor: pointer;
}

.service-modal-container {
  display: flex;
  padding: 40px;
  gap: 20px;
}
.service-modal-container > .service-yellow-picto {
  border-radius: 43px;
  height: 86px;
  min-width: 86px;
}

@media screen and (max-width: 576px) {
  .service-modal-container {
    flex-direction: column;
    padding: 0;
  }
  .service-modal-container > .service-yellow-picto {
    display: none;
  }
}

</style>