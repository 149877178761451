<template>
  <div class="multimodalite-camion-container">
    <div class="multimodalite-camion">
      <p class="multimodalit-du-transport-par-camion isidorasansalt-bold-magic-potion-28px">
        <span class="underline-yellow">MULTIMODALITÉ</span> DU TRANSPORT PAR CAMION
      </p>
      <div class="multimodalite-camion-schema-container">
        <div class="schema-container">
          <img class="truck_pro-1" src="../../img/500-camions-plateaux.png" alt="truck_pro 1" />
          <div class="camions-6">
            <p class="x500-camions-portes-vhicules isidorasansalt-bold-magic-potion-30px">500</p>
            <p class="span-74 montserrat-normal-magic-potion-14px">camions porte-véhicules disponibles en France ou à l'étranger</p>
          </div>
        </div>
        <div class="schema-container">
          <img class="truck_pro-1" src="../../img/schema_selection.png" alt="schema_selection" />
          <div class="selection">
            <p class="slection-de-partena isidorasansalt-bold-magic-potion-30px">Sélection</p>
            <p class="span-74 montserrat-normal-magic-potion-14px">de partenaires avec les valeurs de Pop Valet, qualité de service et réactivité</p>
          </div>
        </div>
        <div class="schema-container">
          <img class="cumul" src="../../img/cumul@2x.png" alt="cumul" />
          <div class="cumul-1">
            <p class="cumul-dun-trajet-gr isidorasansalt-bold-magic-potion-30px">Cumul</p>
            <p class="span-74 montserrat-normal-magic-potion-14px">d'un trajet grand axe par camion, livraison finale par un Valet</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultimodaliteCamion"
}
</script>

<style scoped>
.multimodalite-camion-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 100px 0;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -13px;
  text-decoration-color: var(--mustard);
}

.multimodalit-du-transport-par-camion {
  padding: 0 20px;
  letter-spacing: 0;
  line-height: normal;
}

.multimodalite-camion-schema-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.schema-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.truck_pro-1 {
  height: auto;
  width: 256px;
}

.camions-6 {
  text-align: center;
}

.span-74 {
  margin-top: 8px;
  line-height: 20px;
  font-weight: 500;
}

.selection,
.cumul-1 {
text-align: center;
}

.x500-camions-portes-vhicules,
.slection-de-partena,
.cumul-dun-trajet-gr {
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.cumul {
  margin-top: 30px;
  height: 125px;
  width: 187px;
}
</style>