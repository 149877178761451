<template>
  <div class="bloc_cas_usage">
    <div class="overlap-group4-11">
      <img class="operateur-1-1" src="../../img/operateur-expertise.jpg" alt="operateur 1" />
      <div class="flex-col-28">
        <div class="flex-col-29">
          <img class="business-goal" src="../../img/business---goal-1@2x.png" alt="Business / goal" />
          <div class="cas-dusage isidorasansalt-semi-bold-magic-potion-28px">Cas d'usage</div>
        </div>
        <div class="use-cases-container">
          <div class="use-case" v-for="useCase in useCases">
            <img class="icon-check_mark-7" src="../../img/Approve.png" alt="icon-check_mark" />
            <div>
              <span class="isidorasansalt-bold-magic-potion-19px">{{useCase.title}} : </span>
              <span class="isidorasansalt-semi-bold-magic-potion-19px">{{useCase.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProServicesUseCases",
  props: [
      'useCases'
  ]
}
</script>

<style scoped>
.bloc_cas_usage {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  width: 100%;
  background-color: var(--grisclair);
}

.overlap-group4-11 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 53px;
  justify-content: center;
  max-width: 1440px;
  padding: 100px;
}

.operateur-1-1 {
  max-width: 100%;
  height: auto;
  max-height: 429px;
  border-radius: 8px;
}

.flex-col-28 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 559px;
}

.flex-col-29 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 128px;
  width: 158px;
}

.business-goal {
  height: 77px;
  width: 77px;
}

.cas-dusage {
  letter-spacing: 0;
  line-height: 45.9px;
  min-height: 46px;
  min-width: 158px;
  text-align: center;
  white-space: nowrap;
}

.use-cases-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.use-case {
  display: flex;
  line-height: 26px;
}

.icon-check_mark-7 {
  height: 24px;
  width: 24px;
  margin-top: 3px;
  margin-right: 10px;
}

@media screen and (max-width: 576px) {
  .overlap-group4-11 {
    padding: 100px 10px;
  }
}
</style>