<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Transport en camion porte 8 voitures" :icon-url="require('../../img/truck.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Les solutions de <a href="/transport-voiture-camion" style="color: #002244">transport par camion</a> pensées par Pop Valet impliquent des poids-lourds pouvant déplacer jusqu’à huit voitures en même temps. Les atouts d’une telle méthode ? Elle est économique, elle est fiable, et elle s’impose naturellement aux voitures neuves pour lesquelles vous ne souhaitez pas ajouter de kilomètres au compteur, aux véhicules de prestige ou de collection, ou encore aux véhicules électriques.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Délais de livraison, assurance des voitures transportées, adresses de chargement et de déchargement : nos équipes vous présentent leur outil porte 8.</p>
    </div>
    <div class="pro-services-camion-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Un camion porte 8, qu’est-ce que c’est ?</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Un camion porte 8 désigne un véhicule poids-lourds dont la remorque peut supporter jusqu’à huit voitures. Il s’agit de l’un des plus gros camions en circulation dans le monde : ces modèles peuvent mesurer jusqu’à neuf mètres de long et tolérer une charge supérieure à cinq tonnes.</p>
    </div>
    <div class="pro-services-camion-transport-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Comment fonctionne notre transport de voitures en porte-véhicules ?</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Plusieurs voitures à transporter par camion ? Depuis notre interface dédiée, quelques minutes vous suffisent pour nous indiquer :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Le nombre et les caractéristiques de vos véhicules (marque, modèle, immatriculation, VIN)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Les lieux de départ et d’arrivée de vos véhicules</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Les dates souhaitées</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Pop Valet s’occupe du reste.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Du chargement au déchargement</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Notre transporteur se rend à l’adresse indiquée pour procéder au chargement de vos voitures (ou à la distance la plus proche si le camion n’est pas en mesure d’accéder au point indiqué). Une lettre de voiture est établie pour les véhicules à transporter ; ce document constitue le contrat de transport imposé par la loi.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Notre conducteur procède ensuite au chargement de vos véhicules, dans le plus strict respect des normes en vigueur :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Espace entre les véhicules</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Chargement via la rampe</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Activation du treuil pour faciliter le tractage</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Actionnement des plateaux</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Etc.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Ce n’est qu’une fois vérifiées les conditions d’arrimage de vos véhicules que le transporteur Pop Valet prend la route. Pour une sécurité maximale, nous imposons à nos conducteurs le respect du Code des transports, qui enjoint une pause :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- D’au moins 30 minutes lorsque le total des heures de travail est compris entre six et neuf heures</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- D’au moins 45 minutes lorsque le total des heures de travail est supérieur à neuf heures</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Vos véhicules sont ensuite livrés sur le lieu préalablement indiqué, dans la mesure où l’aire de manœuvre est suffisamment large pour permettre le bon déchargement des voitures.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Vos convoyages par camion en provenance et à destination de toute l’Europe</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Les près de 150 véhicules constituant la flotte Pop Valet assurent une couverture continentale. Notre réseau de transport est tissé :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- En France métropolitaine, ainsi qu’en région Corse</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">- Dans la plupart des pays européens</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Partout, Pop Valet garantit la traçabilité du véhicule et se charge du suivi de la livraison.</p>
    </div>
    <div class="pro-services-camion-faq-container">
      <h2 class="isidorasansalt-bold-white-28px">Transport en camion porte 8 : FAQ</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous avez une question ? Pop Valet a la réponse.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Quel type de voitures puis-je faire transporter en camion porte 8 ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">La solution de transport par porte-véhicules mise en place par Pop Valet s’adapte à tous les types de voitures :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Berline</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Coupé</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Multisegments</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Familiale</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Cabriolet</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Roadster</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- SUV</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Sportive</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Etc.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Nos chauffeurs dédient le même soin aux véhicules d’occasion qu’aux véhicules neufs ou de collection. En outre, nous transportons à la fois :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Des voitures roulantes : comme évoqué précédemment, les cas d’usage sont multiples</p>
      <p class="isidorasansalt-semi-bold-white-18px">- Des voitures non roulantes : en cas de véhicules en panne ou accidentés, Pop Valet peut assurer une collecte ou une livraison dans le garage ou centre d’entretien de votre convenance, ou encore à n’importe quelle autre adresse souhaitée</p>

      <h3 class="isidorasansalt-bold-white-24px">Quels sont les délais de transfert en camion porte-véhicules ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Les outils mis en place par nos équipes nous permettent d’optimiser nos process et de réduire au maximum nos délais de transports par poids-lourds. À partir de la réception de votre commande, comptez cinq jours ouvrés pour un convoyage entre deux adresses.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">À noter : pour une réactivité accrue, nous vous encourageons à plébisciter <a href="/transport-avec-chauffeur" style="color: white">nos solutions de transport par Valet.</a></p>

      <h3 class="isidorasansalt-bold-white-24px">Sur un camion porte 8, comment mes voitures sont-elles assurées ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">À partir du moment où nous chargeons vos véhicules et jusqu’à l’instant où nous les déchargeons, vos voitures sont sous la responsabilité de Pop Valet. Un état des lieux rigoureux, à l’entame et à la conclusion de notre mission, permet l’établissement d’une relation de confiance. Notre partenaire la MAIF nous fait bénéficier des garanties suivantes :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">RC automobile</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Dommages tous accidents</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Quels sont les critères pris en compte pour calculer le coût du transport par porte 8 ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Plusieurs véhicules à transporter ? Le transport par porte-véhicules demeure la solution la plus économique, à plus forte raison pour les longues distances. Le coût final de notre prestation varie selon plusieurs facteurs, les principaux étant :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- La distance séparant le point de collecte du point de livraison</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- La nature des véhicules à transporter : neufs, d’occasion…</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- L’état des véhicules à convoyer : roulants ou en panne</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les coûts de l’essence</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">À noter : lors de la prise de commande, Pop Valet vous propose en ligne une tarification indicative, incluant l’intégralité des prestations commandées. Après contrôle des disponibilités, nos services confirment le tarif soumis ou en soumettent un nouveau, compte-tenu notamment des variations des coûts de l’essence. Pop Valet n’applique ni frais cachés, ni surcoût une fois vos véhicules livrés.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Suis-je obligé de confier huit véhicules d’un coup à Pop Valet ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Non : bien que nous soyons en mesure de traiter des opérations spéciales impliquant le déplacement de plusieurs dizaines de véhicules en simultané, nous ne vous imposons pas de nous confier une flotte de huit véhicules au minimum. Notre solution de transport par camion s’applique également aux besoins plus modestes, à partir d’un unique véhicule à convoyer.</p>
    </div>
    <div class="pro-services-camion-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-16px">Un seul véhicule, roulant ou non roulant, à transporter par camion ? Rendez-vous sur notre service de <a href="" style="color: #002244">camion plateau ou dépanneuse.</a></p>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletProServicesCamionPorte8",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Transport en Camion Porte 8 Voitures | Pop Valet',
    meta: [
      { name: 'description', content: 'Transportez jusqu’à 8 voitures sur le même camion : solution pour véhicules fonctionnels ou en panne, pour de courtes ou longues distances.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.popvalet.com/transport-vehicule-porte8' }
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://www.popvalet.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Transport de voiture par camion",
              "item": "https://www.popvalet.com/transport-voiture-camion"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Transport en camion porte 8 voitures",
              "item": "https://www.popvalet.com/transport-vehicule-porte8"
            }
          ]
        }
      }
    ]
  }
}
</script>

<style scoped>
.pro-services-camion-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-plus-loin-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-faq-container h3 {
  margin-top: 25px;
}
.pro-services-camion-faq-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-faq-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-transport-container h3 {
  margin-top: 25px;
}
.pro-services-camion-transport-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-transport-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-camion-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-camion-faq-container {
    padding: 100px 50px;
  }
  .pro-services-camion-transport-container {
    padding: 100px 50px;
  }
  .pro-services-camion-definition-container {
    padding: 100px 50px;
  }
}
</style>