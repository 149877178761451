<template>
  <div :class="[`bloc_reseau_popvalet-item-1`, className || ``]">
    <img
      class="stock-photo-man-usin-1"
      :src="stockPhotoManUsingASmartPhoneSittin"
      alt="stock-photo-man-using-a-smart-phone-sitting-on-the-front-seat-of-the-car-back-view-focused-on-the-phone-with-617677994 1"
    /><group124 :spanText1="group124Props.spanText1" :spanText2="group124Props.spanText2" />
  </div>
</template>

<script>
import Group124 from "./Group124";
export default {
  name: "X2500Valets",
  components: {
    Group124,
  },
  props: ["stockPhotoManUsingASmartPhoneSittin", "className", "group124Props"],
};
</script>

<style>
.bloc_reseau_popvalet-item-1 {
  display: flex;
  flex-direction: column;
  height: 377px;
  position: relative;
  width: 285px;
}

.stock-photo-man-usin-1 {
  height: 191px;
  object-fit: cover;
  width: 285px;
}
</style>
