<template>
  <div class="overlap-group9-2">
    <div class="flex-col-20">
      <div class="accroche_home_pro-2">
        <div class="accroche_home-2">
          <div class="overlap-group-53">
            <h1 class="nous-livrons-votre-v isidorasansalt-semi-bold-magic-potion-40px">Nous livrons votre véhicule en 48h <span class="underline-yellow">partout en France</span></h1>
          </div>
        </div>
      </div>
      <div class="cta_reserver_transport cta_reserver_transport-1" @click="scrollToPageAnchor('#reserver-transport')">
        <div class="reserver-mon-transport blogtextbold btn-reserver-transport">RÉSERVER MON TRANSPORT EN 30 SECONDES</div>
      </div>
    </div>
    <div class="overlap-group10-2">
      <img class="subtract" src="../../img/subtract@2x.png" alt="Subtract" /><img
        class="stock-photo-car-sale"
        src="../../img/photo-car-salesman-giving-car-keys-from-a-silver-new-car.png"
        alt="stock-photo-car-salesman-giving-car-keys-from-a-silver-new-car-to-a-man-isolated-on-white-background-1754829794 1"
    />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocParticulierAccueilTitle",
  methods: {
    scrollToPageAnchor: (anchor) => {
      document.querySelector(anchor).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .flex-col-20 {
    width: 70%;
    padding: 30px;
    gap: 100px;
    align-items: center;
  }
}

@media screen and (min-width: 500px) and (max-width: 991px) {
  .flex-col-20 {
    width: 85%;
    padding: 30px;
    gap: 100px;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .overlap-group9-2 {
    min-width: 100%;
    flex-direction: column;
    height: unset;
  }
  .accroche_home_pro-2 {
    width: 100%;
  }
  .accroche_home-2 {
    width: 100%;
  }
  .overlap-group-53 {
    width: 100%;
  }
  .nous-livrons-votre-v {
    width: 100%;
  }
  .subtract {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .overlap-group9-2 {
    justify-content: flex-end;
    min-width: 1440px;
    height: 542px;
  }
  .flex-col-20 {
    width: 568px;
    gap: 100px;
    align-items: flex-start;
  }
  .accroche_home_pro-2 {
    width: 557px;
  }
  .accroche_home-2 {
    width: 559px;
  }
  .overlap-group-53 {
    width: 557px;
  }
  .nous-livrons-votre-v {
    width: 557px;
  }
  .subtract {
    left: 0;
  }
}

@media screen and (min-width: 1441px) {
  .overlap-group9-2 {
    justify-content: center;
    min-width: 100%;
    height: 542px;
  }
  .flex-col-20 {
    width: 568px;
    gap: 100px;
    align-items: flex-start;
  }
  .accroche_home_pro-2 {
    width: 557px;
  }
  .accroche_home-2 {
    width: 559px;
  }
  .overlap-group-53 {
    width: 557px;
  }
  .nous-livrons-votre-v {
    width: 557px;
  }
  .subtract {
    left: 0;
  }
}

.overlap-group9-2 {
  align-items: center;
  background-color: #d1cefd66;
  display: flex;
  gap: 44px;
  padding: 0 34px;
}

.flex-col-20 {
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  min-height: 209px;
  position: relative;
}

.accroche_home_pro-2 {
  align-self: flex-end;
  display: flex;
}

.accroche_home-2 {
  align-items: flex-start;
  display: flex;
}

.overlap-group-53 {
  height: 96px;
  position: relative;
}

.nous-livrons-votre-v {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.overlap-group10-2 {
  align-self: flex-end;
  height: 492px;
  position: relative;
  width: 583px;
}

.subtract {
  height: 349px;
  position: absolute;
  top: 143px;
  width: 455px;
}

.stock-photo-car-sale {
  height: 492px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 583px;
}

.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 10px;
  text-underline-offset: -18px;
  text-decoration-color: var(--mustard);
}

.cta_reserver_transport {
  cursor: pointer;
  background-color: #D1CEFE;
  padding: 12px 38px;
  border-radius: 34px;
}

.cta_reserver_transport:hover {
  background-color: #6C63F8;
}
</style>