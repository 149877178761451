<template>
  <div :class="[`picto_reactivite`, className || ``]"><img class="icon-clock-2" src="../../img/vector-3@2x.png" alt="icon-clock" /></div>
</template>

<script>
export default {
  name: "PictoReactivite",
  props: ["className"],
};
</script>

<style>
.picto_reactivite {
  background-color: var(--yellow);
  border-radius: 34.5px;
  display: flex;
  height: 69px;
  margin-top: -10.5px;
  width: 69px;
}

.icon-clock-2 {
  height: 34.17px;
  margin: auto;
  width: 34.17px;
}

.picto_reactivite.picto_reactivite-1 .icon-clock-2 {
  width: 34.17px;
}

.picto_reactivite.picto_reactivite-2 .icon-clock-2 {
  height: 34.17px;
}

.picto_reactivite.picto_convoyage {
  display: unset;
  margin-top: unset;
  position: relative;
}

.picto_reactivite.picto_convoyage .icon-clock-2 {
  height: 36px;
  left: 17px;
  margin-left: unset;
  margin-top: unset;
  position: absolute;
  top: 16px;
  width: 34px;
}
</style>
