<template>
  <div :class="[`line_timeline1`, className || ``]">
    <div class="group-236-1">
      <div class="overlap-group-59">
        <img class="line-18-1" :src="line18" alt="Line 18" />
        <div class="ellipse-40-1"></div>
      </div>
    </div>
    <picto-gerez-planning :src="pictoGerezPlanningProps.src" :className="pictoGerezPlanningProps.className" />
  </div>
</template>

<script>
import PictoGerezPlanning from "./PictoGerezPlanning";
export default {
  name: "LineTimeline1",
  components: {
    PictoGerezPlanning,
  },
  props: ["line18", "className", "pictoGerezPlanningProps"],
};
</script>

<style>
.line_timeline1 {
  display: flex;
  flex-direction: column;
  height: 147px;
  left: 86px;
  position: absolute;
  top: 5px;
  width: 68px;
}

.group-236-1 {
  align-items: flex-end;
  display: flex;
  height: 79px;
  margin-left: 28px;
  min-width: 13px;
}

.overlap-group-59 {
  height: 80px;
  margin-bottom: -1.5px;
  position: relative;
  width: 13px;
}

.line-18-1 {
  height: 78px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 3px;
}

.ellipse-40-1 {
  background-color: var(--blanc);
  border-radius: 6.5px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.line_timeline1.line_timeline5 {
  left: 317px;
  top: 135px;
}
</style>
