<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="CGU" :icon-url="require('../../img/valet.png')"/>
    <div class="general-cgu-container montserrat-semi-bold-magic-potion-16px" style="line-height: 29px">
      <p>1. Objet<br>
        <br>
        La société AB2P propose une interface (ci-après : l’« Interface ») de gestion du transport de véhicules (ci-après : les « Véhicules ») par chauffeur pour le compte d’un bénéficiaire (ci-après : le « Bénéficiaire »), consistant à acheminer des véhicules d’une adresse de départ à une adresse d’arrivée selon les instructions du Bénéficiaire.</p>

      <p>Les Prestations proposées sur l’Interface sont accessibles au Bénéficiaire, qu’il accède à l’Interface : <br>
        - soit directement via le site www.popvalet.com (ci-après : le « Site ») (ci-après : le « Bénéficiaire Direct ») ;<br>
        - soit par l’intermédiaire de l’un des partenaires de la société AB2P (ci-après : les « Partenaires ») (ci-après : le « Bénéficiaire Indirect »). <br>
        <br>
        Le transport du Véhicule peut être effectué par un salarié ou un sous-traitant direct ou indirect de la société AB2P (ci-après : le « Chauffeur »).<br>
        <br>
        Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des Prestations proposés sur l’Interface (ci-après : les « Prestations ») ainsi que de définir les droits et obligations des parties dans ce cadre.</p>

      <p>Elles sont accessibles et imprimables à tout moment par un lien direct en bas de page du Site.</p>

      <p>Elles peuvent être complétées le cas échéant par des conditions d’utilisation particulières à certaines Prestations, lesquelles complètent les présentes conditions générales et, en cas de contradiction, prévalent sur ces dernières.</p><br>

      <p>2. Exploitant de l’Interface et des Prestations, contact</p>

      <p>L’Interface et les Prestations sont exploitées par la société AB2P, SAS au capital social de <br>
        208.808,00 euros, immatriculée au RCS de Paris sous le n° 752 998 161, dont le siège social est situé 36, rue Scheffer 75116 Paris (ci-après : « Pop Valet »).</p>

      <p>POP VALET peut être contactée aux coordonnées suivantes :<br>
        <br>
        Adresse postale : 36, rue Scheffer 75116 Paris<br>
        Téléphone : 01.76.42.00.82<br>
        Adresse électronique : contact@popvalet.com<br>
        <br>
        3. Accès à l’Interface et aux Prestations</p>

      <p>L’Interface et les Prestations sont accessibles :</p>

      <p>- à toute personne physique disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder à l’Interface et aux Prestations qu’avec l’accord de son représentant légal ;</p>

      <p>- à toute personne morale agissant par l’intermédiaire d’une personne physique disposant de la capacité juridique pour contracter au nom et pour le compte de la personne morale.</p><br>

      <p>4. Acceptation des conditions générales<br>
        <br>
        L’acceptation des présentes conditions générales par le Bénéficiaire est matérialisée par une case à cocher au moment :<br>
        - du paiement du Prix de la Prestation pour le Bénéficiaire Direct ;<br>
        - de la saisie des coordonnées bancaires pour le Bénéficiaire Indirect.</p>

      <p>Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. Le Bénéficiaire qui n’accepte pas d’être lié par les présentes conditions générales ne doit pas accéder à l’Interface ni utiliser les Prestations.</p><br>

      <p>5. Processus de commande de la Prestation</p><br>

      <p>5.1 Informations et documents à fournir avant la Prise en Charge</p><br>

      <p>Pour chaque demande de Prestation, le Bénéficiaire doit remplir le formulaire de demande avec toutes les informations marquées comme obligatoires (ci-après : le « Formulaire »). Le Bénéficiaire a le choix entre différentes offres de Prestations qui sont indiquées sur le Site.</p>

      <p>Toute demande incomplète ne sera pas validée.</p>

      <p>A ce titre, le Bénéficiaire s’engage à renseigner l’identité de la personne chargée de :</p>

      <p>- remettre le Véhicule au Chauffeur (ci-après : l’« Expéditeur ») ;<br>
        - réceptionner le Véhicule (ci-après : le « Destinataire »).</p>

      <p>Étant précisé que le Bénéficiaire peut lui-même avoir la qualité d’Expéditeur et/ou de Destinataire. <br>
        <br>
        En tout état de cause, le Bénéficiaire s’engage à choisir un lieu de livraison du Véhicule librement accessible au public sans contrainte ni risque particulier pour le Chauffeur.</p>

      <p>En outre, le Bénéficiaire informe expressément et clairement Pop Valet, par tout moyen utile et notamment dans le Formulaire, des particularités non visibles de ses Véhicules et lui communique toute information utile pour la bonne réalisation des Prestations. Le Bénéficiaire supporte vis-à-vis de Pop Valet les conséquences résultant d'une déclaration fausse ou incomplète au sein du Formulaire ainsi que d'une absence ou d'une insuffisance d’information ayant eu pour effet, entre autres, de dissimuler le caractère dangereux ou frauduleux des Véhicules.</p><br>

      <p>5.2 Validation du Formulaire</p><br>

      <p>Pop Valet valide le Formulaire par le biais d’un email de confirmation dans un délai raisonnable et au plus tard avant la date de la Prestation. Pop Valet ne sera engagée à réaliser la Prestation qu’après confirmation par Pop Valet de cette Prestation au Bénéficiaire.</p>

      <p>Tant que le Formulaire n’a pas été validé par Pop Valet, le Bénéficiaire a la possibilité d’annuler ou de modifier ledit Formulaire en contactant Pop Valet aux coordonnées indiquées à l’article 2 ou directement via l’Interface.</p>

      <p>En tout état de cause, Pop Valet se réserve le droit d'annuler ou de ne pas confirmer tout Formulaire d'un Bénéficiaire avec lequel il existerait un arriéré de paiement ou un litige relatif au paiement de précédentes Prestations.</p>

      <p>L’email de confirmation contiendra un lien permettant au Bénéficiaire Direct de procéder au paiement des Prestations.</p><br>

      <p>6. Description de la Prestation<br>
        <br>
        Le Bénéficiaire a accès aux Prestations qui suivent, sous une forme et selon les fonctionnalités et moyens techniques que Pop Valet juge les plus appropriés.</p><br>

      <p>6.1 Prise en charge et transport du Véhicule</p><br>

      <p>L’Expéditeur s’engage à remettre le Véhicule au Chauffeur conformément aux informations indiquées dans le Formulaire.</p>

      <p>Les parties procéderont à la réalisation d’un état des lieux du Véhicule, sous forme électronique ou papier, qui devra être rempli et signé par l’Expéditeur et le Chauffeur (ci-après : l’« Etat des Lieux »).</p>

      <p>Toute anomalie constatée sur le Véhicule qui n’aurait pas été reportée sur le Formulaire sera notifiée à l’Expéditeur et ajoutée par le Chauffeur sur l’État des Lieux. La Prise en Charge définie ci-après ne pourra avoir lieu qu’à la condition que l’Expéditeur et le Chauffeur s’accordent sur le contenu de l’État des Lieux.</p>

      <p>Une fois l’État des Lieux signé, le Chauffeur procèdera alors à l’ouverture et au démarrage du Véhicule (ci-après : la « Prise en Charge »).</p>

      <p>Le Chauffeur est libre d’emprunter l’itinéraire de son choix. Il s’engage néanmoins à optimiser la durée du transport, compte tenu des contraintes de sécurité, des infrastructures de transport, des circonstances, des caractéristiques et de la nature du Véhicule.</p><br>

      <p>6.2 Livraison du Véhicule</p><br>

      <p>Le Chauffeur procède à la fermeture et à la remise physique du Véhicule auprès du Destinataire au lieu et dans les conditions indiquées dans le Formulaire (ci-après : la « Livraison »).<br>
        <br>
        La remise physique du Véhicule donne lieu à la signature d’un État des Lieux par le Destinataire et le Chauffeur, qui fera notamment état du nom du Destinataire et de la date de la Livraison.<br>
        <br>
        Dès sa signature par le Destinataire et Pop Valet, la Livraison est considérée comme validée par le Destinataire, le cas échéant au nom et pour le compte du Bénéficiaire si celui-ci n’est pas le Destinataire.</p>

      <p>Dans le cas où le Destinataire ne signerait pas l’État des lieux, le Bénéficiaire devra notifier par lettre recommandée avec accusé de réception toute réclamation à Pop Valet relative à l’état du Véhicule dans les 24 (vingt-quatre) heures suivant la Livraison. A défaut de signalement d’anomalie dans ce délai, la Livraison sera réputée validée par le Bénéficiaire.</p><br>

      <p>6.3 Transfert des risques</p><br>

      <p>La charge des risques afférents au Véhicule est transférée à Pop Valet au début de la Prise en Charge, jusqu’à la signature de l’État des Lieux lors de la Livraison.</p>

      <p>Dans le cas où la Livraison s’effectue en dehors de la présence du Destinataire, la charge des risques au Bénéficiaire s’effectue au même moment.<br>
        <br>
        Le transfert de risques n’a aucune incidence sur la propriété du Véhicule, qui n’est à aucun moment transférée à Pop Valet.</p>

      <p>En tout état de cause, le Bénéficiaire supportera l’ensemble des conséquences financières résultant directement ou indirectement de l’état du Véhicule, de l’absence ou de l’invalidité des documents requis (ex : carte grise, attestation d’assurance etc.) lors de la Prise en Charge, ainsi que d’une immobilisation corrélative du Véhicule. A ce titre, le Bénéficiaire remboursera à Pop Valet toute somme que Pop Valet sera amenée à supporter de ce fait, sans préjudice des dommages et intérêts auxquels Pop Valet pourrait prétendre. <br>
        <br>
        6.4 Modification de la Prestation au cours de sa réalisation</p><br>

      <p>Une fois le Formulaire validé par Pop Valet, toute demande de modification de ce dernier par le Bénéficiaire doit faire l’objet d’une demande par téléphone ou par email.</p>

      <p>Pop Valet se réserve le droit de refuser une demande de modification, notamment si, après examen, cette dernière est de nature à porter atteinte à l’exécution des Prestations fournies par Pop Valet. Pop Valet en avisera le Bénéficiaire dans les meilleurs délais par tout moyen.</p>

      <p>Le cas échéant, toute modification des Prestations peut entraîner une révision du prix initial indiqué dans l’email de confirmation, à la hausse ou à la baisse selon les critères énoncés à l’article « Conditions Financières », sous réserve de l’accord des 2 (deux) Parties, et ce, préalablement à l’exécution de la nouvelle Prestation.</p><br>

      <p>6.5 Retard ou impossibilité de réaliser la Prestation<br>
        <br>
        6.5.1 Retard pour réaliser la Prestation</p><br>

      <p>Pour tout retard dans la Prise en Charge du Véhicule par le Chauffeur (i) dû à l’immobilisation du Véhicule, quelle qu’en soit la cause et (ii) dépassant 30 (trente) minutes par rapport à l’horaire prévu dans le Formulaire, Pop Valet se réserve la possibilité de facturer une pénalité de 10 (dix) euros HT par demi-heure de retard.<br>
        <br>
        6.5.2 Impossibilité de réaliser la Prestation</p><br>

      <p>En cas d'impossibilité de réalisation de la Prise en Charge, du transport et/ou de la Livraison due au Bénéficiaire, à l’Expéditeur et/ou au Destinataire, quelle qu’en soit la cause, notamment en cas de :</p>

      <p>- immobilisation du Véhicule ;<br>
        - panne du Véhicule ;<br>
        - retard résultant d’embouteillages, d’une modification imposée par le Bénéficiaire, de déviations ou travaux rendant la circulation difficile ;<br>
        - force majeure telle que prévue à l’article 1218 du Code civil ;<br>
        - absence de l’Expéditeur ou du Destinataire, alors même que la Prise en Charge ou la Livraison en mains propres a été expressément prévue dans le Formulaire ;</p>

      <p>… le Bénéficiaire sera redevable de la totalité du prix de la Prestation si aucun trajet n’a pu être effectué.<br>
        <br>
        6.5.3 Signalement et gestion du retard ou de l’impossibilité de réaliser la Prestation</p><br>

      <p>En cas de retard, ou si la Prise en Charge, le transport et/ou la Livraison n’a pas eu lieu, entrainant une impossibilité pour Pop Valet d’exécuter la Prestation dans les conditions initialement prévues, Pop Valet s’engage à contacter le Bénéficiaire par téléphone dans les meilleurs délais afin de lui signaler le retard ou l’impossibilité d’exécuter la Prestation et d’obtenir des instructions supplémentaires.</p>

      <p>Le Bénéficiaire reconnait et accepte qu’à défaut d’instructions fournies par ce dernier dans un délai de 1 (une) heure à compter de l’heure de la Prise en Charge du Véhicule prévue dans le Formulaire, Pop Valet se réserve le droit de prendre les mesures qui lui paraissent les plus appropriées et l’informera de ces mesures par téléphone confirmé par email.</p>

      <br><p>6.6 Annulation de la Prestation</p><br>

      <p>Le Bénéficiaire peut annuler la Prestation sans frais au plus tard 6 (six) heures avant l’heure de la Prise en Charge.</p>

      <p>Pour toute annulation ultérieure, Pop Valet facturera un montant égal à :<br>
        <br>
        - la totalité du prix de la Prestation si l’annulation a lieu moins de 3 (trois) heures ouvrées avant l’heure de la Prise en Charge du Véhicule indiquée au Formulaire ;<br>
        - 50 (cinquante) % du prix de la Prestation si l’annulation a lieu entre 3 (trois) heures et 6 (six) heures ouvrées avant l’heure de la Prise en Charge du Véhicule indiquée au Formulaire.</p>

      <br><p>6.7 Autres Prestations</p><br>

      <p>Pop Valet se réserve la possibilité de proposer toute autre Prestation qu’elle jugera utile, sous une forme et selon les fonctionnalités et moyens techniques qu’elle estimera les plus appropriés pour rendre lesdites Prestations.</p>

      <br><p>7. Durée de la Prestation</p><br>

      <p>La durée de chaque Prestation est indiquée à titre indicatif dans le Formulaire. La Prestation débute à compter de la Prise en Charge et s’achève à la Livraison du Véhicule.</p>

      <br><p>8. Conditions financières</p><br>

      <p>8.1 Prix<br>
        <br>
        Le montant du prix de chaque Prestation est indiqué dans l’email de confirmation (ci-après : le « Prix ») et est fonction :</p>

      <p>- du nombre de kilomètres ;<br>
        - du lieu de Prise en Charge et de Livraison ;<br>
        - du type de Prestation choisie par le Bénéficiaire ;<br>
        - des services complémentaires demandés par le Bénéficiaire le cas échéant.<br>
        <br>
        Concernant le Bénéficiaire Indirect, le Prix indiqué dans l’email de confirmation est à titre indicatif. Le Bénéficiaire sera informé par le Partenaire du montant exact dont il sera redevable.</p>

      <p>[Sauf dispositions contraires, le Prix inclut tous les frais nécessaires à la réalisation de la Prestation (et notamment les péages et les frais de transport). En revanche, les frais d’essence font l’objet d’une facturation complémentaire correspondant aux frais réels engagés par Pop Valet.]</p>

      <p>[ou]</p>

      <p>[Sauf dispositions contraires, le Prix inclut tous les frais nécessaires à la réalisation de la Prestation (et notamment les péages, les frais d’essence et les frais de transport).]<br>
        <br>
        Sauf mention contraire, ils sont exprimés en Euros et toutes taxes françaises comprises.</p>

      <p>Pop Valet se réserve le droit, à sa libre discrétion et selon des modalités dont elle sera seule juge, de proposer des offres promotionnelles ou réductions de prix.<br>
        <br>
        8.2 Facturation et modalités de paiement<br>
        <br>
        Chaque Prestation fait l’objet d’une facture qui est communiquée à la date de Prise en Charge, à la date de Livraison ou au moment du paiement du Prix par tout moyen utile :</p>

      <p>- par Pop Valet au Bénéficiaire Direct ;<br>
        - par le Partenaire au Bénéficiaire Indirect.<br>
        <br>
        Les coordonnées bancaires sont transmises :</p>

      <p>- par le Bénéficiaire Direct via le lien pour procéder au paiement indiqué dans l’email de confirmation ou via le site internet lorsque la fonctionnalité sera disponible ;<br>
        - par le Bénéficiaire Indirect via un lien pour procéder au dépôt de son empreinte bancaire qui lui sera transmis par Pop Valet 48 (quarante-huit) heures avant l’heure de Prise en Charge.</p>

      <p>Le paiement du Prix s’effectue par prélèvement automatique à partir des coordonnées bancaires du Bénéficiaire, qui interviendra :</p>

      <p>- au moment de la transmission de ses coordonnées bancaires pour le Bénéficiaire Direct ;<br>
        - au plus tard 24 (vingt-quatre) heures à la suite de la Livraison pour le Bénéficiaire Indirect.</p>

      <p>Le prélèvement est mis en œuvre par le prestataire de paiement Stripe, qui seule conserve les coordonnées bancaires du Bénéficiaire à cette fin. Pop Valet ne conserve aucune coordonnée bancaire.<br>
        <br>
        Le Bénéficiaire garantit à Pop Valet qu'il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi. Il s’engage à prendre les mesures nécessaires afin que le prélèvement automatique puisse être effectué.</p>

      <br><p>8.3 Compensation</p><br>

      <p>Le Bénéficiaire accepte expressément que toutes les obligations de paiement des sommes d’argent naissant entre lui-même et Pop Valet, non sujettes à discussion quant à leur exigibilité et à leur montant, se compenseront entre elles, de plein droit et sans formalité, que les conditions de la compensation légale soient ou non réunies. Le jeu de cette compensation ne pourra toutefois pas avoir pour effet de dispenser les parties de leurs obligations comptables relatives notamment à l’émission de factures.</p>

      <br><p>8.4 Retards et incidents de paiement</p><br>

      <p>Le Bénéficiaire est informé et accepte expressément que tout retard de paiement de tout ou partie d’une somme due à son échéance entraînera automatiquement, sans préjudice des dispositions de l’article « Sanctions des manquements » et dès la première présentation d’une mise en demeure par lettre recommandée avec accusé de réception :</p>

      <p>(i) la déchéance du terme de l’ensemble des sommes dues par le Bénéficiaire et leur exigibilité immédiate ;</p>

      <p>(ii) la suspension immédiate des Prestations en cours jusqu’au complet paiement de l’intégralité des sommes dues par le Bénéficiaire ;<br>
        <br>
        (iii) la facturation au profit de Pop Valet :<br>
        - Pour les consommateurs : d’un intérêt de retard au taux de 1,5 fois (une fois et demie) le taux de l’intérêt légal, assis sur le montant de l’intégralité des sommes dues par le Bénéficiaire ;<br>
        - Pour les professionnels : <br>
        - la facturation au profit de POP VALET d’un intérêt de retard au taux de 3 (trois) fois le taux de l’intérêt légal, assis sur le montant de l’intégralité des sommes dues par le Bénéficiaire et d’une indemnité forfaitaire de 40 (quarante) € au titre des frais de recouvrement, sans préjudice d’une indemnisation complémentaire si les frais de recouvrement effectivement exposés sont supérieurs à ce montant.</p>

      <br><p>9. Droit de rétractation</p><br>

      <p>Le présent article ne s’applique qu’aux Bénéficiaires ayant la qualité de particuliers, en ce sens que les Prestations ne sont pas destinés à un usage professionnel, entendu comme tout usage lié directement ou indirectement à une activité rémunérée exercée de façon non occasionnelle dans tous les secteurs d’activité de l’industrie et du commerce.</p>

      <p>Le droit de rétractation n’est pas applicable aux Bénéficiaires ayant la qualité de professionnel, entendus comme toutes personnes physiques ou morales exerçant une activité rémunérée de façon non occasionnelle dans tous les secteurs d’activité de l’industrie et du commerce.</p>

      <br><p>9.1 Conditions d’exercice du droit de rétractation</p><br>

      <p>Le Bénéficiaire dispose d’un droit de rétractation, pendant un délai de 14 (quatorze) jours à compter de sa signature des présentes. Il peut exercer ce droit en adressant à Pop Valet aux coordonnées mentionnées à l’article 2, avant l’expiration du délai susvisé, le formulaire figurant en Annexe des présentes, dûment complété, ou toute autre déclaration, dénuée d’ambiguïté, exprimant sa volonté de se rétracter.</p>

      <p>En cas d’exercice du droit de rétractation, Pop Valet remboursera au Bénéficiaire tous les paiements reçus de celui-ci, sans retard excessif et, en tout état de cause, au plus tard 14 (quatorze) jours à compter du jour où Pop Valet aura été informé de la décision de rétractation du Bénéficiaire. Le remboursement sera effectué en utilisant le même moyen de paiement que celui utilisé pour la transaction initiale, sauf si le Bénéficiaire convient expressément d’un moyen différent. En tout état de cause, ce remboursement n’occasionnera pas de frais pour le Bénéficiaire.</p>

      <p>Si le Bénéficiaire a demandé à ce que les Prestations commencent avant l’expiration du délai de rétractation, en cochant la case à cet effet au bas des présentes, il peut exercer son droit de rétractation dans le délai et selon les modalités susvisées. Il sera dans ce cas redevable, envers Pop Valet, du Prix de la Prestation, calculé au prorata pour la durée écoulée jusqu’à la communication de sa décision de se rétracter à Pop Valet.</p>

      <br><p>9.2 Renonciation au droit de rétractation</p><br>

      <p>Le Bénéficiaire est toutefois expressément informé et accepte que, si la Prestation est intégralement exécutée, en d’autres termes à la signature de l’État des Lieux de la Livraison, avant l’expiration du délai ci-dessus, Pop Valet aura alors pleinement exécuté ses Prestations avant la fin du délai de rétractation. En conséquence, le Bénéficiaire renonce expressément, dans cette hypothèse, à son droit de rétractation, qui ne pourra donc pas être exercé, conformément à l’article L.221-28 du Code de la consommation.</p>

      <br><p>10. Convention de preuve</p><br>

      <p>Le Bénéficiaire reconnaît et accepte expressément :</p>

      <p>(i) que les données recueillies sur l’Interface et les équipements informatiques de Pop Valet font foi de la réalité des opérations intervenues dans le cadre des présentes,</p>

      <p>(ii) que ces données constituent le principal mode de preuve admis entre les parties, notamment pour le calcul des sommes dues à Pop Valet.</p>

      <br><p>11. Obligations du Bénéficiaire</p><br>

      <p>Sans préjudice des autres obligations prévues aux présentes, le Bénéficiaire s’engage à respecter les obligations qui suivent.</p>

      <br><p>11.1 Le Bénéficiaire s’engage, dans son usage des Prestations, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.</p><br>

      <p>Il est seul responsable du bon accomplissement de toutes les formalités et documents requis par la règlementation française et européenne en vigueur (ex : carte grise, attestation d’assurance etc.) et des paiements de toutes natures qui lui incombent le cas échéant en relation avec son Véhicule.</p>

      <p>Le Bénéficiaire s’engage à fournir à Pop Valet toutes les informations nécessaires à la bonne exécution des Prestations, en particulier sur l’état de son Véhicule. Plus généralement le Bénéficiaire s’engage à coopérer activement avec Pop Valet en vue de la bonne exécution des présentes.</p>

      <p>La responsabilité de Pop Valet ne pourra en aucun cas être engagée à ce titre. Le Bénéficiaire est dès lors responsable de l’ensemble des conséquences résultant directement ou indirectement de l’état du Véhicule, de l’absence ou de l’invalidité des documents indiqués ci-dessus.</p>

      <br><p>11.2 Le Bénéficiaire s’engage à faire un usage strictement personnel des Prestations. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.</p><br>

      <p>12. Garantie du Bénéficiaire</p><br>

      <p>Le Bénéficiaire garantit Pop Valet contre toutes plaintes, réclamations, actions et/ou revendications quelconques que Pop Valet pourrait subir du fait de la violation, par le Bénéficiaire de l’une quelconque de ses obligations ou garanties aux termes des présentes conditions générales.</p>

      <p>Il s’engage à indemniser Pop Valet de tout préjudice qu’elle subirait et à lui payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.</p>

      <br><p>13. Comportements prohibés</p><br>

      <p>13.1 Il est strictement interdit d’utiliser les Prestations aux fins suivantes :</p><br>

      <p>- l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,<br>
        - l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,<br>
        - l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire, contrôler, interférer, ou intercepter tout ou partie du système informatique d’un tiers, en violer l’intégrité ou la sécurité,<br>
        - l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,<br>
        - les manipulations destinées à améliorer le référencement d’un site tiers,<br>
        - l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,<br>
        - et plus généralement toute pratique détournant les Prestations à des fins autres que celles pour lesquelles ils ont été conçus.</p>

      <br><p>13.2 Il est strictement interdit aux Bénéficiaires de copier et/ou de détourner à leurs fins ou à celles de tiers le concept, les technologies, tout ou partie des données ou tout autre élément du Site et/ou de l’Interface de Pop Valet.</p><br>

      <p>13.3 Sont également strictement interdits : (i) tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Prestations, (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de Pop Valet, (iii) tous détournements des ressources système de l’Interface et/ou du Site, (iv) toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi) tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de Pop Valet ou des usagers de son Site, et enfin plus généralement (vii) tout manquement aux présentes conditions générales.</p><br>

      <p>13.4 Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de l’accès aux Prestations, à l’Interface ou au Site, ainsi qu’aux informations qui y sont hébergées et/ou partagées.</p><br>

      <p>14. Sanctions des manquements</p><br>

      <p>En cas de manquement à l’une quelconque des dispositions des présentes conditions générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un Bénéficiaire, Pop Valet se réserve le droit de prendre toute mesure appropriée et notamment de :</p>

      <p>(i) suspendre ou supprimer l’accès aux Prestations du Bénéficiaire, auteur du manquement ou de l’infraction, ou y ayant participé,<br>
        (ii) publier sur le Site tout message d’information que Pop Valet jugera utile,<br>
        (iii) avertir toute autorité concernée,<br>
        (iv) engager toute action judiciaire.</p>

      <br><p>15. Garantie et limitation de responsabilité de Pop Valet</p><br>

      <p>15.1 Pop Valet s’engage à fournir les Prestations avec diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Bénéficiaires reconnaissent et acceptent expressément.</p><br>

      <p>15.2 Pop Valet s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité du Site et de l’Interface. A ce titre, Pop Valet se réserve la faculté d’interrompre momentanément l’accès au Site ou à l’Interface pour des raisons de maintenance. Pop Valet ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès au Site ou à l’Interface qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.</p><br>

      <p>15.3 Le Bénéficiaire Indirect reconnaît que tous les litiges l’opposant au Partenaire et découlant des Prestations se règleront directement entre lui et le Partenaire. Pop Valet ne pourra pas être impliqué dans la résolution de tels litiges.</p><br>

      <p>15.4 En tout état de cause, sauf cas particuliers prévus aux présentes, la responsabilité susceptible d’être encourue par Pop Valet au titre des présentes est expressément limitée aux seuls dommages directs avérés subis par le Bénéficiaire.</p><br>

      <p>15.5 Les Parties reconnaissent qu’en cas de dommage direct causé au Véhicule, les dommages intérêts versés au Client ne pourront excéder la valeur de remplacement du Véhicule au jour du sinistre. Pour les Véhicules de moins de 1 (un) an, ladite indemnité ne sera versée par POP VALET que dans les cas suivants : [à compléter par les cas prévus dans votre police d’assurance].</p><br>

      <p> <br>
        16. Propriété intellectuelle</p><br>

      <p>Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc.) exploités par Pop Valet au sein du Site et de l’Interface sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de données en vigueur. Tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation de l’un quelconque de ces éléments, en tout ou partie, sans l’autorisation de Pop Valet sont strictement interdits et pourront faire l’objet de poursuites judiciaires.</p>

      <br><p>17. Données à caractère personnel</p><br>

      <p>Pop Valet pratique une politique de protection des données personnelles dont les caractéristiques sont explicitées dans le document intitulé « Charte relative à la protection des données à caractère personnel », dont le Bénéficiaire Direct est expressément invité à prendre connaissance.</p>

      <br><p>18. Confidentialité</p><br>

      <p>Chaque partie s’engage à garder strictement confidentiels les documents, éléments, données et informations de l’autre partie dont elle serait destinataire qui seront expressément identifiés par l’autre partie comme étant confidentiels. S’agissant de Pop Valet, les parties conviennent d’ores et déjà expressément que cette obligation de confidentialité couvre les données à caractère personnel que Pop Valet sera amené à traiter pour le Bénéficiaire dans le cadre des Prestations. L’ensemble de ces informations est désigné ci-après les « Informations Confidentielles ».</p>

      <p>La partie qui reçoit des Informations Confidentielles s’engage à ne pas les divulguer sans accord préalable de l’autre partie, pendant une durée de 5 (cinq) ans à compter de la fin de l’exécution des Prestations concernées. Elle ne pourra les transmettre à des employés, collaborateurs, stagiaires ou conseils que s’ils sont tenus à la même obligation de confidentialité que celle prévue aux présentes. Cette obligation ne s’étend pas aux documents, éléments, données et informations :</p>

      <p>(i) dont la partie qui les reçoit avait déjà connaissance ;<br>
        (ii) déjà publics lors de leur communication ou qui le deviendraient sans violation des présentes conditions générales;<br>
        (iii) qui auraient été reçus d’un tiers de manière licite ;<br>
        (iv) dont la communication serait exigée par les autorités judiciaires, en application des lois et règlements ou en vue d’établir les droits d’une partie au titre des présentes conditions générales.</p>

      <br><p>19. Publicité</p><br>

      <p>Pop Valet se réserve la faculté d’insérer sur toute page du Site et dans toute communication aux Bénéficiaires tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont Pop Valet sera seule juge.</p>

      <br><p>20. Liens et sites tiers</p><br>

      <p>Pop Valet ne pourra en aucun cas être tenue pour responsable de la disponibilité technique de sites internet ou d’applications mobiles exploités par des tiers (y compris ses éventuels partenaires) auxquels le Bénéficiaire accéderait par l'intermédiaire du Site.</p>

      <p>Pop Valet n'endosse aucune responsabilité au titre des contenus, publicités, produits et/ou Prestations disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils sont régis par leurs propres conditions d’utilisation.</p>

      <p>Pop Valet n'est pas non plus responsable des transactions intervenues entre le Bénéficiaire et un quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers lequel le Bénéficiaire serait orienté par l'intermédiaire du Site et ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou Prestations, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont tenus.</p>

      <br><p>21. Références commerciales</p><br>

      <p>Le Bénéficiaire autorise expressément Pop Valet à le citer et à utiliser le cas échéant la reproduction de sa marque ou de son logo à titre de références commerciales, notamment lors de manifestations ou d’événements, dans ses documents commerciaux et sur son site internet, sous quelque forme que ce soit.</p>

      <br><p>22. Modifications</p><br>

      <p>Pop Valet se réserve la faculté de modifier à tout moment les présentes conditions générales.</p>

      <p>Le Bénéficiaire sera informé de ces modifications par tout moyen utile.</p>

      <p>Tout Bénéficiaire qui a recours aux Prestations postérieurement à l’entrée en vigueur des conditions générales modifiées est réputé avoir accepté ces modifications.</p>

      <br><p>23. Médiation</p><br>

      <p>Le Bénéficiaire dispose du droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable de tout litige portant sur l’exécution des présentes qui l'opposerait à Pop Valet, dans les conditions prévues aux articles L611-1 et suivants et R152-1 et suivants du Code de la consommation.</p>

      <p>Il peut contacter à cette fin le médiateur de la consommation suivant :<br>
        Centre de médiation et de règlement amiable des huissiers de justice (Medicys)<br>
        Adresse postale : 73 Boulevard de Clichy, 75009 Paris<br>
        Adresse électronique : contact@medicys.fr<br>
        Téléphone : 01 49 70 15 93<br>
        http://www.medicys.fr/index.php/consommateurs/<br>
        <br>
        24. Loi applicable et juridiction</p>

      <p>Les présentes conditions générales sont régies par la loi française.</p>

      <p>En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux de Paris seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.</p>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import GeneralHeader from "@/components/GeneralHeader";
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "GeneralCGU",
  components: {
    GeneralHeader,
    CommonHeader,
    CommonSurHeader,
    CommonFooter,
  },
  props: [
    'header',
    'isMaif'

  ],
}
</script>

<style scoped>
.general-cgu-container {
  padding: 100px 150px;
}
@media screen and (max-width: 991px) {
  .general-cgu-container {
    padding: 25px 50px;
  }
}
</style>