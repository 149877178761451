<template>
  <div :class="[`group-116`, className || ``]">
    <group111 :src="group111Props.src" :className="group111Props.className" />
    <div class="ation-automatique textcourantsmall">{{ confirmationAutomatique }}</div>
  </div>
</template>

<script>
import Group111 from "./Group111";
export default {
  name: "Group116",
  components: {
    Group111,
  },
  props: ["confirmationAutomatique", "className", "group111Props"],
};
</script>

<style>
.group-116 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 115px;
  position: relative;
  width: 142px;
}

.ation-automatique {
  color: var(--bluenavy);
  font-weight: 400;
  height: 41px;
  line-height: 19.2px;
  text-align: center;
  width: 140px;
}

.group-116.group-120 {
  left: 0;
  position: absolute;
  top: 0;
}
</style>
