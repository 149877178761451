<template>
  <div :class="[`line_timeline2`, className || ``]">
    <picto-gerez-planning :src="pictoGerezPlanningProps.src" :className="pictoGerezPlanningProps.className" />
    <div class="group-236-2">
      <div class="overlap-group-60">
        <img class="line-18-2" :src="line18" alt="Line 18" />
        <div class="ellipse-40-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PictoGerezPlanning from "./PictoGerezPlanning";
export default {
  name: "LineTimeline2",
  components: {
    PictoGerezPlanning,
  },
  props: ["line18", "className", "pictoGerezPlanningProps"],
};
</script>

<style>
.line_timeline2 {
  display: flex;
  flex-direction: column;
  height: 148px;
  left: 84px;
  position: absolute;
  top: 0;
  width: 68px;
}

.group-236-2 {
  align-items: flex-start;
  display: flex;
  margin-left: 28px;
  min-width: 13px;
}

.overlap-group-60 {
  height: 80px;
  position: relative;
  width: 13px;
}

.line-18-2 {
  height: 78px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 3px;
}

.ellipse-40-2 {
  background-color: var(--blanc);
  border-radius: 6.5px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 67px;
  width: 13px;
}

.line_timeline2.line_timeline4 {
  left: 86px;
}

.line_timeline2.line_timeline4 .ellipse-40-2 {
  border: 1px solid;
  border-color: var(--yellow);
}
</style>
