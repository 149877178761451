<template>
  <div class="group-170"><img class="icon-thumbs_up-2" :src="src" alt="icon-thumbs_up" /></div>
</template>

<script>
export default {
  name: "Group170",
  props: ["src"],
};
</script>

<style>
.group-170 {
  background-color: var(--yellow);
  border-radius: 24.5px;
  display: flex;
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  width: 49px;
}

.icon-thumbs_up-2,
.icon-thumbs_up-3,
.education-badge-15 {
  height: 24px;
  margin-left: 13px;
  margin-top: 13px;
  width: 24px;
}
</style>
