<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Notre technologie" :icon-url="require('../../img/Internet.png')"/>
    <div class="general-technologie-description-section">
      <div class="general-technologie-description-container">
        <p class="isidorasansalt-semi-bold-magic-potion-16px">
          Faire appel à l’équipe Pop Valet, c’est aussi faire le choix du digital au service de vos solutions de transport automobile. Forts de notre fonctionnement d’opérateur logistique intégré, nous collectons les disponibilités de nos Valets en amont de la réception des missions que nous confient nos clients.
          Notre algorithme nous permet non seulement d’optimiser nos prestations, mais aussi de les dispatcher à nos Valets les plus performants grâce à un système de scoring. Le résultat ? Des délais de réalisation de missions raccourcis pour une haute qualité de réalisation. Détails.
        </p><br>
        <a href="/technologie-transports-vehicules" style="color: #002244"><p class="isidorasansalt-semi-bold-magic-potion-16px">En savoir plus</p></a>
      </div>
    </div>
    <div class="general-technologie-productivite-section">
      <div class="general-technologie-productivite-container">
        <div class="general-technologie-productivite-container-title">
          <h2 class="isidorasansalt-bold-white-24px">
            BÉNÉFICIEZ DE LA TECHNOLOGIE LA PLUS COMPLÈTE DU MARCHÉ
          </h2>
          <p class="isidorasansalt-semi-bold-white-16px">
            Gagnez en productivité pour vos convoyages
          </p>
        </div>
        <div class="general-technologie-productivite-container-data">
          <div class="general-technologie-productivite-container-each-data">
            <div class="general-technologie-productivite-container-data-icon left-icon">
              <img src="../../img/laurier.png" alt="">
            </div>
            <p class="montserrat-medium-color-white-20px">10 ANS</p>
            <p class="montserrat-normal-white-16px">D'innovation continue</p>
          </div>
          <div class="general-technologie-productivite-container-each-data">
            <div class="general-technologie-productivite-container-data-icon right-icon">
              <img src="../../img/euro_coin.png" alt="">
            </div>
            <p class="montserrat-medium-color-white-20px">3 M €</p>
            <p class="montserrat-normal-white-16px">Investis dans notre technologie</p>
          </div>
        </div>
      </div>
      <div class="general-technologie-productivite-link">
        <p class="montserrat-medium-gunsmoke-18px" style="cursor: pointer">Démonstration de l'interface ></p>
      </div>
    </div>
    <div class="general-technologie-notre-tech-section">
      <div class="general-technologie-notre-tech-container">
        <div class="general-technologie-notre-tech-container-row">
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-title">
              <img src="../../img/fleche.png" alt="">
              <h2 class="isidorasansalt-bold-magic-potion-28px underline-yellow">Notre technologie</h2>
            </div>
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">1</div>
                <img src="../../img/ecran1_1.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Connexion à notre interface sécurisée</h3>
              <p class="montserrat-normal-magic-potion-12px">Nous vous créons un compte sécurisé par un identifiant et un mot de passe par entité, auquel nous rattachons des utilisateurs dont vous pouvez définir le niveau de droits (administrateur, superviseur, utilisateur en lecture seule). Vous aurez la possibilité d’ajouter des options par défaut comme le lavage des véhicules.</p>
            </div>
          </div>
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">2</div>
                <img src="../../img/ecran1_2.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Gestion des devis simplifiée</h3>
              <p class="montserrat-normal-magic-potion-12px">Vous êtes autonome dans la création de devis et obtenez un prix en temps réel grâce à la reconnaissance des adresses par l’API Google Maps. A l’issue des devis, vous pouvez en un clic le transformer en commande directement sur votre compte.</p>
            </div>
          </div>
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">3</div>
                <img src="../../img/ecran1_3.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Commande d'une mission en 30 secondes</h3>
              <p class="montserrat-normal-magic-potion-12px">Sélectionnez votre type de mission (VN/VO/ APV), renseignez les informations du véhicule grâce à la reconnaissance automatique d’immatriculation et ajoutez les options éventuelles (W-Garage, mise en main, lavage, recharge électrique, remise à niveau de carburant…). Grâce aux disponibilités en temps réel, vous pouvez choisir le créneau désiré pour réalisation de votre mission.</p>
            </div>
          </div>
        </div>
        <div class="general-technologie-notre-tech-container-row notre-tech-container-row-margin-top">
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">4</div>
                <img src="../../img/ecran1_4.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Traitement de la mission sous 1h maximum</h3>
              <p class="montserrat-normal-magic-potion-12px">Nous confirmons votre demande sous 1h maximum (taux d’acceptation de 99 %). Nous affectons votre mission à notre valet le plus performant (localisation géographique, scoring et expérience)
                Vous êtes informés en temps réel par des notifications du suivi de votre mission.</p>
            </div>
          </div>
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">5</div>
                <img src="../../img/ecran1_5.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Réalisation de la mission sous 24h</h3>
              <p class="montserrat-normal-magic-potion-12px">Notre Valet réalise la mission en 24h en respectant des étapes successives telles que les états des lieux. Vous pouvez suivre en temps réel la position du valet grâce au lien reçu par SMS. Chacun des deux parties dispose des informations de contact et peut en un clic entrer en relation avec le client ou le valet.</p>
            </div>
          </div>
          <div class="general-technologie-notre-tech-container-step">
            <div class="general-technologie-notre-tech-container-step-img">
              <div class="general-technologie-notre-tech-container-step-img-container">
                <div class="general-technologie-notre-tech-container-step-img-container-step montserrat-semi-bold-white-16px">6</div>
                <img src="../../img/ecran1_6.png" alt="">
              </div>
            </div>
            <div class="general-technologie-notre-tech-container-step-txt">
              <h3 class="montserrat-semi-bold-magic-potion-14px">Finalisation de la mission</h3>
              <p class="montserrat-normal-magic-potion-12px">A la fin de votre mission, vous recevez un rapport détaillé de mission sous 24h avec l’ensemble des documents signés (PV de restitution, états des lieux…); suivi d’une évaluation et d’un scoring du valet par notre pôle qualité.</p>
            </div>
          </div>
        </div>
        <div class="general-technologie-notre-tech-container-row">
          <div class="general-technologie-notre-tech-container-step"></div>
          <div class="general-technologie-notre-tech-container-step"></div>
          <div class="general-technologie-notre-tech-container-step"></div>
        </div>
      </div>
    </div>
    <div class="general-technologie-innovation-section">
      <div class="general-technologie-innovation-container">
        <div class="general-technologie-innovation-container-cards">
          <div class="general-technologie-innovation-container-card innovation-white-card">
            <h2 class="general-technologie-innovation-container-title isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">INNOVATION</span> CONTINUE</h2>
            <div class="general-technologie-innovation-container-card-container">
              <div class="general-technologie-innovation-container-card-container-title">
                <img src="../../img/Like.png" alt="">
                <h3 class="isidorasansalt-bold-magic-potion-30px">iFRAME</h3>
              </div>
              <div class="general-technologie-innovation-container-card-container-list">
                <div class="general-technologie-innovation-container-card-container-list-point">
                  <img src="../../img/Approve.png" alt="">
                  <p class="montserrat-normal-magic-potion-18px">Implémentation rapide et sans contrainte technique lourde</p>
                </div>
                <div class="general-technologie-innovation-container-card-container-list-point">
                  <img src="../../img/Approve.png" alt="">
                  <p class="montserrat-normal-magic-potion-18px">Intégration sur toute interface , peu importe le langage technique utilisé</p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="general-technologie-innovation-container-card innovation-blue-card">
            <div class="general-technologie-innovation-container-card-container">
              <div class="general-technologie-innovation-container-card-container-title">
                <img src="../../img/internet-white.png" alt="">
                <h3 class="isidorasansalt-bold-white-30px">API</h3>
              </div>
              <div class="general-technologie-innovation-container-card-container-list">
                <div class="general-technologie-innovation-container-card-container-list-point">
                  <img src="../../img/Approve.png" alt="">
                  <p class="montserrat-normal-white-18px">Échange d'informations entre systèmes tiers</p>
                </div>
                <div class="general-technologie-innovation-container-card-container-list-point">
                  <img src="../../img/Approve.png" alt="">
                  <p class="montserrat-normal-white-18px">Paramétrage dans un univers personnalisé</p>
                </div>
                <div class="general-technologie-innovation-container-card-container-list-point">
                  <img src="../../img/Approve.png" alt="">
                  <p class="montserrat-normal-white-18px">Autonomie grâce à la documentation mise à jour régulièrement et disponible sur le Swagger</p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "Technologie",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif"
  ]
}
</script>

<style scoped>
@media screen and (min-width: 1441px) {
  .general-technologie-innovation-container-card-container-list {
    margin-left: 70px;
  }
  .general-technologie-innovation-container-title {
    left: 70px;
  }
  .general-technologie-innovation-container-card {
    margin: 0 10px;
  }
  .general-technologie-innovation-container-card {
    width: 590px;
  }
  .general-technologie-innovation-section {
    padding: 165px 110px 90px 110px;
  }
  .notre-tech-container-row-margin-top {
    margin-top: 75px;
  }
  .general-technologie-notre-tech-title {
    left: 70px;
  }
  .general-technologie-notre-tech-container-step {
    margin: 0 15px;
  }
  .general-technologie-notre-tech-section {
    padding: 90px;
  }
  .general-technologie-productivite-container-each-data {
    margin: 0 100px;
  }
  .general-technologie-productivite-container {
    padding: 30px 150px;
  }
  .general-technologie-productivite-section {
    padding: 90px 250px 90px 250px;
  }
  .general-technologie-description-section {
    padding: 90px 250px 90px 250px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1440px) {
  .general-technologie-innovation-container-card-container-list {
    margin-left: 70px;
  }
  .general-technologie-innovation-container-title {
    left: 70px;
  }
  .general-technologie-innovation-container-card {
    margin: 0 10px;
  }
  .general-technologie-innovation-container-card {
    width: 590px;
  }
  .general-technologie-innovation-section {
    padding: 165px 110px 90px 110px;
  }
  .notre-tech-container-row-margin-top {
    margin-top: 75px;
  }
  .general-technologie-notre-tech-title {
    left: 70px;
  }
  .general-technologie-notre-tech-container-step {
    margin: 0 15px;
  }
  .general-technologie-notre-tech-section {
    padding: 90px;
  }
  .general-technologie-productivite-container-each-data {
    margin: 0 100px;
  }
  .general-technologie-productivite-container {
    padding: 30px 150px;
  }
  .general-technologie-productivite-section {
    padding: 90px 125px 90px 125px;
  }
  .general-technologie-description-section {
    padding: 90px 125px 90px 125px
  }
}
@media screen and (max-width: 990px) {
  .general-technologie-innovation-container-card-container-list {
    margin-left: 0;
  }
  .general-technologie-notre-tech-container-row {
    align-items: center;
  }
  .general-technologie-innovation-container-title {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .general-technologie-innovation-container-card {
    margin: 15px 10px;
  }
  .general-technologie-innovation-container-card {
    width: unset;
  }
  .general-technologie-innovation-section {
    padding: 165px 55px 90px 55px;
  }
  .general-technologie-innovation-container-cards {
    flex-direction: column;
  }
  .notre-tech-container-row-margin-top {
    margin-top: 0;
  }
  .general-technologie-notre-tech-title {
    left: 50px;
  }
  .general-technologie-notre-tech-container-step {
    margin: 15px 15px;
  }
  .general-technologie-notre-tech-container-row {
    flex-direction: column;
  }
  .general-technologie-notre-tech-section {
    padding: 90px 30px;
  }
  .general-technologie-productivite-container-each-data {
    margin: 25px 100px;
  }
  .general-technologie-productivite-container-data {
    flex-direction: column;
  }
  .general-technologie-productivite-container {
    padding: 30px 30px;
  }
  .general-technologie-productivite-section {
    padding: 90px 65px 90px 65px;
  }
  .general-technologie-description-section {
    padding: 90px 65px 90px 65px
  }
}
.general-technologie-innovation-container-card-container-list-point p {
  line-height: 34px;
}
.general-technologie-innovation-container-card-container-list-point img {
  height: 18px;
  margin-top: 7px;
  margin-right: 5px;
}
.general-technologie-innovation-container-card-container-list-point {
  display: flex;
  margin: 10px 0;
}
.general-technologie-innovation-container-card-container-list {
  margin-top: 25px;
}
.general-technologie-innovation-container-card-container-title h3 {
  margin-left: 25px;
}
.general-technologie-innovation-container-card-container-title img {
  height: 48px;
  width: 45px;
}
.general-technologie-innovation-container-card-container-title {
  display: flex;
  align-items: center;
}
.general-technologie-innovation-container-card-container {
  padding: 30px 40px;
}
.general-technologie-innovation-container-card.innovation-blue-card {
  background-color: #002244;
}
.general-technologie-innovation-container-title {
  position: absolute;
  top: -100px;
  width: 350px;
}
.general-technologie-innovation-container-card.innovation-white-card {
  background-color: white;
  position: relative;
}
.general-technologie-innovation-container-card {
  border-radius: 12px;
}
.general-technologie-innovation-container-cards {
  display: flex;
  justify-content: center;
}
.general-technologie-innovation-section {
  background-color: #F7F6F4;
}
.general-technologie-notre-tech-container-step-img-container-step {
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #002244;
  position: absolute;
  left: -3px;
  top: -5%;
}
.general-technologie-notre-tech-container-step-img-container {
  width: fit-content;
  margin: auto;
  position: relative;
}
.general-technologie-notre-tech-title h2 {
  margin-left: 15px;
}
.general-technologie-notre-tech-title {
  margin-bottom: 80px;
  display: flex;
  position: absolute;
  top: -100px;
}
.general-technologie-notre-tech-container-step-img {
  text-align: center;
  position: relative;
  top: 25px;
}
.general-technologie-notre-tech-container-step-txt p {
  line-height: 21px;
}
.general-technologie-notre-tech-container-step-txt h3 {
  margin-bottom: 15px;
}
.general-technologie-notre-tech-container-step-txt {
  padding: 37px;
  min-height: 285px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.general-technologie-notre-tech-container-step {
  width: 387px;
  position: relative;
}
.general-technologie-notre-tech-container-row {
  display: flex;
  justify-content: center;
}
.general-technologie-notre-tech-section {
  margin-top: 20px;
}
.general-technologie-productivite-link {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.general-technologie-productivite-container-each-data p {
  margin-top: 25px;
  min-width: 145px;
}
.general-technologie-productivite-container-each-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.general-technologie-productivite-container-data-icon.left-icon img {
  height: 50px;
  position: relative;
  top: 4px;
}
.general-technologie-productivite-container-data-icon.right-icon img {
  height: 40px;
}
.general-technologie-productivite-container-data-icon {
  height: 69px;
  width: 69px;
  background-color: #FADC41;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
}
.general-technologie-productivite-container-data {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.general-technologie-productivite-container-title h2 {
  margin-bottom: 15px;
  margin-top: 40px;
}
.general-technologie-productivite-container {
  text-align: center;
  background-color: #002244;
  border-radius: 10px;
}
.general-technologie-description-container p {
  line-height: 29px;
}
.general-technologie-description-container {
  text-align: center;
}
</style>