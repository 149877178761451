<script>
export default {
  name: "BlocDecouvrirPopValet",
}
</script>

<template>
<div class="maif-decouvrir-bloc">
  <div class="maif-decouvrir-bloc-container">
    <div class="maif-decouvrir-bloc-video">
      <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Découvrez le service</span> Pop Valet en vidéo</h2>
      <iframe src="https://player.vimeo.com/video/582458453?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="380" scrolling="yes" class="iframe-class" frameborder="0"></iframe>
    </div>
    <div>

      <div class="maif-decouvrir-bloc-reserver">
        <div class="maif-decouvrir-bloc-reserver-row border-side-bloc">
          <div class="maif-decouvrir-bloc-reserver-background-icon"><img src="../../../img/icon-valet-1@2x.png" alt=""></div>
          <p class="isidorasansalt-bold-magic-potion-16px">Professionnalisme des Valets</p>
        </div>
        <div class="maif-decouvrir-bloc-reserver-row border-side-bloc">
          <div class="maif-decouvrir-bloc-reserver-background-icon"><img src="../../../img/user-interface---backup-1@2x.png" alt=""></div>
          <p class="isidorasansalt-bold-magic-potion-16px">Confort et gain de temps</p>
        </div>
        <div class="maif-decouvrir-bloc-reserver-row border-side-bloc">
          <div class="maif-decouvrir-bloc-reserver-background-icon"><img src="../../../img/user-interface---like-1@2x.png" alt=""></div>
          <p class="isidorasansalt-bold-magic-potion-16px">Flexibilité et simplicité</p>
        </div>
        <div class="maif-decouvrir-bloc-reserver-row">
          <div class="maif-decouvrir-bloc-reserver-background-icon"><img src="../../../img/euro_coin.png" alt=""></div>
          <p class="isidorasansalt-bold-magic-potion-16px">Forfait tout inclus</p>
        </div>
        <a href="/reserver-votre-transport" style="text-decoration: none">
          <div class="maif-decouvrir-bloc-reserver-btn">
            <p class="montserrat-medium-magic-potion-20px">Réserver</p>
          </div>
        </a>
      </div>

    </div>
  </div>
</div>
</template>

<style>
.border-side-bloc {
  border-bottom: solid 3px white;
}
.maif-decouvrir-bloc-video {
  width: 55%;
}
.maif-decouvrir-bloc-reserver-btn p {
  color: #002244;
  text-decoration: none;
  text-transform: uppercase;
}
.maif-decouvrir-bloc-reserver-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  background-color: #FADC41;
  border-radius: 0 0 25px 25px;
}
.maif-decouvrir-bloc-reserver-btn:hover {
  background-color: #F4C300;
  color: var(--bluenavy);
}
.maif-decouvrir-bloc-reserver-background-icon {
  padding: 6px 7px;
  border-radius: 20px;
  background-color: #FADC41;
}
.maif-decouvrir-bloc-reserver-row p {
  margin-left: 10px;
}
.maif-decouvrir-bloc-reserver-row img {
  height: 20px;
}
.maif-decouvrir-bloc-reserver-row {
  display: flex;
  align-items: center;
  padding: 30px 20px;
}
.maif-decouvrir-bloc-reserver {
  background-color: #EEF4FC;
  border-radius: 25px;
}
.maif-decouvrir-bloc-container {
  display: flex;
  justify-content: space-between;
}
.maif-decouvrir-bloc h2 {
  margin: 25px 0;
}
.maif-decouvrir-bloc {
  margin: 100px 135px;
}
.underline-yellow {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -12px;
  text-decoration-color: var(--mustard);
}
@media screen and (max-width: 991px) {
  .maif-decouvrir-bloc-video {
    width: 100%;
  }
  .maif-decouvrir-bloc {
    margin: 30px 15px;
  }
  .maif-decouvrir-bloc-container {
    flex-direction: column;
  }
}
</style>