<template>
  <div class="fleches_logos fleches_logos-4">
    <img
      class="arrows"
      src="../../img/left-arrow.png"
      alt="Arrows / left arrow"
      style="display: none"
    />
    <img
      class="arrows variant-1"
      src="../../img/right-arrow.png"
      alt="Arrows / right arrow"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  name: "FlechesLogos",
  props: ["className"],
};
</script>

<style>
.fleches_logos {
  display: flex;
  height: 24px;
  left: 0;
  position: absolute;
  top: 66px;
  gap: unset;
  width: 100%;
  justify-content: space-between;
}

.arrows {
  height: 23.79px;
  margin-top: 0;
  width: 24px;
}

.fleches_logos.fleches_logos-1,
.fleches_logos.fleches_logos-4 {
  height: unset;
  left: unset;
  position: unset;
  top: unset;
}

.fleches_logos.fleches_logos-1 .arrows,
.fleches_logos.fleches_logos-2 .arrows,
.fleches_logos.fleches_logos-4 .arrows {
  height: 24px;
  margin-top: unset;
}

.fleches_logos.fleches_logos-2 {
  align-items: flex-start;
  flex: 1;
  height: unset;
  left: unset;
  max-height: 24px;
  min-width: 100%;
  position: unset;
  top: unset;
  width: unset;
}

.fleches_logos.fleches_logos-3 {
  align-items: flex-start;
  min-width: 1208px;
  width: unset;
}

.fleches_logos.fleches_logos-3 .arrows {
  height: 24px;
}

.fleches_logos.fleches_logos-3 .arrows.variant-1 {
  align-self: flex-end;
  margin-bottom: 0;
  margin-top: unset;
}

.fleches_logos.fleches_logos-1 .arrows.variant-1,
.fleches_logos.fleches_logos-2 .arrows.variant-1,
.fleches_logos.fleches_logos-4 .arrows.variant-1 {
  width: 24px;
}
</style>
