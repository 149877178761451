<template>
  <div class="bloc_tmoignages-1">
    <div class="temoignage-carousel-arrows">
      <img class="temoignage-carousel-arrow-left" @click="goPrev" src="../../img/left-arrow.png" alt="">
      <img class="temoignage-carousel-arrow-right" @click="goNext" src="../../img/right-arrow.png" alt="">
    </div>
    <div class="flicking-viewport">
      <Flicking ref="flicking" :plugins="plugins" :options="{circular: true}">
        <div class="overlap-group3-3">
          <p class="nos-tmoignages-ce-q-1 isidorasansalt-bold-magic-potion-28px">
                <span class="isidorasansalt-bold-magic-potion-28px">NOS TÉMOIGNAGES</span
                ><br><span class="span-46 blogintro">Ce qu’ils disent de nous...</span>
          </p>
          <div class="quotation-mark quotation-mark-blue">“</div>
          <div class="overlap-group1-17">
            <p class="pop-valet-nous-a-acc montserrat-medium-magic-potion-28px">Pop Valet est un partenaire précieux pour nous. Leur solution de convoyage automobile a grandement simplifié notre logistique, nous permettant de nous
              concentrer sur notre mission. Nous apprécions leur engagement à nos côtés.</p>
          </div>
          <div class="noms-5">
            <p class="montserrat-medium-magic-potion-18px">Concessionnaire BMW Paris</p>
            <p class="montserrat-normal-magic-potion-16px" style="margin-top: 5px">Client</p>
          </div>
          <div class="temoignages-photos-container">
            <div class="temoignage-ellipse ellipse-client">
              <img class="temoignage-photo-bmw-img img-temoignage-cursor" @click="goToSlide(0)" src="../../img/logo_bmw.webp" alt="">
            </div>
            <div>
              <img class="temoignage-photo-img temoignage-photo-img-margin img-temoignage-cursor" @click="goToSlide(1)" src="../../img/ellipse-particulier_2x.webp" alt="">
            </div>
            <div>
              <img class="temoignage-photo-img temoignage-photo-img-margin img-temoignage-cursor" @click="goToSlide(2)" src="../../img/photo_2x.webp" alt="">
            </div>
          </div>
        </div>

        <div class="overlap-group3-3">
          <p class="nos-tmoignages-ce-q-1 isidorasansalt-bold-magic-potion-28px">
                <span class="isidorasansalt-bold-magic-potion-28px">NOS TÉMOIGNAGES</span
                ><br><span class="span-46 blogintro">Ce qu’ils disent de nous...</span>
          </p>
          <div class="quotation-mark quotation-mark-purple">“</div>
          <div class="overlap-group1-17">
            <p class="pop-valet-nous-a-acc montserrat-medium-magic-potion-28px">Pop Valet nous a accompagnés pour transporter notre véhicule vers notre résidence secondaire. Tout s’est passé comme convenu, nous avons même eu le droit à un lavage du véhicule en prime.</p>
          </div>
          <div class="noms-5">
            <p class="montserrat-medium-magic-potion-18px">Virginie.D</p>
            <p class="montserrat-normal-magic-potion-16px" style="margin-top: 5px">Particulier</p>
          </div>
          <div class="temoignages-photos-container">
            <div>
              <img class="temoignage-photo-bmw-img img-temoignage-cursor" @click="goToSlide(0)" src="../../img/logo_bmw.webp" alt="">
            </div>
            <div class="temoignage-ellipse temoignage-photo-img-margin ellipse-particulier">
              <img class="temoignage-photo-img img-temoignage-cursor" @click="goToSlide(1)" src="../../img/ellipse-particulier_2x.webp" alt="">
            </div>
            <div>
              <img class="temoignage-photo-img temoignage-photo-img-margin img-temoignage-cursor" @click="goToSlide(2)" src="../../img/photo_2x.webp" alt="">
            </div>
          </div>
        </div>

        <div class="overlap-group3-3">
          <p class="nos-tmoignages-ce-q-1 isidorasansalt-bold-magic-potion-28px">
                <span class="isidorasansalt-bold-magic-potion-28px">NOS TÉMOIGNAGES</span
                ><br><span class="span-46 blogintro">Ce qu’ils disent de nous...</span>
          </p>
          <div class="quotation-mark quotation-mark-yellow">“</div>
          <div class="overlap-group1-17">
            <p class="pop-valet-nous-a-acc montserrat-medium-magic-potion-28px">Je travaille avec Pop Valet depuis près de 2 ans et je suis très satisfait de la diversité des missions proposées et l’accompagnement des équipes Pop Valet au quotidien.</p>
          </div>
          <div class="noms-5">
            <p class="montserrat-medium-magic-potion-18px">Sébastien.D</p>
            <p class="montserrat-normal-magic-potion-16px" style="margin-top: 5px">Valet</p>
          </div>
          <div class="temoignages-photos-container">
            <div>
              <img class="temoignage-photo-bmw-img img-temoignage-cursor" @click="goToSlide(0)" src="../../img/logo_bmw.webp" alt="">
            </div>
            <div>
              <img class="temoignage-photo-img temoignage-photo-img-margin img-temoignage-cursor" @click="goToSlide(1)" src="../../img/ellipse-particulier_2x.webp" alt="">
            </div>
            <div class="temoignage-ellipse temoignage-photo-img-margin ellipse-valet">
              <img class="temoignage-photo-img img-temoignage-cursor" @click="goToSlide(2)" src="../../img/photo_2x.webp" alt="">
            </div>
          </div>
        </div>
      </Flicking>
    </div>
  </div>
</template>

<script>
import LogoTesla from "@/components/LogoTesla";
import flechesLogos from "@/components/FlechesLogos";
import {AutoPlay} from "@egjs/flicking-plugins";
import {Flicking} from "@egjs/vue-flicking";

const plugins = [new AutoPlay({ duration: 5000, direction: "NEXT", stopOnHover: false })];

export default {
  name: "BlocNosTemoignages",
  components: {
    LogoTesla,
    flechesLogos,
    Flicking: Flicking
  },
  methods: {
    goNext() {
      this.$refs.flicking.next();
    },
    goPrev() {
      this.$refs.flicking.prev();
    },
    goToSlide(index) {
      this.$refs.flicking.moveTo(index);
    }
  },
  data() {
    return {
      plugins,
      autoplay: true,
    };
  },
}
</script>

<style scoped>
.img-temoignage-cursor {
  cursor: pointer;
}

.bloc_tmoignages-1 {
  position: relative;
}

.temoignage-carousel-arrow-left {
  z-index: 2;
  position: absolute;
  left: 10%;
  margin-left: 25px;
  top: 45%;
  height: 30px;
}
.temoignage-carousel-arrow-right {
  z-index: 2;
  position: absolute;
  right: 11%;
  margin-right: 25px;
  top: 45%;
  height: 30px;
}
.temoignage-carousel-arrows img:hover {
  cursor: pointer;
}

.temoignage-photo-img-margin {
  margin-left: 36px;
}

.temoignages-photos-container {
  display: flex;
  align-items: center;
  margin-top: 64px;
}

.temoignage-ellipse {
  border: 3px solid;
  border-radius: 46px;
  height: 91px;
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipse-client {
  border-color: var(--bluenavy);
}

.ellipse-valet {
  border-color: var(--yellow);
}

.ellipse-particulier {
  border-color: var(--violet);
}

.temoignage-photo-img {
  height: 67px;
}

.temoignage-photo-bmw-img {
  height: 67px;
}

@media screen and (max-width: 992px) {
  .temoignage-carousel-arrow-left {
    left: 0;
  }
  .temoignage-carousel-arrow-right {
    right: 0;
  }
  .overlap-group3-3 {
    width: 100%;
    padding: 56.8px 15px;
  }
  .overlap-group1-17 {
    align-self: flex-start;
    width: 100%;
    margin-top: 0;
  }
  .bloc_tmoignages-1 {
    width: 100%;
  }
  .nos-tmoignages-ce-q-1 {
    width: 100%;
  }
  .pop-valet-nous-a-acc {
    left: unset;
    position: unset;
    width: 100%;
    padding: 0 60px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .temoignage-carousel-arrow-left {
    left: 0;
  }
  .temoignage-carousel-arrow-right {
    right: 0;
  }
  .overlap-group3-3 {
    width: 100%;
    padding: 56.8px 15px;
  }
  .overlap-group1-17 {
    align-self: flex-start;
    width: 100%;
    margin-top: 0;
  }
  .bloc_tmoignages-1 {
    width: 100%;
  }
  .nos-tmoignages-ce-q-1 {
    width: 100%;
  }
  .pop-valet-nous-a-acc {
    left: unset;
    position: unset;
    width: 100%;
    padding: 0 100px;
  }
}

@media screen and (min-width: 1441px) {
  .overlap-group3-3 {
    width: 100%;
    padding: 56.8px 115px;
  }
  .overlap-group1-17 {
    align-self: center;
    width: 1208px;
    margin-top: 38px;
    display: flex;
    justify-content: center;
  }
  .bloc_tmoignages-1 {
    width: 100%;
  }
  .nos-tmoignages-ce-q-1 {
    width: 577px;
  }
  .pop-valet-nous-a-acc {
    left: 190px;
    width: 830px;
  }
}

.bloc_tmoignages-1.pro-home-nostemoignages {
  margin-top: 60px;
}

.bloc_tmoignages-1 {
  align-items: flex-start;
  display: flex;
}

.overlap-group3-3 {
  align-items: center;
  background-color: var(--grisclair);
  display: flex;
  flex-direction: column;
  min-height: 664px;
  position: relative;
}

.nos-tmoignages-ce-q-1 {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 1px;
  margin-top: 42px;
  min-height: 66px;
  text-align: center;
}

.span-46 {
  color: var(--bluenavy);
  font-weight: 400;
  line-height: 28px;
}

.quotation-mark {
  color: var(--violet);
  font-family: var(--font-family-isidora_sans_alt-semibold);
  font-size: 94px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  margin-top: 14px;
  min-height: 49px;
  text-align: center;
  width: 62px;
}

.quotation-mark-purple {
  color: var(--violet);
}

.quotation-mark-yellow {
  color: var(--yellow);
}

.quotation-mark-blue {
  color: var(--bluenavy);
}

.overlap-group1-17 {
  position: relative;
}

.pop-valet-nous-a-acc {
  letter-spacing: 0;
  line-height: 39.2px;
  text-align: center;
  top: 0;
}

.noms-5 {
  margin-top: 51px;
  text-align: center;
}

.virginie-durand-particulier {
  flex: 1;
  height: 34.83px;
  margin-left: 12.2%;
  margin-right: 13.45%;
  margin-top: 4.3px;
  width: 171.75px;
}


.temoignages-vignettes {
  align-items: center;
  display: flex;
  margin-top: 64px;
  min-width: 326px;
  padding: 0 12px;
  position: relative;
}

.tem_particulier {
  align-items: flex-start;
  display: flex;
  margin-left: 36px;
  min-width: 92px;
}

.ellipse-container {
  border-radius: 46px/45.59px;
  height: 91px;
  position: relative;
  width: 92px;
}

.ellipse-32 {
  border: 3px solid;
  border-color: var(--violet);
  border-radius: 46px/45.59px;
  height: 91px;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
}

.tem_valet {
  background-size: 100% 100%;
  height: 67px;
  margin-left: 37px;
  margin-top: 0;
  width: 68px;
}
</style>