<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Expertise automobile de vos véhicules professionnels" :icon-url="require('../../img/Research.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Vous êtes un <a href="/professionnels" style="color: #002244">professionnel de l’automobile</a> et recherchez une solution d’expertise auto dans le cadre d’un sinistre, d’une reprise ou encore d’une réaffectation ? Sur tout le territoire métropolitain, Pop Valet met à votre service son réseau de spécialistes, avec la possibilité de coupler cette prestation avec le transport du véhicule concerné, pour une économie de temps et d’argent.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Nos équipes vous éclairent sur les champs couverts par l’expertise automobile et vous fournissent des exemples de nos prestations.</p>
    </div>
    <div class="pro-services-expertise-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">L’expertise automobile, c’est quoi ?</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’expertise automobile consiste, pour un professionnel appelé expert automobile, à l’évaluation précise de l’état d’un véhicule, notamment après un accident de la route. Maillon essentiel de la politique de sécurité routière, l’expert doit, pendant la procédure, déceler les défauts de la voiture, en apprécier la valeur, et évaluer le coût de remise en état.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Selon les chiffres officiels de la Sécurité Routière, la France compte environ 3 400 experts en automobile agréés.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">La profession d’expert automobile est réglementée par le Code de la route</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">"Nul ne peut exercer la profession d’expert en automobile s’il ne figure sur une liste fixée par l’autorité administrative", précise l’article L326-3 du Code de la route. Cette inscription vaut donc agrément. L’autorité administrative tient notamment compte :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- De la formation technique</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- De l’absence de condamnation pénale</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- De la souscription d’un contrat d’assurance garantissant la responsabilité civile en raison des activités professionnelles</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Chaque année, et comme le précise l’article R326-12 du même Code, l’administration vérifie que les conditions demeurent remplies pour procéder à la réinscription de l’expert déjà inscrit.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Quels sont les champs d’intervention d’un expert automobile ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’intervention d’un expert automobile trouve sa légitimité dans plusieurs situations. Parmi les principales, citons les suivantes :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous souhaitez faire l’acquisition d’un véhicule d’occasion : en amont, l’expert automobile peut évaluer ledit véhicule</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous avez fait l’acquisition d’un véhicule d’occasion mais une panne est survenue peu de temps après la transaction : l’expert auto va s’atteler à déceler tout vice caché</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous avez confié votre véhicule à un garage mais doutez de la bonne réalisation des réparations ou de la légitimité du montant facturé : l’expert va vous transmettre un avis neutre</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Vous avez reçu le rapport d’expertise réalisé par l’expert désigné par votre compagnie d’assurance mais vous espériez une plus grande indemnisation : l’expert automobile diligente alors une contre-expertise</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">Une fois sa tâche achevée, ce professionnel vous remet un rapport d’expertise automobile. Ce document, à conserver précieusement, mentionne notamment et en vertu de l’article R326-3 du Code de la route :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les renseignements obligatoires</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les procédés effectués</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Les conclusions du professionnel automobile</p><br>

      <h3 class="isidorasansalt-bold-white-24px">L’expertise automobile : obligatoire ou facultative ?</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">L'expertise automobile peut être obligatoire ou facultative. Elle est obligatoire lorsqu'elle est imposée par la compagnie d'assurances pour l’indemnisation de la réparation de votre voiture. Votre assurance désigne alors un professionnel inscrit sur la liste nationale des experts en automobile. Il se déplace sur le lieu d’immobilisation du véhicule ou procède sur photographies ; on parle alors d’expertise à distance ou EAD.</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">À l’inverse, vous avez le droit de solliciter les services d’un expert automobile sans que cela vous soit imposé. Plusieurs cas de figure peuvent motiver cette démarche :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Obtenir un conseil avant l’achat d’un véhicule</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Démontrer l’existence d’un vice caché après achat</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Contester la facture ou la prestation d’un garagiste</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Etc.</p>
    </div>
    <div class="pro-services-expertise-popvalet-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Ce que peut faire Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Les experts auto assignés par Pop Valet se déplacent toute l’année et mettent leur savoir-faire au service de tous vos besoins. Illustrations.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Quelques cas d’usage de notre expertise automobile</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Notre solution répond présente pour les situations suivantes :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Restitution ou pré-restitution : nous chiffrons les frais de remise en état du véhicule, que vous soyez loueur ou gestionnaire</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Réaffectation : nous estimons les coûts de réparation avant la relivraison à de nouveaux utilisateurs</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Reprise ou rachat cash : nous établissons un chiffrage pour un reconditionnement de véhicule avant la revente</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Sinistre : nous évaluons les travaux à réaliser suite à un accident de la route ou à un litige</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Liste non-exhaustive.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Votre expert automobile sur site ou à distance</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Notre solution d’expertise auto est disponible à la fois :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Sur site (exemple : en cas de sinistre)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- À distance (exemple : avec photographies dans le cadre d’une pré-restitution ou pour des restitutions à loueur)</p>
    </div>
    <div class="pro-services-expertise-avantages-container">
      <h2 class="isidorasansalt-bold-white-28px">Les avantages Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Partenaires sur tout le territoire, gains de productivité, compétitivité tarifaire : découvrez trois bonnes raisons de nous choisir.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des partenaires nationaux et digitalisés</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Pop Valet, ce sont plus de 700 experts certifiés disponibles sur site ou à distance selon votre besoin. Notre réseau est étendu dans toute la France métropolitaine ainsi qu’en région Corse.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des économies de productivité</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Pop Valet couvre toutes les situations nécessitant un chiffrage précis dans des délais inférieurs à 48 heures en France.  En intégrant l’expertise de votre véhicule au transport de celui-ci, vous raccourcissez vos délais et gagnez en productivité – pour vous comme pour vos collaborateurs.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des prix compétitifs</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le réseau de partenaires que nous avons tissé et les outils technologiques que nous avons développés nous permettent de vous proposer des tarifs comptant parmi les plus attractifs du marché. Les devis que nous dressons dès votre passage de commande incluent la totalité de notre prestation : pas de frais cachés à l’issue de votre expertise.</p><br>
    </div>
    <div class="pro-services-expertise-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Découvrez nos autres services logistiques complets pour les professionnels de l’automobile :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a> pour tous vos besoins en livraison, convoyage ou jockeyage</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-voiture-camion" style="color: #002244">transport par camion</a> pour vos déplacements de plusieurs véhicules en simultané</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/reparation" style="color: #002244">révision, réparation et reconditionnement</a> pour tous vos véhicules neufs ou d’occasion</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/restitution" style="color: #002244">restitution sur parc</a> pour déléguer l’intégralité de votre gestion</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">En un clic, accédez également à <a href="/popvalet-general-assurance" style="color: #002244">nos assurances partenaires</a> : dommages couverts, protections assurées pour nos clients et nos Valets…</p>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletProServicesExpertiseInfo",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Expertise Automobile de Véhicules Professionnels | Pop Valet',
    meta: [
      { name: 'description', content: 'Pop Valet met à votre service son réseau d’experts automobiles dans toute la France, avec possibilité d’inscrire votre prestation dans le transport du véhicule.' },
    ]
  }
}
</script>

<style scoped>
.pro-services-expertise-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-expertise-plus-loin-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-expertise-avantages-container h3 {
  margin-top: 25px;
}
.pro-services-expertise-avantages-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-expertise-avantages-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-expertise-popvalet-container h3 {
  margin-top: 25px;
}
.pro-services-expertise-popvalet-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-expertise-popvalet-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-expertise-definition-container h3 {
  margin-top: 25px;
}
.pro-services-expertise-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-expertise-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-expertise-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-expertise-avantages-container {
    padding: 100px 50px;
  }
  .pro-services-expertise-popvalet-container {
    padding: 100px 50px;
  }
  .pro-services-expertise-definition-container {
    padding: 100px 50px;
  }
}
</style>