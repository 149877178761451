<template>
  <div class="dropdown">
    <template v-for="item in items">
      <div v-if="item.path">
        <router-link :to="item.path">
          <div class="nav-item">
            <div class="nav-item-text blogtextbold">{{item.name}}</div>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CommonDropdownBody",
  props: ['items']
};
</script>

<style scoped>
.dropdown a {
  text-decoration: none;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 12px;
}

@media screen and (max-width: 576px) {
  .nav-item {
    justify-content: left;
    padding: 12px;
    padding-left: 48px;
  }
}

.nav-item:hover {
  background-color: var(--yellow);
}

.nav-item-text {
  color: var(--bluenavy);
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: fit-content;
}
</style>
