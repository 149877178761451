<template>
  <div>
    <common-sur-header/>
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Contact" :icon-url="require('../../img/valet.png')"/>
    <div class="contact-info-container">
      <div class="contact-info-container-bloc">
        <div class="contact-form">
          <h2 class="isidorasansalt-bold-white-28px">Contactez-nous</h2>

          <ValidationObserver v-slot="{handleSubmit}" ref="form">
            <form @submit.prevent="handleSubmit(sendEmail)">
              <div class="champs_inscription">
                <ValidationProvider rules="required" v-slot="{errors}">
                  <input type="text" v-model="contactName" class="text-field" :class="{'text-field-error': errors[0]}" placeholder="Nom et prénom"/>
                  <div v-if="errors[0]" class="error">Champ requis</div>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{errors}">
                  <input type="email" v-model="email" class="text-field" :class="{'text-field-error': errors[0]}" placeholder="Email"/>
                  <div v-if="errors[0]" class="error">Champ requis</div>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{errors}">
                  <input type="tel" v-model="phone" class="text-field" :class="{'text-field-error': errors[0]}" placeholder="Téléphone"/>
                  <div v-if="errors[0]" class="error">Champ requis</div>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{errors}">
                  <textarea v-model="message" class="text-field" :class="{'text-field-error': errors[0]}" placeholder="Votre message"></textarea>
                  <div v-if="errors[0]" class="error">Champ requis</div>
                </ValidationProvider>

                <button class="blogtextbold" type="submit">ENVOYER</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import GeneralHeader from "@/components/GeneralHeader";
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import CommonFooter from "@/components/common/CommonFooter";
import {Simplert} from "vue2-simplert-plugin";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "axios";

export default {
  name: "GeneralContact",
  components: {
    GeneralHeader,
    CommonHeader,
    CommonSurHeader,
    CommonFooter,
    Simplert,
    ValidationProvider,
    ValidationObserver
  },
  props: [
    'header',
    'isMaif'
  ],
  data: function() {
    return {
      contactName: '',
      email: '',
      phone: '',
      message: ''
    };
  },
  methods: {
    sendEmail: async function () {
      try {
        const apiUrl = location.host === 'www.popvalet.com'
            ? 'https://admin-api.popvalet.com'
            : 'https://admin-beta-api.popvalet.com';

        await axios.post(apiUrl + '/website/email-contact', {
          contactName: this.contactName,
          email: this.email,
          phone: this.phone,
          message: this.message
        });

        this.$Simplert.open({
          message: 'Message envoyé avec succès !',
          type: 'success',
          customClass: 'pv-popup',
          customCloseBtnText: 'Fermer',
          customCloseBtnClass: 'pv-popup-close-button'
        });

        this.contactName = '';
        this.email = '';
        this.phone = '';
        this.message = '';

        this.$nextTick(() => {
          this.$refs.form.reset();
        });

      } catch (error) {
        this.$Simplert.open({
          message: "Erreur lors de l'envoi du message.",
          type: 'error',
          customClass: 'pv-popup',
          customCloseBtnText: 'Fermer',
          customCloseBtnClass: 'pv-popup-close-button'
        });
      }
    }
  }
}
</script>

<style scoped>
.champs_inscription {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}
.champs_inscription > * {
  width: 100%;
}
.contact-info-container-bloc {
  line-height: 29px;
  width: 500px;
}
.contact-info-container {
  display: flex;
  justify-content: space-around;
  padding: 80px 150px;
}
.contact-form {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--bluenavy);
}
.contact-form h2 {
  margin-bottom: 40px;
  text-align: center;
}
.text-field {
  width: 100%;
  background-color: var(--bluenavy);
  border: 1px solid;
  border-color: var(--blanc);
  border-radius: 8px;
  height: 44px;
  padding: 10px;
  color: white;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.text-field::placeholder {
  opacity: 0.7;
  color: white;
}

.error {
  margin-top: 5px;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
  color: red;
}

.text-field-error {
  border-color: red;
}

textarea {
  min-height: 150px;
  resize: vertical;
}
.contact-form button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: var(--yellow);
  color: var(--bluenavy);
  cursor: pointer;
}
.contact-form button:hover {
  background-color: #F4C300;
}

@media screen and (max-width: 991px) {
  .contact-info-container-bloc {
    margin: 50px 0;
    width: unset;
  }
  .contact-info-container {
    flex-direction: column;
    padding: 50px 25px;
  }
}
</style>