<template>
  <div :class="[`group-111-2`, className || ``]"><img class="icon-file" :src="src" alt="icon-file" /></div>
</template>

<script>
export default {
  name: "Group111",
  props: ["src", "className"],
};
</script>

<style>
.group-111-2 {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  width: 56px;
}

.icon-file {
  height: 32px;
  margin-left: 13px;
  margin-top: 12px;
  width: 31px;
}

.group-111-2.group-111 .icon-file,
.group-111-2.group-4-1 .icon-file {
  height: 33px;
}

.group-111-2.group-4,
.group-111-2.group-4-1 {
  margin-left: 42px;
}

.group-111-2.group-115-1 {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-left: 25px;
  width: 56px;
}

.group-111-2.group-115-1 .icon-file,
.group-111-2.group-4 .icon-file,
.group-111-2.group-111-1 .icon-file {
  height: 31px;
  margin-top: 13px;
}

.group-111-2.group-111-1 {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-left: 47px;
  width: 56px;
}

.group-111-2.icon_relation_confiance {
  background-color: var(--yellow);
  border-radius: 28px;
  display: flex;
  height: 56px;
  margin-right: 1px;
  width: 56px;
}

.group-111-2.icon_relation_confiance .icon-file {
  margin-top: 13px;
}
</style>
