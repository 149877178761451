<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Nos assurances et garanties pour couvrir vos transports automobiles" :icon-url="require('../../img/assurance-popvalet-car.png')"/>
    <div class="general-assurance-description">
      <div class="general-assurance-description-text">
        <p class="isidorasansalt-semi-bold-magic-potion-16px">Lorsque vous optez pour la <a href="/" style="color: #002244">solution de transport automobile Pop Valet</a> pour une prestation de livraison ou de convoyage, c’est Pop Valet qui est entièrement responsable du véhicule pendant toute la durée du transport. De la prise en charge de votre voiture jusqu’à sa remise effective, vous êtes entièrement couvert(e).</p>
        <br>
        <p class="isidorasansalt-semi-bold-magic-potion-16px">Assurances partenaires, dommages couverts, protections assurées à la fois pour nos clients et pour nos Valets : gros plan sur nos garanties.</p>
      </div>
    </div>
    <div class="general-assurance-maif">
      <div class="general-assurance-maif-container">
        <img src="../../img/logo-maif.png" class="general-assurance-logo-maif" alt="logo_maif">
        <div class="general-assurance-maif-title">
          <img src="../../img/logo_valet_assurance.png" alt="Nos assurances et garanties pour couvrir vos transports automobiles">
          <h2 class="isidorasansalt-bold-white-28px">La MAIF et Generali : les assurances partenaires de Pop Valet</h2>
        </div>
        <p class="montserrat-normal-white-16px general-assurance-maif-description">Au-delà d’une simple relation de bénéficiaire à prestataire, Pop Valet entretient des liens privilégiés avec ses assureurs. Nos collaborations ont permis à nos équipes d’élaborer un produit sur-mesure couvrant l’intégralité de nos activités.</p><br>
        <p class="montserrat-normal-white-16px">Les compagnies d’assurance qui nous accompagnent sont :</p><br>
        <p class="montserrat-normal-white-16px">- La MAIF pour la RC automobile (dommages matériels et corporels causés à des tiers) et les dommages tous accidents (vol, incendie, tempête…)</p><br>
        <p class="montserrat-normal-white-16px">- Generali pour la RC professionnelle (dommages corporels, matériels et immatériels)</p><br>
        <p class="montserrat-normal-white-16px">Les montants des garanties dont nous disposons sont les plus élevés du secteur. De surcroît, Pop Valet bénéficie d’une assistance sans franchise kilométrique.</p>
      </div>
    </div>
    <div class="general-assurance-additional-info-1">
      <div class="general-assurance-additional-info-section">
        <div class="general-assurance-additional-info-title">
          <img src="../../img/assurance-car-icon.png" alt="Nos assurances et garanties pour couvrir vos transports automobiles">
          <h2 class="isidorasansalt-bold-magic-potion-24px">Des assurances automobiles couvrant nos clients professionnels et particuliers</h2>
        </div>
        <p class="general-assurance-additional-info-description montserrat-normal-magic-potion-16px">Compte tenu de la variété de profils chez nos clients, nous bénéficions d’une sinistralité particulièrement faible, bien en-deçà des moyennes du marché. Dès lors que nous prenons en charge un véhicule, notre responsabilité est engagée et couvre la mission jusqu’à la remise des clés à l’adresse d’arrivée.</p><br>
        <p class="general-assurance-additional-info-description montserrat-normal-magic-potion-16px">Le transfert de responsabilité est matérialisé par les états des lieux et concerne toutes les infractions potentielles ou les éventuels sinistres occasionnés sur tous types de véhicules, y compris haut de gamme. C’est notre expérience des états des lieux et la technologie que nous avons développée en la matière qui nous permet d’éviter tout litige entre les parties prenantes.</p>
      </div>
    </div>
    <div class="general-assurance-additional-info-2">
      <div class="general-assurance-additional-info-section">
        <div class="general-assurance-additional-info-title">
          <img src="../../img/assurance-valet-icon.png" alt="Nos assurances et garanties pour couvrir vos transports automobiles">
          <h2 class="isidorasansalt-bold-magic-potion-24px">Des garanties pour nos clients mais aussi pour nos chauffeurs</h2>
        </div>
        <p class="general-assurance-additional-info-description montserrat-normal-magic-potion-16px">Vous êtes Valet chez nous ou songez à le devenir ? En collaborant avec nous, vous aussi êtes protégé(e) par de solides garanties. Qu’ils exercent en tant que salariés ou prestataires, nos chauffeurs bénéficient d’une couverture sur la sécurité du conducteur.</p><br>
        <p class="general-assurance-additional-info-description montserrat-normal-magic-potion-16px">À noter : les formations dispensées à nos Valets (théorique et pratique), nos process et le suivi scrupuleux des missions ont été pensés pour limiter les risques au maximum.</p>
      </div>
    </div>
    <div class="general-assurance-savoir-plus">
      <h2 class="isidorasansalt-bold-magic-potion-28px">En savoir plus sur Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour mieux nous connaître et vous familiariser avec nos différentes solutions, découvrez :</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Qui nous sommes : comment Pop Valet est né et qui sont ses fondateurs</p><br>-->
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/reactivite" style="color: #002244">Notre réactivité</a> : comment nos outils technologiques nous distinguent de nos confrères en matière d’optimisation des délais</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- <a href="/qualite" style="color: #002244">Nos engagements qualité</a> : les moyens que nous nous donnons pour atteindre nos exigences, de la sélection minutieuse de nos chauffeurs jusqu’à notre pôle qualité dédié</p><br>
<!--      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Notre blog : l’actualité de Pop Valet et du transport automobile</p>-->
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "AssurancePopValet",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
      "header",
      "isMaif"
  ],
  metaInfo: {
    title: 'Transports Automobiles : Assurances et Garanties | Pop Valet',
    meta: [
      { name: 'description', content: 'Assurances partenaires, dommages couverts, protections assurées à la fois pour nos clients et pour nos Valets : zoom sur les garanties Pop Valet.' },
    ]
  }
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .general-assurance-additional-info-2 {
    padding: 50px 50px 100px;
  }
  .general-assurance-additional-info-1 {
    padding: 50px 50px;
  }
  .general-assurance-maif {
    padding: 0 30px 25px 30px;
  }
  .general-assurance-description {
    padding: 100px 50px 100px 50px;
  }
  .general-assurance-additional-info-section {
    padding: 0;
  }
  .general-assurance-maif-container {
    padding: 40px 40px;
  }
  .general-assurance-description-text {
    padding: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .general-assurance-additional-info-2 {
    padding: 100px 150px 200px;
  }
  .general-assurance-additional-info-1 {
    padding: 100px 150px;
  }
  .general-assurance-maif {
    padding: 0 150px 100px 150px;
  }
  .general-assurance-description {
    padding: 0 150px;
  }
  .general-assurance-additional-info-section {
    padding: 0 100px;
  }
  .general-assurance-maif-container {
    padding: 50px 100px;
  }
  .general-assurance-description-text {
    padding: 0 100px;
  }
}

@media screen and (min-width: 1441px) {
  .general-assurance-additional-info-2 {
    padding: 100px 223px 200px;
  }
  .general-assurance-additional-info-1 {
    padding: 100px 223px;
  }
  .general-assurance-maif {
    padding: 0 223px 100px 223px;
  }
  .general-assurance-description {
    padding: 0 223px;
  }
  .general-assurance-additional-info-section {
    padding: 0 150px;
  }
  .general-assurance-maif-container {
    padding: 50px 150px;
  }
  .general-assurance-description-text {
    padding: 0 100px;
  }
}
.general-assurance-savoir-plus h2 {
  margin-bottom: 30px;
  text-align: center;
}
.general-assurance-savoir-plus {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.general-assurance-additional-info-description {
  line-height: 28px;
  margin-top: 15px;
}
.general-assurance-additional-info-title img {
  height: 32px;
}
.general-assurance-additional-info-title h2 {
  margin-left: 15px;
}
.general-assurance-additional-info-title {
  display: flex;
  align-items: center;
}
.general-assurance-maif-description {
  line-height: 28px;
  margin-top: 15px;
}
.general-assurance-maif-title img {
  height: 50px;
}
.general-assurance-maif-title h2 {
  margin-left: 15px;
  position: relative;
  bottom: 3px;
}
.general-assurance-maif-title {
  display: flex;
  align-items: center;
}
.general-assurance-logo-maif {
  height: 100px;
  margin-bottom: 25px;
  margin-left: 50px;
}
.general-assurance-maif-container {
  background-color: #002244;
  min-height: 454px;
  border-radius: 10px;
}
.general-assurance-maif-container p {
  line-height: 29px;
}
.general-assurance-description {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 494px;
}
.general-assurance-description-text h2 {
  text-align: center;
}

.general-assurance-description-text p {
  line-height: 30px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .general-assurance-savoir-plus {
    padding: 100px 50px;
  }
}
</style>