<template>
  <div class="bloc_etapes_nous_rejoindre-1">
    <p class="les-tapes-pour-la-r isidorasansalt-bold-magic-potion-28px">LES ÉTAPES POUR LA RESERVATION DU TRANSPORT</p>
    <div class="etape_reservation_transport">
      <div class="group-container-6">
        <div class="overlap-group4-4">
          <img class="ecommerce-checkout" src="../../img/ecommerce-checkout-laptop-rafiki@2x.png" alt="gps-navigator/cuate" />
        </div>
      </div>
      <div class="line-container">
        <img class="line-13-1" src="../../img/line-13@2x.png" alt="Line 13" />
      </div>
      <div class="group-176-1 group-176-2">
        <div class="group-174">
          <img
              class="vector-24"
              src="../../img/right-arrow-1.png"
              style="height: 50px"
              alt="Vector"
          />
          <div class="un-statut-dauto-entrepreneur-actif montserrat-medium-magic-potion-24px">
            Commande du véhicule
          </div>
        </div>
      </div>
    </div>
    <div class="etape_reservation_transport">
      <div class="group-container-6">
        <div class="overlap-group4-4">
          <img class="gps-navigatorcuate" src="../../img/gps-navigator-cuate@2x.png" alt="gps-navigator/cuate" />
        </div>
        <img class="group-176-5" src="../../img/group-176@2x.png" alt="Group 176" />
        <img
            class="group-177-2"
            src="../../img/group-177@2x.png"
            alt="Group 177"
        />
      </div>
      <div class="line-container">
        <img class="line-13-1" src="../../img/line-13@2x.png" alt="Line 13" />
      </div>
      <div class="group-176-1 group-176-2">
        <div class="group-174">
          <img
              class="vector-24"
              src="../../img/right-arrow-1.png"
              style="height: 50px"
              alt="Vector"
          />
          <div class="un-statut-dauto-entrepreneur-actif montserrat-medium-magic-potion-24px">
            Dispatch de la mission
          </div>
        </div>
      </div>
    </div>
    <div class="etape_reservation_transport">
      <div class="group-container-6">
        <div class="overlap-group4-4">
          <img class="popvaletcv" src="../../img/schema4.png" alt="gps-navigator/cuate" />
        </div>
      </div>
      <div class="line-container">
        <img class="line-13-1" src="../../img/line-13@2x.png" alt="Line 13" />
      </div>
      <div class="group-176-1 group-176-2">
        <div class="group-174">
          <img
              class="vector-24"
              src="../../img/right-arrow-1.png"
              style="height: 50px"
              alt="Vector"
          />
          <div class="un-statut-dauto-entrepreneur-actif montserrat-medium-magic-potion-24px">
            Etat des lieux
          </div>
        </div>
      </div>
    </div>
    <div class="etape_reservation_transport">
      <div class="group-container-6">
        <div class="overlap-group4-4">
          <img class="popvalettransport" src="../../img/schema3-1@2x.png" alt="gps-navigator/cuate" />
        </div>
      </div>
      <div class="line-container">
        <img class="line-13-1" src="../../img/line-13@2x.png" alt="Line 13" />
        <img class="line-12-1" src="../../img/line-12@2x.png" alt="Line 12" />
      </div>
      <div class="group-176-1 group-176-2">
        <div class="group-174">
          <img
              class="vector-24"
              src="../../img/right-arrow-1.png"
              style="height: 50px"
              alt="Vector"
          />
          <div class="un-statut-dauto-entrepreneur-actif montserrat-medium-magic-potion-24px">
            Transport et Finalisation
          </div>
        </div>
      </div>
    </div>
    <div class="cta_reserver_transport cta_reserver_transport-2" @click="scrollToPageAnchor('#reserver-transport')">
      <div class="reserver-mon-transport blogtextbold btn-reserver-transport">RÉSERVER MON TRANSPORT</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocEtapesReservationTransport",
  methods: {
    scrollToPageAnchor: (anchor) => {
      document.querySelector(anchor).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .bloc_etapes_nous_rejoindre-1 {
    width: 100%;
  }
  .les-tapes-pour-la-r {
    width: 100%;
  }
  .line-container {
    display: none;
  }
  .etape_reservation_transport {
    min-width: unset;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
  }
  .group-container-6 {
    align-self: center;
  }
  .blogtextbold.btn-reserver-transport {
    font-size: 22px;
  }
  .cta_reserver_transport {
    width: 80%;
  }
  .cta_reserver_transport.cta_reserver_transport-2 {
    margin-left: 0;
    margin-top: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .bloc_etapes_nous_rejoindre-1 {
    width: 1008px;
  }
  .les-tapes-pour-la-r {
    width: 747px;
  }
  .etape_reservation_transport {
    min-width: 911px;
    margin-top: 18px;
  }
  .group-container-6 {
    align-self: flex-end;
  }
  .blogtextbold.btn-reserver-transport {
    font-size: 16px;
  }
  .cta_reserver_transport {
    width: fit-content;
  }
  .cta_reserver_transport.cta_reserver_transport-2 {
    margin-left: 77px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 1441px) {
  .bloc_etapes_nous_rejoindre-1 {
    width: 1008px;
  }
  .les-tapes-pour-la-r {
    width: 747px;
  }
  .etape_reservation_transport {
    min-width: 911px;
    margin-top: 18px;
  }
  .group-container-6 {
    align-self: flex-end;
  }
  .blogtextbold.btn-reserver-transport {
    font-size: 16px;
  }
  .cta_reserver_transport {
    width: fit-content;
  }
  .cta_reserver_transport.cta_reserver_transport-2 {
    margin-left: 77px;
    margin-top: 30px;
  }
}

.popvaletcv {
  height: 169px;
  left: 55px;
  position: absolute;
  top: 5px;
  width: 173px;
}

.popvalettransport {
  height: 169px;
  left: 55px;
  position: absolute;
  top: 0;
  width: 173px;
}

.bloc_etapes_nous_rejoindre-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: 107px;
  min-height: 912px;
  position: relative;
}

.les-tapes-pour-la-r {
  letter-spacing: 0;
  line-height: normal;
  min-height: 35px;
}

.line-container {
  height: 179px;
  margin-bottom: -5.33px;
  margin-left: 55px;
  position: relative;
  width: 11px;
}

.line-13-1 {
  height: 169px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 11px;
}

.line-12-1 {
  height: 179px;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.etape_reservation_transport {
  align-items: center;
  display: flex;
}

.group-container-6 {
  border-radius: 8px;
  height: 168px;
  margin-bottom: 0;
  position: relative;
  width: 284px;
}

.overlap-group4-4 {
  align-items: flex-end;
  background-color: var(--grisclair);
  border-radius: 8px;
  display: flex;
  height: 168px;
  justify-content: flex-end;
  left: 0;
  min-width: 284px;
  padding: 24.8px 43px;
  position: absolute;
  top: 0;
}

.ecommerce-checkout {
  height: 118px;
  width: 181px;
}

.gps-navigatorcuate {
  height: 118px;
  width: 181px;
}

.group-176-5 {
  height: 18px;
  left: 160px;
  position: absolute;
  top: 74px;
  width: 14px;
}

.group-177-2 {
  height: 11px;
  left: 209px;
  position: absolute;
  top: 56px;
  width: 9px;
}

.cta_reserver_transport {
  cursor: pointer;
}

.cta_reserver_transport {
  align-items: flex-start;
  background-color: var(--violetclair);
  border-radius: 34px;
  display: flex;
  gap: 10px;
  left: 1007px;
  padding: 12px 38px;
  position: absolute;
  top: 20px
}

.reserver-mon-transport {
  color: var(--bluenavy);
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  text-align: center;
  width: fit-content;
}

.cta_reserver_transport.cta_reserver_transport-1 {
  left: unset;
  position: unset;
  top: unset;
}

.cta_reserver_transport.cta_reserver_transport-2 {
  align-self: center;
  left: unset;
  position: unset;
  top: unset;
}

.cta_reserver_transport.cta_reserver_transport-2:hover {
  background-color: #6C63F8;
}

.cta_reserver_transport.cta_reserver_transport-3 {
  left: unset;
  padding: 12px 70px;
  position: unset;
  top: unset;
}
</style>