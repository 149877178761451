<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Jockeyage de véhicules : transport et entretien dans toute la France" :icon-url="require('../../img/valet.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Avec la livraison et le convoyage, le jockeyage de véhicules routiers complète l’offre de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a> mise en place en France et en Europe par nos équipes. Ils sont près de 2 500 chauffeurs Pop Valet à assurer un service de conciergerie irréprochable pour l’entretien de vos voitures neuves ou d’occasion.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">En quoi consiste exactement notre service de jockeyage automobile ? Quelles sont les différentes interventions assurées dans le cadre de notre service de voituriers ? Quels sont les atouts du jockeyage pensé et orchestré par Pop Valet ?</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Réponses de nos experts.</p>
    </div>
    <div class="pro-services-jockeyage-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Définition du jockeyage de voiture</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le jockeyage est l’activité assurée par un jockey, qu’on appelle plus souvent chauffeur-jockey pour éviter toute confusion avec le cavalier qui monte les chevaux de course. Proche du convoyage de véhicule, qui désigne l’action de déplacer un véhicule d’une adresse à une autre sans transport d’individus, le jockeyage se distingue pourtant de ce premier. En effet, il désigne un service de conciergerie impliquant une prestation d’entretien du véhicule – de la simple réparation au garage jusqu’au contrôle technique.</p>
    </div>
    <div class="pro-services-jockeyage-interventions-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Les interventions assurées par notre service de jockeyage</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Les prestations comprises dans les solutions de jockeyage de Pop Valet tiennent toutes les promesses inhérentes au principe de pick-up & delivery : récupérer votre véhicule, où qu’il se trouve, que vous soyez un professionnel ou un particulier, puis assurer son entretien et vous le restituer en toute sérénité.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">La maintenance, l’entretien et la réparation de vos véhicules</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Un ou plusieurs véhicules à entretenir ou à réparer ? La solution Pop Valet vous permet de faire déplacer vos véhicules vers les garages ou les centres d’entretien de votre choix dans toute la France. L’étendue des interventions est illimitée et inclut :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Les réparations mécaniques, électroniques et hydrauliques</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- La fourniture de pièces détachées</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- La remise à niveau du liquide de freins et du liquide de refroidissement</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Le remplacement des pièces usées, des ampoules et des balais d’essuie-glace</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Les contrôles techniques imposés par la législation</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Etc.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Si, au-delà du transport, vous souhaitez faire confiance à Pop Valet pour organiser l’entretien et les remises en état de vos véhicules, par exemple avant une restitution au loueur, c’est une possibilité. Pop Valet dispose de partenaires compétitifs et réactifs sur l’ensemble du territoire, à même de challenger vos prestataires actuels.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour en savoir plus, rendez-vous sur notre page dédiée à nos <a href="/reparation" style="color: #002244">activités de réparation.</a></p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">La restitution de vos véhicules neufs ou d’occasion</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous avez confié votre voiture, votre utilitaire ou autre à Pop Valet ou à un autre prestataire pour une maintenance ou une réparation ? Sur simple demande de votre part, nos chauffeurs-jockey récupèrent votre véhicule dans le garage ou le centre d’entretien et assurent sa livraison à l’adresse de votre choix. Ni vous, ni un potentiel membre de votre entreprise n’est astreint à cette tâche chronophage ; le véhicule qui vous est restitué est entièrement fonctionnel pour prendre la route.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">La relocalisation de vos véhicules</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous êtes un acteur professionnel des véhicules terrestres (agence de location, concessionnaire…) et cherchez à faire transférer vos véhicules en inter-sites ou afin de rééquilibrer votre parc, tout en vous assurant que votre véhicule arrive en état de fonctionnement optimal ? Là aussi, libérez du temps pour vos collaborateurs en assignant cette tâche à un prestataire externe : les chauffeurs-jockeys Pop Valet répondent présents toute l’année sur l’ensemble du territoire.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">La livraison à vos clients professionnels et particuliers</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Un véhicule défectueux ou nécessitant une révision avant d’être livré à un client particulier ou professionnel ? Pop Valet assure chaque étape de ce processus, de la collecte du véhicule à sa remise au destinataire final, en passant par le garage ou le centre d’entretien.</p><br>
    </div>
    <div class="pro-services-jockeyage-choisir-container">
      <h2 class="isidorasansalt-bold-white-28px">Pourquoi choisir le service de jockeyage de véhicules Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Interface digitale optimisée, assurances et garanties, chauffeurs-jockeys sélectionnés avec soin, réseau de partenaires national : tels sont les piliers sur lesquels s’appuient nos services de voituriers. Avec Pop Valet, votre véhicule est entre de bonnes mains.</p>

      <h3 class="isidorasansalt-bold-white-24px">Une technologie pour mieux organiser votre conciergerie</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">L’interface unique développée et exploitée par Pop Valet permet à nos clients :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- De passer commande en quelques clics seulement</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- De bénéficier d’un suivi en temps réel de leurs courses : nos trajets géolocalisés s’affichent en direct pour que vous puissiez suivre la progression de votre Valet</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’accéder de manière centralisée à tous leurs documents : vous retrouvez au même endroit l’entièreté des pièces administratives nécessaires</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- D’évaluer leur chauffeur-jockey</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Des tests minutieux pour sélectionner nos chauffeurs-jockeys</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Trois années de permis de conduire ainsi qu’un capital minimum de huit points sont requis pour postuler à un poste de chauffeur-jockey au sein de Pop Valet. Une fois recruté, un Valet suit :</p>
      <p class="isidorasansalt-semi-bold-white-18px">- Une formation théorique, avec revue des processus et des outils utilisés chez Pop Valet</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Une formation pratique, avec vérification des réflexes de conduite et du respect du Code de la route</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Un réseau de partenaires de confiance</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Ces grands groupes ont choisi Pop Valet pour tous leurs besoins en jockeyage et en déplacement de véhicules à réparer ou réviser. Liste non-exhaustive.</p><br>
      <h4 class="isidorasansalt-bold-white-20px">MAIF</h4><br>
      <p class="isidorasansalt-semi-bold-white-18px">La société d’assurance mutuelle fait confiance à Pop Valet pour le rapatriement des véhicules post-sinistre de ses assurés. Après réparation, les chauffeurs récupèrent ainsi le véhicule auprès du garage et le conduisent depuis le lieu du sinistre jusqu’au domicile des assurés. Ce service, gain de temps et source de confort, est plébiscité par les sociétaires de la MAIF, avec une note moyenne de 4,9/5.</p><br>
      <h4 class="isidorasansalt-bold-white-20px">Carglass</h4><br>
      <p class="isidorasansalt-semi-bold-white-18px">Le leader français de la réparation et du remplacement de vitrages a sélectionné Pop Valet comme unique prestataire pour assurer le jockeyage des véhicules de ses clients vers quelque 450 ateliers disposés sur tout le territoire français. Le choix de Carglass s’est porté à la fois sur :</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- Le respect des créneaux horaires</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- La rigueur dans la gestion documentaire</p><br>
      <p class="isidorasansalt-semi-bold-white-18px">- La plateforme de suivi</p><br>
    </div>
    <div class="pro-services-jockeyage-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">De la livraison au convoyage, découvrez les autres solutions de mobilité proposées par Pop Valet :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/convoyage-voiture" style="color: #002244">convoyage de véhicules</a>> si vous avez besoin de transporter par la route une ou plusieurs voitures entre deux lieux définis ou pour des besoins internes</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/livraison-voiture" style="color: #002244">livraison de véhicules</a> si vous avez besoin de livrer une voiture, un utilitaire ou autre à un client professionnel ou particulier</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Rendez-vous sur notre service de <a href="/transport-avec-chauffeur-reparation" style="color: #002244">réparation et reconditionnement</a> pour tous vos véhicules neufs ou d’occasion</p><br>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopValetProServicesValetJockeyage",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Jockeyage de Véhicules : Transport et Entretien | Pop Valet',
    meta: [
      { name: 'description', content: 'Découvrez notre service de conciergerie pour le transport et l’entretien de vos voitures neuves ou d’occasion dans toute la France.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.popvalet.com/jockeyage-voiture' }
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://www.popvalet.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Transport avec chauffeur",
              "item": "https://www.popvalet.com/transport-avec-chauffeur"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Jockeyage de véhicules",
              "item": "https://www.popvalet.com/jockeyage-voiture"
            }
          ]
        }
      }
    ]
  }
}
</script>

<style scoped>
.pro-services-jockeyage-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-jockeyage-plus-loin-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-jockeyage-choisir-container h4 {
  margin-top: 15px;
}
.pro-services-jockeyage-choisir-container h3 {
  margin-top: 25px;
}
.pro-services-jockeyage-choisir-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-jockeyage-choisir-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-jockeyage-interventions-container h3 {
  margin-top: 25px;
}
.pro-services-jockeyage-interventions-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-jockeyage-interventions-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-jockeyage-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-jockeyage-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-jockeyage-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-jockeyage-choisir-container {
    padding: 100px 50px;
  }
  .pro-services-jockeyage-interventions-container {
    padding: 100px 50px;
  }
  .pro-services-jockeyage-definition-container {
    padding: 100px 50px;
  }
}
</style>