<template>
  <div :class="[`picto_gerez_planning`, className || ``]"><img class="icon" :src="src" alt="icon-calendar" /></div>
</template>

<script>
export default {
  name: "PictoGerezPlanning",
  props: ["src", "className"],
};
</script>

<style>
.picto_gerez_planning {
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  height: 68px;
  margin-left: 66px;
  width: 68px;
}

.icon {
  height: 40px;
  margin-left: 14px;
  margin-top: 14px;
  width: 40px;
}

.picto_gerez_planning.picto_pouce {
  margin-left: 89px;
}

.picto_gerez_planning.group-237 {
  background-color: var(--bluemed);
  margin-left: unset;
}

.picto_gerez_planning.group-237-1 {
  background-color: var(--grey);
  margin-left: unset;
}

.picto_gerez_planning.group-237-2 {
  background-color: var(--blanc);
  margin-left: unset;
}

.picto_gerez_planning.group-237-3 {
  margin-left: unset;
}

.picto_gerez_planning.group-237-4 {
  background-color: var(--violet);
  margin-left: unset;
}
</style>
