<template>
  <div class="privacy-policy-assurance-container">
    <p class="isidorasansalt-semi-bold-magic-potion-18px">CHARTE RELATIVE A LA PROTECTION DES DONNEES A CARACTERE PERSONNEL </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Définition et nature des données à caractère personnel</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Les termes et expressions suivants, lorsqu’ils sont employés avec des initiales majuscules, ont la signification qui leur est attribuée dans les Conditions générales d’utilisation des Prestations de la société AB2P.</p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Lors de votre utilisation du site <a :href="assuranceUrl">{{assuranceUrl}}</a> (ci-après : le « Site »), nous pouvons être amenés à vous demander de nous communiquer des données à caractère personnel vous concernant, afin d’utiliser les prestations proposées par la société AB2P. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Dans le cadre de la présente charte, le terme « données à caractère personnel » désigne toutes les données qui permettent d’identifier un individu, ce qui correspond notamment à vos nom, prénoms, , adresses postales et de courrier électronique, numéros de téléphone, immatriculation ou numéro de châssis, données relatives à vos transactions sur le Site, détails de vos achats et abonnements, numéros de carte bancaire, ainsi qu’à tout autre renseignement que vous choisirez de nous communiquer à votre sujet. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Objet de la présente charte</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">La présente charte a pour objet de vous informer sur les moyens que nous mettons en œuvre pour collecter vos données à caractère personnel, dans le respect le plus strict de vos droits. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion de vos données à caractère personnel, à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, dans sa version actuelle (ci-après : la « Loi Informatique et Libertés »), ainsi que le règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après : le « RGPD »). </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Identité du responsable de la collecte de données</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Le responsable de la collecte de vos données à caractère personnel est la société AB2P, société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés de Paris sous le n° 752 998 161, ayant son siège social 36, rue Scheffer 75116 Paris (ci-après : « Nous »). </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Collecte des données à caractère personnel</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Le fondement légal de notre collecte de vos données personnelles est le suivant : </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px; margin-bottom: 25px">- L’intérêt légitime lorsque vous nous fournissez volontairement des données à caractère personnel lors de votre visite sur notre Site, les données étant alors collectées pour nous permettre de mieux répondre à vos demandes d’information sur nos Prestations, <br />
      - Votre consentement s’agissant des cookies Google Analytics visés à l’article 9, <br />
      - Cette collecte est nécessaire afin d’exécuter le contrat conclu lorsque vous utilisez nos Prestations sur notre Site. <br /></p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vos données à caractère personnel sont collectées pour répondre à une ou plusieurs des finalités suivantes : </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px; margin-bottom: 25px">Gérer votre accès à certaines prestations accessibles sur le Site et leur utilisation, <br />
      - Effectuer les opérations relatives à la gestion des clients concernant les contrats, commandes, livraisons, factures, programmes de fidélité, suivis de la relation avec les clients, <br />
      - Gérer les impayés et les contentieux éventuels quant à l’utilisation de nos produits et services, <br />
      - Personnaliser les réponses à vos demandes d’information, <br />
      - Respecter nos obligations légales et réglementaires. <br /></p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives. Les données obligatoires sont nécessaires pour le fonctionnement des Prestations. Concernant les données facultatives, vous êtes entièrement libre de les indiquer ou non. Nous vous indiquons également quelles sont les conséquences éventuelles d’un défaut de réponse.</p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Destinataires des données collectées</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Auront accès à vos données à caractère personnel le personnel de notre société, les services chargés du contrôle (commissaire aux comptes notamment), nos sous-traitants ainsi que notre partenaire Inter Mutuelles Assistance dans le cadre du traitement et du suivi de la satisfaction relatif à votre dossier d’assistance.</p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Peuvent également être destinataires de vos données à caractère personnel les organismes publics, exclusivement pour répondre à nos obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le recouvrement de créances. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">En outre, vos données à caractère personnel ne feront pas l’objet de cessions, locations ou échanges au bénéfice de tiers. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Durée de conservation des données à caractère personnel</h3><br />

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-bottom: 25px">Concernant les données relatives à la gestion de clients et prospects : <br />
      Vos données à caractère personnel ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de notre relation commerciale avec vous. Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être conservées au titre du respect d’une obligation légale, le seront pendant la durée prévue par la loi en vigueur. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Concernant les données relatives aux cartes bancaires :<br />
    Les transactions financières relatives au paiement des Prestations, sont confiées à un prestataire de services de paiement qui en assure le bon déroulement et la sécurité. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour les besoins des services, ce prestataire de services de paiement peut être amené à être destinataire de vos données à caractère personnel relatives à vos numéros de cartes bancaires, qu’il recueille et conserve en notre nom et pour notre compte. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous n’avons pas accès à ces données. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Pour vous permettre de régler les Prestations, vos données relatives à vos cartes bancaires sont conservées pendant le temps de votre inscription sur le Site, et à tout le moins, jusqu’au moment où vous réalisez votre dernière transaction. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">En ayant coché sur le Site la case expressément prévue à cet effet, vous nous donnez votre consentement exprès pour cette conservation. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Les données relatives au cryptogramme visuel ou CVV2, inscrit sur votre carte bancaire, ne sont pas stockées. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Si vous refusez que vos données à caractère personnel relatives à vos numéros de cartes bancaires soient conservées dans les conditions précisées ci-dessus, nous ne conserverons pas ces données au-delà du temps nécessaire pour permettre la réalisation de la transaction. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-bottom: 25px">En tout état de cause, les données relatives à celles-ci pourront être conservées, pour une finalité de preuve en cas d’éventuelle contestation de la transaction, en archives intermédiaires, pour la durée prévue par l’article L 133-24 du Code monétaire et financier, en l’occurrence 13 (treize) mois suivant la date de débit. Ce délai peut être étendu à 15 (quinze) mois afin de prendre en compte la possibilité d’utilisation des cartes de paiement à débit différé. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Concernant les cookies : <br />
      La durée de conservation des cookies visés à l’article 9 est de 13 (treize) mois. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Sécurité</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès. Nous recourrons également à des systèmes de paiement sécurisé conformes à l’état de l’art et à la réglementation applicable. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">A ce titre, nous vous informons respecter les mesures de sécurité mises en place par notre prestataire d’hébergement de vos données, la société Amazon Web Services. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Hébergement </h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous vous informons que vos données sont conservées et stockées, pendant toute la durée de leur conservation sur les serveurs de la société Amazon Web Services, situés en France dans l’Union européenne. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Cookies </h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils sont créés lorsque le navigateur d’un utilisateur charge un site internet donné : le site envoie des informations au navigateur, qui créé alors un fichier texte. Chaque fois que l’utilisateur revient sur le même site, le navigateur récupère ce fichier et l’envoie au serveur du site internet. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px; margin-bottom: 25px">On peut distinguer différents types de cookies, qui n’ont pas les mêmes finalités : </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">- Les cookies techniques sont utilisés tout au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions. Un cookie technique peut par exemple être utilisé pour mémoriser les réponses renseignées dans un formulaire ou encore les préférences de l’utilisateur s’agissant de la langue ou de la présentation d’un site internet, lorsque de telles options sont disponibles. <br />
      Nous utilisons des cookies techniques. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px">- Les cookies des réseaux sociaux peuvent être créés par les plateformes sociales pour permettre aux concepteurs de sites internet de partager le contenu de leur site sur lesdites plateformes. Ces cookies peuvent notamment être utilisés par les plateformes sociales pour tracer la navigation des internautes sur le site internet concerné, qu’ils utilisent ou non ces cookies. <br />
      Nous n’utilisons pas de cookies des réseaux sociaux. Si nous décidons de le faire ultérieurement, nous vous demanderons votre accord avant de les déposer. Vous aurez en outre la possibilité de vous informer sur leur nature, de les accepter ou de les refuser. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px">- Les cookies publicitaires peuvent être créés non seulement par le site internet sur lequel l’utilisateur navigue, mais également par d’autres sites internet diffusant des publicités, annonces, widgets ou autres éléments sur la page affichée. Ces cookies peuvent notamment être utilisés pour effectuer de la publicité ciblée, c’est-à-dire de la publicité déterminée en fonction de la navigation de l’utilisateur. <br />
      Nous n’utilisons pas de cookies publicitaires. Si nous décidons de le faire ultérieurement, nous vous demanderons votre accord avant de les déposer. Vous aurez en outre la possibilité de vous informer sur leur nature, de les accepter ou de les refuser. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px">- Nous utilisons Google Analytics qui est un outil statistique d’analyse d’audience qui génère un cookie permettant de mesurer le nombre de visites sur le Site, le nombre de pages vues et l’activité des visiteurs. Votre adresse IP est également collectée pour déterminer la ville depuis laquelle vous vous connectez. Ce cookie n’est déposé que si vous donnez votre accord. Vous pouvez l’accepter ou le refuser. <br />
      Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer au dépôt de cookies en configurant votre navigateur. Un tel refus pourrait toutefois empêcher le bon fonctionnement du Site. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Accès à vos données à caractère personnel</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, et au règlement (UE) 2016/679 du 27 avril 2016, dès son entrée en application (ci-après : « RGPD »), vous disposez du droit d’obtenir la communication et, le cas échéant, la rectification ou l’effacement des données vous concernant en vous adressant à : </p><br />

    <p class="isidorasansalt-semi-bold-magic-potion-18px">adresse de courrier électronique : service-clients@popvalet.com</p><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">adresse de courrier postal : AB2P – 36 rue Scheffer 75116 Paris</p><br />

    <h3 class="isidorasansalt-bold-magic-potion-24px">Droit de définir des directives relatives au traitement des données après votre mort</h3><br />

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous disposez du droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre mort. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Ces directives peuvent être générales, c’est-à-dire qu’elles portent alors sur l’ensemble des données à caractère personnel qui vous concernent. Elles doivent dans ce cas être enregistrées auprès d’un tiers de confiance numérique certifié par la CNIL. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px">Les directives peuvent aussi être spécifiques aux données traitées par notre société. Il convient alors de nous les transmettre aux coordonnées suivantes : </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">adresse de courrier électronique : service-clients@popvalet.com <br />
      adresse de courrier postal : AB2P – 36 rue Scheffer 75116 Paris</p><br />

    <p class="isidorasansalt-semi-bold-magic-potion-18px">En nous transmettant de telles directives, vous donnez expressément votre consentement pour que ces directives soient conservées, transmises et exécutées selon les modalités prévues aux présentes. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous pouvez désigner dans vos directives une personne chargée de leur exécution. Celle-ci aura alors qualité, lorsque vous serez décédé(e), pour prendre connaissance desdites directives et nous demander leur mise en œuvre. A défaut de désignation vos héritiers auront qualité pour prendre connaissance de vos directives à votre décès et nous demander leur mise en œuvre. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous pouvez modifier ou révoquer vos directives à tout moment, en nous écrivant aux coordonnées ci-dessus. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Portabilité de vos données à caractère personnel</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous disposez d’un droit à la portabilité des données à caractère personnel que vous nous aurez fournies, entendues comme les données que vous avez déclarées activement et consciemment dans le cadre de l’accès et de l’utilisation des prestations, ainsi que des données générées par votre activité dans le cadre de l’utilisation des prestations. Nous vous rappelons que ce droit ne porte pas sur les données collectées et traitées sur une autre base légale que le consentement ou l’exécution du contrat nous liant. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Ce droit peut être exercé gratuitement, à tout moment, et notamment lors de la fermeture de votre compte sur la Plateforme, afin de récupérer et de conserver vos données à caractère personnel. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">Dans ce cadre, nous vous adresserons vos données à caractère personnel, par tous moyens jugés utiles, dans un format ouvert standard couramment utilisé et lisible par machine, conformément à l’état de l’art. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Introduction d’une réclamation devant une autorité de contrôle</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous êtes également informés que vous disposez du droit d’introduire une réclamation auprès d’une autorité de contrôle compétente, (la Commission Nationale Informatique et Libertés pour la France), dans l’Etat membre dans lequel se trouve votre résidence habituelle, votre lieu de travail ou le lieu où la violation de vos droits aurait été commise, si vous considérez que le traitement de vos données à caractère personnel objet de la présente Charte constitue une violation des textes applicables. </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px" style="margin-top: 25px">Ce recours pourra être exercé sans préjudice de tout autre recours devant une juridiction administrative ou juridictionnelle. En effet, vous disposez également d’un droit à un recours administratif ou juridictionnel effectif si vous considérez que traitement de vos données à caractère personnel objet de la présente Charte constitue une violation des textes applicables. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Limitation du traitement</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous avez le droit d’obtenir la limitation du traitement de vos données personnelles, dans les cas suivants: </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">- Pendant la durée de vérification que nous mettons en œuvre, lorsque vous contestez l’exactitude de vos données à caractère personnel, </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">- Lorsque le traitement de ces données est illicite, et que vous souhaitez limiter ce traitement plutôt que supprimer vos données, </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">- Lorsque nous n’avons plus besoin de vos données personnelles, mais que vous souhaitez leur conservation pour exercer vos droits, </p>

    <p class="isidorasansalt-semi-bold-magic-potion-18px">- Pendant la période de vérification des motifs légitimes, lorsque vous vous êtes opposés au traitement de vos données personnelles. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Modifications </h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment la présente charte, en totalité ou en partie. Ces modifications entreront en vigueur à compter de la publication de la nouvelle charte. Votre utilisation du Site suite à l’entrée en vigueur de ces modifications vaudra reconnaissance et acceptation de la nouvelle charte. A défaut et si cette nouvelle charte ne vous convient pas, vous ne devrez plus accéder au Site. </p>

    <h3 class="isidorasansalt-bold-magic-potion-24px">Entrée en vigueur</h3><br />
    <p class="isidorasansalt-semi-bold-magic-potion-18px">La présente charte est entrée en vigueur le 1er octobre 2020.</p>
  </div>
</template>

<script>
export default {
  name: 'BlocPrivacyPolicyMaif',
  props: [
    'assuranceUrl'
  ],
}
</script>

<style>
.privacy-policy-assurance-container h3 {
  margin-top: 25px;
}
.privacy-policy-assurance-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.privacy-policy-assurance-container {
  background-color: white;
  padding: 100px 150px;
  line-height: 29px;
}
@media screen and (max-width: 991px) {
  .privacy-policy-assurance-container {
    padding: 100px 50px;
  }
}
</style>