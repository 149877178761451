import Vue from "vue";
import Router from "vue-router";
import AccueilPopvaletPro from "./components/AccueilPopvaletPro";
import PopvaletGeneralAccueil from "./components/PopvaletGeneralAccueil";
import PopvaletParticulierAccueil from "./components/PopvaletParticulierAccueil";
import PopvaletGeneralVision from "./components/PopvaletGeneralVision";
import PopvaletGeneralQualite from "./components/PopvaletGeneralQualite";
import PopvaletProServicesValet from "./components/PopvaletProServicesValet";
import PopvaletProServicesExpertise from "./components/PopvaletProServicesExpertise";
import PopvaletProServicesCamion from "./components/PopvaletProServicesCamion";
import AssurancePopValet from "@/components/AssurancePopValet";
import {
  accueilPopvaletProData,
  popvaletGeneralAccueilData,
  popvaletParticulierAccueilData,
  popvaletGeneralVisionData,
  popvaletGeneralQualiteData,
  popvaletProServicesValetData,
  popvaletProServicesExpertiseData,
  popvaletProServicesCamionData,
  popvaletGeneralAssuranceData,
  popvaletGeneralNosUsesCasesData,
  popvaletGeneralReactiviteData,
  popvaletGeneralTechnologieData,
  popvaletProServicesReparationData,
  popvaletReserverTransportData,
  popvaletProServicesRestitutionData,
  popvaletLandingPageData,
  popvaletGeneralCGUData,
  popvaletGeneralContactData,
  accueilMaifData,
  commentCaMarcheMaifData,
  popvaletMaifReserverTransportData,
  commentCaMarcheMatmutData,
  accueilMatmutData,
  privacyPolicyMaifData,
  privacyPolicyMatmutData,
  popvaletMatmutReserverTransportData,
  popvaletGeneralMaifVisionData,
  popvaletGeneralMaifQualiteData,
  popvaletGeneralMaifTechnologieData,
  popvaletGeneralMaifReactiviteData,
  popvaletGeneralMaifContactData,
  popvaletGeneralMaifCGUData,
  popvaletGeneralMaifAssuranceData,
  popvaletProCommonData,
} from "./data";
import NosUsesCases from "@/components/NosUsesCases";
import ReactiviteForte from "@/components/ReactiviteForte";
import Technologie from "@/components/Technologie";
import PopvaletProServicesReparation from "@/components/PopvaletProServicesReparation";
import PopvaletReserverTransport from "@/components/PopvaletReserverTransport";
import PopvaletProServicesRestitution from "@/components/PopvaletProServicesRestitution";
import PopValetProServicesValetLivraison from "@/components/PopValetProServicesValetLivraison";
import PopValetProServicesValetConvoyage from "@/components/PopValetProServicesValetConvoyage";
import PopValetProServicesValetJockeyage from "@/components/PopValetProServicesValetJockeyage";
import PopvaletProServicesCamionPorte8 from "@/components/PopvaletProServicesCamionPorte8";
import PopvaletProServicesCamionDepanneuse from "@/components/PopvaletProServicesCamionDepanneuse";
import PopvaletProServicesReparationInfo from "@/components/PopvaletProServicesReparationInfo";
import PopvaletProServicesExpertiseInfo from "@/components/PopvaletProServicesExpertiseInfo";
import PopvaletProServicesRestitutionInfo from "@/components/PopvaletProServicesRestitutionInfo";
import PopvaletGeneralVisionInfo from "@/components/PopvaletGeneralVisionInfo";
import PopvaletGeneralQualiteInfo from "@/components/PopvaletGeneralQualiteInfo";
import ReactiviteForteInfo from "@/components/ReactiviteForteInfo";
import TechnologieInfo from "@/components/TechnologieInfo";
import GeneralCGU from "@/components/GeneralCGU";
import GeneralContact from "@/components/GeneralContact";
import AccueilMaifPopvalet from "@/components/maif/AccueilMaifPopvalet.vue";
import CommentCaMarcheMaifPopvalet from "@/components/maif/CommentCaMarcheMaifPopvalet.vue";
import PrivacyPolicyMaifPopValet from "@/components/maif/PrivacyPolicyMaifPopValet.vue";
import CommentCaMarcheMatmutPopvalet from "@/components/matmut/CommentCaMarcheMatmutPopvalet.vue";
import PopValetProTransportVoiture from "@/components/PopValetProTransportVoiture.vue";
import PopValetProTransportVehiculePlateau from "@/components/PopValetProTransportVehiculePlateau.vue";
import PopValetProTransportVoitureRemorque from "@/components/PopValetProTransportVoitureRemorque.vue";
import PopValetProTransportVoitureLocation from "@/components/PopValetProTransportVoitureLocation.vue";
import PopValetProTransportVoitureLuxe from "@/components/PopValetProTransportVoitureLuxe.vue";
import PopValetProTransportVoitureCollection from "@/components/PopValetProTransportVoitureCollection.vue";
import PopValetProTransportVoitureEurope from "@/components/PopValetProTransportVoitureEurope.vue";
import PopValetProTransportVoitureFrance from "@/components/PopValetProTransportVoitureFrance.vue";
import PopValetProTransportVoitureAllemagneFrance from "@/components/PopValetProTransportVoitureAllemagneFrance.vue";
import PopValetProTransportVoitureItalieFrance from "@/components/PopValetProTransportVoitureItalieFrance.vue";
import PopValetProTransportVoitureEspagneFrance from "@/components/PopValetProTransportVoitureEspagneFrance.vue";
import PopValetProTransportVoitureSuedeFrance from "@/components/PopValetProTransportVoitureSuedeFrance.vue";
import PopValetProTransportVoitureBelgiqueFrance from "@/components/PopValetProTransportVoitureBelgiqueFrance.vue";
import PopValetProTransportVoitureParis from "@/components/PopValetProTransportVoitureParis.vue";
import PopValetProTransportVoitureLyon from "@/components/PopValetProTransportVoitureLyon.vue";
import PopValetProTransportVoitureMarseille from "@/components/PopValetProTransportVoitureMarseille.vue";
import PopValetProTransportVoitureBordeaux from "@/components/PopValetProTransportVoitureBordeaux.vue";
import PopValetProTransportVoitureLille from "@/components/PopValetProTransportVoitureLille.vue";
import PopValetProTransportVoitureNantes from "@/components/PopValetProTransportVoitureNantes.vue";

Vue.use(Router);

let routes

if (window.location.hostname.startsWith('maif')) {
  routes = [
    {
      path: "*",
      component: AccueilMaifPopvalet,
      props: { ...accueilMaifData },
    },
    {
      path: "/comment-ca-marche",
      component: CommentCaMarcheMaifPopvalet,
      props: { ...commentCaMarcheMaifData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletMaifReserverTransportData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralMaifAssuranceData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralMaifCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralMaifContactData },
    },
    {
      path: "/privacy-policy/",
      component: PrivacyPolicyMaifPopValet,
      props: { ...privacyPolicyMaifData },
    }
  ]
} else if (window.location.hostname.startsWith('matmut')) {
  routes = [
    {
      path: "*",
      component: AccueilMaifPopvalet,
      props: { ...accueilMatmutData },
    },
    {
      path: "/comment-ca-marche",
      component: CommentCaMarcheMatmutPopvalet,
      props: { ...commentCaMarcheMatmutData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletMatmutReserverTransportData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralMaifAssuranceData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralMaifCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralMaifContactData },
    },
    {
      path: "/privacy-policy/",
      component: PrivacyPolicyMaifPopValet,
      props: { ...privacyPolicyMatmutData },
    }
  ]
} else {
  routes = [
    {
      path: "*",
      component: PopvaletGeneralAccueil,
      props: { ...popvaletGeneralAccueilData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralAssuranceData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-nos-use-cases",
      redirect: '/nos-use-cases',
    },
    {
      path: "/nos-use-cases",
      component: NosUsesCases,
      props: { ...popvaletGeneralNosUsesCasesData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralContactData },
    },
    {
      path: "/professionnels",
      component: AccueilPopvaletPro,
      props: { ...accueilPopvaletProData },
    },
    {
      path: "/popvalet-pro-services-valet",
      redirect: '/transport-avec-chauffeur',
    },
    {
      path: "/popvalet-pro-services-valet-livraison",
      redirect: '/livraison-voiture',
    },
    {
      path: "/popvalet-pro-services-valet-convoyage",
      redirect: '/convoyage-voiture',
    },
    {
      path: "/popvalet-pro-services-valet-jockeyage",
      redirect: '/jockeyage-voiture',
    },
    {
      path: "/transport-avec-chauffeur",
      component: PopvaletProServicesValet,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/livraison-voiture",
      component: PopValetProServicesValetLivraison,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/convoyage-voiture",
      component: PopValetProServicesValetConvoyage,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/jockeyage-voiture",
      component: PopValetProServicesValetJockeyage,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/popvalet-pro-services-expertise",
      redirect: '/expertise',
    },
    {
      path: "/popvalet-pro-services-expertise-info",
      redirect: '/expertise-vehicules-professionnels',
    },
    {
      path: "/expertise",
      component: PopvaletProServicesExpertise,
      props: { ...popvaletProServicesExpertiseData },
    },
    {
      path: "/expertise-vehicules-professionnels",
      component: PopvaletProServicesExpertiseInfo,
      props: { ...popvaletProServicesExpertiseData },
    },
    {
      path: "/popvalet-pro-services-camion",
      redirect: '/transport-voiture-camion',
    },
    {
      path: "/popvalet-pro-services-camion-porte8",
      redirect: '/transport-vehicule-porte8',
    },
    {
      path: "/popvalet-pro-services-camion-depanneuse",
      redirect: '/transport-vehicule-depanneuse',
    },
    {
      path: "/transport-voiture-camion",
      component: PopvaletProServicesCamion,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/transport-vehicule-porte8",
      component: PopvaletProServicesCamionPorte8,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/transport-vehicule-depanneuse",
      component: PopvaletProServicesCamionDepanneuse,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/popvalet-pro-services-reparation",
      redirect: '/reparation',
    },
    {
      path: "/popvalet-pro-services-reparation-info",
      redirect: '/reparation-vehicules-professionnels',
    },
    {
      path: "/reparation",
      component: PopvaletProServicesReparation,
      props: { ...popvaletProServicesReparationData },
    },
    {
      path: "/reparation-vehicules-professionnels",
      component: PopvaletProServicesReparationInfo,
      props: { ...popvaletProServicesReparationData },
    },
    {
      path: "/popvalet-pro-services-restitution",
      redirect: '/restitution',
    },
    {
      path: "/popvalet-pro-services-restitution-info",
      redirect: '/restitution-vehicules-professionnels',
    },
    {
      path: "/restitution",
      component: PopvaletProServicesRestitution,
      props: { ...popvaletProServicesRestitutionData },
    },
    {
      path: "/restitution-vehicules-professionnels",
      component: PopvaletProServicesRestitutionInfo,
      props: { ...popvaletProServicesRestitutionData },
    },
    // {
    //   path: "/convoyage-residence-secondaire",
    //   component: PopvaletLandingPage,
    //   props: { ...popvaletLandingPageData },
    // },
    {
      path: "/particuliers",
      component: PopvaletParticulierAccueil,
      props: { ...popvaletParticulierAccueilData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletReserverTransportData },
    },
    {
      path: "/transport-voiture",
      component: PopValetProTransportVoiture,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-vehicule-plateau",
      component: PopValetProTransportVehiculePlateau,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-remorque",
      component: PopValetProTransportVoitureRemorque,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-location",
      component: PopValetProTransportVoitureLocation,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-luxe",
      component: PopValetProTransportVoitureLuxe,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-collection",
      component: PopValetProTransportVoitureCollection,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-europe",
      component: PopValetProTransportVoitureEurope,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-france",
      component: PopValetProTransportVoitureFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-allemagne-france",
      component: PopValetProTransportVoitureAllemagneFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-italie-france",
      component: PopValetProTransportVoitureItalieFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-espagne-france",
      component: PopValetProTransportVoitureEspagneFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-suede-france",
      component: PopValetProTransportVoitureSuedeFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-belgique-france",
      component: PopValetProTransportVoitureBelgiqueFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-paris",
      component: PopValetProTransportVoitureParis,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-lyon",
      component: PopValetProTransportVoitureLyon,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-marseille",
      component: PopValetProTransportVoitureMarseille,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-bordeaux",
      component: PopValetProTransportVoitureBordeaux,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-lille",
      component: PopValetProTransportVoitureLille,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-nantes",
      component: PopValetProTransportVoitureNantes,
      props: { ...popvaletProCommonData },
    },
  ]
}

export default new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0};
  },
  routes
});
