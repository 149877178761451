<template>
  <div class="maif-rapatrier-vehicule-section">
    <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow">Pourquoi faire rapatrier</span> votre véhicule ?</h2>
    <p class="maif-rapatrier-vehicule-texte isidorasansalt-semi-bold-magic-potion-19px">La Matmut a choisi POP VALET en raison de ses méthodes et de son expertise pour vous proposer des services de rapatriement de véhicule. Les coûts de cette prestation sont partiellement pris en charge grâce à la garantie d'assistance incluse dans votre contrat automobile Matmut.</p>
    <div class="rapatrier-reserver-cta-button-container">
      <a class="rapatrier-reserver-cta-button yellow" href="/reserver-votre-transport">Réserver</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocRapatrierVotreVehiculeMatmut",
  props: [
    'assuranceName'
  ],
}
</script>

<style>
.rapatrier-reserver-cta-button-container {
  margin-top: 50px;
}
.rapatrier-reserver-cta-button {
  color: var(--bluenavy);
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  justify-content: center;
  padding: 15px 50px;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  letter-spacing: 0;
  margin: 0 auto;
}
.maif-rapatrier-vehicule-texte {
  margin-top: 50px;
  line-height: 28px;
}
.maif-rapatrier-vehicule-section {
  margin: 100px 135px;
}
@media screen and (max-width: 991px) {
  .maif-rapatrier-vehicule-section {
    margin: 30px 15px;
  }
}
</style>