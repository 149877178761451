<template>
  <div class="header-container">

    <header class="header">
      <router-link to="/">
        <img class="popvalet-logo" src="../../../img/header-composant-2@2x.png" alt="Pop Valet" />
      </router-link>

      <div class="nav-item-container">
        <template v-for="navItem in navItems">

          <dropdown v-if="navItem.type === 'dropdown'" class-name="pv-dropdown">
            <template slot="btn">
              <div class="nav-item">
                <div class="nav-item-text blogtextbold">{{navItem.name}}</div>
              </div>
            </template>
            <template slot="body" >
              <common-dropdown-body :items="navItem.items"/>
            </template>
          </dropdown>

          <router-link v-if="navItem.type === 'link'" :to="navItem.path" style="text-decoration: none">
            <div class="nav-item">
              <div class="nav-item-text blogtextbold">{{navItem.name}}</div>
            </div>
          </router-link>

        </template>
      </div>

      <div class="cta-container">
        <template v-for="button in ctaButtons">
          <a v-if="button.externalPath" :href="button.externalPath" style="text-decoration: none">
            <div class="cta-button blogtextbold" :class="[button.color]">
              {{button.name}}
            </div>
          </a>
          <router-link v-if="button.internalPath" :to="button.internalPath" style="text-decoration: none">
            <div class="cta-button blogtextbold" :class="[button.color]">
              {{button.name}}
            </div>
          </router-link>
          <div v-if="button.pageAnchor"
               class="cta-button blogtextbold"
               :class="[button.color]"
               @click="scrollToPageAnchor(button.pageAnchor)">
            {{button.name}}
          </div>
        </template>
      </div>

      <div class="mobile-nav-button">
        <dropdown class-name="pv-mobile-dropdown" :abs-x="0" :abs-y="68">
          <template slot="icon">
            <img src="../../../img/content---bars.svg" alt="Menu" />
          </template>
          <template slot="body">
            <div class="mobile-nav-container">

              <template v-for="navItem in navItems">

                <dropdown v-if="navItem.type === 'dropdown'" class-name="pv-static-dropdown">
                  <template slot="btn">
                    <div class="mobile-nav-item">
                      <div class="mobile-nav-item-text blogtextbold">{{navItem.name}}</div>
                    </div>
                  </template>
                  <template slot="body" >
                    <common-dropdown-body :items="navItem.items"/>
                  </template>
                </dropdown>

                <router-link v-if="navItem.type === 'link'" :to="navItem.path" style="text-decoration: none">
                  <div class="mobile-nav-item">
                    <div class="mobile-nav-item-text blogtextbold">{{navItem.name}}</div>
                  </div>
                </router-link>

              </template>

              <template v-for="button in ctaButtons">
                <a v-if="button.externalPath" :href="button.externalPath" style="text-decoration: none">
                  <div class="cta-button blogtextbold" :class="[button.color]">
                    {{button.name}}
                  </div>
                </a>
                <router-link v-if="button.internalPath" :to="button.internalPath" style="text-decoration: none">
                  <div class="cta-button blogtextbold" :class="[button.color]">
                    {{button.name}}
                  </div>
                </router-link>
                <div v-if="button.pageAnchor"
                     class="cta-button blogtextbold"
                     :class="[button.color]"
                     @click="scrollToPageAnchor(button.pageAnchor)">
                  {{button.name}}
                </div>
              </template>

            </div>
          </template>
        </dropdown>
      </div>

    </header>

  </div>
</template>

<script>
import CommonDropdownBody from "@/components/common/CommonDropdownBody";
import Dropdown from '@/components/common/CommonDropdown';
export default {
  name: "CommonHeader",
  props: [
    'navItems',
    'ctaButtons'
  ],
  components: {
    CommonDropdownBody,
    Dropdown
  },
  mounted() {
    this.checkForAnchorInUrl();
  },
  methods: {
    scrollToPageAnchor: (anchor) => {
      document.querySelector(anchor).scrollIntoView({
        behavior: 'smooth'
      });
    },
    checkForAnchorInUrl() {
      const hash = window.location.hash;
      if (hash === '#devenez-client') {
        setTimeout(() => {
          this.scrollToPageAnchor(hash);
        }, 500)
      }
    }
  }
}
</script>

<style scoped>
.cta-button.blogtextbold.bluenavy:hover {
  background-color: var(--yellow);
  color: var(--bluenavy);
}

.cta-button.blogtextbold.yellow:hover {
  background-color: #F4C300;
  color: var(--bluenavy);
}

.cta-button.blogtextbold.violetclair:hover {
  background-color: #6C63F8;
  color: var(--bluenavy);
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  min-height: 84px;
}

.header {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 120px;
}

.popvalet-logo {
  height: 35px;
  width: 182px;
}

.nav-item-container {
  display: flex;
  flex-shrink: 2;
  justify-content: flex-end;
  gap: 12px;
}

.nav-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 12px;
}

.nav-item-text {
  color: var(--bluenavy);
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.cta-container {
  display: flex;
  gap: 32px;
}

.cta-button {
  align-items: center;
  background-color: var(--bluenavy);
  border-radius: 34px;
  display: flex;
  height: 44px;
  justify-content: center;
  padding: 12px 38px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}

.bluenavy {
  background-color: var(--bluenavy);
  color: var(--blanc);
}
.yellow {
  background-color: var(--yellow);
  color: var(--bluenavy);
}
.violetclair {
  background-color: var(--violetclair);
  color: var(--bluenavy);
}

.mobile-nav-button {
  display: none;
}

.mobile-nav-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mobile-nav-item {
  padding: 12px;
}

.mobile-nav-item-text {
  color: var(--bluenavy);
  font-weight: 500;
}


@media screen and (max-width: 1400px) {
  .header {
    padding: 20px;
  }

  .nav-item {
    padding: 12px;
  }

  .cta-container {
    gap: 16px;
  }
}

@media screen and (max-width: 576px) {
  .header-container {
    height: 68px;
  }

  .popvalet-logo {
    height: 30px;
    width: 158px;
  }

  .nav-item-container, .cta-container {
    display: none;
  }

  .mobile-nav-button {
    display: initial;
  }

  .nav-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 12px;
  }

  .cta-button {
    margin: 12px;
  }
}
</style>