<template>
  <div class="pro-services-picture-container">
    <img class="pro-services-picture"
         :src="pictureUrl"
         alt="pro-services-picture"/>
  </div>
</template>

<script>
export default {
  name: "ProServicesPicture",
  props: [
      'pictureUrl'
  ]
}
</script>

<style scoped>
.pro-services-picture-container {
  text-align: center;
  width: 100%;
  background: linear-gradient(
      to top,
      var(--bluenavy) 0%,
      var(--bluenavy) 50%,
      white 50%,
      white 100%
  );
  margin-top: 150px;
}

.pro-services-picture {
  max-width: 100%;
  height: auto;
  max-height: 440px;
  padding: 0 10px;
}
</style>