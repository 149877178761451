<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <general-header text="Réactivité forte" :icon-url="require('../../img/stopwatch.png')"/>
    <div class="general-reactivite-description-section">
      <div class="general-reactivite-description-container">
        <h2 class="isidorasansalt-bold-magic-potion-24px">UNE ÉQUIPE DE 32 COLLABORATEURS À VOTRE ÉCOUTE...</h2>
        <p class="isidorasansalt-semi-bold-magic-potion-16px">Pop Valet c'est la solution digitale du transport automobile. Notre savoir-faire : déplacer des véhicules d'un point A à un point B par l'intermédiaire de chauffeurs professionnels et de camions. Au fil du temps, nous avons développer des services complémentaires comme l'expertise, la réparation et la restitutions de véhicules. En insistant sur la qualité de service et la réactivité, nous avons développé notre clientèle avec plus de 550 partenaires automobiles : distributeurs, concession, loueurs et entreprises. Grâce à nos 10 ans d'expérience, nous sommes fiers de transporter +50 000 véhicules par an et de vous proposer une solution complète et 100 % digital.</p><br>
        <a href="/reactivite-transport" style="color: #002244"><p class="isidorasansalt-semi-bold-magic-potion-16px">En savoir plus</p></a>
      </div>
    </div>
    <div class="general-reactivite-organisation-section">
      <h2 class="isidorasansalt-bold-white-28px underline-yellow-organisation">Notre organisation</h2>
      <div class="general-reactivite-organisation-parts">
        <div class="general-reactivite-organisation-part general-reactivite-organisation-margin-part">
          <div class="general-reactivite-organisation-part-circle">
            <img src="../../img/contrat.png" alt="contrat">
          </div>
          <div class="general-reactivite-organisation-part-container">
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px" style="text-transform: uppercase">Sales</p>
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px">Acquisition Nouveaux Clients</p>
            <p class="montserrat-normal-magic-potion-16px">Sourcing et contact de nouveaux prospects</p>
          </div>
        </div>
        <img class="organisation-top-arrow1" src="../../img/organisation-top-arrow.png" alt="organisation_top_arrow">
        <div class="general-reactivite-organisation-part general-reactivite-organisation-margin-part">
          <div class="general-reactivite-organisation-part-circle">
            <img src="../../img/operation.png" alt="operation">
          </div>
          <div class="general-reactivite-organisation-part-container">
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px" style="text-transform: uppercase">Opérations</p>
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px">Dispatch</p>
            <p class="montserrat-normal-magic-potion-16px">Organisation des missions avec le soutien de notre algorithme</p>
          </div>
        </div>
        <img class="organisation-bottom-arrow" src="../../img/organisation-bottom-arrow.png" alt="organisation_bottom_arrow">
        <div class="general-reactivite-organisation-part general-reactivite-organisation-margin-part">
          <div class="general-reactivite-organisation-part-circle">
            <img src="../../img/tel.png" alt="tel">
          </div>
          <div class="general-reactivite-organisation-part-container">
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px" style="text-transform: uppercase">Technique</p>
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px">Support Clients</p>
            <p class="montserrat-normal-magic-potion-16px">Point de contact privilégié de nos clients via nos canaux de communication</p>
          </div>
        </div>
        <img class="organisation-top-arrow2" src="../../img/organisation-top-arrow.png" alt="organisation_top_arrow2">
        <div class="general-reactivite-organisation-part">
          <div class="general-reactivite-organisation-part-circle">
            <img src="../../img/pen.png" alt="pen">
          </div>
          <div class="general-reactivite-organisation-part-container">
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px" style="text-transform: uppercase">Administratif</p>
            <p class="general-reactivite-organisation-part-text montserrat-bold-magic-potion-16px">Support Valets</p>
            <p class="montserrat-normal-magic-potion-16px">Point de contact privilégié de nos valets</p>
          </div>
        </div>
      </div>
    </div>
    <div class="general-reactivite-solutions-section">
      <div class="general-reactivite-solutions-container">
        <div class="general-reactivite-solutions-img">
          <div class="general-reactivite-solutions-stat-container">
            <div class="general-reactivite-solutions-stat-container-position">
              <div class="general-reactivite-solutions-stat-container-circle">
                <img src="../../img/thumb-up.png" alt="thumb_up">
              </div>
              <p class="montserrat-semi-bold-magic-potion-14px"><span class="montserrat-semi-bold-magic-potion-22px">Capacité</span> d'intervenir sur une mission pour le jour-même</p>
            </div>
          </div>
          <img class="general-reactivite-solutions-img-screen" src="../../img/operateur_1.png" alt="">
          <div class="general-reactivite-solutions-stat2-container">
            <div class="general-reactivite-solutions-stat2-container-position">
              <div class="general-reactivite-solutions-stat2-container-circle">
                <img src="../../img/accepted-missions.png" alt="thumb_up">
              </div>
              <p class="montserrat-semi-bold-magic-potion-14px"><span class="montserrat-semi-bold-magic-potion-22px">98 % </span> de missions<br>acceptées instantanément</p>
            </div>
          </div>
        </div>
        <div class="general-reactivite-solutions-txt">
          <img class="general-reactivite-solutions-txt-screen-img" src="../../img/screen.png" alt="">
          <h2 class="isidorasansalt-semi-bold-magic-potion-28px">Algorithmie et technologie</h2>
          <div class="general-reactivite-solutions-all-txt">
            <div class="general-reactivite-solutions-each-txt general-reactivite-solutions-each-txt-margin">
              <img src="../../img/Approve.png" alt="">
              <p class="isidorasansalt-semi-bold-magic-potion-16px">Notre solution technologique développée en interne, permet de définir et d'assembler des briques pour construire un workflow sur mesure pour chaque client</p>
            </div>
            <div class="general-reactivite-solutions-each-txt general-reactivite-solutions-each-txt-margin">
              <img src="../../img/Approve.png" alt="">
              <p class="isidorasansalt-semi-bold-magic-potion-16px">Il n'y a aucune limite de workflow et nous pouvons même les distinguer par entité, par marque ou typologie de client</p>
            </div>
            <div class="general-reactivite-solutions-each-txt general-reactivite-solutions-each-txt-margin">
              <img src="../../img/Approve.png" alt="">
              <p class="isidorasansalt-semi-bold-magic-potion-16px">L'application mobile métier de nos Valets affichera alors le process défini au préalable et les guidera pas à pas dans la réalisation de mission</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-footer :is-maif="isMaif"></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import GeneralHeader from "@/components/GeneralHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "ReactiviteForte",
  components: {
    CommonHeader,
    CommonSurHeader,
    GeneralHeader,
    CommonFooter,
  },
  props: [
    "header",
    "isMaif"
  ]
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .general-reactivite-organisation-parts {
    flex-direction: column;
    align-items: center;
  }
  .general-reactivite-organisation-part {
    min-width: 330px;
    max-width: 330px;
  }
  .organisation-top-arrow1 {
    display: none;
  }
  .organisation-top-arrow2 {
    display: none;
  }
  .organisation-bottom-arrow {
    display: none;
  }
  .general-reactivite-organisation-margin-part {
    margin-right: 0;
    margin-bottom: 100px;
  }
  .general-reactivite-organisation-section {
    padding: 100px 30px 100px 30px;
  }
  .general-reactivite-description-section {
    padding: 90px 30px 90px 30px;
  }
  .general-reactivite-solutions-container {
    display: block;
    padding: 120px 30px 120px 30px;
  }
  .general-reactivite-solutions-img-screen {
    height: 250px;
  }
  .general-reactivite-solutions-img {
    text-align: center;
  }
  .general-reactivite-solutions-stat-container {
    left: 10px;
    top: -40px;
  }
  .general-reactivite-solutions-stat2-container {
    right: 10px;
    bottom: 50px;
  }
  .general-reactivite-solutions-img-screen {
    margin: 50px 0 150px 0;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .general-reactivite-organisation-part {
    min-width: 220px;
    max-width: 220px;
  }
  .organisation-top-arrow1 {
    display: none;
  }
  .organisation-top-arrow2 {
    display: none;
  }
  .organisation-bottom-arrow {
    display: none;
  }
  .general-reactivite-organisation-margin-part {
    margin-right: 20px;
  }
  .general-reactivite-organisation-section {
    padding: 100px 200px 100px 200px;
  }
  .general-reactivite-description-section {
    padding: 90px 250px 90px 250px;
  }
  .general-reactivite-solutions-container {
    display: flex;
    padding: 120px 100px 120px 100px;
  }
  .general-reactivite-solutions-img-screen {
    height: 300px;
    margin-right: 40px;
  }
  .general-reactivite-solutions-stat-container {
    left: -40px;
    top: -50px;
  }
  .general-reactivite-solutions-stat2-container {
    right: 30px;
    bottom: -55px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .general-reactivite-organisation-part {
    min-width: 260px;
    max-width: 260px;
  }
  .organisation-top-arrow1 {
    margin-right: 560px;
  }
  .organisation-top-arrow2 {
    margin-left: 560px;
  }
  .general-reactivite-organisation-margin-part {
    margin-right: 20px;
  }
  .general-reactivite-organisation-section {
    padding: 100px 200px 100px 200px;
  }
  .general-reactivite-description-section {
    padding: 90px 250px 90px 250px;
  }
  .general-reactivite-solutions-container {
    display: flex;
    padding: 120px 150px 120px 150px;
  }
  .general-reactivite-solutions-img-screen {
    height: 450px;
    margin-right: 40px;
  }
  .general-reactivite-solutions-stat-container {
    left: 0;
    top: -15px;
  }
  .general-reactivite-solutions-stat2-container {
    right: 30px;
    bottom: -30px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {

}
@media screen and (min-width: 1441px) {
  .general-reactivite-organisation-part {
    min-width: 285px;
    max-width: 285px;
  }
  .organisation-top-arrow1 {
    margin-right: 610px;
  }
  .organisation-top-arrow2 {
    margin-left: 610px;
  }
  .general-reactivite-organisation-margin-part {
    margin-right: 20px;
  }
  .general-reactivite-organisation-section {
    padding: 100px 200px 100px 200px;
  }
  .general-reactivite-description-section {
    padding: 90px 250px 90px 250px;
  }
  .general-reactivite-solutions-container {
    display: flex;
    padding: 120px 200px 120px 200px;
  }
  .general-reactivite-solutions-img-screen {
    height: 529px;
    margin-right: 40px;
  }
  .general-reactivite-solutions-stat-container {
    left: 0;
    top: -15px;
  }
  .general-reactivite-solutions-stat2-container {
    right: 30px;
    bottom: -15px;
  }
}
.general-reactivite-description-container {
  text-align: center;
}
.general-reactivite-description-container h2 {
  margin-bottom: 50px;
}
.general-reactivite-description-container p {
  line-height: 29px;
}
.general-reactivite-organisation-section {
  background-color: #002244;
}
.general-reactivite-organisation-section h2 {
  margin-bottom: 120px;
}
.underline-yellow-organisation {
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-position: under;
  text-decoration-thickness: 6px;
  text-underline-offset: -9px;
  text-decoration-color: var(--mustard);
}
.general-reactivite-organisation-parts {
  display: flex;
  justify-content: center;
  position: relative;
}
.general-reactivite-organisation-part {
  height: 315px;
  background-color: white;
  align-items: center;
  display: flex;
  border-radius: 8px;
  position: relative;
}
.general-reactivite-organisation-part-container {
  text-align: center;
  padding: 30px;
  margin-top: 80px;
}
.general-reactivite-organisation-part-text {
  margin-bottom: 15px;
}
.general-reactivite-organisation-part-circle {
  height: 115px;
  width: 115px;
  border-radius: 60px;
  background-color: #FADC41;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.general-reactivite-organisation-part-circle img {
  height: 53px;
}
.general-reactivite-solutions-section {
  background-color: #F7F6F4;
}
.general-reactivite-solutions-container {
  align-items: center;
}
.general-reactivite-solutions-each-txt {
  display: flex;
}
.general-reactivite-solutions-each-txt p {
  line-height: 28px;
}
.general-reactivite-solutions-each-txt img {
  height: 20px;
  margin-top: 5px;
  margin-right: 5px;
}
.general-reactivite-solutions-each-txt-margin {
  margin-bottom: 15px;
}
.general-reactivite-solutions-txt h2 {
  margin-bottom: 25px;
}
.general-reactivite-solutions-txt-screen-img {
  margin-bottom: 15px;
  height: 60px;
}
.general-reactivite-solutions-img {
  position: relative;
}
.general-reactivite-solutions-stat-container {
  position: absolute;
  background-color: white;
  width: 310px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}
.general-reactivite-solutions-stat-container-position {
  position: relative;
  padding: 25px 40px 15px 40px;
}
.general-reactivite-solutions-stat-container-position p {
  line-height: 24px;
}
.general-reactivite-solutions-stat-container-circle {
  position: absolute;
  height: 49px;
  width: 49px;
  background-color: #FADC41;
  border-radius: 30px;
  top: -25px;
  left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.general-reactivite-solutions-stat2-container {
  position: absolute;
  background-color: white;
  width: 310px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}
.general-reactivite-solutions-stat2-container-position {
  position: relative;
  padding: 25px 40px 15px 40px;
}
.general-reactivite-solutions-stat2-container-position p {
  line-height: 24px;
}
.general-reactivite-solutions-stat2-container-circle {
  position: absolute;
  height: 49px;
  width: 49px;
  background-color: #FADC41;
  border-radius: 30px;
  top: -25px;
  left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.organisation-top-arrow1 {
  position: absolute;
  top: -40px;
}
.organisation-bottom-arrow {
  position: absolute;
  bottom: -40px;
}
.organisation-top-arrow2 {
  position: absolute;
  top: -40px;
}
</style>