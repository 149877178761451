<template>
  <div :class="[`savoirplus-6`, className || ``]">
    <a :href="link" style="text-decoration: none"><div class="en-savoir-plus montserrat-medium-gunsmoke-18px">{{ children }}</div></a>
  </div>
</template>

<script>
export default {
  name: "VoirTousLesUsesCases2",
  props: ["children", "className", "link"],
};
</script>

<style>
.savoirplus-6 {
  display: flex;
  height: 23px;
  margin-bottom: -10.5px;
  min-width: 158px;
}

.en-savoir-plus {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 158px;
}

.savoirplus-6.voir-tous-les-uses-cases-6,
.savoirplus-6.voir-tous-les-uses-cases-7 {
  display: flex;
  height: 23px;
  left: 168px;
  position: absolute;
  top: 393px;
  width: 158px;
}

.savoirplus-6.savoirplus-3,
.savoirplus-6.savoirplus-4,
.savoirplus-6.savoirplus-5 {
  margin-bottom: unset;
}
</style>
