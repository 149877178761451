<template>
  <div :class="[`logo_tesla`, className || ``]"><img class="logo_tesla-1" :src="src" alt="logo_tesla 1" /></div>
</template>

<script>
export default {
  name: "LogoTesla",
  props: ["src", "className"],
};
</script>

<style>
.logo_tesla {
  background-color: var(--blanc);
  border-radius: 34.5px/34.19px;
  display: flex;
  height: 68px;
  left: 153px;
  position: absolute;
  top: 11px;
  width: 69px;
}

.logo_tesla-1 {
  height: 35.75px;
  margin-left: 21.2px;
  margin-top: 16.3px;
  object-fit: cover;
  width: 26.53px;
}

.logo_tesla.tem_tesla {
  align-items: center;
  left: unset;
  margin-bottom: 0.99px;
  min-width: 69px;
  padding: 0 21.2px;
  position: unset;
  top: unset;
  width: unset;
}

.logo_tesla.tem_tesla .logo_tesla-1 {
  height: 36px;
  margin-left: unset;
  margin-top: unset;
  width: 27px;
}
</style>
