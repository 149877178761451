<template>
  <div class="footer">
    <div class="footer-1">
      <div class="overlap-group-23">
        <div class="flex-row-1">
          <div class="flex-col">
            <div class="logo-adresse">
              <img class="pop_logotype_rvb" src="../../../img/pop-logotype-rvb-3@2x.png" alt="pop_logotype_rvb" />
            </div>
            <div class="avis">
              <div class="etoiles">
                <div class="group-7-13"><div class="text-1 isidorasansalt-semi-bold-white-14px">4,7 / 5</div></div>
                <div class="group-60-2">
                  <div class="group-61-26">
                    <img
                        class="solid-favorite-1"
                        src="../../../img/solid---favorite-1@2x.png"
                        alt="Solid / favorite"
                    />
                    <img
                        class="solid-favorite-1 variant-1"
                        src="../../../img/solid---favorite-1@2x.png"
                        alt="Solid / favorite"
                    />
                    <img
                        class="solid-favorite-1 variant-2"
                        src="../../../img/solid---favorite-1@2x.png"
                        alt="Solid / favorite"
                    />
                    <img
                      class="solid-favorite-1 variant-3"
                      src="../../../img/solid---favorite-1@2x.png"
                      alt="Solid / favorite"
                    />
                    <img
                      class="solid-favorite-1 variant-4"
                      src="../../../img/solid---favorite-1@2x.png"
                      alt="Solid / favorite"
                    />
                  </div>
                </div>
              </div>
              <div class="group-61-4">
                <a href="https://www.avis-verifies.com/avis-clients/popvalet.com" target="_blank" style="color: white; text-decoration: none"><p class="montserrat-normal-white-10px" style="line-height: normal">+ 4 000 AVIS CLIENTS</p></a>
              </div>
            </div>
          </div>
          <div class="aide-contact-questio blogtextbold">
            <span class="span-13 blogtextbold">Aide<br /></span>
            <span class="span-14 montserrat-normal-white-14px">
              <a href="/popvalet-general-contact">Contact</a><br />
              <a href="/popvalet-general-assurance">Assurance</a><br />
              <a href="/popvalet-general-cgu">CGU</a><br />
              <a v-if="isMaif" href="/privacy-policy">Politique de confidentitalité</a><br />
            </span>
          </div>
          <p v-if="!isMaif" class="nos-services-transpo blogtextbold">
            <span class="span-13 blogtextbold">Nos Services<br /></span>
            <span class="span-14 montserrat-normal-white-14px">
              <a href="/transport-avec-chauffeur">Transport par Valet</a><br />
              <a href="/transport-voiture-camion">Transport par Camion</a><br />
              <a href="/expertise">Expertise</a><br />
              <a href="/reparation">Réparation</a><br />
              <a href="/restitution">Restitution</a>
            </span>
          </p>
          <p class="nous-connaitre-qui-s blogtextbold">
            <span class="span-13 blogtextbold">Qui sommes-nous ?<br /></span>
            <span v-if="!isMaif" class="span-14 montserrat-normal-white-14px">
              <a href="/vision">Notre vision</a><br />
              <a href="/qualite">Qualité de service</a><br />
              <a href="/reactivite">Réactivité</a><br />
              <a href="/technologie">Notre technologie</a><br />
              <a href="/nos-use-cases">Nos use cases</a>
            </span>
            <span v-if="isMaif" class="span-14 montserrat-normal-white-14px">
              <a href="/comment-ca-marche">Comment ça marche ?</a><br />
              <a href="/qualite">Qualité de service</a><br />
              <a href="/reactivite">Réactivité</a><br />
              <a href="/vision">Notre vision</a><br />
              <a href="/technologie">Notre technologie</a><br />
            </span>
          </p>
          <div v-if="isMaif" class="footer-reserver">
            <a class="reserver-cta-button yellow blogtextbold" href="/reserver-votre-transport">Réserver</a>
          </div>
        </div>
        <div class="overlap-group1"><div class="copyright montserrat-normal-white-14px">© 2023 par Popvalet</div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonFooter",
  props: [
    'isMaif',
  ],
}
</script>

<style>
@media screen and (max-width: 991px) {
  .footer {
    width: 100%;
    height: unset;
  }
  .overlap-group-23 {
    width: 100%;
  }
  .footer-1 {
    min-width: 100%;
  }
  .flex-row-1 {
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 40px;
    margin-right: 0;
  }
  .aide-contact-questio {
    margin-left: 0;
    text-align: center;
  }
  .footer-reserver {
    margin-left: 0;
    text-align: center;
  }
  .nos-services-transpo {
    margin-left: 0;
    text-align: center;
  }
  .nous-connaitre-qui-s {
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .footer {
    width: 100%;
    height: 328px;
  }
  .overlap-group-23 {
    width: 100%;
  }
  .footer-1 {
    min-width: 100%;
  }
  .overlap-group1 {
    width: 100%;
    justify-content: center;
  }
  .flex-row-1 {
    align-items: flex-start;
    height: 191px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .flex-row-1 {
    justify-content: center;
  }
  .aide-contact-questio {
    margin-left: 60px;
  }
  .footer-reserver {
    margin-left: 60px;
  }
  .nos-services-transpo {
    margin-left: 60px;
  }
  .nous-connaitre-qui-s {
    margin-left: 60px;
  }
  .flex-row-1 {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .aide-contact-questio {
    margin-left: 120px;
  }
  .footer-reserver {
    margin-left: 122px;
  }
  .nos-services-transpo {
    margin-left: 122px;
  }
  .nous-connaitre-qui-s {
    margin-left: 122px;
  }
  .flex-row-1 {
    margin-right: 82px;
  }
}

.footer-reserver .reserver-cta-button {
  color: var(--bluenavy);
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  background-color: var(--yellow);
  border-radius: 34px;
  display: flex;
  justify-content: center;
  padding: 15px 38px;
  cursor: pointer;
  width: fit-content;
  letter-spacing: 0;
  margin: 0 auto;
}

.footer-reserver .reserver-cta-button:hover {
  background-color: #F4C300;
  color: var(--bluenavy);
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer {
  display: flex;
}

.footer-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
}

.overlap-group-23 {
  align-items: center;
  background-image: url(../../../img/fond-bleu-footer.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 42px;
  min-height: 328px;
}

.flex-row-1 {
  display: flex;
  margin-top: 47px;
}

.flex-col {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-bottom: 2.55px;
  min-height: 183px;
  width: 185px;
}

.logo-adresse {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  /*min-height: 110px;*/
  width: 185px;
}

.pop_logotype_rvb {
  height: 34px;
  width: 183px;
}

.x54-rue-de-londres-75008-paris {
  color: var(--blanc);
  font-weight: 400;
  line-height: normal;
  min-height: 47px;
  text-align: center;
  width: 183px;
}

.avis {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 1px;
  min-height: 47px;
  width: 106px;
}

.etoiles {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 0.7px;
  min-height: 30px;
  position: relative;
  width: 67px;
}

.group-7-13 {
  display: flex;
  height: 19px;
  margin-left: 0.3px;
  width: 69px;
}

.text-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 67px;
}

.group-61-4 {
  display: flex;
  height: 12px;
  margin-left: 2px;
  width: 115px;
}

.x2-800-avis-clients-2 {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 106px;
}

.aide-contact-questio {
  color: var(--blanc);
  font-weight: 400;
  line-height: 28px;
  min-height: 140px;
  width: 183px;
}

.span-13 {
  font-weight: 500;
}

.span-14 {
  letter-spacing: 0;
}

.footer-reserver {
  font-weight: 400;
  min-height: 174px;
  width: 183px;
  display: flex;
  align-items: center;
}

.nos-services-transpo {
  color: var(--blanc);
  font-weight: 400;
  line-height: 28px;
  min-height: 174px;
  width: 183px;
}

.nous-connaitre-qui-s {
  color: var(--blanc);
  font-weight: 400;
  line-height: 28px;
  min-height: 191px;
  width: 203px;
}

.overlap-group1 {
  align-items: center;
  background-image: url(../../../img/fond-bleu-footer2.png);
  background-size: 100% 100%;
  display: flex;
  height: 48px;
  width: 100%;
}

.copyright {
  letter-spacing: 0;
  line-height: normal;
  min-height: 16px;
  text-align: center;
  width: 100%;
}

.footer.footer-4 .logo-adresse,
.footer.footer-10 .logo-adresse,
.footer.footer-14 .logo-adresse,
.footer.footer-16 .logo-adresse {
  padding: 0px 0;
}

.footer.footer-4 .avis,
.footer.footer-4 .x2-800-avis-clients-2 {
  width: 108px;
}

.footer.footer-4 .etoiles {
  margin-left: 8.01px;
  width: 75px;
}

.footer.footer-4 .group-7-13 {
  width: 76px;
}

.footer.footer-4 .text-1 {
  width: 74.3px;
}

.footer.footer-4 .group-61-4 {
  width: 115px;
}

.footer.footer-14 {
  margin-top: 108px;
}

.footer.footer-16 {
  margin-top: 102px;
}

.footer.footer-18 {
  margin-top: 197px;
}

.group-60-2 {
  display: flex;
  height: 9px;
  width: 63px;
}

.group-61-26 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 4px;
  min-width: 63.39px;
  padding: 0 0px;
}

.solid-favorite-1 {
  height: 9px;
  margin-top: 0;
  width: 9px;
}

.group-60-2.group-60-13 .group-61-26,
.group-60-2.group-60-15 .group-61-26,
.group-60-2.group-60-16 .group-61-26,
.group-60-2.group-60-18 .group-61-26 {
  padding: unset;
}

.group-60-2.group-60-14 .group-61-26 {
  align-items: center;
  justify-content: center;
  padding: unset;
}

.group-60-2.group-60-14 .solid-favorite-1,
.group-60-2.group-60-17 .solid-favorite-1,
.group-60-2.group-60-19 .solid-favorite-1,
.group-60-2.group-60-20 .solid-favorite-1,
.group-60-2.group-60-21 .solid-favorite-1 {
  margin-top: unset;
}

.group-60-2.group-60-17 .group-61-26,
.group-60-2.group-60-19 .group-61-26,
.group-60-2.group-60-20 .group-61-26 {
  align-items: center;
}

.group-60-2.group-60-21 .group-61-26 {
  align-items: center;
  padding: unset;
}

.group-60-2.group-60-14 .solid-favorite-1.variant-1,
.group-60-2.group-60-14 .solid-favorite-1.variant-2,
.group-60-2.group-60-14 .solid-favorite-1.variant-3,
.group-60-2.group-60-14 .solid-favorite-1.variant-4,
.group-60-2.group-60-17 .solid-favorite-1.variant-1,
.group-60-2.group-60-17 .solid-favorite-1.variant-2,
.group-60-2.group-60-17 .solid-favorite-1.variant-3,
.group-60-2.group-60-17 .solid-favorite-1.variant-4,
.group-60-2.group-60-19 .solid-favorite-1.variant-1,
.group-60-2.group-60-19 .solid-favorite-1.variant-2,
.group-60-2.group-60-19 .solid-favorite-1.variant-3,
.group-60-2.group-60-19 .solid-favorite-1.variant-4,
.group-60-2.group-60-20 .solid-favorite-1.variant-1,
.group-60-2.group-60-20 .solid-favorite-1.variant-2,
.group-60-2.group-60-20 .solid-favorite-1.variant-3,
.group-60-2.group-60-20 .solid-favorite-1.variant-4,
.group-60-2.group-60-21 .solid-favorite-1.variant-1,
.group-60-2.group-60-21 .solid-favorite-1.variant-2,
.group-60-2.group-60-21 .solid-favorite-1.variant-3,
.group-60-2.group-60-21 .solid-favorite-1.variant-4 {
  height: 9px;
  width: 9px;
}
</style>