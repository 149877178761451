<template>
  <div>
    <common-sur-header />
    <common-header
        :nav-items="header.navItems"
        :cta-buttons="header.ctaButtons"
    />
    <pro-services-header text="Transport en camion plateau (porte 1) ou dépanneuse" :icon-url="require('../../img/truck.png')"/>
    <div class="pro-services-intro-container">
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Les solutions de <a href="/transport-voiture-camion" style="color: #002244">transport par camion</a> imaginées et mises en place par Pop Valet, ce sont aussi des camions plateau et des dépanneuses à votre disposition pour déplacer vos véhicules roulants, non roulants, neufs, d’occasion ou encore de collection.</p><br>
      <p class="intro-text isidorasansalt-semi-bold-magic-potion-16px">Délais, garanties, lieux de retrait et de livraison : découvrez les possibilités qui s’offrent à vous pour vos convoyages en toute sécurité.</p>
    </div>
    <div class="pro-services-camion-depanneuse-definition-container">
      <h2 class="isidorasansalt-bold-white-28px">Camion plateau porte 1, dépanneuse : de quoi parle-t-on ?</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Que désignent exactement les porteurs porte 1 et les dépanneuses ? Explications signées Pop Valet.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Camion plateau porte 1 : définition</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Un camion plateau désigne un poids-lourd dont le châssis est recouvert par un plateau. La dénomination porte 1 est apposée à ce genre de camions lorsqu’ils sont conçus pour porter et transporter un unique véhicule. Certains camions plateau sont surbaissés, surélevés ou extensibles en fonction du type de véhicules qu’ils transportent.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Dépanneuse : définition</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Une dépanneuse est un véhicule muni d’un système de levage et de remorquage, dont la vocation est de ramener à l’atelier de dépannage un véhicule immobilisé.</p>
    </div>
    <div class="pro-services-camion-depanneuse-transport-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Le déroulé de votre transport de véhicule en camion plateau ou en dépanneuse</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">La première étape est entre vos mains. Vous vous rendez sur notre interface numérique et, en quelques minutes, vous nous indiquez :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Le type de véhicule à transporter (marque, modèle, immatriculation)</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- L’adresse de départ et l’adresse d’arrivée ainsi que les contacts sur place</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Les dates de collecte ou d’arrivée</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Pop Valet prend ensuite la main sur tout le reste.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">La collecte de votre véhicule assurée par Pop Valet</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Une fois rendu sur le lieu de collecte, notre chauffeur établit avec vous une lettre de voiture, document légalement obligatoire avant toute prise de véhicule. Après l’étape administrative vient celle de l’arrimage de votre véhicule sur notre plateau porte-voiture. Notre transporteur s’assure que la carrosserie est centrée et stable. Il utilise ensuite les sangles, crochets et attaches de sécurité pour ancrer solidement les roues avant et arrière sur le plateau.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">À noter : le processus déployé par notre chauffeur varie selon que votre véhicule est roulant ou non roulant.</p>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Une technologie éprouvée pour suivre en direct le trajet de votre voiture</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Une fois sur la route, priorité à l’écoconduite et à la sécurité : Pop Valet s’aligne sur la durée et la fréquence des pauses édictées par le Code de transports. Depuis votre téléphone portable, votre tablette ou votre ordinateur, vous suivez en direct l’itinéraire et le positionnement de votre voiture grâce à notre interface.</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Arrivé à bon port, notre chauffeur décharge votre véhicule :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Soit à l’adresse que vous aurez préalablement renseignée</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Soit à proximité directe si le périmètre ne permet pas le déchargement du véhicule</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Une couverture territoriale dans toute la France et en Europe</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Une voiture à transporter en camion porte 1 ou en dépanneuse ? Pop Valet répond présent dans toute la France métropolitaine ainsi qu’en Corse, mais aussi au départ de ou en direction de toute l’Europe.</p>
    </div>
    <div class="pro-services-camion-depanneuse-raisons-container">
      <h2 class="isidorasansalt-bold-white-28px">Quatre bonnes raisons de passer par Pop Valet pour votre plateau porte-voiture</h2><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous cherchiez une raison de tester la solution Pop Valet ? En voilà quatre.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Votre véhicule doit être réparé</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Votre véhicule est en panne ou a subi un accident de la route et, conséquemment, doit être transporté jusqu’au garage le plus proche. La méthode que nous proposons permet de ne pas solliciter vos propres moyens, à plus forte raison si le véhicule n’est plus en état de rouler.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Votre véhicule est une voiture de collection ou de prestige</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Voiture de collection, de sport, type GT ou supercar : il y a des modèles que l’on a envie de protéger de tous les risques inhérents aux départementales, nationales et autres autoroutes… La solution Pop Valet vous assure un convoyage en toute sérénité.</p><br>

      <h3 class="isidorasansalt-bold-white-24px">Votre véhicule fait partie d’une flotte professionnelle</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">Vous exercez votre métier dans un garage, une concession automobile, une agence de location ou tout autre lieu dédié à l’auto-moto ? Vous connaissez alors mieux qui quiconque les impératifs inhérents au déplacement régulier de vos voitures. Passer par un camion plateau Pop Valet vous permet de minimiser les risques d’endommager vos véhicules et de vous tranquilliser l’esprit.</p><br>
      <h3 class="isidorasansalt-bold-white-24px">Votre véhicule est électrique avec une autonomie limitée</h3><br>
      <p class="isidorasansalt-semi-bold-white-18px">À ce jour, l’autonomie de la plupart des voitures électriques ne permet pas à celles-ci d’être convoyées sur de longues distances. Pour ne pas dépendre de bornes à la disponibilité aléatoire et ne pas s’imposer de nombreux arrêts, qui rallongent immanquablement le temps de trajet, le transport par plateau s’impose naturellement.</p>
    </div>
    <div class="pro-services-camion-depanneuse-faq-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Transport en dépanneuse ou camion porte 1 : FAQ</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous avez une question ? Pop Valet a la réponse.</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Quels sont les véhicules que je peux faire transporter en camion porte 1 ou en dépanneuse ?</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Nos chauffeurs déploient leur savoir-faire pour une large gamme de quatre-roues : les voitures (citadine, berline, break, monospace, coupé, cabriolet, pick-up, 4x4, crossover…) nécessitant ou ne nécessitant pas de permis de conduire, les petits ou les gros utilitaires (jusqu’à 20m3), les vans, les camping-cars…</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">À quels délais m’attendre pour des transferts en porte 1 ou en dépanneuse ?</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Les équipes Pop Valet sont fières de proposer des temps d’exécution particulièrement réduits. Comptez :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Un délai à partir de 48 heures pour un transport en camion plateau porte 1</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Quelques heures seulement pour un transport urgent en dépanneuse</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Comment mes voitures sont-elles garanties sur les camions et dépanneuses Pop Valet ?</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Dès le chargement et jusqu’à la livraison, votre voiture est sous notre entière responsabilité. Grâce à la MAIF et Generali, nos partenaires, nous sommes couverts par les garanties suivantes :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- RC automobile</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- RC professionnelle</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Dommages tous accidents</p><br>

      <h3 class="isidorasansalt-bold-magic-potion-24px">Quels sont les facteurs entrant en jeu pour calculer le tarif de mon transport en camion plateau ou en dépanneuse ?</h3><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Les critères pris en compte pour établir le devis le plus juste et compétitif sont :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- La distance séparant le point de collecte du point de livraison</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Le type de véhicule à transporter : citadine, monospace, utilitaire…</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- L’état du véhicule à convoyer : roulant ou non roulant</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Une promesse : vous présenter pour chaque contrat le prix final, et le prix final uniquement. Zéro frais cachés ni surcoût à la livraison.</p>
    </div>
    <div class="pro-services-camion-depanneuse-plus-loin-container">
      <h2 class="isidorasansalt-bold-magic-potion-28px">Aller plus loin avec Pop Valet</h2><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">Vous souhaitez :</p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Faire déplacer plusieurs véhicules, roulants ou non roulants, simultanément : rendez-vous sur notre service de <a href="/transport-vehicule-porte8" style="color: #002244">camion porte 8 voitures</a></p><br>
      <p class="isidorasansalt-semi-bold-magic-potion-18px">- Bénéficier de solutions telles que la mise en main ou la gestion documentaire : rendez-vous sur nos services de <a href="/transport-avec-chauffeur" style="color: #002244">transport par Valet</a></p>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import CommonSurHeader from "@/components/common/CommonSurHeader";
import ProServicesHeader from "@/components/ProServicesHeader";
import CommonFooter from "@/components/common/CommonFooter";

export default {
  name: "PopvaletProServicesCamionDepanneuse",
  components: {
    CommonHeader,
    CommonSurHeader,
    ProServicesHeader,
    CommonFooter,
  },
  props: [
    'header',
  ],
  metaInfo: {
    title: 'Transport en Camion Plateau (Porte 1) / Dépanneuse | Pop Valet',
    meta: [
      { name: 'description', content: 'Déplacez vos véhicules roulants, non roulants, neufs, d’occasion ou de collection grâce à notre flotte de camions plateau et de dépanneuses.' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.popvalet.com/transport-vehicule-depanneuse' }
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Accueil",
              "item": "https://www.popvalet.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Transport de voiture par camion",
              "item": "https://www.popvalet.com/transport-voiture-camion"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Transport en camion plateau (porte 1) ou dépanneuse",
              "item": "https://www.popvalet.com/transport-vehicule-depanneuse"
            }
          ]
        }
      }
    ]
  }
}
</script>

<style scoped>
.pro-services-camion-depanneuse-plus-loin-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-depanneuse-plus-loin-container {
  background-color: var(--grisclair);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-depanneuse-faq-container h3 {
  margin-top: 25px;
}
.pro-services-camion-depanneuse-faq-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-depanneuse-faq-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-depanneuse-raisons-container h3 {
  margin-top: 25px;
}
.pro-services-camion-depanneuse-raisons-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-depanneuse-raisons-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-depanneuse-transport-container h3 {
  margin-top: 25px;
}
.pro-services-camion-depanneuse-transport-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-depanneuse-transport-container {
  padding: 100px 150px;
  line-height: 29px;
}
.pro-services-camion-depanneuse-definition-container h2 {
  margin-bottom: 30px;
  text-align: center;
}
.pro-services-camion-depanneuse-definition-container {
  background-color: var(--bluenavy);
  padding: 100px 150px;
  line-height: 29px;
}
.intro-text {
  max-width: 1000px;
  margin: auto;
  letter-spacing: 0;
  line-height: 28.8px;
  text-align: center;
  padding: 0 20px;
}
.pro-services-intro-container {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

@media screen and (max-width: 991px) {
  .pro-services-camion-depanneuse-plus-loin-container {
    padding: 100px 50px;
  }
  .pro-services-camion-depanneuse-faq-container {
    padding: 100px 50px;
  }
  .pro-services-camion-depanneuse-raisons-container {
    padding: 100px 50px;
  }
  .pro-services-camion-depanneuse-transport-container {
    padding: 100px 50px;
  }
  .pro-services-camion-depanneuse-definition-container {
    padding: 100px 50px;
  }
}
</style>